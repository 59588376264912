const React = require('react');
const TextField = require('./TextField');
const DateField = require('./DateField');
const BooleanField = require('./BooleanField');
const CheckboxesField = require('./CheckboxesField');
const RadioField = require('./RadioField');
const SelectField = require('./SelectField');
const RatingField = require('./RatingField');
const Divider = require('./Divider');
const Paragraph = require('./Paragraph');

module.exports = {
    text: {
        component: (props) => <TextField {...props} />
    },
    date: {
        component: (props) => <DateField {...props} />
    },
    boolean: {
        component: (props) => <BooleanField {...props} />
    },
    checkboxes: {
        component: (props) => <CheckboxesField {...props} />
    },
    radio: {
        component: (props) => <RadioField {...props} />
    },
    select: {
        component: (props) => <SelectField {...props} />
    },
    rating: {
        component: (props) => <RatingField {...props} />
    },
    divider: {
        component: (props) => <Divider {...props} />
    },
    paragraph: {
        component: (props) => <Paragraph {...props} />
    }
};
