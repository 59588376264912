/**
 * Simple helper function for comparing two sets of values within a basic object
 * @param {object} props1
 * @param {object} props2
 * @return {boolean}
 */
module.exports = (props1, props2) => {

    if (!props1 || !props2) {

        return false;
    }

    // Warning! This is not 100% supported in IE11 - do we need to support this?
    const values1 = Object.values(props1);
    const values2 = Object.values(props2);

    // If the arrays of values are different lengths, we already know values are different, return out.
    if ((values1 && values2) && values1.length !== values2.length) {

        return false;
    }

    for (let i = 0; i < values1.length; i += 1) {

        if (values1[i] !== values2[i]) {

            return false;
        }
    }

    return true;
};
