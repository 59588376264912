const React = require('react');
const { default: Styled } = require('styled-components');
const { default: Divider } = require('@material-ui/core/Divider');

const internals = {};

module.exports = function DividerType() {

    const { StyledDivider } = internals;

    return (
        <StyledDivider />
    );
};

internals.StyledDivider = Styled(Divider)`
    margin: ${({ theme }) => theme.spacing(2)}px 0;
`;
