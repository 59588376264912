const React = require('react');
const T = require('prop-types');
const ErrorFallback = require('./ErrorFallback');
const LoadingFallback = require('./LoadingFallback');
const { default: Styled } = require('styled-components');
const { default: ErrorBoundary } = require('react-error-boundary');
const AppSnackbar = require('../containers/Snackbar');

const internals = {};

module.exports = class Layout extends React.Component {

    static propTypes = {
        children: T.any,
        location: T.shape({
            key: T.string
        })
    };

    componentDidMount() {

    }

    render() {

        const { Container, AppContainer } = internals;
        const { children, location } = this.props;

        return (
            <AppContainer>
                <Container>
                    <ErrorBoundary key={location.key} FallbackComponent={ErrorFallback}>
                        <React.Suspense fallback={<LoadingFallback />}>
                            {children}
                        </React.Suspense>
                    </ErrorBoundary>
                </Container>
                <AppSnackbar />
            </AppContainer>
        );
    }
};

internals.Container = Styled.div`
    display: flex;
    flex: 1;
`;

internals.AppContainer = Styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;
