const Connect = require('react-redux').connect;
const EmailScreeningResultsPage = require('../components/EmailScreeningResults');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        screenings: M.selectors.model.fetchProgramScreeningsWithResults(state)
    }),
    (dispatch, props) => ({
        fetchScreenings: () => M.dispatch.model.fetchProgramScreeningsWithResults(props.match.params.id),
        onSubmit: async (screeningIds) => {

            const [err] = await M.dispatch.model.sendResultsEmails({
                programId: props.match.params.id,
                screeningIds
            });

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Emails sent successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err];
        }
    })
);

module.exports = internals.connect(EmailScreeningResultsPage);
