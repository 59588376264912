/* eslint-disable react-hooks/exhaustive-deps */
const React = require('react');
const { useState } = require('react');
const T = require('prop-types');
const { default: RichTextEditor } = require('react-rte');
const FieldHeader = require('./FieldHeader');
const { Field } = require('./styles');

const internals = {};

module.exports = function Paragraph(props) {

    const { field, onChange } = props;

    const [isEditing, setIsEditing] = useState(false);
    const [rteValue, setRteValue] = useState(() => RichTextEditor.createValueFromString(field.value, 'html'));

    const onRteChange = (value) => {

        setRteValue(value);
        onChange(field, { value: value.toString('html') });
    };

    return (
        <Field isEditing={isEditing}>
            <FieldHeader
                display={() => <>Paragraph</>}
                toggleEdit={() => setIsEditing(!isEditing)}
                {...props}
            />
            {isEditing && (
                <RichTextEditor
                    value={rteValue}
                    onChange={onRteChange}
                    toolbarConfig={internals.toolbarConfig}
                />
            )}
        </Field>
    );
};

module.exports.propTypes = {
    field: T.object.isRequired,
    onChange: T.func.isRequired,
    onDelete: T.func.isRequired
};

internals.toolbarConfig = {
    display: ['BLOCK_TYPE_DROPDOWN', 'INLINE_STYLE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_BUTTONS'],
    BLOCK_TYPE_DROPDOWN: [
        { label: 'Normal', style: 'unstyled' },
        { label: 'Heading Large', style: 'header-one' },
        { label: 'Heading Medium', style: 'header-two' },
        { label: 'Heading Small', style: 'header-three' }
    ],
    INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' }
    ]
};
