const React = require('react');
const AlertDialog = require('../../components/AlertDialog');
const M = require('../../middle-end');
const Debounce = require('../../utils/debounce');

const internals = {};

const TIMEOUT_INTERVAL = 15 * 60 * 1000;
const WARNING_INTERVAL = 10 * 60 * 1000;

// enabling on all environments for now, but keeping this flag in case they change their mind
// or if we want to disable it for local development in the future
const IS_TIMER_ACTIVE = true;

module.exports = (RouteComponent) => {

    return class InactiveComponent extends React.Component {

        constructor(props) {

            super(props);

            this.state = {
                showWarning: false,
                secondsBeforeLogout: null
            };

            this.processAuthenticationTimer;
            this.abortController = new AbortController();

            this.onUserAction = this._onUserAction.bind(this);
            this.processAuthentication = this._processAuthentication.bind(this);
        }

        _onUserAction() {

            if (!this.state.showWarning) {
                internals.updateActiveTime();
            }
        }

        _processAuthentication() {

            const lastActiveTime = localStorage.getItem('lastActiveTime');
            const timeSinceLastAction = Date.now() - lastActiveTime;

            if (timeSinceLastAction > TIMEOUT_INTERVAL) {
                internals.logout();
                return;
            }

            if (timeSinceLastAction > WARNING_INTERVAL) {
                if (!this.state.showWarning) {
                    this.setState({ showWarning: true });
                }
            }
            // if activity gets caught in another tab, this will dismiss the warning
            else if (this.state.showWarning === true) {
                this.setState({ showWarning: false });
            }

            const secondsBeforeLogout = Math.floor((TIMEOUT_INTERVAL - (timeSinceLastAction)) / 1000);

            this.setState({ secondsBeforeLogout });
            this.processAuthenticationTimer = setTimeout(this.processAuthentication, 1000);
        }

        componentDidMount() {

            if (IS_TIMER_ACTIVE) {

                // initialize activity timer
                internals.updateActiveTime();

                // setup user activity listeners
                const debounced = Debounce(this.onUserAction, 100);
                const userEvents = [
                    'mousemove',
                    'mousedown',
                    'mouseup',
                    'keypress',
                    'scroll'
                ];
                userEvents.forEach((eventName) => {

                    document.addEventListener(eventName, debounced, { signal: this.abortController.signal });
                });

                // listen to token in local storage to catch if another tab logs the user out
                window.onstorage = internals.checkAccessToken;

                this.processAuthenticationTimer = setTimeout(this.processAuthentication, 1000);
            }
        }

        componentWillUnmount() {

            if (IS_TIMER_ACTIVE) {
                clearTimeout(this.processAuthenticationTimer);
                this.abortController.abort();
                window.onstorage = null;
            }
        }

        render() {

            return (
                <>
                    <RouteComponent {...this.props} />
                    <AlertDialog
                        confirmAction={() => {

                            internals.updateActiveTime();
                            this.setState({ showWarning: false });
                        }}
                        cancelAction={internals.logout}
                        dialogTitle='Inactivity'
                        dialogDescription={`You will be logged out for inactivity${internals.formatTimeBeforeLogout(this.state.secondsBeforeLogout)}.`}
                        affirmativeLabel='Stay Logged In'
                        negativeLabel='Log Out'
                        toggleModal={() => null}
                        isModalOpen={this.state.showWarning}
                    />
                </>
            );
        }
    };
};

internals.checkAccessToken = () => {

    const accessToken = window.localStorage.getItem('access_token');

    if (!accessToken) {
        internals.logout();
    }
};

internals.logout = () => {

    M.dispatch.auth.logout();
};

internals.updateActiveTime = () => {

    localStorage.setItem('lastActiveTime', Date.now());
};

internals.formatTimeBeforeLogout = (totalSeconds) => {

    // display nothing if we run out of time
    if (totalSeconds < 1) {
        return '';
    }

    const minutes = Math.floor(totalSeconds / 60);
    const displaySeconds = totalSeconds % 60;

    const formattedTimeBeforeLogout = ` in ${minutes.toString().padStart(2, '0')}:${displaySeconds.toString().padStart(2, '0')}`;

    return formattedTimeBeforeLogout;
};
