const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: FormControlLabel } = require('@material-ui/core/FormControlLabel');
const { default: FormGroup } = require('@material-ui/core/FormGroup');
const { default: Checkbox } = require('@material-ui/core/Checkbox');

const internals = {};

module.exports = function CheckboxesField({ field, value, onChange, error }) {

    const { Error } = internals;

    const handleChange = (choice, isChecked) => {

        const index = value.indexOf(choice);

        if (isChecked && index === -1) {
            value.push(choice);
        }

        if (!isChecked && index > -1) {
            value.splice(index, 1);
        }

        onChange(value);
    };

    return (
        <>
            <p style={{ marginTop: 0 }}><strong>{field.name}{field.settings.isRequired && '*'}</strong></p>
            <FormGroup>
                {field.choices.map((choice) => {

                    return (
                        <FormControlLabel
                            key={choice}
                            control={<Checkbox checked={value.indexOf(choice) > -1} onChange={(e) => handleChange(choice, e.target.checked)} name={choice} />}
                            label={choice}
                        />
                    );
                })}
            </FormGroup>
            <Error>{error}</Error>
        </>
    );
};

module.exports.propTypes = {
    field: T.object.isRequired,
    onChange: T.func.isRequired,
    value: T.array,
    error: T.string
};

module.exports.defaultProps = {
    value: []
};

internals.Error = Styled.div`
    color: ${({ theme }) => theme.palette.error.main};
`;
