const React = require('react');
const T = require('prop-types');
const StrangeForms = require('strange-forms');
const { default: Typography } = require('@material-ui/core/Typography');
const Table = require('./Table');
const Nullable = require('./Nullable');
const GetDateString = require('../utils/get-date-string');

const internals = {};

module.exports = class EventListTable extends StrangeForms(React.Component) {

    static propTypes = {
        events: T.arrayOf(T.shape({
            id: T.string,
            name: T.string,
            isPublished: T.bool,
            address1: T.string,
            address2: T.string,
            state: T.string,
            zip: T.string,
            appointments: T.arrayOf(T.shape({
                id: T.string,
                patientId: T.string,
                time: T.string
            }))
        })),
        value: T.objectOf(T.bool),
        onChange: T.func.isRequired
    };

    static fields = [
        'selectedEvents',
        'shouldSelectAll'
    ];

    constructor(props) {

        super(props);

        this.state = {
            isSubmitting: false,
            orderDirection: 'desc'
        };

        const emptyObject = {};

        // If there's an event ID, backfill the appropriate values to the inputs
        // When a field is changed, clear out any of the following fields
        // When the appointment field is filled, assign the ID to the component
        this.strangeForm({
            fields: EventListTable.fields,
            get: {
                selectedEvents: (someProps) => someProps.value || emptyObject,
                shouldSelectAll: () => false
            },
            act: {
                selectedEvents: (field, value) => this.props.onChange({ ...this.props.value, [value]: !this.props.value[value] }),
                shouldSelectAll: (field, value) => {

                    if (value) {
                        let eventObj = {};
                        this.props.events.forEach(({ id }) => {

                            eventObj = ({ ...eventObj, [id]: true });
                        });

                        this.props.onChange(eventObj);
                    }
                    else {
                        this.props.onChange(emptyObject);
                    }
                }
            },
            getFormValue: {
                '*': (ev) => ev.target.checked || ''
            }
        });
    }

    handleSelectAll = () => {

        this.proposeNew('shouldSelectAll', () => true)();
    }

    handleSelectNone = () => {

        this.proposeNew('shouldSelectAll', () => false)();
    }

    handleSelectRow = (ev, value, x) => {

        ev.preventDefault();
        this.proposeNew('selectedEvents', () => value)();
    }

    render() {

        // Prevent an issue where onChange is spread into the element by destructuring onChange here
        // eslint-disable-next-line no-unused-vars
        const { appointments, onChange, ...otherProps } = this.props;

        const columns = [
            {
                id: 'name',
                header: 'Event Name',
                label: true,
                format: (name) => <Nullable value={name} />
            },
            {
                id: 'location',
                header: 'Event Location',
                label: true,
                format: (location) => <Nullable value={location} />
            },
            {
                id: 'state',
                header: 'State',
                label: true,
                format: (st) => <Nullable value={st} />
            },
            {
                id: 'date',
                header: 'Date',
                label: true,
                format: (date) => <Nullable value={GetDateString(date)} />
            }
        ];

        return (
            <>
                {this.props.events && !!this.props.events.length &&
                <Table.Wrapper {...otherProps}>
                    <Table
                        columns={columns}
                        selection={this.fieldValue('selectedEvents')}
                        idAttribute='id'
                        orderDirection={this.state.orderDirection}
                        onSelectAllClick={this.handleSelectAll}
                        onSelectNoneClick={this.handleSelectNone}
                        onSelectRow={this.handleSelectRow}
                    >
                        <Table.Rows data={this.props.events} />
                    </Table>
                </Table.Wrapper>}
                {(!this.props.events || !this.props.events.length) &&
                    <Typography>No results for the given date range</Typography>}
            </>
        );
    }
};

internals.findEvent = ({ value, events }) => {

    if (value && events) {
        const appointments = events.map((event) => event.appointments).flat();
        const selectedAppointment = appointments.find(({ id }) => id === value) || {};
        const appointmentEvent = events.find(({ id }) => id === selectedAppointment.eventId) || null;
        return appointmentEvent;
    }

    return null;
};
