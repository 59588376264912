const React = require('react');
const T = require('prop-types');
const { default: Snackbar } = require('@material-ui/core/Snackbar');
const { default: IconButton } = require('@material-ui/core/IconButton');
const { default: CloseIcon } = require('@material-ui/icons/Close');

const internals = {};

module.exports = class AppSnackbar extends React.PureComponent {

    static propTypes = {
        nextFromQueue: T.any,
        shiftQueue: T.func.isRequired
    };

    constructor(props) {

        super();

        this.state = {
            open: false,
            message: props.nextFromQueue || internals.defaultMessage()
        };
    }

    componentDidMount() {

        if (this.props.nextFromQueue) {
            this.handleMessage(this.props.nextFromQueue);
        }
    }

    componentWillReceiveProps(nextProps) {

        if (!this.props.nextFromQueue && nextProps.nextFromQueue) {
            this.handleMessage(nextProps.nextFromQueue);
        }
    }

    handleMessage = (msg) => {

        const { shiftQueue } = this.props;

        if (this.state.open) {
            return;
        }

        this.setState({
            open: true,
            message: { ...msg }
        }, () => {

            shiftQueue();
        });
    }

    handleClose = (event, reason) => {

        if (reason === 'clickaway') {
            return;
        }

        this.setState({ open: false });
    }

    handleExited = () => {

        const { nextFromQueue } = this.props;
        nextFromQueue && this.handleMessage(nextFromQueue);
    }

    render() {

        const { open, message } = this.state;

        return (
            <Snackbar
                autoHideDuration={3500}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                onClose={this.handleClose}
                onExited={this.handleExited}
                message={message.message}
                key={message.key}
                open={open}
                action={[
                    <IconButton
                        key='close'
                        aria-label='Close'
                        color='inherit'
                        onClick={this.handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            />
        );
    }
};

internals.defaultMessage = () => ({ message: '', key: new Date().getTime() });
