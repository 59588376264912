const React = require('react');
const T = require('prop-types');
const { NavLink } = require('react-router-dom');
const { default: Link } = require('@material-ui/core/Link');
const { default: Divider } = require('@material-ui/core/Divider');
const { default: Styled } = require('styled-components');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: Card } = require('@material-ui/core/Card');
const { default: CardContent } = require('@material-ui/core/CardContent');
const { default: CardActions } = require('@material-ui/core/CardActions');
const { default: Button } = require('@material-ui/core/Button');
// const OrderBy = require('lodash/orderBy');
const StrangeForms = require('strange-forms');
const Portlet = require('../../../components/Portlet');
const SectionLabel = require('../../../components/SectionLabel');
const DateMaskInput = require('../../../components/DateMaskInput');
const GetCurrentAppointment = require('../../../utils/get-current-appointment');
const GetFormattedTime = require('../../../utils/get-formatted-time');


const internals = {};

module.exports = class SearchPatientsPage extends StrangeForms(React.Component) {

    static propTypes = {
        searchResults: T.array,
        fetchProgram: T.func,
        searchPatients: T.func
    };

    static fields = {
        dob: '',
        lastName: '',
        identifier: ''
    };

    constructor(props) {

        super(props);

        this.state = {
            hasSearched: false,
            isSubmitting: false
        };

        this.strangeForm({
            fields: Object.keys(SearchPatientsPage.fields),
            get: () => '',
            act: () => null
        });
    }

    componentDidMount() {

        this.props.fetchData();
    }

    handleSearch = async (ev) => {

        ev.preventDefault();
        const lastName = this.fieldValue('lastName');
        const identifier = this.fieldValue('identifier');
        const dob = this.fieldValue('dob');
        this.setState({ isSubmitting: true });
        await this.props.searchPatients({
            clientId: this.props.program && this.props.program.client && this.props.program.client.id,
            lastName,
            identifier,
            dob
        });

        this.setState({ hasSearched: true, isSubmitting: false });
    }

    shouldDisableSubmit() {

        return (
            !(this.fieldValue('identifier') || this.fieldValue('lastName')) ||
            this.state.isSubmitting
        );
    }

    render() {

        const {
            PageContainer,
            FieldsWrapper,
            StyledDivider,
            PatientCard,
            ForwardedLink,
            StyledAppointmentText,
            CardWrapper
        } = internals;

        const { program, searchResults } = this.props;
        const { hasSearched } = this.state;
        const { name = '', client = {}, id = '' } = program || {};
        const { logoUrl, name: clientName } = client;

        // TODO: Re-enable this sorting
        const sortedSearchResults = searchResults || null;
        // const sortedSearchResults = searchResults ?
        //     OrderBy(
        //         searchResults,
        //         ({ lastName, appointments }) => {

        //             const sortOn = [lastName];

        //             if (appointments.length) {
        //                 sortOn.unshift(appointments[0].time, appointments[0].event.date);
        //             }

        //             return sortOn;
        //         },
        //         ['desc', 'desc', 'asc']
        //     ) :
        //     null;

        return (
            <PageContainer>
                <Portlet
                    title={`Program: ${name}`}
                    toolbar={logoUrl && <Portlet.LogoImg src={logoUrl} alt={`${clientName} logo`} />}
                    body={
                        <>
                            <SectionLabel>Search for Patient Record</SectionLabel>
                            <Typography>To find a patient record, enter their employee ID AND their date of birth. If two records are found, please confirm the name and address before proceeding with the correct one.</Typography>
                            <StyledDivider />
                            <FieldsWrapper onSubmit={this.handleSearch}>
                                <TextField
                                    label='Employee ID'
                                    value={this.fieldValue('identifier')}
                                    onChange={this.proposeNew('identifier')}
                                    margin='dense'
                                />
                                <TextField
                                    label='Last Name'
                                    value={this.fieldValue('lastName')}
                                    onChange={this.proposeNew('lastName')}
                                    margin='dense'
                                />
                                <TextField
                                    label='Date of Birth'
                                    value={this.fieldValue('dob')}
                                    onChange={this.proposeNew('dob')}
                                    margin='dense'
                                    InputProps={{
                                        inputComponent: DateMaskInput
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                <Button
                                    type='submit'
                                    color='primary'
                                    variant='contained'
                                    disabled={this.shouldDisableSubmit()}
                                >
                                    Search Patients
                                </Button>
                            </FieldsWrapper>
                            <StyledDivider />
                            <CardWrapper>
                                {
                                    hasSearched &&
                                    sortedSearchResults &&
                                    sortedSearchResults.map((patient) => {

                                        const appointment = GetCurrentAppointment(patient.appointments);

                                        return (
                                            <PatientCard key={patient.id}>
                                                <CardContent>
                                                    <Typography variant='h5' gutterBottom>{patient.firstName} {patient.lastName}</Typography>
                                                    <Typography variant='subtitle2' gutterBottom>{patient.identifierDecrypted}</Typography>
                                                    {appointment &&
                                                        <StyledAppointmentText variant='body2'>
                                                            {appointment.event.program.name}<br />
                                                            {appointment.event.name}<br />
                                                            {`${appointment.event.date} - ${GetFormattedTime(appointment.time)}`}
                                                        </StyledAppointmentText>}
                                                    {!!patient.dob && <Typography variant='body2'>{patient.dob}</Typography>}
                                                    <Typography variant='body2'>{patient.address1}</Typography>
                                                    {patient.address2 && <Typography variant='body2'>{patient.address2}</Typography>}
                                                    <Typography variant='body2'>{`${patient.city}, ${patient.st} ${patient.zip}`}</Typography>
                                                </CardContent>
                                                <CardActions>
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                        fullWidth
                                                        component={NavLink}
                                                        exact to={`/${this.props.role === 'screener' ? this.props.role + '/' : ''}patient/${patient.id}/screening`}
                                                    >
                                                        Select
                                                    </Button>
                                                </CardActions>
                                            </PatientCard>
                                        );
                                    })
                                }
                            </CardWrapper>
                            {
                                hasSearched && searchResults && !searchResults.length && this.state.hasSearched &&
                                <SectionLabel>No results found</SectionLabel>
                            }
                            <Link
                                exact to={`/${this.props.role === 'screener' ? this.props.role + '/' : ''}programs/${id}/screening/new`}
                                component={ForwardedLink}
                            >
                                Add a screening for a patient missing from this program
                            </Link>
                        </>
                    }
                />
            </PageContainer>
        );
    }
};

internals.PatientCard = Styled(Card)`
    width: fit-content;
    max-width: 400px;
    margin: 0 ${({ theme }) => theme.spacing(1)}px ${({ theme }) => theme.spacing(2)}px;
`;

internals.StyledDivider = Styled(Divider)`
    margin: ${({ theme }) => theme.spacing(2)}px 0;
`;

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.FieldsWrapper = Styled.form`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;

    // Select all but the last two (final text field and submit button)
    > :not(:nth-last-child(-n + 2)) {
        margin-right: ${({ theme }) => theme.spacing(1)}px;
    }
`;

internals.CardWrapper = Styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 ${({ theme }) => theme.spacing(1) * -1}px;
`;

internals.StyledAppointmentText = Styled(Typography)`
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    color: ${({ theme }) => theme.palette.primary.main};
`;

internals.ForwardedLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);
