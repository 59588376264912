const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: Button } = require('@material-ui/core/Button');
const { default: TablePagination } = require('@material-ui/core/TablePagination');
const Table = require('../../../components/Table');
const Nullable = require('../../../components/Nullable');
const Portlet = require('../../../components/Portlet');
const GetDateString = require('../../../utils/get-date-string');
const { API_BASE_URL } = require('../../../constants');

const internals = {};

const columns = [
    {
        id: 'date',
        header: 'Date',
        label: true,
        sortable: true,
        format: (date) => <Nullable value={GetDateString(date)} />
    },
    {
        id: 'name',
        header: 'Event',
        label: true,
        sortable: true,
        format: (contact) => <Nullable value={contact} />
    },
    {
        id: 'slots',
        header: 'Total Appointments',
        label: true,
        sortable: true,
        format: (slots) => <Nullable value={slots} />
    },
    {
        id: 'signups',
        header: 'Filled Appointments',
        label: true,
        sortable: true,
        format: (signups) => <Nullable value={signups} />
    },
    {
        id: 'utilization',
        header: 'Participation (%)',
        label: true,
        sortable: true,
        format: (utilization) => <Nullable value={`${Math.round(utilization)}%`} />
    }
];

module.exports = class ParticipationReport extends React.Component {

    static propTypes = {
        fetchData: T.func,
        participationResults: T.arrayOf(
            T.shape({
                date: T.string,
                name: T.string,
                slots: T.number,
                signups: T.number,
                utilization: T.number
            })
        ),
        match: T.shape({
            params: T.shape({
                id: T.string
            })
        })
    };

    constructor(props) {

        super(props);

        this.state = {
            orderDirection: 'desc',
            rowsPerPage: 10,
            page: 0
        };
    }

    componentDidMount() {

        this.props.fetchData();
    }

    sortEvents = (events) => {

        const { compareFn } = this.state;

        if (!compareFn) {
            return events;
        }

        return compareFn([...events]);
    }

    handleRequestSort = (_, order) => {

        const { orderBy, orderDirection, compareFn } = order;

        this.setState({
            orderBy,
            orderDirection,
            compareFn
        });
    }

    handleChangePage = (_, newPage) => {

        this.setState({
            page: newPage
        });
    }

    handleChangeRowsPerPage = (ev) => {

        this.setState({
            page: 0,
            rowsPerPage: ev.target.value
        });
    }

    render() {

        const { PageContainer, StyledButton } = internals;
        const { rowsPerPage, page } = this.state;
        const { participationResults, match: { params } } = this.props;
        const sortedEvents = this.sortEvents(participationResults) || [];
        const slicedEvents = sortedEvents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        const hasResults = Array.isArray(participationResults) && !!participationResults.length;

        return (
            <PageContainer>
                <Portlet
                    title='Participation Report'
                    toolbar={
                        hasResults ? (
                            <StyledButton
                                component='a'
                                href={`${API_BASE_URL}/reports/participation/${params.id}`}
                                variant='contained'
                                color='primary'
                                download
                            >
                                Export
                            </StyledButton>
                        ) : null
                    }
                    body={
                        hasResults ? (
                            <Table.Wrapper>
                                <Table
                                    columns={columns}
                                    idAttribute='id'
                                    orderDirection={this.state.orderDirection}
                                    orderBy={this.state.orderBy}
                                    onRequestSort={this.handleRequestSort}
                                >
                                    <Table.Rows data={slicedEvents} />
                                </Table>
                                <TablePagination
                                    count={sortedEvents.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </Table.Wrapper>
                        ) : (
                            <div>There are currently no results. Please check back after completing your first screening event.</div>
                        )
                    }
                />
            </PageContainer>
        );
    }
};

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.StyledButton = Styled(Button)`
  margin: 0 ${({ theme }) => theme.spacing(2)}px;
`;
