const React = require('react');
const { v4: UUID } = require('uuid');
const { default: TextFieldsIcon } = require('@material-ui/icons/TextFields');
const { default: DateRangeIcon } = require('@material-ui/icons/DateRange');
const { default: CheckIcon } = require('@material-ui/icons/Check');
const { default: DoneAllIcon } = require('@material-ui/icons/DoneAll');
const { default: RadioButtonCheckedIcon } = require('@material-ui/icons/RadioButtonChecked');
const { default: ArrowDropDownIcon } = require('@material-ui/icons/ArrowDropDown');
const { default: StarHalfIcon } = require('@material-ui/icons/StarHalf');
const { default: RemoveIcon } = require('@material-ui/icons/Remove');
const { default: NotesIcon } = require('@material-ui/icons/Notes');
const TextField = require('./TextField');
const DateField = require('./DateField');
const BooleanField = require('./BooleanField');
const CheckboxesField = require('./CheckboxesField');
const RadioField = require('./RadioField');
const SelectField = require('./SelectField');
const RatingField = require('./RatingField');
const Divider = require('./Divider');
const Paragraph = require('./Paragraph');

module.exports = {
    text: {
        component: (props) => <TextField icon={TextFieldsIcon} {...props} />,
        icon: TextFieldsIcon,
        get new() {

            return {
                id: `temp-${UUID()}`,
                name: 'New Text Field',
                type: 'text',
                settings: {
                    isRequired: false,
                    width: '100'
                }
            };
        }
    },
    date: {
        component: (props) => <DateField icon={DateRangeIcon} {...props} />,
        icon: DateRangeIcon,
        get new() {

            return {
                id: `temp-${UUID()}`,
                name: 'New Date Field',
                type: 'date',
                format: 'MM/dd/yyyy',
                settings: {
                    isRequired: false,
                    width: '100'
                }
            };
        }
    },
    boolean: {
        component: (props) => <BooleanField icon={CheckIcon} {...props} />,
        icon: CheckIcon,
        get new() {

            return {
                id: `temp-${UUID()}`,
                name: 'New Boolean Field',
                type: 'boolean',
                settings: {
                    width: '100'
                }
            };
        }
    },
    checkboxes: {
        component: (props) => <CheckboxesField icon={DoneAllIcon} {...props} />,
        icon: DoneAllIcon,
        get new() {

            return {
                id: `temp-${UUID()}`,
                name: 'New Checkboxes Field',
                type: 'checkboxes',
                choices: [
                    'Choice 1',
                    'Choice 2'
                ],
                settings: {
                    isRequired: false,
                    width: '100'
                }
            };
        }
    },
    radio: {
        component: (props) => <RadioField icon={RadioButtonCheckedIcon} {...props} />,
        icon: RadioButtonCheckedIcon,
        get new() {

            return {
                id: `temp-${UUID()}`,
                name: 'New Radio Field',
                type: 'radio',
                choices: [
                    'Choice 1',
                    'Choice 2'
                ],
                settings: {
                    isRequired: false,
                    width: '100'
                }
            };
        }
    },
    select: {
        component: (props) => <SelectField icon={ArrowDropDownIcon} {...props} />,
        icon: ArrowDropDownIcon,
        get new() {

            return {
                id: `temp-${UUID()}`,
                name: 'New Dropdown Field',
                type: 'select',
                choices: [
                    'Choice 1',
                    'Choice 2'
                ],
                settings: {
                    isRequired: false,
                    width: '100'
                }
            };
        }
    },
    rating: {
        component: (props) => <RatingField icon={StarHalfIcon} {...props} />,
        icon: StarHalfIcon,
        get new() {

            return {
                id: `temp-${UUID()}`,
                name: 'New Rating Field',
                type: 'rating',
                min: 1,
                max: 5,
                settings: {
                    isRequired: false,
                    width: '100'
                }
            };
        }
    },
    divider: {
        component: (props) => <Divider icon={RemoveIcon} {...props} />,
        icon: RemoveIcon,
        get new() {

            return {
                id: `temp-${UUID()}`,
                type: 'divider'
            };
        }
    },
    paragraph: {
        component: (props) => <Paragraph icon={NotesIcon} {...props} />,
        icon: NotesIcon,
        get new() {

            return {
                id: `temp-${UUID()}`,
                type: 'paragraph',
                value: ''
            };
        }
    }
};
