// Intended to be passed to a '.sort()' call

module.exports = (a, b) => {

    const dateA = new Date(a.date);
    const dateB = new Date(b.date);

    // Subsort by event 'name'
    if (dateA === dateB) {
        return a.name < b.name ? -1 : 1;
    }

    // Default sort given by .sort()
    return dateA < dateB ? -1 : 1;
};
