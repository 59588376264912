const React = require('react');
const T = require('prop-types');
const { NavLink } = require('react-router-dom');
const { default: Styled } = require('styled-components');
const { default: Button } = require('@material-ui/core/Button');
const StrangeForms = require('strange-forms');
const Portlet = require('../../../components/Portlet');
const UserFields = require('../../../components/UserFields');

const internals = {};

module.exports = class CreateUserPage extends StrangeForms(React.Component) {

    static propTypes = {
        onSubmit: T.func.isRequired
    };

    constructor(props) {

        super(props);

        const defaultUserFields = {
            name: '',
            phone: '',
            email: '',
            role: ''
        };

        this.state = {
            userFields: { ...defaultUserFields },
            isSubmitting: false
        };

        this.initialState = { ...this.state };

        this.fields = {
            ...defaultUserFields,
            userFields: { ...defaultUserFields }
        };

        this.strangeForm({
            fields: Object.keys(this.fields),
            get: (someProps, field) => this.state[field],
            act: (field, value) => this.setState({ [field]: value }),
            getFormValue: {
                userFields: (val) => val
            }
        });
    }

    shouldDisableSubmit() {

        const { email, role } = this.fieldValue('userFields');
        return !email || this.state.isSubmitting || !role;
    }

    handleSubmit = async (ev) => {

        ev.preventDefault();
        const accountInfo = { ...this.fieldValue('userFields') };
        const { role } = accountInfo;
        delete accountInfo.role;
        this.setState({ isSubmitting: true });
        // API expects email + username, but we want to capture only email and use that as username
        const [err] = await this.props.onSubmit(
            {
                user: { ...accountInfo, username: accountInfo.email },
                role
            }
        );
        this.setState({ isSubmitting: false });

        if (!err) {
            this.setState({ ...this.initialState });
        }
    }

    render() {

        const { PageContainer, StyledButton, ForwardedLink } = internals;

        return (
            <PageContainer>
                <Portlet
                    title='Add New User'
                    body={
                        <UserFields
                            fields={this.fieldValue('userFields')}
                            value={this.fieldValue('userFields')}
                            onChange={this.proposeNew('userFields')}
                        />
                    }
                    foot={
                        <>
                            <StyledButton
                                onClick={this.handleSubmit}
                                variant='contained'
                                color='primary'
                                disabled={this.shouldDisableSubmit()}
                            >
                                Save
                            </StyledButton>
                            <StyledButton
                                component={ForwardedLink}
                                to='/'
                                variant='text'
                            >
                                Cancel
                            </StyledButton>
                        </>
                    }
                />
            </PageContainer>
        );
    }
};

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.StyledButton = Styled(Button)`
    margin: 0 8px;
`;

internals.ForwardedLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);
