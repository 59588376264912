const React = require('react');
const T = require('prop-types');
const Joi = require('joi');
const { default: Memoize } = require('memoize-one');
const { default: Styled } = require('styled-components');
const { default: Card } = require('@material-ui/core/Card');
const { default: CardContent } = require('@material-ui/core/CardContent');
const { default: CardActions } = require('@material-ui/core/CardActions');
const { default: Button } = require('@material-ui/core/Button');
const { default: Chip } = require('@material-ui/core/Chip');
const StrangeForms = require('strange-forms');
const Portlet = require('../../../components/Portlet');
const EventFields = require('../../../components/EventFields');
const AddAppointmentsModule = require('../../../components/AddAppointmentsModule');
const EventCommunicationDialog = require('../../../components/EventCommunicationDialog');
const CompareProps = require('../../../utils/compare-props');
const { API_BASE_URL } = require('../../../constants');

const internals = {};

module.exports = class CreateEventPage extends StrangeForms(React.Component) {

    static propTypes = {
        event: T.object,
        onSubmit: T.func.isRequired,
        sendCommunication: T.func
    };

    static formSchema = Joi.object({
        name: Joi.string().required(),
        date: Joi.string().pattern(/^\d{2}\/\d{2}\/\d{4}$/),
        location: Joi.string(),
        address1: Joi.string(),
        address2: Joi.string().empty('').allow(null).default(null),
        city: Joi.string(),
        state: Joi.string(),
        zip: Joi.string(),
        notes: Joi.string().empty('').allow(null).default(null),
        appointments: Joi.array(),
        isPublished: Joi.boolean(),
        locationCode: Joi.string().empty('').allow(null).default(null),
        startTime: Joi.string().empty('').allow(null).default(null),
        endTime: Joi.string().empty('').allow(null).default(null)
    }).unknown(true);

    constructor(props) {

        super(props);

        this.state = {
            isCommunicationDialogOpen: false
        };

        this.strangeForm({
            fields: ['eventFields', 'appointments'],
            get: {
                eventFields: Memoize((someProps) => someProps.event || {}, ([props1], [props2]) => CompareProps(props1.event, props2.event)),
                appointments: (someProps) => someProps.event && someProps.event.appointments || []
            },
            act: () => null,
            getFormValue: (val) => val
        });
    }

    componentDidMount() {

        this.props.fetchData();
    }

    shouldDisableSubmit() {

        return false;
    }

    formatFields = () => {

        const eventFields = this.fieldValue('eventFields');
        const appointments = this.fieldValue('appointments');

        // Get rid of any existing events
        delete eventFields.appointments;
        eventFields.appointments = appointments.map((appointment) => {

            // This would be a makeshift UUID instead of an ID assigned from the database
            if (appointment.id.startsWith('staged')) {
                delete appointment.id;
            }

            return appointment;
        });

        return eventFields;
    }

    validate = (event) => {

        const results = CreateEventPage.formSchema.validate(event);

        if (!results.error) {
            return null;
        }

        const field = results.error.message.match(/\"(.+)\"/)[1];

        return { [field]: results.error.message };
    }

    handleSubmit = async (ev, params = { shouldSetDraft: false }) => {

        ev.preventDefault();

        const { shouldSetDraft } = params;
        const eventInfo = this.formatFields();

        if (this.props.event && this.props.event.program.isCovidTesting) {
            // Existing additionalInformation values may or may not be on the event from props,
            // and any updated form values will be on eventInfo. There isn't a guarantee
            // any of these will be available
            const propsAdditionalInformation = this.props.event.additionalInformation || {};

            eventInfo.additionalInformation = {
                fax: eventInfo.fax || propsAdditionalInformation.fax,
                contact: eventInfo.contact || propsAdditionalInformation.contact,
                telephone: eventInfo.telephone || propsAdditionalInformation.telephone
            };

            delete eventInfo.fax;
            delete eventInfo.contact;
            delete eventInfo.telephone;
        }

        if (shouldSetDraft) {
            eventInfo.isPublished = false;
        }
        else {
            eventInfo.isPublished = true;
        }

        const errors = this.validate(eventInfo);

        if (errors) {
            this.setState({ errors });
            return;
        }

        this.setState({ isSubmitting: true });

        await this.props.onSubmit(eventInfo);

        this.setState({ isSubmitting: false });
    }

    handleSendCommunication = async (comm) => {

        return await this.props.sendCommunication(comm);
    }

    handleOpenCommunicationDialog = () => {

        this.setState({ isCommunicationDialogOpen: true });
    }

    handleCloseCommunicationDialog = () => {

        this.setState({ isCommunicationDialogOpen: false });
    }

    handleImportAppointments = async (data) => {

        return await this.props.uploadAppointments(data);
    }

    onCancelAppointment = async (appointmentInfo) => {

        await this.props.cancelAppointment(appointmentInfo);
        this.props.fetchData();
    }

    render() {

        const { PageContainer, StyledForm, StyledButton, BodyContainer } = internals;
        const { event } = this.props;

        return (
            <PageContainer>
                <Portlet
                    title={event && event.id ? 'Edit Event' : 'Add New Event'}
                    toolbar={
                        <Chip
                            color='secondary'
                            label='Send Communication'
                            onClick={this.handleOpenCommunicationDialog}
                            clickable
                        />
                    }
                    body={
                        <BodyContainer>
                            <Card>
                                <CardContent>
                                    <StyledForm>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <EventFields
                                                event={event}
                                                fields={this.fieldValue('eventFields')}
                                                onChange={this.proposeNew('eventFields')}
                                                errors={this.state.errors}
                                            />
                                        </div>
                                    </StyledForm>
                                </CardContent>
                                <CardActions>
                                    <StyledButton
                                        onClick={this.handleSubmit}
                                        variant='contained'
                                        color='primary'
                                        disabled={this.shouldDisableSubmit()}
                                    >
                                        Publish
                                    </StyledButton>
                                    <StyledButton
                                        onClick={(ev) => this.handleSubmit(ev, { shouldSetDraft: true })}
                                        variant='contained'
                                        color='secondary'
                                        disabled={this.shouldDisableSubmit()}
                                    >
                                        Draft
                                    </StyledButton>
                                    {
                                        event && event.id &&
                                        <StyledButton
                                            component='a'
                                            href={`${API_BASE_URL}/events/export/${event.id}`}
                                            variant='contained'
                                            download
                                        >
                                            Export
                                        </StyledButton>
                                    }
                                </CardActions>
                            </Card>
                            <AddAppointmentsModule
                                event={event}
                                appointments={this.fieldValue('appointments')}
                                onChange={this.proposeNew('appointments')}
                                onUploadAppointments={this.handleImportAppointments}
                                handleCancelAppointment={this.onCancelAppointment}
                            />
                            {
                                event &&
                                <EventCommunicationDialog
                                    isOpen={this.state.isCommunicationDialogOpen}
                                    event={event}
                                    onSubmit={this.handleSendCommunication}
                                    onClose={this.handleCloseCommunicationDialog}
                                />
                            }
                        </BodyContainer>
                    }
                />
            </PageContainer>
        );
    }
};

internals.BodyContainer = Styled.div`

    ${({ theme }) => theme.breakpoints.up('sm')} {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        >div {
            flex: 0 calc(50% - ${({ theme }) => theme.spacing(1)}px);
        }
    }
`;

internals.StyledForm = Styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.StyledButton = Styled(Button)`
    margin: 0 ${({ theme }) => theme.spacing(2)}px;
`;
