const React = require('react');
const { useState } = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: Button } = require('@material-ui/core/Button');
const { default: FormGroup } = require('@material-ui/core/FormGroup');
const { default: FormLabel } = require('@material-ui/core/FormLabel');
const { default: FormControlLabel } = require('@material-ui/core/FormControlLabel');
const { default: Checkbox } = require('@material-ui/core/Checkbox');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: ArrowRight } = require('@material-ui/icons/ArrowRight');
const { default: ArrowDown } = require('@material-ui/icons/ArrowDropDown');
const FieldHeader = require('./FieldHeader');
const { Field, Settings } = require('./styles');

const internals = {};

module.exports = function RatingField(props) {

    const { field, onChange } = props;
    const { RatingSettings } = internals;

    const [showSettings, setShowSettings] = useState(true);
    const [isEditing, setIsEditing] = useState(false);

    return (
        <Field isEditing={isEditing}>
            <FieldHeader {...props} toggleEdit={() => setIsEditing(!isEditing)} />
            {isEditing && (
                <>
                    <RatingSettings>
                        <TextField
                            label='Minimum Rating'
                            value={field.min}
                            required
                            onChange={(e) => onChange(field, { min: e.target.value })}
                        />
                        <TextField
                            label='Maximum Rating'
                            value={field.max}
                            required
                            onChange={(e) => onChange(field, { max: e.target.value })}
                        />
                    </RatingSettings>
                    <Settings>
                        <Button
                            onClick={() => setShowSettings(!showSettings)}
                        >
                            {showSettings ? <ArrowDown /> : <ArrowRight />} Settings
                        </Button>
                        {showSettings && (
                            <div>
                                <FormLabel component='legend'>Validation</FormLabel>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Checkbox checked={field.settings.isRequired} onChange={(ev, isRequired) => onChange(field, { settings: { isRequired } })} name='isRequired' />}
                                        label='Required'
                                    />
                                </FormGroup>
                            </div>
                        )}
                    </Settings>
                </>
            )}
        </Field>
    );
};

module.exports.propTypes = {
    field: T.object.isRequired,
    onChange: T.func.isRequired
};

internals.RatingSettings = Styled.div`
    display: flex;

    > div {
        margin-top: ${({ theme }) => theme.spacing(2)}px;
        margin-left: ${({ theme }) => theme.spacing(2)}px;
    }
`;
