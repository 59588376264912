const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: Button } = require('@material-ui/core/Button');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: Card } = require('@material-ui/core/Card');
const { default: CardContent } = require('@material-ui/core/CardContent');
const ScreeningFieldsGroup = require('../components/ScreeningFieldsGroup');
const AlertDialog = require('../components/AlertDialog');
const { program } = require('../middle-end/model/schema');

const internals = {};

const { useEffect, useState } = React;

module.exports = function ScreeningFields(props) {

    const {
        disabled,
        appointmentId,
        initValue,
        value,
        role,
        onChange,
        screeningMetrics,
        screeningCategories,
        confirmedAt,
        onClickSubmit,
        shouldDisableSubmit,
        onClickConfirm,
        onClickRelease,
        ...screeningFieldsGroupProps
    } = props;

    const {
        StyledForm,
        FieldGrid,
        ButtonContainer,
        StyledCard,
        CategoryHeader,
        NotesField
    } = internals;

    const setField = (metricId) => {

        return (val) => {

            onChange({
                ...value,
                [metricId]: val
            });
        };
    };

    const setFieldWithEvt = (metricId) => {

        return (evt) => setField(metricId)(evt.target.value);
    };

    const stringifiedInitValue = JSON.stringify(initValue);

    // Reset the form if appointmentId changes,
    // Or if updated data from the API came in,
    // which will change 'stringifiedInitValue'
    useEffect(() => {

        onChange(initValue);
    }, [appointmentId, stringifiedInitValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const [showModal, setShowModal] = useState(false);

    return (
        <StyledCard>
            <CardContent>
                <StyledForm isConfirmed={!!confirmedAt} autoComplete='off'>
                    <Typography variant='h5'>Screening Data</Typography>
                    {screeningCategories.filter((category) => screeningMetrics.map((metric) => metric.categoryId).includes(category.id)).map((category) => {

                        return (
                            <FieldGrid key={category.id}>
                                <CategoryHeader>{category.name}</CategoryHeader>

                                {screeningMetrics && screeningMetrics.filter((metric) => metric.categoryId === category.id).map((metric) => {

                                    return (
                                        <ScreeningFieldsGroup
                                            disabled={disabled}
                                            {...screeningFieldsGroupProps}
                                            key={metric.name}
                                            label={metric.name}
                                            fieldsMap={metric.fields}
                                            formula={metric.formula}
                                            value={value[metric.id]}
                                            onChange={setField(metric.id)}
                                        />
                                    );
                                })}
                            </FieldGrid>
                        );
                    })}
                    <NotesField
                        disabled={disabled}
                        multiline
                        rows={8}
                        label='Notes'
                        value={value.notes || ''}
                        onChange={setFieldWithEvt('notes')}
                    />
                    <ButtonContainer>
                        {!confirmedAt && (
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={onClickSubmit}
                                disabled={disabled || shouldDisableSubmit}
                                style={{ marginLeft: 0 }}
                            >
                                Save Record
                            </Button>
                        )}
                        {!confirmedAt && !program.isCovidTesting && (
                            <Button
                                disabled={disabled}
                                variant='contained'
                                onClick={() => setShowModal(true)}
                            >
                                Finalize and Lock Record
                            </Button>
                        )}
                        {role !== 'screener' && (
                            confirmedAt &&
                            <Button
                                disabled={disabled}
                                variant='contained'
                                onClick={onClickRelease}
                            >
                                Release Record
                            </Button>
                        )}
                    </ButtonContainer>
                </StyledForm>
            </CardContent>
            <AlertDialog
                dialogTitle='Lock record?'
                dialogDescription='Are you sure you want to finalize and lock this record? Once the record is locked it cannot be edited again unless unlocked by an administrator.'
                affirmativeLabel='Lock Record'
                isModalOpen={showModal}
                confirmAction={onClickConfirm}
                toggleModal={() => setShowModal(false)}
            />
        </StyledCard>
    );
};

module.exports.propTypes = {
    disabled: T.bool,
    appointmentId: T.string,
    fields: T.object.isRequired,
    // 'initValue' and 'value' have the following shape but I can't easily express it in prop-types
    // T.shape({
    //     notes: T.string,
    //     // For each metric id in the screening
    //     [hashed metric id]: T.shape({
    //         metricId: T.string,
    //         values: T.object
    //     })
    // })
    initValue: T.object,
    value: T.object,
    role: T.string,
    onChange: T.func.isRequired,
    screeningCategories: T.arrayOf(T.shape({
        id: T.string,
        name: T.string
    })),
    screeningMetrics: T.arrayOf(T.shape({
        id: T.string,
        name: T.string,
        category: T.string,
        fields: T.arrayOf(T.shape({
            name: T.string,
            type: T.string,
            units: T.string
        })),
        formula: T.string
    })).isRequired,
    program: T.object,
    confirmedAt: T.instanceOf(Date),
    shouldDisableSubmit: T.bool,
    onClickSubmit: T.func,
    onClickConfirm: T.func,
    onClickRelease: T.func
};

internals.StyledCard = Styled(Card)`
    max-width: 900px;
`;

internals.ButtonContainer = Styled.div`
    display: flex;
`;

internals.StyledForm = Styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${({ isConfirmed }) => {

        return isConfirmed ?
            `
                > div:not(:last-child) {
                    opacity: 0.6;
                    pointer-events: none;
                }
            ` :
            null;
    }}
`;

internals.FieldGrid = Styled.div`
    display: grid;
    width: 100%;
    gap: 0 ${({ theme }) => theme.spacing(2)}px;
    grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

internals.CategoryHeader = Styled.div`
    grid-column: 1 / -1;
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
    font-weight: bold;
`;

internals.NotesField = Styled(TextField)`
    width: 100%;
    max-width: 500px;
`;
