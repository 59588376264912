const Connect = require('react-redux').connect;
const AllCovidLabelsForEvent = require('../components/AllCovidLabelsForEvent');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        appointments: M.selectors.model.fetchAllAppointmentsForEvent(state)
    }),
    (dispatch, props) => ({
        fetchData: () => M.dispatch.model.fetchAllAppointmentsForEvent(props.match.params.eventId)
    })
);

module.exports = internals.connect(AllCovidLabelsForEvent);
