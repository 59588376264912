const Connect = require('react-redux').connect;
const CreateClientPage = require('../components/CreateClientPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({}),
    (dispatch, props) => ({

        onSubmit: async (clientInfo) => {

            const [err] = await M.dispatch.model.createClient(clientInfo);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Client created successfully');
                M.dispatch.router.push('/clients/search');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }
        },
        uploadLogo: async (file) => {

            const [err, result] = await M.dispatch.model.uploadClientLogo(file);
            if (!err) {
                M.dispatch.snackbar.pushToQueue('Logo uploaded successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err, result];
        }
    })
);

module.exports = internals.connect(CreateClientPage);
