const React = require('react');
const T = require('prop-types');
const { default: Styled, ...S } = require('styled-components');
const { default: Select } = require('@material-ui/core/Select');
const { default: MenuItem } = require('@material-ui/core/MenuItem');
const { default: FormControl } = require('@material-ui/core/FormControl');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: Button } = require('@material-ui/core/Button');
const StrangeForms = require('strange-forms');
const StateSelect = require('./StateSelect');
const GetDateString = require('../utils/get-date-string');
const GetFormattedTime = require('../utils/get-formatted-time');

const internals = {};

module.exports = class EventPicker extends StrangeForms(React.Component) {

    static propTypes = {
        events: T.arrayOf(T.shape({
            id: T.string,
            name: T.string,
            isPublished: T.bool,
            address1: T.string,
            address2: T.string,
            state: T.string,
            zip: T.string,
            appointments: T.arrayOf(T.shape({
                id: T.string,
                patientId: T.string,
                time: T.string
            }))
        })),
        locationCode: T.string,
        value: T.shape({
            appointmentId: T.string,
            eventId: T.string
        }),
        onChange: T.func.isRequired,
        hidePastEvents: T.bool,
        disallowWalkIns: T.bool,
        showAppointmentTimeError: T.bool
    };

    static defaultProps = {
        showAppointmentTimeError: false
    }

    static fields = [
        'city',
        'state',
        'event',
        'appointment'
    ];

    constructor(props) {

        super(props);

        this.state = {
            isSubmitting: false
        };

        // If there's an event ID, backfill the appropriate values to the inputs
        // When a field is changed, clear out any of the following fields
        // When the appointment field is filled, assign the ID to the component
        this.strangeForm({
            fields: EventPicker.fields,
            get: {
                // If the picker is initialized with an event, these fields will
                // correctly pre-populate
                state: (someProps) => internals.getEventAttribute(someProps, 'state'),
                city: (someProps) => internals.getEventAttribute(someProps, 'city'),
                event: (someProps) => someProps.value?.eventId || '',
                appointment: (someProps) => someProps.value?.appointmentId || '',
                '*': () => ''
            },
            act: {
                state: (field, value) => {

                    this.proposeNew('city', () => '')();
                    this.proposeNew('event', () => '')();
                    this.proposeNew('appointment', () => '')();
                    this.props.onChange({
                        eventId: null,
                        appointmentId: null
                    });
                },
                city: (field, city) => {

                    if (!city) {
                        this.props.onChange({
                            eventId: null,
                            appointmentId: null
                        });
                        return '';
                    }

                    let cityHasSingleEvent = false;

                    const events = this.props.events.filter((e) => e.city === city);

                    if (events.length === 1) {
                        cityHasSingleEvent = true;
                    }

                    const newEventVal = cityHasSingleEvent ? events[0].id : '';

                    this.proposeNew('event', () => newEventVal)();
                    this.proposeNew('appointment', () => '')();
                    this.props.onChange({
                        eventId: newEventVal,
                        appointmentId: null
                    });
                },
                event: (field, value) => {

                    this.proposeNew('appointment', () => '')();
                    this.props.onChange({
                        eventId: value,
                        appointmentId: null
                    });
                },
                appointment: (field, value) => {

                    this.props.onChange({
                        eventId: this.props.value.eventId,
                        appointmentId: value
                    });
                },
                '*': () => this.props.onChange({ eventId: null, appointmentId: null })
            }
        });
    }

    handleClickSuggestedEvent(e, event) {

        e.preventDefault();

        this.proposeNew('state', () => event.state)();
        this.proposeNew('city', () => event.city)();
        this.proposeNew('event', () => event.id)();
    }

    render() {

        const { StyledForm, SuggestedEvents, SuggestedEvent, AppointmentTimeSelect } = internals;
        // Prevent an issue where onChange is spread into the element by destructuring onChange here
        // eslint-disable-next-line no-unused-vars
        const { events, onChange, locationCode, showAppointmentTimeError, ...otherProps } = this.props;

        const availableStates = [...new Set(events.map((event) => event.state).sort())];
        const availableCities = this.fieldValue('state') ?
            [...new Set(
                events.filter(({ state }) => state === this.fieldValue('state')).map(({ city }) => city).sort()
            )] :
            [];
        const availableEvents = this.fieldValue('city') ?
            events.filter(({ city }) => city === this.fieldValue('city')) :
            [];
        const selectedEvent = this.fieldValue('event') ?
            events.find(({ id }) => id === this.fieldValue('event')) :
            '';

        const eventAppointments = (selectedEvent && selectedEvent.appointments) || [];

        // First return a list of all appointment objects without a patient
        // OR an appointment that matches the value for the appointment on file
        const availableAppointments = eventAppointments.filter(({ id, patientId, time, eventId }) => {

            if (!time) {
                return false;
            }

            if (this.props.hidePastEvents) {
                const event = events.find((e) => e.id === eventId);
                const [apptHour, apptMinutes] = time.split(':').map((t) => Number(t));
                const now = new Date();

                if (event.date === GetDateString(now, { padDate: true }) &&
                    apptHour < now.getHours() ||
                    (apptHour === now.getHours() && apptMinutes < now.getMinutes())
                ) {
                    return false;
                }
            }

            return !patientId || id === this.fieldValue('appointment');
        });

        const localEvents = locationCode ? events.filter((event) => event.locationCode === locationCode && event.appointments.some((appt) => appt.patientId === null)) : [];

        // Remove duplicated timeslots
        // First let's make a Set of all of the timeslots
        const dedupedTimeslots = [...new Set(availableAppointments.map(({ time }) => time))];

        // Now let's make an array where we find() the first appointment with the appropriate timeslot
        // (Accounting for the one the user may have)
        const dedupedAppointments = dedupedTimeslots.map((apptTime) => availableAppointments.find(({ time, id }) => id === (this.fieldValue('appointment') && time === apptTime) || time === apptTime));

        return (
            <>
                {(locationCode && !!localEvents.length) && (
                    <SuggestedEvents>
                        <Typography gutterBottom>
                            <strong>Suggested Events</strong>
                        </Typography>
                        {localEvents.map((ev) => (

                            <SuggestedEvent
                                key={ev.id}
                                onClick={(e) => this.handleClickSuggestedEvent(e, ev)}
                            >
                                {ev.name} - {ev.date}
                            </SuggestedEvent>
                        ))}
                    </SuggestedEvents>
                )}
                <StyledForm {...otherProps}>
                    <StateSelect
                        value={this.fieldValue('state')}
                        onChange={this.proposeNew('state')}
                        stateSubset={availableStates}
                    />
                    <FormControl margin='dense'>
                        <Select
                            displayEmpty
                            disabled={!availableCities.length}
                            name='city'
                            value={this.fieldValue('city')}
                            onChange={this.proposeNew('city')}
                        >
                            <MenuItem value=''><em>City</em></MenuItem>
                            {availableCities.map((city) => <MenuItem key={city} value={city}>{city}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl margin='dense'>
                        <Select
                            displayEmpty
                            disabled={!availableEvents.length}
                            name='event'
                            value={this.fieldValue('event')}
                            onChange={this.proposeNew('event')}
                        >
                            <MenuItem value=''><em>Event</em></MenuItem>
                            {!!availableEvents.length && availableEvents.map((event) => <MenuItem key={event.id} value={event.id}>{internals.getFormattedEvent(event)}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl margin='dense'>
                        <AppointmentTimeSelect
                            displayEmpty
                            disabled={!dedupedAppointments.length}
                            name='appointment'
                            value={this.fieldValue('appointment')}
                            onChange={this.proposeNew('appointment')}
                            $showAppointmentTimeError={showAppointmentTimeError && this.fieldValue('event')}
                        >
                            <MenuItem value=''><em>Appointment</em></MenuItem>
                            {dedupedAppointments.map((appointment) => <MenuItem key={appointment.id} value={appointment.id}>{GetFormattedTime(appointment.time)}</MenuItem>)}
                        </AppointmentTimeSelect>
                    </FormControl>
                </StyledForm>
            </>
        );
    }
};

internals.findEvent = ({ value, events }) => {

    if (value && events && events.length) {
        const appointments = events.map((event) => event.appointments).flat();
        const selectedAppointment = appointments.find(({ id }) => id === value) || {};
        const appointmentEvent = events.find(({ id }) => id === selectedAppointment.eventId) || {};
        return appointmentEvent;
    }

    return null;
};

// attr should be oneOf 'id', 'state', or 'city'
internals.getEventAttribute = (someProps, attr) => {

    const event = internals.findEvent(someProps);
    return event && event[attr] ? event[attr] : '';
};

internals.getFormattedEvent = (event) => {

    return event && event.name && event.date ? [event.name, GetDateString(event.date)].join(' ') : '';
};

internals.StyledForm = Styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    align-items: stretch;

    >div {
        margin-right: ${({ theme }) => theme.spacing(1)}px;

        :nth-child(2), :nth-child(3) {
            flex-basis: calc(70% - ${({ theme }) => theme.spacing(1)}px);
            flex-grow: 1;
        }
        :last-child, :first-child {
            flex: 1 calc(30% - ${({ theme }) => theme.spacing(1)}px);
            min-width: 90px;
        }
    }
`;

internals.AppointmentTimeSelect = Styled(Select)`
    ${({ $showAppointmentTimeError }) => {

        if ($showAppointmentTimeError) {
            return S.css`

                outline: 2px solid ${({ theme }) => theme.palette.error.main};
            `;
        }
    }}
`;

internals.SuggestedEvents = Styled.div`
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

internals.SuggestedEvent = Styled(Button)`
    display: block;
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.palette.primary.main};
`;
