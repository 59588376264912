const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: Card } = require('@material-ui/core/Card');
const { default: CardContent } = require('@material-ui/core/CardContent');
const { default: FormControl } = require('@material-ui/core/FormControl');
const { default: Select } = require('@material-ui/core/Select');
const { default: MenuItem } = require('@material-ui/core/MenuItem');
const StrangeForms = require('strange-forms');
const Portlet = require('../../../components/Portlet');
const ScreeningFields = require('../../../components/ScreeningFields');
const PersonalDetailFields = require('../../../components/PersonalDetailFields');
const SortEvents = require('../../../utils/sort-events');

const internals = {};

module.exports = class UnlistedPatientScreeningPage extends StrangeForms(React.Component) {

    static propTypes = {
        searchResults: T.array,
        role: T.string,
        fetchData: T.func,
        patient: T.shape({
            firstName: T.string,
            lastName: T.string,
            address1: T.string,
            address2: T.string,
            city: T.string,
            st: T.string,
            zip: T.string,
            id: T.string,
            screenings: T.arrayOf(T.shape({
                id: T.string,
                createdAt: T.instanceOf(Date)
            }))
        }),
        program: T.shape({
            name: T.string,
            client: T.shape({
                name: T.string,
                logoUrl: T.string
            }),
            screeningMetrics: T.arrayOf(T.shape({
                id: T.string,
                name: T.string,
                formula: T.string,
                fields: T.arrayOf(T.shape({
                    name: T.string,
                    type: T.string,
                    units: T.string
                }))
            })),
            events: T.arrayOf(T.shape({
                name: T.string,
                date: T.string
            }))
        }),
        screeningCategories: T.arrayOf(T.shape({
            id: T.string,
            name: T.string
        }))
    };

    constructor(props) {

        super(props);

        this.state = {
            hasSearched: false,
            isSubmitting: false
        };

        this.emptyObject = {};

        this.strangeForm({
            fields: [
                'patientInfo',
                'screeningFieldsAndNotes',
                'newEvent',
                'appointment'
            ],
            get: {
                // The ScreeningFields component will handle resetting the form
                // with useEffect when it detects a change in the passed 'appointmentId'
                // screeningFieldsAndNotes:
                patientInfo: () => this.emptyObject,
                '*': () => ''
            },
            act: {
                '*': () => null
            },
            getFormValue: {
                screeningFieldsAndNotes: (val) => val,
                patientInfo: (val) => val,
                '*': (ev) => ev.target.value
            }
        });

        this.handleScreeningFieldsOnChange = this.proposeNew('screeningFieldsAndNotes');
    }

    async componentDidMount() {

        await this.props.fetchData();
    }

    handleSubmit = async (ev) => {

        if (ev && ev.preventDefault) {
            ev.preventDefault();
        }

        const { serializeScreeningValuesFromIds } = internals;

        const patientInfo = this.fieldValue('patientInfo');
        const { notes, ...resultsById } = this.fieldValue('screeningFieldsAndNotes');

        const eventId = this.fieldValue('newEvent');
        const appointmentId = this.fieldValue('appointment');

        const patient = {
            ...patientInfo,
            identifier: patientInfo.identifierDecrypted
        };

        delete patient.identifierDecrypted;

        const [err, result] = await this.props.onSubmit({
            eventId,
            appointmentId: appointmentId || null,
            screening: {
                results: serializeScreeningValuesFromIds(resultsById || {}),
                notes
            },
            patient
        });

        if (!err) {
            // Navigate to edit screening page on success so the screening can be confirmed by screeners
            if (result && result.patient && result.patient.id) {
                return this.props.history.push(`/patient/${result.patient.id}/screening`);
            }
        }
    }

    render() {

        const {
            filterAppointments,
            getInitScreeningFieldsValues,
            PageContainer,
            StyledCard
        } = internals;

        const {
            program,
            screeningCategories,
            role
        } = this.props;

        const {
            name = '',
            client = {},
            screeningMetrics = [],
            events = []
        } = program || {};

        const { logoUrl, name: clientName } = client;
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
        const thisWeeksEvents = events ? events.filter(({ date }) => new Date(date) >= oneWeekAgo).sort(SortEvents) : [];
        const initValue = getInitScreeningFieldsValues({ screeningMetrics });

        const selectedEvent = this.fieldValue('newEvent');

        const appointments = selectedEvent ?
            filterAppointments(this.props.events.find((e) => e.id === selectedEvent).appointments) :
            [];

        return (
            <PageContainer>
                <Portlet
                    title={`Program: ${name}`}
                    toolbar={logoUrl && <Portlet.LogoImg src={logoUrl} alt={`${clientName} logo`} />}
                    body={
                        <>
                            <StyledCard>
                                <CardContent>
                                    <div className='changeEvent'>
                                        <Typography variant='h6' gutterBottom>Change Event</Typography>
                                        <FormControl margin='dense'>
                                            <Select
                                                value={this.fieldValue('newEvent')}
                                                onChange={this.proposeNew('newEvent')}
                                                displayEmpty
                                            >
                                                <MenuItem value=''><em>Select Event</em></MenuItem>
                                                {
                                                    thisWeeksEvents.map(({ id, name: eventName, date }) => {

                                                        return <MenuItem key={id} value={id}>{date} - {eventName}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                        <FormControl margin='dense'>
                                            {!!appointments.length && (
                                                <Select
                                                    value={this.fieldValue('appointment')}
                                                    onChange={this.proposeNew('appointment')}
                                                    displayEmpty
                                                >
                                                    <MenuItem value=''><em>Select Appointment (Optional)</em></MenuItem>
                                                    {
                                                        appointments.map(({ id, time }) => {

                                                            return <MenuItem key={id} value={id}>{internals.to12HourTime(time)}</MenuItem>;
                                                        })
                                                    }
                                                </Select>
                                            )}
                                        </FormControl>
                                    </div>
                                </CardContent>
                            </StyledCard>
                            <PersonalDetailFields
                                value={this.fieldValue('patientInfo')}
                                onChange={this.proposeNew('patientInfo')}
                            />
                            <ScreeningFields
                                disabled={!selectedEvent}
                                role={role}
                                program={program}
                                screeningCategories={screeningCategories}
                                screeningMetrics={screeningMetrics}
                                // Appointments won't change so we want this to be static
                                appointmentId={null}
                                initValue={initValue}
                                // Keep track of the edited form in this value
                                value={this.fieldValue('screeningFieldsAndNotes')}
                                onChange={this.handleScreeningFieldsOnChange}
                                onClickSubmit={this.handleSubmit}
                                onClickConfirm={this.handleConfirm}
                                onClickRelease={this.handleShowModal}
                            />
                        </>
                    }
                />
            </PageContainer>
        );
    }
};

internals.filterAppointments = (appointments) => {

    return appointments.filter((appt) => appt.patientId === null && appt.time);
};

internals.to12HourTime = (time) => {

    const [hour, min] = time.split(':');

    const smallHour = hour === '12' ? hour : Number(hour) % 12;

    return `${smallHour}:${min} ${Number(hour) > 11 ? 'PM' : 'AM'}`;
};

internals.getInitScreeningFieldsValues = ({ screeningMetrics }) => {

    const vals = {};

    if (!screeningMetrics) {
        return vals;
    }

    vals.notes = '';

    screeningMetrics.forEach(({ id, fields }) => {

        vals[id] = {
            screeningMetricId: id,
            values: fields.reduce((collector, { name }) => ({
                ...collector,
                [name]: ''
            }), {})
        };
    });

    return vals;
};

internals.serializeScreeningValuesFromIds = (valuesById) => {

    return Object.keys(valuesById).reduce((collector, id) => ([
        ...collector,
        {
            screeningMetricId: valuesById[id].screeningMetricId,
            values: valuesById[id].values
        }
    ]), []);
};

internals.StyledCard = Styled(Card)`
    max-width: 900px;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;

    .changeEvent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
`;

internals.NotesField = Styled(TextField)`
    width: 100%;
    max-width: 500px;
`;

internals.PageContainer = Styled.div`
    width: 100%;
`;
