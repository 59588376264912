const Connect = require('react-redux').connect;
const EditEligibilityMappingPage = require('../components/EditEligibilityMapping');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        program: M.selectors.model.fetchProgram(state),
        eligibilityMapping: M.selectors.model.fetchEligibilityMapping(state)
    }),
    (dispatch, props) => ({
        updateEligibilityMapping: async (data) => {

            const [err] = await M.dispatch.model.updateEligibilityMapping(data);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Eligibility file updated successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err];
        },
        fetchProgram: () => M.dispatch.model.fetchProgram(props.match.params.id),
        fetchEligibilityMapping: () => M.dispatch.model.fetchEligibilityMapping(props.match.params.id)
    })
);

module.exports = internals.connect(EditEligibilityMappingPage);
