const React = require('react');
const T = require('prop-types');
const { default: ErrorIcon } = require('@material-ui/icons/Error');
const Header = require('./Header');
const SuperAdminNav = require('./SuperAdminNav');
const ErrorFallback = require('./ErrorFallback');
const LoadingFallback = require('./LoadingFallback');
const ProgramSwitcher = require('./ProgramSwitcher');
const { default: Styled } = require('styled-components');
const { default: ErrorBoundary } = require('react-error-boundary');
const AppSnackbar = require('../containers/Snackbar');
const Theme = require('../theme');
const Constants = require('../utils/constants');
const RedirectRole = require('../utils/redirect-role');

const internals = {};

module.exports = class Layout extends React.Component {

    static propTypes = {
        children: T.any,
        history: T.any,
        location: T.shape({
            key: T.string
        }),
        apiErrors: T.array,
        programs: T.array,
        currentProgramId: T.string,
        fetchData: T.func,
        clearApiErrors: T.func,
        isAuthenticated: T.bool,
        selectProgram: T.func,
        logout: T.func.isRequired,
        user: T.object,
        role: T.string
    };

    constructor(props) {

        super(props);

        this.state = {
            isDrawerOpen: false,
            isSwitcherOpen: false
        };
    }

    componentDidMount = () => {

        const route = this.props.history.location.pathname;

        // Enforce role-based landing pages
        // TODO: Superadmins should be excluded from this redirect
        if (Object.values(Constants.LANDING_PAGES).includes(route) && Constants.LANDING_PAGES[this.props.role] !== route) {
            RedirectRole(this.props);
        }

        this.props.fetchData();

        window.scrollTo(0, 0);
        this.checkWidth = () => {

            // We'll want to user a permanent nav drawer at desktop widths.
            // Normally we could use theme.breakpoints.down('md'), but that gives us the "@media" too
            const { matches } = window.matchMedia(`(min-width: ${Theme.breakpoints.values.md}px)`);
            this.setState({ isDesktopWidth: matches });
        };

        this.checkWidth();
        window.addEventListener('resize', this.checkWidth);

        this.props.history.listen((location, action) => {

            if (action === 'PUSH') {
                this.props.clearApiErrors();
            }
        });
    }

    componentWillUnmount() {

        window.removeEventListener('resize', this.checkWidth);
    }

    toggleDrawer = () => {

        this.setState((currentState) => {

            return { isDrawerOpen: !currentState.isDrawerOpen };
        });
    }

    onRequestOpenSwitcher = () => {

        this.setState({ isSwitcherOpen: true });
    }

    onRequestCloseSwitcher = () => {

        this.setState({ isSwitcherOpen: false });
    }

    handleSetActiveProgram = (id) => {

        this.props.selectProgram(id);
    }

    render() {

        const { Container, AppContainer, LayoutContainer, ErrorMessage } = internals;
        const { children, location, isAuthenticated, logout, apiErrors, programs, currentProgramId, user, role } = this.props;
        const { isDrawerOpen, isSwitcherOpen, isDesktopWidth } = this.state;
        const currentProgram = programs.find((program) => program.id === currentProgramId) || null;
        const userProgramIds = user.programs.map(( { id }) => id);

        return (
            <LayoutContainer>
                {(role === Constants.ROLE.SUPERADMIN || role === Constants.ROLE.ADMIN) &&
                    <SuperAdminNav
                        role={role}
                        isPermanent={isDesktopWidth}
                        isDrawerOpen={isDrawerOpen}
                        handleToggleDrawer={this.toggleDrawer}
                        activeProgram={currentProgram}
                    />}
                <AppContainer>
                    <Header
                        role={role}
                        hideMenuIcon={isDesktopWidth || role === Constants.ROLE.SCREENER}
                        hideSwapIcon={!(role === Constants.ROLE.SCREENER && user.programs.length > 1)}
                        hideLogo={isDesktopWidth && (role === Constants.ROLE.SUPERADMIN || role === Constants.ROLE.ADMIN)}
                        handleToggleDrawer={this.toggleDrawer}
                        isAuthenticated={isAuthenticated}
                        user={user}
                        logout={logout}
                        handleOpenSwitcher={this.onRequestOpenSwitcher}
                    />
                    <Container>
                        <ErrorBoundary key={location.key} FallbackComponent={ErrorFallback}>
                            <React.Suspense fallback={<LoadingFallback />}>
                                {!!apiErrors.length && <ErrorMessage><p><ErrorIcon /> {apiErrors[0].message}</p></ErrorMessage>}
                                {!apiErrors.length && children}
                            </React.Suspense>
                        </ErrorBoundary>
                    </Container>
                    <AppSnackbar />
                    {programs && !!programs.length &&
                    <ProgramSwitcher
                        activeProgram={currentProgram}
                        programs={programs.filter(({ id }) => userProgramIds.includes(id))}
                        isOpen={isSwitcherOpen}
                        handleClose={this.onRequestCloseSwitcher}
                        onSelectProgram={this.handleSetActiveProgram}
                    />}
                </AppContainer>
            </LayoutContainer>
        );
    }
};

internals.ErrorMessage = Styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.theme.spacing(50)}px;
    max-width: 100%;
    margin: 0 auto;
    font-size: ${(props) => props.theme.spacing(2)}px;
    text-align: center;

    svg {
        display: block;
        width: ${(props) => props.theme.spacing(40)}px;
        height: ${(props) => props.theme.spacing(40)}px;
        margin: ${(props) => props.theme.spacing(2)}px auto;
        fill: rgba(0, 0, 0, 0.1);
    }
`;

internals.LayoutContainer = Styled.div`
    display: flex;
`;

internals.Container = Styled.div`
    display: flex;
    padding-top:10px;
    background-color: ${({ theme }) => theme.palette.background.paleGray};

    // Alternatively,the Toolbar component in <Header /> could be given
    // a disableGutters prop, and the left/right padding could be applied
    // to the entire AppContainer.
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
    flex: 1;
    @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
        padding-left: ${(props) => props.theme.spacing(3)}px;
        padding-right: ${(props) => props.theme.spacing(3)}px;
    }
`;

internals.AppContainer = Styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;
