const React = require('react');
const T = require('prop-types');
const StrangeForms = require('strange-forms');
const EventPicker = require('./EventPicker');
const { default: Styled } = require('styled-components');
const { default: Dialog } = require('@material-ui/core/Dialog');
const { default: DialogActions } = require('@material-ui/core/DialogActions');
const { default: DialogContent } = require('@material-ui/core/DialogContent');
const { default: DialogContentText } = require('@material-ui/core/DialogContentText');
const { default: DialogTitle } = require('@material-ui/core/DialogTitle');
const { default: Button } = require('@material-ui/core/Button');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: IconButton } = require('@material-ui/core/IconButton');
const { default: CloseIcon } = require('@material-ui/icons/Close');
const { default: FormControlLabel } = require('@material-ui/core/FormControlLabel');
const { default: Checkbox } = require('@material-ui/core/Checkbox');

const internals = {};

module.exports = class RescheduleModal extends StrangeForms(React.Component) {

    static propTypes = {
        currentAppointment: T.object.isRequired,
        confirmAction: T.func.isRequired,
        patientName: T.string,
        affirmativeLabel: T.string,
        negativeLabel: T.oneOfType([T.string, T.oneOf([null])]),
        consentFormUrl: T.string,
        toggleModal: T.func.isRequired,
        isModalOpen: T.bool,
        events: T.array.isRequired,
        showNotificationToggle: T.bool
    };

    static defaultProps = {
        affirmativeLabel: 'Submit',
        negativeLabel: 'Cancel',
        isModalOpen: true,
        showNotificationToggle: false
    };

    static fields = [
        'appointmentInfo',
        'email',
        'phone',
        'shouldNotify'
    ];

    constructor(props) {

        super(props);

        this.state = {
            isSubmitting: false
        };

        this._unmounted = false;

        this.strangeForm({
            fields: RescheduleModal.fields,
            get: {
                email: (someProps, field) => someProps.value && someProps.value[field] || someProps.currentAppointment.email,
                phone: (someProps, field) => someProps.value && someProps.value[field] || someProps.currentAppointment.phone,
                shouldNotify: (someProps, field) => {

                    return someProps.value &&
                        typeof someProps.value[field] === 'boolean' &&
                        someProps.value[field] || true;
                },
                appointmentInfo: () => ({
                    eventId: null,
                    appointmentId: null
                }),
                '*': (someProps, field) => someProps.value && someProps.value[field] || ''
            },
            act: () => null,
            getFormValue: {
                appointmentInfo: (val) => val,
                '*': (ev) => ev.target.value
            }
        });
    }

    handleSubmit = async () => {

        // Just showing what we're sending up
        const {
            eventId,
            appointmentId
        } = this.fieldValue('appointmentInfo');

        const formData = {
            eventId,
            appointmentId,
            currentAppointment: this.props.currentAppointment,
            email: this.fieldValue('email'),
            phone: this.fieldValue('phone'),
            sendEmail: !!this.fieldValue('shouldNotify')
        };

        this.setState({ isSubmitting: true });

        await this.props.confirmAction(formData);

        if (!this._unmounted) {
            this.setState({ isSubmitting: false });
        }
    }

    shouldDisableSubmit = () => {

        const { eventId } = this.fieldValue('appointmentInfo');

        return (
            !eventId ||
            !this.fieldValue('email') ||
            this.state.isSubmitting
        );
    }

    componentWillUnmount() {

        // "Necessary" evil since props.confirmAction() is likely but not guaranteed to cause an unmount

        this._unmounted = true;
    }

    render() {

        // eslint-disable-next-line no-unused-vars
        const {
            isModalOpen,
            toggleModal,
            affirmativeLabel,
            negativeLabel,
            patientName,
            events,
            showNotificationToggle,
            ...others
        } = this.props;

        const { CloseButton, StyledTypography, StyledEventPicker } = internals;

        return (
            <Dialog
                open={isModalOpen}
                onClose={toggleModal}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                {...others}
            >
                <DialogTitle disableTypography id='alert-dialog-title'>
                    <StyledTypography variant='h6'>Reschedule An Appointment</StyledTypography>
                    <CloseButton aria-label='close' onClick={toggleModal}>
                        <CloseIcon />
                    </CloseButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Schedule an appointment for <strong>{patientName}</strong>.
                    </DialogContentText>
                    <StyledEventPicker
                        events={events}
                        value={this.fieldValue('appointmentInfo')}
                        onChange={this.proposeNew('appointmentInfo')}
                    />
                    <Typography>* Email address <strong>required</strong> for confirmation and updates. An email reminder will be sent to the provided email address.</Typography>
                    <TextField
                        label='Email'
                        type='email'
                        value={this.fieldValue('email')}
                        onChange={this.proposeNew('email')}
                        fullWidth
                    />
                    <Typography gutterBottom>If the patient would also like to receive a text message from the program, provide their mobile number below (including area code — 555-555-5555).</Typography>
                    <Typography gutterBottom variant='body2'><strong>The following texts will be sent when applicable:</strong></Typography>
                    <Typography gutterBottom variant='body2'>Appointment Confirmation, Appointment Change Confirmation, Appointment Cancellation Confirmation, Appointment Reminder, and Event-Specific Communications.</Typography>
                    <Typography gutterBottom variant='body2'>At any time you can reply STOP to the text messages to unsubscribe, or HELP to receive further information.</Typography>
                    <TextField
                        label='Mobile'
                        type='tel'
                        value={this.fieldValue('phone')}
                        onChange={this.proposeNew('phone')}
                        margin='dense'
                    />
                    {showNotificationToggle && (
                        <div>
                            <FormControlLabel
                                label='Notify patient'
                                control={
                                    <Checkbox
                                        checked={this.fieldValue('shouldNotify')}
                                        onChange={this.proposeNew('shouldNotify')}
                                        name='shouldNotify'
                                    />
                                }
                            />
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    {negativeLabel !== null &&
                        <Button onClick={toggleModal}>
                            {negativeLabel}
                        </Button>}
                    <Button
                        onClick={this.handleSubmit}
                        color='primary'
                        variant='contained'
                        disabled={this.shouldDisableSubmit()}
                    >
                        {affirmativeLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
};

internals.FieldsContainer = Styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

internals.StyledTypography = Styled(Typography)`
    // 45px is the size of the CloseButton
    max-width: calc(100% - 45px);
`;

internals.CloseButton = Styled(IconButton)`
    position: absolute;
    right: ${({ theme }) => theme.spacing(1)}px;
    top: ${({ theme }) => theme.spacing(1)}px;
`;

internals.StyledEventPicker = Styled(EventPicker)`
    padding: ${({ theme }) => theme.spacing(1)}px 0;
`;
