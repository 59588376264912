const Connect = require('react-redux').connect;
const SearchFormsPage = require('../components/SearchFormsPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        forms: M.selectors.model.fetchForms(state)
    }),
    (dispatch, props) => ({
        fetchForms: () => M.dispatch.model.fetchForms(),
        deleteForm: (id) => M.dispatch.model.deleteForm(id)
    })
);

module.exports = internals.connect(SearchFormsPage);
