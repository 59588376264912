const React = require('react');
const T = require('prop-types');
const { NavLink } = require('react-router-dom');
const { default: Styled } = require('styled-components');
const { default: Memoize } = require('memoize-one');
const { default: Button } = require('@material-ui/core/Button');
const { default: Chip } = require('@material-ui/core/Chip');
const StrangeForms = require('strange-forms');
const Portlet = require('../../../components/Portlet');
const UserFields = require('../../../components/UserFields');
const AlertDialog = require('../../../components/AlertDialog');

const internals = {};

module.exports = class EditUserPage extends StrangeForms(React.Component) {

    static propTypes = {
        onSubmit: T.func.isRequired,
        doesUserExist: T.bool.isRequired,
        isLoading: T.bool.isRequired
    };

    constructor(props) {

        super(props);

        const defaultUserFields = {
            name: '',
            phone: '',
            email: '',
            role: ''
        };

        this.state = {
            userFields: { ...defaultUserFields },
            isSubmitting: false,
            dialogName: null
        };

        this.initialState = { ...this.state };

        this.fields = {
            ...defaultUserFields,
            userFields: { ...defaultUserFields }
        };

        this.strangeForm({
            fields: Object.keys(this.fields),
            get: {
                '*': () => '',
                userFields: Memoize((someProps) => {

                    return someProps.user || {};
                }, ([props1], [props2]) => {

                    return (
                        (props1.user && props2.user) &&
                        props1.user.id === props2.user.id &&
                        props1.user.name === props2.user.name &&
                        props1.user.phone === props2.user.phone &&
                        props1.user.email === props2.user.email &&
                        props1.user.role === props2.user.role
                    );
                })
            },
            act: (field, value) => this.setState({ [field]: value }),
            getFormValue: {
                '*': (ev) => ev.target.value,
                userFields: (val) => val
            }
        });
    }

    componentDidMount() {

        this.props.fetchUser();
    }

    shouldDisableSubmit() {

        const { email, role } = this.fieldValue('userFields') || {};
        return !email || this.state.isSubmitting || !role;
    }

    handleSubmit = async (ev) => {

        ev.preventDefault();
        const accountInfo = { ...this.fieldValue('userFields') };
        const { role } = accountInfo;
        delete accountInfo.role;
        this.setState({ isSubmitting: true });
        // API expects email + username, but we want to capture only email and use that as username
        const [err] = await this.props.onSubmit(
            {
                user: { ...accountInfo, username: accountInfo.email, roleId: null },
                role
            }
        );
        this.setState({ isSubmitting: false });

        if (!err) {
            this.setState({ ...this.initialState });
        }
    }

    handleShowModal = (dialogName) => {

        this.setState({
            dialogName
        });
    }

    closeDialog = () => {

        this.setState({ dialogName: null });
    };

    render() {

        const { PageContainer, StyledButton, ForwardedLink, DeleteButton, ButtonWrapper } = internals;
        const { doesUserExist, isLoading, user = {} } = this.props;
        const { archivedAt = null } = user || {};
        const isArchived = !!archivedAt;
        const { dialogName } = this.state;

        if (isLoading) {
            return null;
        }

        return (
            <PageContainer>
                {doesUserExist &&
                <Portlet
                    title='Edit User'
                    toolbar={
                        isArchived &&
                        <Chip
                            size='small'
                            label='Archived'
                        />
                    }
                    body={
                        <>
                            <UserFields
                                fields={this.fieldValue('userFields')}
                                onChange={this.proposeNew('userFields')}
                            />
                            <AlertDialog
                                dialogTitle='Delete client?'
                                dialogDescription={`This will delete "${this.props.user && this.props.user.name}" permanently.`}
                                affirmativeLabel='Delete'
                                isModalOpen={dialogName === 'delete'}
                                confirmAction={() => this.props.onConfirmDelete()}
                                toggleModal={this.closeDialog}
                            />
                        </>
                    }
                    foot={
                        <ButtonWrapper>
                            <StyledButton
                                onClick={this.handleSubmit}
                                variant='contained'
                                color='primary'
                                disabled={this.shouldDisableSubmit()}
                            >
                                Save
                            </StyledButton>
                            <StyledButton
                                component={ForwardedLink}
                                to='/users/search'
                                variant='text'
                            >
                                Cancel
                            </StyledButton>
                            <DeleteButton
                                onClick={() => this.handleShowModal('delete')}
                            >
                                Delete
                            </DeleteButton>
                        </ButtonWrapper>
                    }
                />}
                {!doesUserExist &&
                    <Portlet
                        title='User Not Found'
                        body={
                            <StyledButton
                                component={ForwardedLink}
                                to='/users/search'
                                variant='text'
                            >
                                Search Users
                            </StyledButton>
                        }
                    />}
            </PageContainer>
        );
    }
};

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.StyledButton = Styled(Button)`
    margin: 0 8px;
`;

internals.DeleteButton = Styled(internals.StyledButton)`
    margin-left: auto;
    color: ${({ theme }) => theme.palette.error.main};
`;

internals.ButtonWrapper = Styled.div`
    display: flex;
`;

internals.ForwardedLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);
