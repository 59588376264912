const Connect = require('react-redux').connect;
const EditUserPage = require('../components/EditUserPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        user: M.selectors.model.fetchUser(state),
        doesUserExist: !M.selectors.model.fetchUserNotFound(state),
        isLoading: M.selectors.model.fetchUserLoading(state)
    }),
    (dispatch, props) => ({
        fetchUser: () => M.dispatch.model.fetchUser(props.match.params.id),
        onConfirmDelete: async () => {

            const [err] = await M.dispatch.model.deleteUser(props.match.params.id);
            if (!err) {
                M.dispatch.snackbar.pushToQueue('User deleted successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }
        },
        onSubmit: async (userInfo) => {

            const [err] = await M.dispatch.model.editUser(userInfo);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('User updated successfully');
                M.dispatch.router.push('/users/search');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err];
        }
    })
);

module.exports = internals.connect(EditUserPage);
