const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: Button } = require('@material-ui/core/Button');
const Portlet = require('../../../components/Portlet');
const SectionLabel = require('../../../components/SectionLabel');

const internals = {};

module.exports = class PatientScheduleCancelPage extends React.Component {

    static propTypes = {
        history: T.shape({
            goBack: T.func
        })
    };

    handleGoBack = () => this.props.history.goBack();

    render() {

        const { PageContainer, StyledButton } = internals;

        return (
            <PageContainer>
                <Portlet
                    body={
                        <>
                            <SectionLabel>Appointment canceled</SectionLabel>
                            <Typography>Your  appointment has been successfully canceled.</Typography>
                            <StyledButton
                                onClick={this.handleGoBack}
                                variant='contained'
                                color='primary'
                            >
                                Return to Scheduling Page
                            </StyledButton>
                        </>
                    }
                />
            </PageContainer>
        );
    }
};

internals.PageContainer = Styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: ${({ theme }) => theme.spacing(100)}px;
    margin: 0 auto;
`;

internals.StyledButton = Styled(Button)`
  margin: ${({ theme }) => theme.spacing(2)}px 0;
`;
