const Connect = require('react-redux').connect;
const SearchProgramsPage = require('../components/SearchProgramsPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        programs: M.selectors.model.fetchPrograms(state),
        role: M.selectors.auth.getUserRole(state)
    }),
    (dispatch, props) => ({
        selectProgram: M.dispatch.app.selectProgram,
        fetchData: () => M.dispatch.model.fetchPrograms(),
        archiveProgram: async (id) => {

            const [err] = await M.dispatch.model.archiveProgram(id);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Program updated successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }
        },
        deleteProgram: async (id) => {

            const [err] = await M.dispatch.model.deleteProgram(id);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Program deleted successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }
        },
        onSubmit: async (programInfo) => {

            const [err] = await M.dispatch.model.editProgram(programInfo);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Program updated successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }
        }
    })
);

module.exports = internals.connect(SearchProgramsPage);
