const Connect = require('react-redux').connect;
const Layout = require('../components/Layout');
const M = require('../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        isAuthenticated: M.selectors.auth.getIsAuthenticated(state),
        apiErrors: state.app.apiErrors,
        programs: M.selectors.model.fetchPrograms(state),
        currentProgramId: M.selectors.app.getCurrentProgramId(state),
        user: M.selectors.auth.getUser(state),
        role: M.selectors.auth.getUserRole(state)
    }),
    (dispatch) => ({
        fetchData: M.dispatch.model.fetchPrograms,
        selectProgram: M.dispatch.app.selectProgram,
        clearApiErrors: M.dispatch.app.clearApiErrors,
        logout: async () => {

            const [err, result] = await M.dispatch.auth.logout();
            return [err, result];
        }
    })
);

module.exports = internals.connect(Layout);
