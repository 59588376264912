const React = require('react');
const T = require('prop-types');
const { default: FormControl } = require('@material-ui/core/FormControl');
const { default: Select } = require('@material-ui/core/Select');
const { default: MenuItem } = require('@material-ui/core/MenuItem');

module.exports = (props) => {

    const { clients, ...otherProps } = props;

    return (
        <FormControl margin='dense'>
            <Select
                {...otherProps}
                displayEmpty
                MenuProps={{
                    PaperProps: {
                        // each <li> is ~31px
                        // And there's ~8px padding-top
                        // Let's show 4.5 menu items
                        style: { maxHeight: 132 }
                    }
                }}
            >
                <MenuItem value=''><em>Client</em></MenuItem>
                {
                    clients && clients.map(({ id, name }) => <MenuItem key={id} value={id}>{name}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
};

module.exports.propTypes = {
    clients: T.array
};

module.exports.displayName = 'ClientSelect';
