const React = require('react');
const T = require('prop-types');
const IsEqual = require('lodash/isEqual');
const { NavLink } = require('react-router-dom');
const { default: ReactJson } = require('react-json-view');
const { default: Styled } = require('styled-components');
const { default: Button } = require('@material-ui/core/Button');
const { default: Chip } = require('@material-ui/core/Chip');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: FormGroup } = require('@material-ui/core/FormGroup');
const { default: ArrowDown } = require('@material-ui/icons/ArrowDropDown');
const { default: ArrowUp } = require('@material-ui/icons/ArrowDropUp');
const Portlet = require('../../../components/Portlet');
const SectionLabel = require('../../../components/SectionLabel');
const { ROLE } = require('../../../utils/constants');

const internals = {};

const emptyMetric = {
    name: 'New Metric',
    formula: null,
    fields: [
        {
            name: 'New Metric',
            type: 'text',
            choices: []
        }
    ],
    referenceRanges: [],
    template: '[@]'
};

module.exports = class SetupScreeningMetricsPage extends React.Component {

    static propTypes = {
        fetchData: T.func.isRequired,
        screeningMetrics: T.array,
        screeningCategories: T.array,
        isLoading: T.bool,
        role: T.string,
        onSubmit: T.func.isRequired,
        onSubmitEditMetric: T.func.isRequired
    };

    constructor(props) {

        super(props);

        this.state = {
            isSubmitting: false,
            activeMetric: '',
            updatedMetric: null,
            categories: []
        };
    }

    async componentDidMount() {

        await this.props.fetchData();

        this.parseListing(this.props.screeningCategories, this.props.screeningMetrics);
    }

    componentWillReceiveProps(nextProps) {

        if (!IsEqual(nextProps.screeningMetrics, this.props.screeningMetrics)) {
            this.parseListing(this.props.screeningCategories, nextProps.screeningMetrics);
        }
    }

    shouldDisableSubmit() {

        return false;
    }

    handleSubmit = async (ev) => {

        ev.preventDefault();
        this.setState({ isSubmitting: true });

        const mapped = this.state.categories.flatMap((cat) => {

            return [...cat.metrics]
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map(({ id }, i) => ({ id, sortOrder: i }));
        });

        await this.props.onSubmit(mapped);

        this.setState({ isSubmitting: false });
    }

    handleFormChange = (e) => this.setState({ updatedMetric: e.updated_src });

    handleNew = (e) => {

        e.preventDefault();
        this.setState({ updatedMetric: { ...emptyMetric, categoryId: this.props.screeningCategories[0].id } }, async () => {

            await this.handleSave(e);
        });
    }

    handleSave = async (e) => {

        e.preventDefault();
        this.setState({ isSubmitting: true });
        await this.props.onSubmitEditMetric(this.state.updatedMetric);
        await this.props.fetchData();

        this.setState({ activeMetric: null, isSubmitting: false });
    }

    handleToggleEdit = (e) => {

        e.preventDefault();
        if (e.currentTarget.value === this.state.activeMetric) {

            return this.setState({ activeMetric: null });
        }

        this.setState({ activeMetric: e.currentTarget.value });
    }

    sortMetrics = (metrics) => {

        return [...metrics]
            .sort((a, b) => ((a.sortOrder > b.sortOrder) ? 1 : -1))
            .map((metric, index) => ({ ...metric, sortOrder: metric.sortOrder || index }));
    }

    parseListing = (categories, metrics) => {

        this.setState({
            categories: categories.map((category) => ({
                category,
                metrics: this.sortMetrics(metrics.filter((metric) => metric.category.id === category.id))
            }))
        });
    }

    handleChangeSortOrder = (id, delta) => {

        const cats = this.state.categories;

        const category = cats.find((c) => c.metrics.some((m) => m.id === id));
        const metric = category.metrics.find((m) => m.id === id);
        const ogSortOrder = metric.sortOrder;

        if ((ogSortOrder === 0 && delta === '-1') || (ogSortOrder === category.metrics.length - 1 && delta === '+1')) {
            return;
        }

        let sibling;

        if (delta === '-1') {
            sibling = category.metrics[ogSortOrder - 1];
        }
        else {
            sibling = category.metrics[ogSortOrder + 1];
        }

        metric.sortOrder = sibling.sortOrder;
        sibling.sortOrder = ogSortOrder;

        category.metrics = this.sortMetrics(category.metrics);

        this.setState({
            categories: cats
        });
    }

    render() {

        const { PageContainer, StyledButton, ForwardedLink, ButtonWrapper, EditContainer, MetricWrapper, SortButton, SortWrapper, EditWrapper } = internals;
        const { isLoading, role } = this.props;
        const isSuperAdmin = role === ROLE.SUPERADMIN;

        if (isLoading) {
            return null;
        }

        return (
            <PageContainer>
                <Portlet
                    title='Screening Metrics'
                    toolbar={
                        <Chip
                            color='secondary'
                            label='Add New Metric +'
                            onClick={this.handleNew}
                            clickable
                        />
                    }
                    body={
                        <>
                            {
                                this.state.categories.map(({ category, metrics }) => {

                                    return (
                                        <React.Fragment key={category.id}>
                                            <SectionLabel>{category.name}</SectionLabel>
                                            <FormGroup>
                                                {
                                                    metrics.map((metric, index) => {

                                                        return (
                                                            <MetricWrapper key={metric.id}>
                                                                <ButtonWrapper>
                                                                    <SortWrapper>
                                                                        <SortButton
                                                                            disabled={!index}
                                                                            onClick={() => this.handleChangeSortOrder(metric.id, '-1')}
                                                                        >
                                                                            <ArrowUp />
                                                                        </SortButton>
                                                                        <SortButton
                                                                            disabled={index === metrics.length - 1}
                                                                            onClick={() => this.handleChangeSortOrder(metric.id, '+1')}
                                                                        >
                                                                            <ArrowDown />
                                                                        </SortButton>
                                                                    </SortWrapper>
                                                                    <Typography>{metric.name}</Typography>
                                                                    {isSuperAdmin && (
                                                                        <Button
                                                                            onClick={this.handleToggleEdit}
                                                                            value={metric.id}
                                                                            size='small'
                                                                            color='primary'
                                                                            style={{ margin: '0' }}
                                                                        >
                                                                            Edit {this.state.activeMetric === metric.id ? <ArrowUp /> : <ArrowDown />}
                                                                        </Button>
                                                                    )}
                                                                </ButtonWrapper>
                                                                {this.state.activeMetric === metric.id && (
                                                                    <EditWrapper>
                                                                        <ReactJson
                                                                            src={metric}
                                                                            onEdit={this.handleFormChange}
                                                                            onDelete={this.handleFormChange}
                                                                            onAdd={this.handleFormChange}
                                                                        />
                                                                        <EditContainer>
                                                                            <StyledButton
                                                                                onClick={this.handleSave}
                                                                                variant='contained'
                                                                                color='primary'
                                                                                disabled={this.shouldDisableSubmit()}
                                                                            >
                                                                                Save
                                                                            </StyledButton>
                                                                            <StyledButton
                                                                                onClick={this.handleToggleEdit}
                                                                                variant='text'
                                                                            >
                                                                                Cancel
                                                                            </StyledButton>
                                                                        </EditContainer>
                                                                    </EditWrapper>
                                                                )}
                                                            </MetricWrapper>
                                                        );
                                                    })
                                                }
                                            </FormGroup>
                                        </React.Fragment>
                                    );
                                })
                            }
                        </>
                    }
                    foot={
                        <ButtonWrapper>
                            <StyledButton
                                onClick={this.handleSubmit}
                                variant='contained'
                                color='primary'
                                disabled={this.shouldDisableSubmit()}
                            >
                                Save
                            </StyledButton>
                            <StyledButton
                                component={ForwardedLink}
                                to='/'
                                variant='text'
                            >
                                Cancel
                            </StyledButton>
                        </ButtonWrapper>
                    }
                />
            </PageContainer>
        );
    }
};

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.StyledButton = Styled(Button)`
    margin: 0 8px;
`;

internals.ButtonWrapper = Styled.div`
    display: flex;
    align-items: center;
`;

internals.EditWrapper = Styled.div`
    padding: ${({ theme }) => theme.spacing(1)}px ${({ theme }) => theme.spacing(5)}px
`;

internals.EditContainer = Styled.div`
    padding: ${({ theme }) => theme.spacing(1)}px 0;
    margin: ${({ theme }) => theme.spacing(1)}px 0;
`;

internals.MetricWrapper = Styled.div`
    padding: ${({ theme }) => theme.spacing(1)}px 0;
    border-bottom: 1px solid #ddd;

    &:last-child {
        border-bottom: none;
    }
`;

internals.SortButton = Styled(Button)`
    min-width: 0;
    margin: 0 ${({ theme }) => theme.spacing(2)}px 0 0;
    padding: 0;
`;

internals.SortWrapper = Styled.div`
    display: flex;
    flex-direction: column;
`;

internals.ForwardedLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);
