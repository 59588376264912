const MiddleEnd = require('strange-middle-end');

module.exports = MiddleEnd.createTypes('model', {
    CREATE_USER: MiddleEnd.type.async,
    FETCH_USER: MiddleEnd.type.async,
    FETCH_USERS: MiddleEnd.type.async,
    EDIT_USER: MiddleEnd.type.async,
    DELETE_USER: MiddleEnd.type.async,
    CREATE_CLIENT: MiddleEnd.type.async,
    EDIT_CLIENT: MiddleEnd.type.async,
    FETCH_CLIENT: MiddleEnd.type.async,
    FETCH_CLIENTS: MiddleEnd.type.async,
    UPLOAD_CLIENT_LOGO: MiddleEnd.type.async,
    DELETE_CLIENT: MiddleEnd.type.async,
    ARCHIVE_CLIENT: MiddleEnd.type.async,
    CREATE_PROGRAM: MiddleEnd.type.async,
    FETCH_PROGRAM: MiddleEnd.type.async,
    FETCH_PROGRAMS: MiddleEnd.type.async,
    DELETE_PROGRAM: MiddleEnd.type.async,
    EDIT_PROGRAM: MiddleEnd.type.async,
    ARCHIVE_PROGRAM: MiddleEnd.type.async,
    UPLOAD_ELIGIBILITY: MiddleEnd.type.async,
    FETCH_ELIGIBILITY_FILES: MiddleEnd.type.async,
    UPDATE_ELIGIBILTY_MAPPING: MiddleEnd.type.async,
    FETCH_ELIGIBILITY_MAPPING: MiddleEnd.type.async,
    UPLOAD_CONSENT: MiddleEnd.type.async,
    UPLOAD_EVENTS: MiddleEnd.type.async,
    UPLOAD_PDFFORM: MiddleEnd.type.async,
    UPLOAD_COVIDFORM: MiddleEnd.type.async,
    UPLOAD_COVID_REQUISITION: MiddleEnd.type.async,
    UPLOAD_APPOINTMENTS: MiddleEnd.type.async,
    FETCH_SCREENING_METRICS: MiddleEnd.type.async,
    UPDATE_SCREENING_METRICS_SORT: MiddleEnd.type.async,
    FETCH_SCREENING_CATEGORIES: MiddleEnd.type.async,
    SEARCH_PATIENTS: MiddleEnd.type.async,
    FETCH_PATIENT: MiddleEnd.type.async,
    CREATE_PATIENT: MiddleEnd.type.async,
    UPDATE_PATIENT: MiddleEnd.type.async,
    FETCH_SCREENING: MiddleEnd.type.async,
    EDIT_SCREENING: MiddleEnd.type.async,
    CONFIRM_SCREENING: MiddleEnd.type.async,
    EDIT_SCREENING_METRICS: MiddleEnd.type.async,
    CREATE_UNLINKED_SCREENING: MiddleEnd.type.async,
    FETCH_EVENTS: MiddleEnd.type.async,
    FETCH_ALL_EVENTS: MiddleEnd.type.async,
    FETCH_APPOINTMENT: MiddleEnd.type.async,
    UPDATE_APPOINTMENT: MiddleEnd.type.async,
    SCHEDULE_APPOINTMENT: MiddleEnd.type.async,
    RESCHEDULE_APPOINTMENT: MiddleEnd.type.async,
    CANCEL_APPOINTMENT: MiddleEnd.type.async,
    SEND_CONFIRMATION: MiddleEnd.type.async,
    FETCH_EVENT: MiddleEnd.type.async,
    EDIT_EVENT: MiddleEnd.type.async,
    DELETE_EVENT: MiddleEnd.type.async,
    SEND_EVENT_COMMUNICATION: MiddleEnd.type.async,
    FETCH_CLIENT_REPORT: MiddleEnd.type.async,
    FETCH_PARTICIPATION_REPORT: MiddleEnd.type.async,
    FETCH_SCREENINGS_WITH_RESULTS: MiddleEnd.type.async,
    FETCH_ALL_APPOINTMENTS_FOR_EVENT: MiddleEnd.type.async,
    SEND_RESULTS_EMAILS: MiddleEnd.type.async,
    PURGE_DATA: MiddleEnd.type.async,
    UPSERT_FORM: MiddleEnd.type.async,
    FETCH_FORMS: MiddleEnd.type.async,
    FETCH_FORM: MiddleEnd.type.async,
    DELETE_FORM: MiddleEnd.type.async,
    UPSERT_FORMSUBMISSION: MiddleEnd.type.async,
    FETCH_FORMSUBMISSION: MiddleEnd.type.async,
    FETCH_FORMSUBMISSIONS: MiddleEnd.type.async
});
