const Connect = require('react-redux').connect;
const PatientSchedulePage = require('../components/PatientSchedulePage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        events: M.selectors.model.fetchEvents(state),
        program: M.selectors.model.fetchProgram(state),
        patient: M.selectors.model.fetchPatient(state)

    }),
    (dispatch, props) => ({
        fetchData: async () => {

            await Promise.all([
                M.dispatch.model.fetchProgram(props.match.params.id),
                M.dispatch.model.fetchEvents(props.match.params.id),
                M.dispatch.model.fetchPatient(props.match.params.patientId)
            ]);
        },
        submitAppointment: async (appointmentInfo) => {

            const [err, res] = await M.dispatch.model.scheduleAppointment({ patientId: props.match.params.patientId, ...appointmentInfo });

            if (!err) {
                // Pull the id from the returned appt.
                const { result: apptId } = res;
                M.dispatch.router.push(`/portal/${props.match.params.id}/success/${apptId}`);
                M.dispatch.snackbar.pushToQueue('Appointment scheduled successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('This appointment is no longer available');
            }
        },
        rescheduleAppointment: async (appointmentInfo) => {

            const [err, res] = await M.dispatch.model.rescheduleAppointment({ patientId: props.match.params.patientId, ...appointmentInfo });

            if (!err) {
                // Pull the id from the returned appt.
                const { result: apptId } = res;
                M.dispatch.router.push(`/portal/${props.match.params.id}/success/${apptId}`);
                M.dispatch.snackbar.pushToQueue('Appointment rescheduled successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('This appointment is no longer available');
            }
        },
        cancelAppointment: async (appointmentInfo) => {

            const [err] = await M.dispatch.model.cancelAppointment({ patientId: props.match.params.patientId, ...appointmentInfo });
            if (!err) {
                M.dispatch.snackbar.pushToQueue('Appointment cancelled successfully');
                M.dispatch.router.push(`/portal/${props.match.params.id}/cancel`);
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }
        }
    })
);

module.exports = internals.connect(PatientSchedulePage);
