const React = require('react');
const T = require('prop-types');
const { NavLink } = require('react-router-dom');
const { default: Memoize } = require('memoize-one');
const { default: RichTextEditor } = require('react-rte');
const { default: Styled } = require('styled-components');
const { default: Button } = require('@material-ui/core/Button');
const StrangeForms = require('strange-forms');
const Portlet = require('../../../components/Portlet');
const SectionLabel = require('../../../components/SectionLabel');

const internals = {};

module.exports = class SetupPatientSignupPage extends StrangeForms(React.Component) {

    static propTypes = {
        program: T.object,
        onSubmit: T.func.isRequired,
        doesProgramExist: T.bool.isRequired
    };

    constructor(props) {

        super(props);

        const defaultEmailFields = {
            portalLandingCopy: '',
            portalSchedulingCopy: '',
            portalInformationalCopy: '',
            portalEligibilityErrorMessage: ''
        };

        this.state = {
            isSubmitting: false
        };

        const getFromProgram = (program, key) => (program && program[key]) || '';

        const richText = (prop) => {

            return Memoize(({ program }) => {

                const value = getFromProgram(program, prop);

                return RichTextEditor.createValueFromString(value, 'html');
            }, ([{ program: p1 }], [{ program: p2 }]) => {

                return getFromProgram(p1, prop) === getFromProgram(p2, prop);
            });
        };

        this.strangeForm({
            fields: Object.keys(defaultEmailFields),
            get: {
                portalLandingCopy: richText('portalLandingCopy'),
                portalSchedulingCopy: richText('portalSchedulingCopy'),
                portalInformationalCopy: richText('portalInformationalCopy'),
                portalEligibilityErrorMessage: richText('portalEligibilityErrorMessage')
            },
            act: () => null,
            getFormValue: (val) => val
        });
    }

    componentDidMount() {

        this.props.fetchProgram();
    }

    shouldDisableSubmit() {

        return this.state.isSubmitting ||
            !this.fieldValue('portalLandingCopy').toString('html') ||
            !this.fieldValue('portalSchedulingCopy').toString('html') ||
            !this.fieldValue('portalInformationalCopy').toString('html') ||
            !this.fieldValue('portalEligibilityErrorMessage').toString('html');
    }

    handleSubmit = async (ev) => {

        ev.preventDefault();

        const portalLandingCopy = this.fieldValue('portalLandingCopy').toString('html');
        const portalSchedulingCopy = this.fieldValue('portalSchedulingCopy').toString('html');
        const portalInformationalCopy = this.fieldValue('portalInformationalCopy').toString('html');
        const portalEligibilityErrorMessage = this.fieldValue('portalEligibilityErrorMessage').toString('html');

        this.setState({ isSubmitting: true });

        await this.props.onSubmit(
            {
                id: this.props.program.id,
                portalLandingCopy,
                portalSchedulingCopy,
                portalInformationalCopy,
                portalEligibilityErrorMessage
            }
        );
        this.setState({ isSubmitting: false });
    }

    render() {

        const { PageContainer, StyledButton, ForwardedLink, ButtonWrapper, StyledForm, StyledRichTextEditor } = internals;
        const { doesProgramExist, isLoading, program } = this.props;
        const { name = '', client = {} } = program || {};
        const { logoUrl, name: clientName } = client;

        if (isLoading) {
            return null;
        }

        return (
            <PageContainer>
                {doesProgramExist &&
                    <Portlet
                        title={`Program: ${name}`}
                        toolbar={logoUrl && <Portlet.LogoImg src={logoUrl} alt={`${clientName} logo`} />}
                        body={
                            <>
                                <StyledForm>
                                    <SectionLabel>Landing Page Portal Content</SectionLabel>
                                    <StyledRichTextEditor
                                        value={this.fieldValue('portalLandingCopy')}
                                        onChange={this.proposeNew('portalLandingCopy')}
                                        toolbarConfig={internals.toolbarConfig}
                                    />
                                </StyledForm>
                                <StyledForm>
                                    <SectionLabel>Appointment Selector Portal Content 1</SectionLabel>
                                    <StyledRichTextEditor
                                        value={this.fieldValue('portalSchedulingCopy')}
                                        onChange={this.proposeNew('portalSchedulingCopy')}
                                        toolbarConfig={internals.toolbarConfig}
                                    />
                                </StyledForm>
                                <StyledForm>
                                    <SectionLabel>Appointment Selector Portal Content 2</SectionLabel>
                                    <StyledRichTextEditor
                                        value={this.fieldValue('portalInformationalCopy')}
                                        onChange={this.proposeNew('portalInformationalCopy')}
                                        toolbarConfig={internals.toolbarConfig}
                                    />
                                </StyledForm>
                                <StyledForm>
                                    <SectionLabel>Eligibility Error Message</SectionLabel>
                                    <StyledRichTextEditor
                                        value={this.fieldValue('portalEligibilityErrorMessage')}
                                        onChange={this.proposeNew('portalEligibilityErrorMessage')}
                                        toolbarConfig={internals.toolbarConfig}
                                    />
                                </StyledForm>
                            </>
                        }
                        foot={
                            <ButtonWrapper>
                                <StyledButton
                                    onClick={this.handleSubmit}
                                    variant='contained'
                                    color='primary'
                                    disabled={this.shouldDisableSubmit()}
                                >
                                    Save
                                </StyledButton>
                                <StyledButton
                                    component={ForwardedLink}
                                    to='/'
                                    variant='text'
                                >
                                    Cancel
                                </StyledButton>
                            </ButtonWrapper>
                        }
                    />}
                {!doesProgramExist &&
                    <Portlet
                        title='Program Not Found'
                        body={
                            <StyledButton
                                component={ForwardedLink}
                                to='/programs/search'
                                variant='text'
                            >
                                Search Programs
                            </StyledButton>
                        }
                    />}
            </PageContainer>
        );
    }
};

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.StyledButton = Styled(Button)`
    margin: 0 8px;
`;

internals.ButtonWrapper = Styled.div`
    display: flex;
`;

internals.StyledForm = Styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: ${({ theme }) => theme.spacing(4)}px
`;

internals.StyledRichTextEditor = Styled(RichTextEditor)`
    width: 100%;
    max-width: 520px;
`;

internals.ForwardedLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);

internals.toolbarConfig = {
    display: ['BLOCK_TYPE_DROPDOWN', 'INLINE_STYLE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_BUTTONS'],
    BLOCK_TYPE_DROPDOWN: [
        { label: 'Normal', style: 'unstyled' },
        { label: 'Heading Large', style: 'header-one' },
        { label: 'Heading Medium', style: 'header-two' },
        { label: 'Heading Small', style: 'header-three' }
    ],
    INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' }
    ]
};
