const React = require('react');
const T = require('prop-types');
const { default: ArrowDown } = require('@material-ui/icons/ArrowDropDown');
const { default: ArrowUp } = require('@material-ui/icons/ArrowDropUp');
const { default: Styled, ...S } = require('styled-components');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: Button } = require('@material-ui/core/Button');
const { default: FormControl } = require('@material-ui/core/FormControl');
const { default: FormLabel } = require('@material-ui/core/FormLabel');
const { default: FormGroup } = require('@material-ui/core/FormGroup');
const Portlet = require('../../../components/Portlet');
const SectionLabel = require('../../../components/SectionLabel');

const internals = {};

module.exports = class ResultsMetricsPage extends React.Component {

    static propTypes = {
        fetchData: T.func.isRequired,
        program: T.object,
        screeningMetrics: T.array,
        screeningCategories: T.array,
        onSubmit: T.func.isRequired,
        doesProgramExist: T.bool,
        isLoading: T.bool
    };

    constructor(props) {

        super(props);

        this.state = {
            isSubmitting: false,
            updatedMetric: null,
            categories: []
        };
    }

    async componentDidMount() {

        await this.props.fetchData();
        this.parseListing(this.props.screeningCategories, this.props.program.screeningMetrics, this.props.program.resultsEmailMetricIds || []);
    }

    shouldDisableSubmit() {

        return false;
    }

    handleSubmit = async (ev) => {

        ev.preventDefault();
        this.setState({ isSubmitting: true });

        const mapped = this.state.categories.flatMap((cat) => {

            return [...cat.metrics]
                .filter(({ isExcluded }) => !isExcluded)
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map(({ id }) => id);
        });

        await this.props.onSubmit({
            id: this.props.program.id,
            resultsEmailMetricIds: mapped
        });

        this.setState({ isSubmitting: false });
    }

    handleResetDefaults = async () => {

        this.parseListing(this.props.screeningCategories, this.props.program.screeningMetrics, []);
        await this.props.onSubmit({
            id: this.props.program.id,
            resultsEmailMetricIds: null
        });
    }

    sortMetrics = (metrics) => {

        return [...metrics]
            .sort((a, b) => ((a.sortOrder > b.sortOrder) ? 1 : -1))
            .map((metric, index) => ({ ...metric, sortOrder: metric.sortOrder || index }));
    }

    parseListing = (categories, metrics, resultsEmailMetrics) => {

        this.setState({
            // For each category, map a list of metrics, starting with included items (in order),
            // followed by excluded items
            categories: categories.map((category) => {

                let sortOrder = 0;
                const categoricalMetricIds = metrics.filter(({ categoryId }) => categoryId === category.id).map(({ id }) => id);
                const categoricalResultsEmailMetrics = [...resultsEmailMetrics].filter((x) => categoricalMetricIds.includes(x));
                return {
                    category,
                    metrics: [
                        ...categoricalResultsEmailMetrics.map((id) => {

                            const metric = {
                                ...metrics.find((x) => x.id === id),
                                sortOrder,
                                isExcluded: false
                            };
                            sortOrder++;
                            return metric;
                        }),
                        ...metrics.filter(({ id, categoryId }) => categoryId === category.id && !categoricalResultsEmailMetrics.includes(id)).map((x) => {

                            const metric = {
                                ...x,
                                sortOrder,
                                // If resultsEmailMetrics = null or [], include all by default
                                isExcluded: resultsEmailMetrics.length > 0 ? true : false
                            };
                            sortOrder++;
                            return metric;
                        })
                    ]
                };

            })
        });
    }

    handleChangeSortOrder = (id, delta) => {

        const cats = this.state.categories;

        const category = cats.find((c) => c.metrics.some((m) => m.id === id));
        const metric = category.metrics.find((m) => m.id === id);
        const ogSortOrder = metric.sortOrder;

        if ((ogSortOrder === 0 && delta === '-1') || (ogSortOrder === category.metrics.length - 1 && delta === '+1')) {
            return;
        }

        let sibling;

        if (delta === '-1') {
            sibling = category.metrics[ogSortOrder - 1];
        }
        else {
            sibling = category.metrics[ogSortOrder + 1];
        }

        metric.sortOrder = sibling.sortOrder;
        sibling.sortOrder = ogSortOrder;

        category.metrics = this.sortMetrics(category.metrics);

        this.setState({
            categories: cats
        });
    }

    handleToggleInclude = (categoryId, id) => {

        // Give metric isExcluded: True
        let cats = this.state.categories;

        cats = cats.map((item) => {

            if (item.category.id === categoryId) {
                return ({
                    ...item,
                    metrics: item.metrics.map((metric) => {

                        if (metric.id === id) {
                            return ({
                                ...metric,
                                isExcluded: !metric.isExcluded
                            });
                        }

                        return metric;
                    })
                });

            }

            return item;
        });

        this.setState({
            categories: cats
        });
    }

    render() {

        const { PageContainer, StyledButton, ForwardedLink, ButtonWrapper, StyledForm, MetricWrapper, MetricName, SortWrapper, SortButton } = internals;
        const { doesProgramExist, isLoading, program, screeningMetrics: metricList } = this.props;
        const { name = '', client = {} } = program || {};
        const { logoUrl, name: clientName } = client;

        if (isLoading) {
            return null;
        }

        return (
            <PageContainer>
                {doesProgramExist &&
                <Portlet
                    title={`Program: ${name}`}
                    toolbar={logoUrl && <Portlet.LogoImg src={logoUrl} alt={`${clientName} logo`} />}
                    body={
                        <StyledForm>
                            <SectionLabel>Screening Metrics</SectionLabel>
                            <FormControl component='fieldset'>
                                {
                                    // Filter the list of categories that contain at least one metric
                                    this.state.categories.filter(({ metrics }) => metrics.length > 0).map(({ category, metrics }) => {

                                        return (
                                            <React.Fragment key={category.id}>
                                                <SectionLabel>{category.name}</SectionLabel>
                                                <FormLabel component='legend' hidden label={`Assign ${category.name} screening metrics`} />
                                                <FormGroup>
                                                    {
                                                        metrics.filter((metric) => metric.categoryId === category.id).map((metric, index) => {

                                                            return (
                                                                <MetricWrapper key={metric.id}>
                                                                    <ButtonWrapper>
                                                                        <SortWrapper>
                                                                            <SortButton
                                                                                disabled={!index || metric.isExcluded}
                                                                                onClick={() => this.handleChangeSortOrder(metric.id, '-1')}
                                                                            >
                                                                                <ArrowUp />
                                                                            </SortButton>
                                                                            <SortButton
                                                                                disabled={index === metricList.length - 1 || metric.isExcluded}
                                                                                onClick={() => this.handleChangeSortOrder(metric.id, '+1')}
                                                                            >
                                                                                <ArrowDown />
                                                                            </SortButton>
                                                                        </SortWrapper>
                                                                        <MetricName disabled={metric.isExcluded}>{metric.name}</MetricName>
                                                                        <Button
                                                                            onClick={() => this.handleToggleInclude(metric.categoryId, metric.id)}
                                                                            value={metric.id}
                                                                            size='small'
                                                                            color={metric.isExcluded ? 'secondary' : 'primary'}
                                                                            style={{ margin: '0' }}
                                                                        >
                                                                            {metric.isExcluded ? 'Include' : 'Exclude'}
                                                                        </Button>
                                                                    </ButtonWrapper>
                                                                </MetricWrapper>
                                                            );
                                                        })
                                                    }
                                                </FormGroup>
                                            </React.Fragment>
                                        );
                                    })
                                }
                            </FormControl>
                        </StyledForm>
                    }
                    foot={
                        <ButtonWrapper>
                            <div>
                                <StyledButton
                                    onClick={this.handleSubmit}
                                    variant='contained'
                                    color='primary'
                                    disabled={this.shouldDisableSubmit()}
                                >
                                    Save
                                </StyledButton>
                                <StyledButton
                                    onClick={this.handleResetDefaults}
                                    variant='contained'
                                    disabled={this.shouldDisableSubmit()}
                                >
                                    Reset Defaults
                                </StyledButton>
                            </div>
                            <StyledButton
                                component={ForwardedLink}
                                to='/'
                                variant='text'
                            >
                                Cancel
                            </StyledButton>
                        </ButtonWrapper>
                    }
                />}
                {!doesProgramExist &&
                    <Portlet
                        title='Program Not Found'
                        body={
                            <StyledButton
                                component={ForwardedLink}
                                to='/programs/search'
                                variant='text'
                            >
                                Search Programs
                            </StyledButton>
                        }
                    />}
            </PageContainer>
        );
    }
};

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.StyledButton = Styled(Button)`
    margin: 0 8px;
`;

internals.ButtonWrapper = Styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

internals.StyledForm = Styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

internals.MetricName = Styled(Typography)`
    flex: 1;
    ${({ disabled, theme }) => {

        if (disabled) {
            return S.css`
                color: ${theme.palette.disabled.main};
            `;
        }
    }}
`;

internals.MetricWrapper = Styled.div`
    padding: ${({ theme }) => theme.spacing(1)}px 0;
    border-bottom: 1px solid #ddd;

    &:last-child {
        border-bottom: none;
    }
`;

internals.SortButton = Styled(Button)`
    min-width: 0;
    margin: 0 ${({ theme }) => theme.spacing(2)}px 0 0;
    padding: 0;
`;

internals.SortWrapper = Styled.div`
    display: flex;
    flex-direction: column;
`;
