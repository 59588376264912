const Connect = require('react-redux').connect;
const Layout = require('../components/PatientLayout');
const M = require('../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        program: M.selectors.model.fetchProgram(state)
    }),
    (dispatch, { match }) => ({
        fetchData: () => M.dispatch.model.fetchProgram(match.params.id)
    })
);

module.exports = internals.connect(Layout);
