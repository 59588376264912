const React = require('react');
const T = require('prop-types');
const { NavLink } = require('react-router-dom');
const { default: Styled } = require('styled-components');
const { default: Button } = require('@material-ui/core/Button');
const { default: Chip } = require('@material-ui/core/Chip');
const { default: Divider } = require('@material-ui/core/Divider');
const { default: Format } = require('date-fns/format');
const Portlet = require('../../../components/Portlet');
const UploadForm = require('./UploadForm');
const { palette } = require('../../../theme');

const internals = {};

module.exports = class SetupFormsPage extends React.Component {

    static propTypes = {
        program: T.object,
        eligibilityFiles: T.arrayOf(T.object),
        doesProgramExist: T.bool.isRequired,
        fetchProgram: T.func.isRequired,
        uploadEligibility: T.func.isRequired,
        uploadConsent: T.func.isRequired,
        uploadEvents: T.func.isRequired,
        uploadPDFForm: T.func.isRequired,
        uploadCovidResults: T.func.isRequired,
        uploadCovidRequisition: T.func.isRequired,
        fetchEligibilityFiles: T.func.isRequired,
        isLoading: T.bool
    };

    constructor(props) {

        super(props);

        this.state = {
            isSubmitting: false,
            unmatchedRecords: []
        };
    }

    componentDidMount() {

        this.props.fetchProgram();
        this.props.fetchEligibilityFiles();
    }

    async handleEligibilitySubmit(file) {

        this.setState({ isSubmitting: true });

        await this.props.uploadEligibility({
            id: this.props.program.id,
            file
        });

        await this.props.fetchEligibilityFiles();

        this.setState({ isSubmitting: false });
    }

    async handleConsentFormSubmit(file) {

        this.setState({ isSubmitting: true });

        await this.props.uploadConsent({
            id: this.props.program.id,
            file
        });

        await this.props.fetchProgram();

        this.setState({ isSubmitting: false });
    }

    async handleEventImportSubmit(file) {

        this.setState({ isSubmitting: true });

        await this.props.uploadEvents({
            id: this.props.program.id,
            file
        });

        this.setState({ isSubmitting: false });
    }

    async handlePDFFormSubmit(file) {

        this.setState({ isSubmitting: true });

        await this.props.uploadPDFForm({
            id: this.props.program.id,
            file
        });

        this.setState({ isSubmitting: false });
    }

    async handleCovidResultsSubmit(file) {

        this.setState({ isSubmitting: true });

        const [, result] = await this.props.uploadCovidResults({
            id: this.props.program.id,
            file
        });

        this.setState({ isSubmitting: false });

        if (result && result.length) {
            this.setState({ unmatchedRecords: result });
        }
    }

    async handleCovidRequisitionSubmit(file) {

        this.setState({ isSubmitting: true });

        const [, result] = await this.props.uploadCovidRequisition({
            id: this.props.program.id,
            file
        });

        this.setState({ isSubmitting: false });

        if (result && result.length) {
            this.setState({ unmatchedRecords: result });
        }
    }

    render() {

        const { PageContainer, FormWrapper, StyledButton, ForwardedLink, ImportTable, ImportStatus, StyledDivider } = internals;
        const { doesProgramExist, isLoading, program, eligibilityFiles } = this.props;
        const { name = '', client = {}, consentFormUrl = '', pdfFormUrl = '' } = program || {};
        const { logoUrl, name: clientName } = client;

        if (isLoading) {
            return null;
        }

        return (
            <PageContainer>
                {doesProgramExist &&
                    <Portlet
                        title={`Program: ${name}`}
                        toolbar={logoUrl && <Portlet.LogoImg src={logoUrl} alt={`${clientName} logo`} />}
                        body={
                            <>
                                <FormWrapper>
                                    <UploadForm
                                        label='Eligibility Form'
                                        onSubmit={(file) => this.handleEligibilitySubmit(file)}
                                    />
                                    <UploadForm
                                        label='Consent Form'
                                        onSubmit={(file) => this.handleConsentFormSubmit(file)}
                                        currentFileUrl={consentFormUrl}
                                    />
                                    <UploadForm
                                        label='Event Import'
                                        onSubmit={(file) => this.handleEventImportSubmit(file)}
                                    />
                                    <UploadForm
                                        label='Fillable PDF Form'
                                        onSubmit={(file) => this.handlePDFFormSubmit(file)}
                                        currentFileUrl={pdfFormUrl}
                                    />
                                    {program && program.isCovidTesting && (
                                        <>
                                            <UploadForm
                                                label='COVID Requisition'
                                                onSubmit={(file) => this.handleCovidRequisitionSubmit(file)}
                                            />
                                            <UploadForm
                                                label='COVID Results'
                                                onSubmit={(file) => this.handleCovidResultsSubmit(file)}
                                            />
                                        </>
                                    )}
                                </FormWrapper>
                                <StyledDivider />
                                {!!this.state.unmatchedRecords.length && (
                                    <div style={{ color: palette.error.main }}>
                                        <h3>Unmatched Records</h3>
                                        {this.state.unmatchedRecords.map((record, i) => (<div key={record[0]}>{record[0]}</div>))}
                                    </div>
                                )}
                                <div>
                                    <h3>Eligibility Imports</h3>
                                    <ImportTable>
                                        <thead>
                                            <tr>
                                                <th>Imported On</th>
                                                <th>Processed On</th>
                                                <th>Duration</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {eligibilityFiles.map((file, index) => {

                                                const status = !file.importedAt ? 'pending' :
                                                    file.hasErrors ? 'failed' : 'success';

                                                return (
                                                    <tr key={file.id}>
                                                        <td>{Format(new Date(file.createdAt), 'PPpp')}</td>
                                                        <td>{file.importedAt && Format(new Date(file.importedAt), 'PPpp')}</td>
                                                        <td>{file.importedAt && `${Math.round((new Date(file.importedAt) - new Date(file.createdAt)) / 1000)} seconds`}</td>
                                                        <td>
                                                            <ImportStatus className={status} label={status} />
                                                        </td>
                                                    </tr>);
                                            })}
                                        </tbody>
                                    </ImportTable>
                                </div>
                            </>
                        }
                    />}
                {!doesProgramExist &&
                    <Portlet
                        title='Program Not Found'
                        body={
                            <StyledButton
                                component={ForwardedLink}
                                to='/programs/search'
                                variant='text'
                            >
                                Search Programs
                            </StyledButton>
                        }
                    />}
            </PageContainer>
        );
    }
};

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.FormWrapper = Styled.div`
    display: flex;
`;

internals.ImportTable = Styled.table`
    width: 100%;

    th {
        text-align: left;
    }

    th,
    td {
        padding: ${({ theme }) => theme.spacing(1)}px;
        padding-left: 0;
    }
`;

internals.ImportStatus = Styled(Chip)`
    width: ${({ theme }) => theme.spacing(10)}px;
    color: ${({ theme }) => theme.palette.common.white};

    &.success {
        background: ${({ theme }) => theme.palette.secondary.main};
    }

    &.pending {
        background: ${({ theme }) => theme.palette.ochre.main};
        color: ${({ theme }) => theme.palette.common.black};
    }

    &.failed {
        background: ${({ theme }) => theme.palette.error.main};
    }
`;

internals.StyledDivider = Styled(Divider)`
    margin: ${({ theme }) => theme.spacing(3)}px 0;
`;

internals.StyledButton = Styled(Button)`
    margin: 0 8px;
`;

internals.ForwardedLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);
