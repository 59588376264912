const React = require('react');
const T = require('prop-types');
const { default: Table } = require('@material-ui/core/Table');
const { default: TableBody } = require('@material-ui/core/TableBody');
const { default: TableCell } = require('@material-ui/core/TableCell');
const { default: TableHead } = require('@material-ui/core/TableHead');
const { default: TableRow } = require('@material-ui/core/TableRow');
const { default: TableSortLabel } = require('@material-ui/core/TableSortLabel');
const { default: Checkbox } = require('@material-ui/core/Checkbox');
const { default: Styled } = require('styled-components');

const internals = {};

module.exports = ({
    selection,
    columns,
    rows,
    idAttribute,
    orderBy,
    orderDirection,
    onRequestSort,
    onSelectAllClick,
    onSelectNoneClick,
    onSelectRow,
    id: tableId,
    children,
    hideHead,
    ...others
}) => {

    const showCheckboxes = !!selection;
    const getRowId = (typeof idAttribute === 'function') ? idAttribute : (row) => row[idAttribute];

    // Flatten down to empties, renderable data, and react elements.

    rows = ([...(rows || []), ...React.Children.toArray(children)]);

    let rowCount = 0;
    let selectionCount = 0;

    internals.forEachRow(rows, (row) => {

        const id = getRowId(row);
        const isSelected = Boolean(selection && selection[id]);

        rowCount += 1;
        selectionCount += Number(isSelected);
    });

    return (
        <Table id={tableId} {...others}>
            {!hideHead &&
                <TableHead>
                    <TableRow>
                        {showCheckboxes && (
                            // This needs z-index of 2 because there's some z-fighting with checkboxes in the table body, which notably have z-index of 1
                            <TableCell padding='checkbox' style={{ zIndex: 2 }}>
                                <Checkbox
                                    indeterminate={(selectionCount > 0) && (selectionCount < rowCount)}
                                    checked={selectionCount === rowCount}
                                    onChange={selectionCount === rowCount ? onSelectNoneClick : onSelectAllClick}
                                />
                            </TableCell>
                        )}
                        {columns.map(({ id, header, sortable, align }) => {

                            return (
                                <TableCell key={id} align={align || 'inherit'}>
                                    {!sortable && header}
                                    {!!sortable && (
                                        <TableSortLabel
                                            active={orderBy === id}
                                            direction={orderDirection}
                                            onClick={onRequestSort && ((ev) => {

                                                const nextOrderDirection = orderDirection === 'asc' ? 'desc' : 'asc';

                                                return onRequestSort(ev, {
                                                    orderBy: id,
                                                    orderDirection: nextOrderDirection,
                                                    compareFn: internals.getCompareFn({
                                                        field: id,
                                                        direction: nextOrderDirection,
                                                        descFn: (typeof sortable === 'function') && sortable
                                                    })
                                                });
                                            })}
                                        >
                                            {header}
                                        </TableSortLabel>
                                    )}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>}
            <TableBody>
                {internals.mapDataRows(rows, (row, index, { isData, meta }) => {

                    if (!isData) {
                        return row;
                    }

                    const rowId = getRowId(row);
                    const isSelected = Boolean(selection && selection[rowId]);
                    const labelId = tableId && `${tableId}-row-${index}-label`;
                    const { StyledTableRow } = internals;

                    return (
                        <StyledTableRow
                            key={rowId}
                            hover={showCheckboxes}
                            role={showCheckboxes ? 'checkbox' : undefined}
                            tabIndex={showCheckboxes ? -1 : undefined}
                            selected={isSelected}
                            aria-checked={isSelected}
                            onClick={(showCheckboxes && onSelectRow) ? ((ev) => onSelectRow(ev, rowId, meta)) : undefined}
                        >
                            {showCheckboxes && (
                                <TableCell padding='checkbox'>
                                    <Checkbox
                                        checked={isSelected}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </TableCell>
                            )}
                            {columns.map(({ id, format, label, align }) => {

                                const value = row[id];

                                return (
                                    <TableCell
                                        key={id}
                                        id={label && labelId}
                                        scope={label && 'row'}
                                        align={align || 'inherit'}
                                    >
                                        {!format && value}
                                        {!!format && format(value, row, meta)}
                                    </TableCell>
                                );
                            })}
                        </StyledTableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

module.exports.propTypes = {
    id: T.string,
    selection: T.objectOf(T.bool),
    onRequestSort: T.func,
    onSelectAllClick: T.func,
    onSelectNoneClick: T.func,
    onSelectRow: T.func,
    idAttribute: T.oneOfType([T.string, T.func]).isRequired,
    rows: T.arrayOf(T.object),
    columns: T.arrayOf(T.shape({
        id: T.string.isRequired,
        format: T.func,
        label: T.bool,
        header: T.node,
        sortable: T.oneOfType([T.bool, T.func])
    })).isRequired,
    orderBy: T.string,
    orderDirection: T.oneOf(['asc', 'desc']),
    children: T.node,
    hideHead: T.bool
};

module.exports.Rows = () => null;

module.exports.Rows.isTableRows = true;

module.exports.Rows.propTypes = {
    data: T.arrayOf(T.object).isRequired,
    meta: T.any
};

internals.getCompareFn = ({ field, direction, descFn }) => {

    descFn = descFn || internals.descBy(field);

    return (direction === 'desc') ? descFn : internals.negateFn(descFn);
};

internals.descBy = (field) => {

    return (rows, { direction = 'desc' } = {}) => {

        const sorted = rows.sort((a, b) => {

            if (b[field] < a[field]) {
                return -1;
            }

            if (b[field] > a[field]) {
                return 1;
            }

            return 0;
        });

        return direction === 'desc' ? sorted : sorted.reverse();
    };
};

internals.negateFn = (fn) => {

    return (...args) => fn(...args, { direction: 'asc' });
};

internals.forEachRow = (rows, fn) => {

    let counter = 0;

    rows.forEach((row) => {

        const isElement = React.isValidElement(row);

        if (isElement && row.type.isTableRows) {
            const { data, meta } = row.props;
            data.forEach((item) => fn(item, counter++, { isData: true, meta }));
        }
        else if (row && typeof row === 'object' && !isElement) {
            fn(row, counter++, { isData: true });
        }
    });
};

internals.mapDataRows = (rows, fn) => {

    const results = [];

    internals.forEachRow(rows, (row, index, info) => {

        if (info.isData) {
            results.push(fn(row, index, info));
        }
        else {
            results.push(row);
        }
    });

    return results;
};

internals.StyledTableRow = Styled(TableRow)`

&:nth-child(even) {
    background: ${({ theme }) => theme.palette.background.paleGray}
}
`;

module.exports.Wrapper = Styled.div`
    overflow-x: scroll;
`;
