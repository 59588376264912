const React = require('react');
const T = require('prop-types');
const Qs = require('qs');
const { default: Styled } = require('styled-components');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: Button } = require('@material-ui/core/Button');
const { default: Box } = require('@material-ui/core/Box');
const { default: Alert } = require('@material-ui/lab/Alert');
const StrangeForms = require('strange-forms');
const { default: LogoImage } = require('../../../img/promerica-mark.svg');
const { default: LandingPic } = require('../../../img/landing-pic.jpg');

const internals = {};

module.exports = class ResetPasswordPage extends StrangeForms(React.Component) {

    static propTypes = {
        sendResetPassword: T.func.isRequired,
        isAuthenticated: T.bool
    };

    static fields = ['password', 'confirmPassword'];

    constructor(props) {

        super(props);

        this.state = {
            hash: '',
            token: '',
            isSubmitting: false,
            hasSubmitted: false,
            error: null
        };

        this.strangeForm({
            fields: ResetPasswordPage.fields,
            get: () => '',
            act: () => null
        });
    }

    shouldDisableSubmit() {

        return !this.fieldValue('password') || !this.fieldValue('confirmPassword');
    }

    componentDidMount() {

        const { u: hash, t: token } = Qs.parse(this.props.location.search.replace(/^\?/, ''));

        this.setState({ hash, token });
    }

    validateForm() {

        const { password, confirmPassword } = this.formatFields();

        if (password !== confirmPassword) {
            this.setState({ error: { message: 'Passwords must match' } });
            return false;
        }

        return true;
    }

    handleSubmit = async (ev) => {

        ev.preventDefault();

        if (!this.validateForm()) {
            return;
        }

        this.setState({ isSubmitting: true });

        const [err] = await this.props.sendResetPassword(this.formatFields());

        this.setState({ isSubmitting: false, hasSubmitted: true });

        if (err) {
            this.setState({ error: err });
        }
        else {
            this.props.history.push('/login');
        }
    }

    formatFields = () => {

        return {
            hash: this.state.hash,
            token: this.state.token,
            password: this.fieldValue('password'),
            confirmPassword: this.fieldValue('confirmPassword')
        };
    };

    render() {

        const { error, isSubmitting } = this.state;
        const { PageContainer, FormContainer, StyledForm, SiteLogo, Background, Headline, StyledButton, StyledAlert } = internals;

        return (
            <PageContainer>
                <FormContainer>
                    <SiteLogo src={LogoImage} alt='Promerica Logo' />
                    <Typography variant='h4' align='center' gutterBottom>Reset Password</Typography>
                    {error && <StyledAlert severity='error'>{error.message}</StyledAlert>}
                    <StyledForm onSubmit={this.handleSubmit}>
                        <TextField
                            required
                            type='password'
                            label='Password'
                            value={this.fieldValue('password')}
                            onChange={this.proposeNew('password')}
                        />

                        <TextField
                            required
                            type='password'
                            label='Confirm Password'
                            value={this.fieldValue('confirmPassword')}
                            onChange={this.proposeNew('confirmPassword')}
                        />
                        <Box
                            my={2}
                        >
                            <StyledButton
                                type='submit'
                                variant='contained'
                                color='primary'
                                fullWidth
                                disabled={this.shouldDisableSubmit() || isSubmitting}
                            >
                                Submit
                            </StyledButton>
                        </Box>
                    </StyledForm>
                </FormContainer>
                <Background>
                    <Headline>Welcome to Promerica Health</Headline>
                </Background>
            </PageContainer>
        );
    }
};

internals.StyledForm = Styled.form`
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: 0 auto;
`;

internals.StyledButton = Styled(Button)`
    margin: 0;
`;

internals.Background = Styled.div`
    position: relative;
    width: auto;
    flex: 1;
    background-image: url(${LandingPic});
    background-size: cover;
    background-position: 70% center;

    @media(max-width: 700px) {
        display: none;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: ${(props) => (props.theme.palette.primary.dark)};
        opacity: 0.8;
    }
`;

internals.Headline = Styled(Typography)`
    position: absolute;
    width: 100%;
    padding: ${(props) => props.theme.spacing(2)}px;
    color: ${({ theme }) => theme.palette.common.white};
    top: 50%;
    transform: translateY(-50%);
    font-size: ${(props) => (props.theme.typography.fontSize * 4)}px;
    font-weight: bold;
    text-align: center;
    z-index: 10;

    @media(max-width: 1200px) {
        font-size: ${(props) => (props.theme.typography.fontSize * 3)}px;
    }

    @media(max-width: 900px) {
        font-size: ${(props) => (props.theme.typography.fontSize * 2)}px;
    }
`;

internals.PageContainer = Styled.div`
    display: flex;
    width: 100%;
`;

internals.FormContainer = Styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    width: 100%;
    max-width: 700px;
    padding: ${(props) => props.theme.spacing(3)}px;
    background: white;
`;

internals.SiteLogo = Styled.img`
    display: block;
    margin: 0 auto 4em;
    height: 100px;
`;

internals.StyledAlert = Styled(Alert)`
    width: 100%;
    max-width: 300px;
    margin: ${(props) => props.theme.spacing(2)}px auto ${(props) => props.theme.spacing(1)}px;
`;

