const React = require('react');
const T = require('prop-types');
const { default: Divider } = require('@material-ui/core/Divider');
const { default: Styled } = require('styled-components');
const { default: Typography } = require('@material-ui/core/Typography');
const Portlet = require('../../../components/Portlet');
const SectionLabel = require('../../../components/SectionLabel');
const AppointmentCard = require('../../../components/AppointmentCard');

const internals = {};

module.exports = class PatientScheduleSuccessPage extends React.Component {

    static propTypes = {
        appointment: T.object,
        fetchData: T.func
    };

    constructor(props) {

        super(props);
    }

    componentDidMount() {

        this.props.fetchData();
    }

    render() {

        const { PageContainer, StyledPortlet, PortletBodyContainer, PortalCopy } = internals;
        const { appointment = {} } = this.props;
        const { patient = {}, event = {}, confirmation = {} } = appointment || {};
        const patientName = patient && patient.firstName && [patient.firstName, patient.lastName].join(' ');

        return (
            <PageContainer>
                <StyledPortlet
                    body={
                        <>
                            <SectionLabel>Hi, {patientName}</SectionLabel>
                            <Typography><strong>Your  appointment has been scheduled and confirmed on:</strong></Typography>
                            {event && appointment &&
                            <AppointmentCard
                                event={event}
                                appointment={appointment}
                            />}
                            {confirmation.body && <PortalCopy dangerouslySetInnerHTML={{ __html: confirmation.body }} />}
                        </>
                    }
                />
                <StyledPortlet
                    body={
                        <PortletBodyContainer>
                            {confirmation.footer && <PortalCopy dangerouslySetInnerHTML={{ __html: confirmation.footer }} />}
                        </PortletBodyContainer>
                    }
                />
            </PageContainer>
        );
    }
};

internals.PortalCopy = Styled.div`
    p {
        line-height: 1.5;
    }
`;

internals.PortletBodyContainer = Styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

internals.StyledDivider = Styled(Divider)`
    margin: ${({ theme }) => theme.spacing(2)}px 0;
`;

internals.PageContainer = Styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: ${({ theme }) => theme.spacing(150)}px;
    margin: 0 auto;
`;

internals.StyledPortlet = Styled(Portlet)`
    flex: 1 250px;
    margin: ${({ theme }) => theme.spacing(1)}px;
`;
