
module.exports = (appointments = []) => {

    const compareTimes = (a, b) => Number(b.time.replace(':', '')) < Number(a.time.replace(':', ''));

    // Slice the array first to copy it — otherwise a frozen array
    // will throw here!
    const sortedAppointments = appointments.slice().sort((a, b) => {

        if (b.event.date < a.event.date) {
            return -1;
        }
        else if (b.event.date > a.event.date) {
            return 1;
        }
        // Account for walk-in appointments, which have null as time
        else if (a.time && b.time && compareTimes(a, b)) {
            return -1;
        }

        // No other sorting criteria so,
        return 1;
    });

    return sortedAppointments[0] || null;
};
