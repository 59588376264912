const Connect = require('react-redux').connect;
const SettingsPage = require('../components/SettingsPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        user: M.selectors.auth.getUser(state),
        role: M.selectors.auth.getUserRole(state)
    }),
    (dispatch, props) => ({
        purgeData: M.dispatch.model.purgeData
    })
);

module.exports = internals.connect(SettingsPage);
