const React = require('react');
const T = require('prop-types');
const { default: MaskedInput } = require('react-text-mask');

module.exports = (props) => {

    const { inputRef, ...otherProps } = props;

    return (
        <MaskedInput
            ref={(ref) => inputRef(ref ? ref.inputElement : null)}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            showMask
            guide={false}
            {...otherProps}
        />
    );
};

module.exports.propTypes = {
    inputRef: T.func.isRequired
};

module.exports.displayName = 'PhoneMaskInput';
