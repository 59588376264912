const React = require('react');
const T = require('prop-types');
const Joi = require('joi');
const { default: Styled } = require('styled-components');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: Divider } = require('@material-ui/core/Divider');
const { default: Button } = require('@material-ui/core/Button');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: CheckIcon } = require('@material-ui/icons/Check');
const GetDateString = require('../utils/get-date-string');
const GetFormattedTime = require('../utils/get-formatted-time');
const StrangeForms = require('strange-forms');
const AlertDialog = require('./AlertDialog');
const ConsentDialog = require('./ConsentDialog');

const internals = {};

module.exports = class AppointmentAdmin extends StrangeForms(React.Component) {

    static propTypes = {
        handleClickSave: T.func.isRequired,
        handleClickReschedule: T.func.isRequired,
        handleClickCancel: T.func.isRequired,
        handleSendConfirmation: T.func.isRequired,
        selectedAppointmentId: T.string,
        event: T.shape({
            date: T.instanceOf(Date),
            address1: T.string,
            address2: T.string,
            city: T.string,
            name: T.string,
            location: T.string,
            state: T.string,
            zip: T.string
        }).isRequired,
        appointment: T.shape({
            time: T.string
        }).isRequired
    }

    static fields = [
        'phone',
        'email'
    ];

    static formSchema = Joi.object({
        email: Joi.string().email({ tlds: { allow: false } }).message('Invalid email address'),
        phone: Joi.string().pattern(/^\+\d{11}$/).message('Format must be +15555555555').allow(null).empty('')
    });

    constructor(props) {

        super(props);

        this.state = {
            sendingConfirmation: false,
            showCancellationAlert: false,
            showConsentDialog: false,
            errors: {}
        };

        this.strangeForm({
            fields: AppointmentAdmin.fields,
            get: {
                '*': (someProps, field) => (someProps.fields && someProps.fields[field]) || someProps.appointment[field] || ''
            },
            act: () => null
        });
    }

    validate() {

        const results = AppointmentAdmin.formSchema.validate({
            phone: this.fieldValue('phone'),
            email: this.fieldValue('email')
        });

        const errors = results.error ? results.error.details.reduce((acc, { message, path }) => {

            return {
                ...acc,
                [path[0]]: message
            };
        }, {}) : null;


        this.setState({ errors: errors || {} });

        return errors;
    }

    hasChanged() {

        return AppointmentAdmin.fields.some((field) => this.fieldValue(field) !== (this.props.appointment[field] || ''));
    }

    async handleClickSave() {

        const errors = this.validate();

        if (errors) {
            return;
        }

        await this.props.handleClickSave({
            id: this.props.selectedAppointmentId,
            phone: this.fieldValue('phone'),
            email: this.fieldValue('email')
        });

        this.setState({ errors: {} });
    }

    async handleSendConfirmation(id) {

        this.setState({ sendingConfirmation: true });

        await this.props.handleSendConfirmation(id);

        this.setState({ sendingConfirmation: false });
    }

    handleSubmit = async ({ consentText }) => {

        const appointmentId = this.props.appointment.id;

        await this.props.handleSignConsent({
            id: appointmentId,
            consentText
        });

        this.setState({ showConsentDialog: false });
    }

    render() {

        const { errors, showCancellationAlert, showConsentDialog } = this.state;
        const { program, event, appointment, selectedAppointmentId } = this.props;
        const { Sections, Section, StyledAppointmentButtons, DangerButton, ConsentSigned, ConsentSignedIcon } = internals;

        const isConsentSigned = !!appointment.consentText;

        return (
            <>
                <div>
                    {isConsentSigned && (
                        <ConsentSigned>
                            <ConsentSignedIcon />
                            Consent form signed by {appointment.consentText}
                        </ConsentSigned>
                    )}
                    {!isConsentSigned && (
                        <DangerButton
                            onClick={() => (this.setState({ showConsentDialog: true }))}
                        >
                            Sign Consent Form
                        </DangerButton>
                    )}
                </div>
                <Sections>
                    <Section>
                        {event.date && appointment.time && <Typography><strong>{GetDateString(event.date)} at {GetFormattedTime(appointment.time)}</strong></Typography>}
                        <Typography>{event.location} – {event.city}</Typography>
                        <Typography>{event.name}</Typography>
                        <Divider style={{ margin: '10px 0' }} />
                        {event.address1 && <Typography>{event.address1}</Typography>}
                        {event.address2 && <Typography>{event.address2}</Typography>}
                        {event.city && <Typography>{event.city}</Typography>}
                        {event.state && <Typography>{event.state}{event.zip && `, ${event.zip}`}</Typography>}
                    </Section>
                    <Section>
                        {/* <Typography gutterBottom><strong>Consent Name:</strong> {appointment.consentText}</Typography> */}
                        <TextField
                            label='Email Address'
                            value={this.fieldValue('email')}
                            onChange={this.proposeNew('email')}
                            error={errors.hasOwnProperty('email')}
                            helperText={errors.hasOwnProperty('email') ? errors.email : ''}
                        />
                        <br />
                        <TextField
                            label='Phone Number'
                            value={this.fieldValue('phone')}
                            onChange={this.proposeNew('phone')}
                            error={errors.hasOwnProperty('phone')}
                            helperText={errors.hasOwnProperty('phone') ? errors.phone : 'e.g. +15555555555'}
                        />
                    </Section>
                    <StyledAppointmentButtons>
                        <Button
                            color='secondary'
                            variant='contained'
                            disabled={!this.hasChanged()}
                            onClick={() => this.handleClickSave()}
                        >
                            Save Changes
                        </Button>
                        <Button
                            color='secondary'
                            variant='contained'
                            disabled={!selectedAppointmentId || this.state.sendingConfirmation}
                            onClick={() => this.handleSendConfirmation(selectedAppointmentId)}
                        >
                            Resend Confirmation
                        </Button>
                        <Button
                            color='secondary'
                            variant='contained'
                            disabled={!selectedAppointmentId}
                            onClick={this.props.handleClickReschedule}
                        >
                            Reschedule Appointment
                        </Button>
                        <Button
                            color='secondary'
                            variant='contained'
                            disabled={!selectedAppointmentId}
                            onClick={() => this.setState({ showCancellationAlert: true })}
                        >
                            Cancel Appointment
                        </Button>
                    </StyledAppointmentButtons>
                </Sections>
                <AlertDialog
                    dialogTitle='Notify Patient?'
                    dialogDescription='We will send a cancellation notification to the patient'
                    affirmativeLabel='Notify'
                    negativeLabel={`Don't Notify`}
                    isModalOpen={showCancellationAlert}
                    confirmAction={() => this.props.handleClickCancel({ sendEmail: true })}
                    cancelAction={() => this.props.handleClickCancel({ sendEmail: false })}
                    toggleModal={() => this.setState({ showCancellationAlert: false })}
                />
                <ConsentDialog
                    affirmativeLabel='Submit'
                    negativeLabel={null}
                    isModalOpen={showConsentDialog}
                    onConfirmAction={this.handleSubmit}
                    toggleModal={() => this.setState({ showConsentDialog: false })}
                    consentFormUrl={program.consentFormUrl}
                />
            </>
        );
    }
};

internals.Sections = Styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 ${({ theme }) => theme.spacing(-2)}px;
`;

internals.Section = Styled.div`
    width: calc(50% - ${({ theme }) => theme.spacing(4)}px);
    margin: 0 ${({ theme }) => theme.spacing(2)}px;
`;

internals.StyledAppointmentButtons = Styled.div`
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing(2)}px;
    margin-left: ${({ theme }) => theme.spacing(1)}px;
`;

internals.DangerButton = Styled(Button).attrs({ variant: 'contained' })`
    margin: ${({ theme }) => theme.spacing(0, 0, 2)};
    background: ${({ theme }) => theme.palette.error.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
`;

internals.ConsentSigned = Styled(Typography)`
    margin: ${({ theme }) => theme.spacing(0, 0, 2)};
`;

internals.ConsentSignedIcon = Styled(CheckIcon)`
    position: relative;
    top: 5px;
    margin-right: ${({ theme }) => theme.spacing(1)}px;
    fill: ${({ theme }) => theme.palette.success.main};
`;
