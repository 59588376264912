const React = require('react');
const { useState } = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: Card } = require('@material-ui/core/Card');
const { default: CardActions } = require('@material-ui/core/CardActions');
const { default: CardContent } = require('@material-ui/core/CardContent');
const { default: Button } = require('@material-ui/core/Button');
const { default: Typography } = require('@material-ui/core/Typography');
const Portlet = require('../../../components/Portlet');
const AlertDialog = require('../../../components/AlertDialog');
const Constants = require('../../../utils/constants');

const internals = {};

module.exports = function SettingsPage({ role, purgeData }) {

    const [showConfirmPurgeDialog, setShowConfirmPurgeDialog] = useState(false);

    const { PageContainer } = internals;

    const isProduction = Constants.ENVIRONMENT === 'production';

    return (
        <>
            <PageContainer>
                <Portlet
                    title='Settings'
                    body={
                        <>
                            {!isProduction && role === Constants.ROLE.SUPERADMIN && (
                                <Card>
                                    <CardContent>
                                        <Typography color='textSecondary' gutterBottom>
                                            superadmin
                                        </Typography>
                                        <Typography variant='h5' component='h2' gutterBottom>
                                            Purge All Data
                                        </Typography>
                                        <Typography variant='body2' component='p'>
                                            This will clear all data from the database and seed a minimal set of predefined data.
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={() => setShowConfirmPurgeDialog(true)}
                                        >
                                            Purge
                                        </Button>
                                    </CardActions>
                                </Card>
                            )}
                        </>
                    }
                />
            </PageContainer>
            <AlertDialog
                dialogTitle='Purge data?'
                dialogDescription='This will remove all data from the database and cannot be undone'
                affirmativeLabel='Purge All Data'
                isModalOpen={showConfirmPurgeDialog}
                confirmAction={purgeData}
                toggleModal={() => setShowConfirmPurgeDialog(false)}
            />
        </>
    );
};

module.exports.propTypes = {
    role: T.string.isRequired,
    purgeData: T.func.isRequired
};

internals.PageContainer = Styled.div`
    width: 100%;
`;
