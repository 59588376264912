const React = require('react');
const T = require('prop-types');
const { NavLink } = require('react-router-dom');
const { default: Styled } = require('styled-components');
const { default: AppBar } = require('@material-ui/core/AppBar');
const { default: Toolbar } = require('@material-ui/core/Toolbar');
const { default: IconButton } = require('@material-ui/core/IconButton');
const { default: MenuIcon } = require('@material-ui/icons/Menu');
const { default: SwapIcon } = require('@material-ui/icons/SwapHoriz');
const { default: LogoutIcon } = require('@material-ui/icons/ExitToApp');
const { default: SettingsIcon } = require('@material-ui/icons/Settings');
const Constants = require('../utils/constants');
const { default: LogoImage } = require('../img/logo-light.svg');
const Theme = require('../theme');

const internals = {};

module.exports = (props) => {

    const { SiteTitle, SiteLogo, StyledUsername, SettingsLink } = internals;
    const { isAuthenticated, logout, hideMenuIcon, hideSwapIcon, hideLogo, user, role } = props;
    const isProduction = Constants.ENVIRONMENT === 'production';

    return (
        <AppBar position='static' elevation={0}>
            <Toolbar variant='dense' style={{ background: isProduction ? 'inherit' : Theme.palette.error.main }}>
                {!hideMenuIcon &&
                    <IconButton edge='start' color='inherit' size='small' aria-label='menu' onClick={props.handleToggleDrawer}>
                        <MenuIcon />
                    </IconButton>}
                {!hideSwapIcon &&
                    <IconButton edge='start' color='inherit' size='small' aria-label='menu' onClick={props.handleOpenSwitcher}>
                        <SwapIcon />
                    </IconButton>}
                <SiteTitle to='/'>
                    {!hideLogo && <SiteLogo src={LogoImage} alt='Promerica Logo' />}
                </SiteTitle>
                {isAuthenticated &&
                    <>
                        <StyledUsername>{!!user && user.username}</StyledUsername>
                        <IconButton edge='end' color='inherit' size='small' aria-label='logout-button' onClick={logout}>
                            <LogoutIcon />
                        </IconButton>
                    </>}
                {role === Constants.ROLE.SUPERADMIN && (
                    <SettingsLink to='/settings'>
                        <SettingsIcon />
                    </SettingsLink>
                )}
            </Toolbar>
        </AppBar>
    );
};

module.exports.propTypes = {
    handleToggleDrawer: T.func,
    handleOpenSwitcher: T.func,
    isAuthenticated: T.bool,
    user: T.object,
    role: T.string,
    logout: T.func,
    hideMenuIcon: T.bool,
    hideSwapIcon: T.bool,
    hideLogo: T.bool
};

internals.SiteTitle = Styled(NavLink)`
    flex-grow: 1;
`;

internals.SiteLogo = Styled.img`
    display: block;
    height: 30px;
    margin-left: ${({ theme }) => theme.spacing(1)}px;
`;

internals.StyledUsername = Styled.div`
    margin-right: ${({ theme }) => theme.spacing(2)}px;
`;

internals.SettingsLink = Styled(NavLink)`
    margin-top: 5px;
    margin-left: ${({ theme }) => theme.spacing(2)}px;
    color: inherit;
`;
