const Connect = require('react-redux').connect;
const ForgotPasswordPage = require('../components/ForgotPasswordPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({

    }),
    (dispatch, props) => ({

        sendForgotPassword: async (email) => {

            const [err, result] = await M.dispatch.auth.sendForgotPassword(email);

            if (!err) {
                M.dispatch.snackbar.pushToQueue(`Email sent to ${email}`);
            }

            return [err, result];
        }
    })
);

module.exports = internals.connect(ForgotPasswordPage);
