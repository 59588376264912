const MiddleEnd = require('strange-middle-end');
const {
    API_ERRORS,
    CLEAR_API_ERRORS,
    SELECT_PROGRAM,
    SELECT_EVENTS
} = require('./action-types');

module.exports = MiddleEnd.createReducer({ mutable: true }, { apiErrors: [], currentProgramId: null }, {
    [API_ERRORS]: (draft, { payload: error }) => {

        draft.apiErrors.push(error);
    },
    [CLEAR_API_ERRORS]: (draft) => {

        draft.apiErrors = [];
    },
    [SELECT_PROGRAM]: (draft, { payload: { id } }) => {

        draft.currentProgramId = id;
    },
    [SELECT_EVENTS]: (draft, { payload: { eventIds } }) => {

        draft.selectedEvents = eventIds;
    }
});
