const React = require('react');
const T = require('prop-types');
const { NavLink } = require('react-router-dom');
const { default: Divider } = require('@material-ui/core/Divider');
const { default: Styled } = require('styled-components');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: Card } = require('@material-ui/core/Card');
const { default: CardContent } = require('@material-ui/core/CardContent');
const { default: CardActions } = require('@material-ui/core/CardActions');
const { default: Button } = require('@material-ui/core/Button');
const { default: Chip } = require('@material-ui/core/Chip');
const StrangeForms = require('strange-forms');
const Portlet = require('../../../components/Portlet');
const SectionLabel = require('../../../components/SectionLabel');
const EventFilter = require('../../../components/EventFilter');
const GetDateString = require('../../../utils/get-date-string');

const internals = {};

module.exports = class SearchAllEventsPage extends StrangeForms(React.Component) {

    static propTypes = {
        events: T.array
    };

    constructor(props) {

        super(props);
        const emptyObject = {};

        this.strangeForm({
            fields: ['eventFilterCriteria'],
            get: () => emptyObject,
            act: () => null,
            getFormValue: (val) => val
        });
    }

    componentDidMount() {

        this.props.fetchData();
    }

    shouldShowResults() {

        const criteria = this.fieldValue('eventFilterCriteria');
        const { city, date, state, shouldOnlyShowFutureEvents } = criteria;
        return Boolean(
            Object.keys(criteria).length &&
            (city || date || state || shouldOnlyShowFutureEvents)
        );
    }

    filterEvents = (events) => {

        const { city, state, date, shouldOnlyShowFutureEvents } = this.fieldValue('eventFilterCriteria');
        let filteredArray = events;
        if (state) {
            filteredArray = filteredArray.filter(({ state: eventState }) => eventState === state);
        }

        if (city) {
            filteredArray = filteredArray.filter(({ city: eventCity }) => eventCity === city);
        }

        if (date) {
            filteredArray = filteredArray.filter(({ date: eventDate }) => GetDateString(new Date(eventDate)) === date);
        }

        if (shouldOnlyShowFutureEvents) {
            const now = new Date();
            filteredArray = filteredArray.filter(({ date: eventDate }) => new Date(eventDate) > now);
        }

        return filteredArray;
    }

    render() {

        const { PageContainer, StyledDivider, EventCard, EventList, StyledChip, StyledCardContent } = internals;
        const { events } = this.props;
        const filteredEvents = this.filterEvents(events);

        return (
            <PageContainer>
                <Portlet
                    title='Select Event'
                    body={
                        <>
                            <SectionLabel>Search for Events</SectionLabel>
                            <EventFilter
                                events={events}
                                fields={this.fieldValue('eventFilterCriteria')}
                                onChange={this.proposeNew('eventFilterCriteria')}
                            />
                            <StyledDivider />
                            <EventList>
                                {
                                    this.shouldShowResults() &&
                                    filteredEvents &&
                                    filteredEvents.map((event) => {

                                        return (
                                            <EventCard key={event.id}>
                                                <StyledCardContent>
                                                    <Typography variant='h5' gutterBottom>{event.name}</Typography>
                                                    <Typography variant='body2'>{GetDateString(event.date)}</Typography>
                                                    <Typography variant='body2'>{event.address1}</Typography>
                                                    {event.address2 && <Typography variant='body2'>{event.address2}</Typography>}
                                                    <Typography variant='body2' gutterBottom>{`${event.city}, ${event.st} ${event.zip}`}</Typography>
                                                    <StyledChip
                                                        color={event.isPublished ? 'secondary' : 'default'}
                                                        label={event.isPublished ? 'Published' : 'Unpublished'}
                                                        size='small'
                                                    />
                                                </StyledCardContent>
                                                <CardActions>
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                        component={NavLink}
                                                        exact to={`/manager/event/${event.id}`}
                                                    >
                                                        View
                                                    </Button>
                                                </CardActions>
                                            </EventCard>
                                        );
                                    })
                                }
                                {
                                    events && !events.length &&
                                    <SectionLabel>No events found</SectionLabel>
                                }
                            </EventList>
                        </>
                    }
                />
            </PageContainer>
        );
    }
};

internals.StyledCardContent = Styled(CardContent)`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

internals.StyledChip = Styled(Chip)`
    margin-top: auto;
    align-self: flex-start;
`;

internals.EventCard = Styled(Card)`
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

internals.EventList = Styled.div`

    ${({ theme }) => theme.breakpoints.up('sm')} {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: ${({ theme }) => theme.spacing(1)}px;
    }
`;

internals.StyledDivider = Styled(Divider)`
    margin: ${({ theme }) => theme.spacing(2)}px 0;
`;

internals.PageContainer = Styled.div`
    width: 100%;
`;
