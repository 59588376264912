const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: FormLabel } = require('@material-ui/core/FormLabel');
const { default: FormGroup } = require('@material-ui/core/FormGroup');
const { default: FormControlLabel } = require('@material-ui/core/FormControlLabel');
const { default: Checkbox } = require('@material-ui/core/Checkbox');
const { default: Chip } = require('@material-ui/core/Chip');
const { default: Link } = require('@material-ui/core/Link');
const { default: Button } = require('@material-ui/core/Button');
const StrangeForms = require('strange-forms');
const GetFormattedTime = require('../utils/get-formatted-time');
const AlertDialog = require('./AlertDialog');

const internals = {};

module.exports = class AppointmentList extends StrangeForms(React.Component) {

    static propTypes = {
        appointments: T.arrayOf(T.shape({
            id: T.string,
            time: T.string
        })).isRequired,
        onCancelAppointment: T.func.isRequired
    };

    static fields = [
        'selectedIds',
        'shouldSelectAll'
    ];

    constructor(props) {

        super(props);

        this.state = {
            isSubmitting: false,
            dialogName: null,
            showCancellationAlert: false,
            cancelAppointmentId: null
        };

        this.strangeForm({
            fields: AppointmentList.fields,
            get: {
                '*': (someProps, field) => (someProps.fields && someProps.fields[field]) || false,
                selectedIds: (someProps, field) => someProps.value || []
            },
            act: {
                '*': () => null,
                selectedIds: (field, value) => this.props.onChange(value)
            },
            getFormValue: {
                selectedIds: (selectedId) => {

                    const selectedIds = this.fieldValue('selectedIds');

                    if (selectedIds.some((id) => id === selectedId)) {
                        // Perform uncheck action
                        return selectedIds.filter((id) => id !== selectedId);
                    }

                    return [...selectedIds, selectedId];
                },
                'shouldSelectAll': (ev) => {

                    if (ev.target.checked) {
                        this.proposeNew('selectedIds', () => {

                            return this.props.appointments.filter((appointment) => !appointment.patient)
                                .map(({ id }) => id);
                        })();
                    }
                    else {
                        this.proposeNew('selectedIds', () => [])();
                    }

                    return ev.target.checked;
                }
            }
        });
    }

    onCancelAppointment = async ({ sendEmail }) => {

        const selectedAppointmentId = this.state.cancelAppointmentId;
        const appointment = this.props.appointments.find(({ id }) => id === selectedAppointmentId);

        this.setState({ isSubmitting: true });

        await this.props.onCancelAppointment({
            patientId: appointment.patient.id,
            id: appointment.id,
            sendEmail
        });

        this.setState({
            isSubmitting: false,
            showCancellationAlert: false,
            cancelAppointmentId: null
        });
    }

    render() {

        const { PatientName, StyledChip, CancelButton, AppointmentWrapper } = internals;
        const { appointments } = this.props;
        const { showCancellationAlert } = this.state;

        return (
            <>
                <FormLabel component='legend' hidden>Select Appointments</FormLabel>
                <FormGroup>
                    {appointments && !!appointments.length &&
                        <AppointmentWrapper
                            control={
                                <Checkbox
                                    checked={this.fieldValue('shouldSelectAll')}
                                    onChange={this.proposeNew('shouldSelectAll')}
                                />
                            }
                            label='Select All'
                        />}
                    {appointments && appointments.map((appointment) =>

                        <AppointmentWrapper
                            control={
                                <Checkbox
                                    checked={this.fieldValue('selectedIds').includes(appointment.id)}
                                    onChange={() => this.proposeNew('selectedIds')(appointment.id)}
                                    // should be disabled if there's a patient on the appointment
                                    disabled={appointment.patient && !!appointment.patient.id}
                                />
                            }
                            label={
                                <>
                                    <StyledChip
                                        size='small'
                                        color={appointment.time ? 'primary' : 'default'}
                                        label={GetFormattedTime(appointment.time)}
                                        hasDuplicate={internals.hasDuplicate(appointment, appointments)}
                                    />
                                    {appointment.patient && appointment.patient.firstName && appointment.patient.lastName && (
                                        <>
                                            <PatientName href={`/patient/${appointment.patient.id}/screening`} display='inline'>{appointment.patient.firstName} {appointment.patient.lastName}</PatientName>
                                            <CancelButton
                                                color='secondary'
                                                onClick={() => {

                                                    this.setState({
                                                        showCancellationAlert: true,
                                                        cancelAppointmentId: appointment.id
                                                    });
                                                }}
                                            >
                                                Cancel Appointment
                                            </CancelButton>
                                        </>
                                    )}
                                </>
                            }
                            key={appointment.id}
                        />
                    )}
                </FormGroup>
                <AlertDialog
                    dialogTitle='Notify Patient?'
                    dialogDescription='We will send a cancellation notification to the patient'
                    affirmativeLabel='Notify'
                    negativeLabel={`Don't Notify`}
                    isModalOpen={showCancellationAlert}
                    confirmAction={() => this.onCancelAppointment({ sendEmail: true })}
                    cancelAction={() => this.onCancelAppointment({ sendEmail: false })}
                    toggleModal={() => {

                        this.setState({
                            showCancellationAlert: false,
                            cancelAppointmentId: null
                        });
                    }}
                />
            </>
        );
    }
};

internals.hasDuplicate = ({ patient }, appointments) => {

    if (!patient) {
        return false;
    }

    const a = appointments.filter(({ patient: p }) => {

        if (!p) {
            return false;
        }

        return p.firstName === patient.firstName &&
            p.lastName === patient.lastName &&
            p.dob === patient.dob;
    });

    return a.length > 1;
};

internals.PatientName = Styled(Link).attrs({ display: 'inline' })`
    margin-left: ${({ theme }) => theme.spacing(1)}px;
`;

internals.StyledChip = Styled(Chip)`
    min-width: ${({ theme }) => theme.spacing(3)}px;

    ${({ hasDuplicate }) => {

        if (hasDuplicate) {
            return 'background: red;';
        }
    }}
`;

internals.CancelButton = Styled(Button)`
    margin-left: auto;
    color: ${({ theme }) => theme.palette.error.main};
`;

internals.AppointmentWrapper = Styled(FormControlLabel)`
    display: flex;

    &:hover {
        background: ${({ theme }) => theme.palette.background.paleGray};
    }

    .MuiFormControlLabel-label {
        display: flex;
        align-items: center;
        flex-grow: 1;
    }
`;
