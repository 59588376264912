const {
    FETCH_CLIENT,
    FETCH_CLIENTS,
    FETCH_USER,
    FETCH_USERS,
    FETCH_PROGRAM,
    FETCH_PROGRAMS,
    FETCH_SCREENING_METRICS,
    FETCH_SCREENING_CATEGORIES,
    SEARCH_PATIENTS,
    FETCH_PATIENT,
    FETCH_SCREENING,
    FETCH_ELIGIBILITY_FILES,
    FETCH_ELIGIBILITY_MAPPING,
    FETCH_EVENTS,
    FETCH_ALL_EVENTS,
    FETCH_EVENT,
    FETCH_APPOINTMENT,
    FETCH_CLIENT_REPORT,
    FETCH_PARTICIPATION_REPORT,
    FETCH_SCREENINGS_WITH_RESULTS,
    FETCH_ALL_APPOINTMENTS_FOR_EVENT,
    FETCH_FORMS,
    FETCH_FORM
} = require('./action-types');

const GetFormattedDate = require('../../utils/get-formatted-date');

const internals = {};

exports.fetchClient = ({ model }) => {

    const { result } = model.indexes[FETCH_CLIENT.BASE] || {};

    if (!result) {
        return null;
    }

    const { clients } = model.entities;
    const toClientResult = ({ archivedAt, ...client }) => ({
        ...client,
        archivedAt: archivedAt && new Date(archivedAt)
    });
    return toClientResult(clients[result]);
};

exports.fetchUser = ({ model }) => {

    const { result } = model.indexes[FETCH_USER.BASE] || {};

    if (!result) {
        return null;
    }

    const { users } = model.entities;
    const toUserResult = ({ role, ...user }) => ({ ...user, role: role.name });
    return toUserResult(users[result]);
};

exports.fetchUserNotFound = ({ model }) => {

    const { error, inFlight } = model.indexes[FETCH_USER.BASE] || {};

    if (error && error.response) {
        return (inFlight === 0 && error.response.status === 404);
    }

    return false;
};

exports.fetchUserLoading = ({ model }) => {

    const { inFlight = 0 } = model.indexes[FETCH_USER.BASE] || {};

    return (inFlight > 0);
};

exports.fetchClients = ({ model }) => {

    const { result } = model.indexes[FETCH_CLIENTS.BASE] || {};

    if (!result) {
        return [];
    }

    const { clients } = model.entities;
    const toClientResult = ({ archivedAt, ...client }) => ({
        ...client,
        archivedAt: archivedAt && new Date(archivedAt)
    });

    return result.map((id) => toClientResult(clients[id]));
};

exports.fetchUsers = ({ model }) => {

    const { result } = model.indexes[FETCH_USERS.BASE] || {};

    if (!result) {
        return [];
    }

    const { users } = model.entities;
    const toUserResult = ({ archivedAt, role, ...user }) => ({
        ...user,
        role: role.name,
        archivedAt: archivedAt && new Date(archivedAt)
    });

    return result.map((id) => toUserResult(users[id]));
};

exports.fetchProgram = ({ model }) => {

    const { result } = model.indexes[FETCH_PROGRAM.BASE] || {};
    const { toUserResult, toEventResult } = internals;
    const { programs, users } = model.entities;
    const toProgramResult = ({ archivedAt, users: userIds, events, ...program }) => ({
        ...program,
        users: userIds ? userIds.map((userId) => toUserResult(users[userId])) : null,
        archivedAt: archivedAt && new Date(archivedAt),
        events: events ? events.map((event) => toEventResult(event)) : null
    });

    if (!result) {
        return null;
    }

    return toProgramResult(programs[result]);
};

exports.fetchPrograms = ({ model }) => {

    const { result } = model.indexes[FETCH_PROGRAMS.BASE] || {};

    if (!result) {
        return [];
    }

    const { programs } = model.entities;
    return result.map((id) => programs[id]);
};

exports.fetchProgramNotFound = ({ model }) => {

    const { error, inFlight } = model.indexes[FETCH_PROGRAM.BASE] || {};
    if (error && error.response) {
        return (inFlight === 0 && error.response.status === 404);
    }

    return false;
};

exports.fetchScreeningMetrics = ({ model }) => {

    const { result } = model.indexes[FETCH_SCREENING_METRICS.BASE] || {};

    if (!result) {
        return [];
    }

    return result;
};

exports.fetchScreeningCategories = ({ model }) => {

    const { result } = model.indexes[FETCH_SCREENING_CATEGORIES.BASE] || {};

    if (!result) {
        return [];
    }

    return result;
};

exports.fetchPatient = ({ model }) => {

    const { result } = model.indexes[FETCH_PATIENT.BASE] || {};

    if (!result) {
        return null;
    }

    const { patients } = model.entities;

    return internals.toPatientResult(patients[result]);
};

exports.fetchProgramScreeningsWithResults = ({ app, model }) => {

    const { result } = model.indexes[FETCH_SCREENINGS_WITH_RESULTS.BASE] || {};

    if (!result || (Array.isArray(result) && !result.length)) {
        return [];
    }

    const { currentProgramId } = app;

    return result
        // A be-super-safe filter to ensure these screenings are part of the selected program
        .filter((screening) => screening.programId === currentProgramId);
};

exports.fetchScreening = ({ model }) => {

    const { result } = model.indexes[FETCH_SCREENING.BASE] || {};

    if (!result) {
        return null;
    }

    const { screenings } = model.entities;
    return screenings[result];
};

exports.searchPatients = ({ model }) => {

    const { result } = model.indexes[SEARCH_PATIENTS.BASE] || {};

    if (!result) {
        return [];
    }

    const { patients } = model.entities;
    return result.map((id) => internals.toPatientResult(patients[id]));
};

exports.fetchEligibilityFiles = ({ model }) => {

    const { result } = model.indexes[FETCH_ELIGIBILITY_FILES.BASE] || {};

    return !result ? [] : [...result].sort((a, b) => {

        return (new Date(b.createdAt) - new Date(a.createdAt));
    });
};

exports.fetchEligibilityMapping = ({ model }) => {

    const { result } = model.indexes[FETCH_ELIGIBILITY_MAPPING.BASE] || {};

    return result || [];
};

exports.fetchEvents = ({ model }) => {

    const { result } = model.indexes[FETCH_EVENTS.BASE] || {};

    if (!result) {
        return [];
    }

    const { events } = model.entities;

    return result.map((id) => internals.toEventResult(events[id]));
};

exports.fetchAllEvents = ({ model }) => {

    const { result } = model.indexes[FETCH_ALL_EVENTS.BASE] || {};

    if (!result) {
        return [];
    }

    const { events } = model.entities;

    return result.map((id) => internals.toEventResult(events[id]));
};

exports.fetchEvent = ({ model }) => {

    const { result } = model.indexes[FETCH_EVENT.BASE] || {};

    if (!result) {
        return null;
    }

    const { events } = model.entities;
    return internals.toEventResult(events[result]);
};

exports.fetchAppointment = ({ model }) => {

    const { result } = model.indexes[FETCH_APPOINTMENT.BASE] || {};

    if (!result) {
        return null;
    }

    const { appointments } = model.entities;
    const toAppointmentResult = ({ event, ...appointment }) => ({ ...appointment, event: internals.toEventResult(event) });
    return toAppointmentResult(appointments[result]);
};

exports.fetchClientReport = ({ model }) => {

    const { result } = model.indexes[FETCH_CLIENT_REPORT.BASE] || {};

    if (!result) {
        return null;
    }

    return result;
};

exports.fetchParticipationResult = ({ model }) => {

    const { result } = model.indexes[FETCH_PARTICIPATION_REPORT.BASE] || {};

    if (!result) {
        return null;
    }

    return result;
};

exports.fetchAllAppointmentsForEvent = ({ model }) => {

    const { result } = model.indexes[FETCH_ALL_APPOINTMENTS_FOR_EVENT.BASE] || {};

    if (!result) {
        return [];
    }

    const { toAppointmentResult } = internals;
    const { appointments } = model.entities;

    return result
        .map((res) => appointments[res])
        .map(toAppointmentResult);
};

exports.fetchForms = ({ model }) => {

    const { result } = model.indexes[FETCH_FORMS.BASE] || {};

    if (!result) {
        return [];
    }

    const { forms } = model.entities;
    return result.map((id) => forms[id]);
};

exports.fetchForm = ({ model }) => {

    const { result } = model.indexes[FETCH_FORM.BASE] || {};

    if (!result) {
        return {};
    }

    const { form } = model.entities;
    return result.map((id) => form[id]);
};

internals.toEventResult = ({ date, ...event }) => ({ date: date && GetFormattedDate(date), ...event });

internals.toPatientResult = ({ dob, appointments = [], ...patient }) => ({
    ...patient,
    dob: dob && GetFormattedDate(dob),
    appointments: !appointments ? [] : appointments.map((appointment) => ({
        ...appointment,
        event: internals.toEventResult(appointment.event),
        createdAt: new Date(appointment.createdAt),
        screening: !!appointment.screening && {
            ...appointment.screening,
            createdAt: appointment.screening && appointment.screening.createdAt && new Date(appointment.screening.createdAt)
        }
    }))
});

internals.toUserResult = ({ role, ...user }) => ({ ...user, role: role.name });

internals.toAppointmentResult = ({ createdAt, updatedAt, ...appointment }) => ({
    ...appointment,
    createdAt: new Date(createdAt),
    updatedAt: new Date(updatedAt)
});
