const React = require('react');
const { default: Styled } = require('styled-components');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: Card } = require('@material-ui/core/Card');
const { default: CardContent } = require('@material-ui/core/CardContent');
const { default: CardActions } = require('@material-ui/core/CardActions');
const { default: Button } = require('@material-ui/core/Button');
const StrangeForms = require('strange-forms');
const SectionLabel = require('./SectionLabel');
const AppointmentList = require('./AppointmentList');
const UploadForm = require('../routes/setup-forms/components/UploadForm');

const internals = {};

module.exports = class AddAppointmentsModule extends StrangeForms(React.Component) {

    static fields = [
        'rangeStart',
        'rangeStop',
        'interval',
        'slots',
        'appointments',
        'selectedAppointmentIds'
    ];

    constructor(props) {

        super(props);

        this.state = {
            isSubmitting: false
        };

        this.counter = 0;

        this.strangeForm({
            fields: AddAppointmentsModule.fields,
            get: {
                '*': (someProps, field) => (someProps.fields && someProps.fields[field]) || '',
                'appointments': (someProps) => someProps.appointments && [...someProps.appointments].sort((a, b) => new Date(`1970/01/01 ${a.time}`) - new Date(`1970/01/01 ${b.time}`)) || [],
                'selectedAppointmentIds': (someProps) => someProps.selectedAppointmentIds || []
            },
            act: {
                '*': () => null,
                'appointments': (field, value) => this.props.onChange(value)
            },
            getFormValue: {
                '*': (ev) => ev.target.value,
                selectedAppointmentIds: (val) => val
            }
        });
    }

    shouldDisableSubmit() {

        return (
            !this.fieldValue('rangeStart') ||
            !this.fieldValue('rangeStop') ||
            !this.fieldValue('interval') ||
            !this.fieldValue('slots')
        );
    }

    handleAddAppointments = () => {

        // The time appointments should start (24-hour time 'xx:xx')
        const [hourStart, minuteStart] = this.fieldValue('rangeStart').split(':').map((time) => parseInt(time));

        // The time appointments should end (inclusive)
        const [hourStop, minuteStop] = this.fieldValue('rangeStop').split(':').map((time) => parseInt(time));

        // The number of minutes between appointments
        const interval = parseInt(this.fieldValue('interval'));

        // The number of appointments to add per timeslot
        const slots = parseInt(this.fieldValue('slots'));

        // The appointments
        const appointments = [...this.fieldValue('appointments')];
        let hour = hourStart;
        let minute = minuteStart;

        while (!(hour > hourStop || (hour === hourStop && minute > minuteStop))) {

            for (let i = 0; i < slots; ++i) {
                appointments.push({
                    // Not /quite/ a UUID, but appending a string to our counter prevents collisions
                    // with existing appointment IDs
                    id: 'staged' + this.counter,
                    time: [hour.toString().padStart(2, '0'), minute.toString().padStart(2, '0')].join(':')
                });

                ++this.counter;
            }

            hour = hour + Math.floor((minute + interval) / 60);
            minute = (minute + interval) % 60;
        }

        // We want to sort appointments by time so that pressing 'ADD' again will put the timeslots in the right order.
        // We'll do that by creating a new Date() with the time string interpolated in for sorting.
        const sortedAppointments = appointments.sort((a, b) => new Date(`1970/01/01 ${a.time}`) - new Date(`1970/01/01 ${b.time}`));
        this.proposeNew('appointments', () => sortedAppointments)();
    }

    handleDeleteAppointments = () => {

        const selectedAppointmentIds = this.fieldValue('selectedAppointmentIds');
        const appointments = this.fieldValue('appointments');
        const newAppointmentList = appointments.filter(({ id }) => !selectedAppointmentIds.includes(id));
        this.proposeNew('appointments', () => newAppointmentList)();
    }

    handleImportAppointments = async (file) => {

        this.setState({ isSubmitting: true });

        await this.props.onUploadAppointments({
            id: this.props.event.id,
            file
        });

        this.setState({ isSubmitting: false });
    }

    render() {

        const { StyledCard, StyledForm, StyledCardActions, DeleteButton } = internals;
        const appointments = this.fieldValue('appointments');

        return (
            <div>
                <StyledCard>
                    <CardContent>
                        <SectionLabel>Add Appointments</SectionLabel>
                        <StyledForm>
                            <TextField
                                label='Time Start'
                                type='time'
                                value={this.fieldValue('rangeStart')}
                                onChange={this.proposeNew('rangeStart')}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                inputProps={{
                                    step: 300 // 5 min
                                }}
                            />
                            <TextField
                                label='Time End'
                                type='time'
                                value={this.fieldValue('rangeStop')}
                                onChange={this.proposeNew('rangeStop')}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                inputProps={{
                                    step: 300 // 5 min
                                }}
                            />
                            <TextField
                                type='number'
                                label='Interval (Mins)'
                                value={this.fieldValue('interval')}
                                onChange={this.proposeNew('interval')}
                            />
                            <TextField
                                type='number'
                                label='Slots'
                                value={this.fieldValue('slots')}
                                onChange={this.proposeNew('slots')}
                            />
                            <Button
                                onClick={this.handleAddAppointments}
                                variant='contained'
                                color='secondary'
                                disabled={this.shouldDisableSubmit()}
                            >
                                Add
                            </Button>
                        </StyledForm>
                        <AppointmentList
                            appointments={appointments}
                            value={this.fieldValue('selectedAppointmentIds')}
                            onChange={this.proposeNew('selectedAppointmentIds')}
                            onClickDeleteAppointments={this.handleDeleteAppointments}
                            onCancelAppointment={this.props.handleCancelAppointment}
                        />
                    </CardContent>
                    <StyledCardActions>
                        <DeleteButton
                            onClick={this.handleDeleteAppointments}
                        >
                            Delete
                        </DeleteButton>
                    </StyledCardActions>
                </StyledCard>
                <StyledCard>
                    <CardContent>
                        <SectionLabel>Bulk Import Appointments</SectionLabel>
                        <StyledForm>
                            <UploadForm
                                onSubmit={(file) => this.handleImportAppointments(file)}
                            />
                        </StyledForm>
                    </CardContent>
                </StyledCard>
            </div>
        );
    }
};

internals.StyledCard = Styled(Card)`
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

internals.StyledCardActions = Styled(CardActions)`
    display: flex;
    justify-content: space-between;
`;

internals.DeleteButton = Styled(Button)`
    margin-left: auto;
    color: ${({ theme }) => theme.palette.error.main};
`;

internals.StyledForm = Styled.form`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    >div {
        margin-right: ${({ theme }) => theme.spacing(1)}px;
        flex: 0 calc(50% - ${({ theme }) => theme.spacing(1)}px);
    }
`;
