const React = require('react');
const T = require('prop-types');
const { NavLink } = require('react-router-dom');
const { default: Memoize } = require('memoize-one');
const { default: RichTextEditor } = require('react-rte');
const { default: Styled } = require('styled-components');
const { default: Button } = require('@material-ui/core/Button');
const StrangeForms = require('strange-forms');
const Portlet = require('../../../components/Portlet');
const SectionLabel = require('../../../components/SectionLabel');

const internals = {};

module.exports = class SetupEmailPage extends StrangeForms(React.Component) {

    static propTypes = {
        program: T.object,
        onSubmit: T.func.isRequired,
        doesProgramExist: T.bool.isRequired,
        emailBodyLabel: T.string,
        emailFooterLabel: T.string,
        emailBodyKey: T.string,
        emailFooterKey: T.string
    };

    static defaultProps = {
        emailBodyLabel: 'Email Body',
        emailFooterLabel: 'Email Footer',
        emailBodyKey: 'emailCommunicationBody',
        emailFooterKey: 'emailCommunicationFooter'
    }

    constructor(props) {

        super(props);

        const defaultEmailFields = {
            emailCommunicationBody: '',
            emailCommunicationFooter: ''
        };

        this.state = {
            isSubmitting: false
        };

        const getProgramValue = ({ program }, field) => (program && program[field]) || '';

        const useCachedValue = ([props1, field1], [props2, field2]) => getProgramValue(props1, field1) === getProgramValue(props2, field2);

        const fieldValueToWysiwygState = ({ program }, field) => {

            const value = getProgramValue({ program }, field);

            return RichTextEditor.createValueFromString(value, 'html');
        };

        this.strangeForm({
            fields: Object.keys(defaultEmailFields),
            get: {
                emailCommunicationBody: Memoize((someProps) => fieldValueToWysiwygState(someProps, props.emailBodyKey), useCachedValue),
                emailCommunicationFooter: Memoize((someProps) => fieldValueToWysiwygState(someProps, props.emailFooterKey), useCachedValue)
            },
            act: () => null,
            getFormValue: (val) => val
        });
    }

    componentDidMount() {

        this.props.fetchProgram();
    }

    shouldDisableSubmit() {

        const emailCommunicationBody = this.fieldValue('emailCommunicationBody');
        const emailCommunicationFooter = this.fieldValue('emailCommunicationFooter');
        return !emailCommunicationBody || !emailCommunicationFooter || this.state.isSubmitting;
    }

    handleSubmit = async (ev) => {

        ev.preventDefault();
        const emailCommunicationBody = this.fieldValue('emailCommunicationBody').toString('html');
        const emailCommunicationFooter = this.fieldValue('emailCommunicationFooter').toString('html');
        this.setState({ isSubmitting: true });
        const { emailBodyKey, emailFooterKey } = this.props;

        const emailFields =
            {
                [emailBodyKey]: emailCommunicationBody,
                [emailFooterKey]: emailCommunicationFooter
            };


        await this.props.onSubmit(
            {
                id: this.props.program.id,
                ...emailFields
            }
        );

        this.setState({ isSubmitting: false });
    }

    render() {

        const { PageContainer, StyledButton, ForwardedLink, ButtonWrapper, StyledForm, StyledRichTextEditor } = internals;
        const { doesProgramExist, isLoading, program, emailBodyLabel, emailFooterLabel } = this.props;
        const { name = '', client = {} } = program || {};
        const { logoUrl, name: clientName } = client;

        if (isLoading) {
            return null;
        }

        return (
            <PageContainer>
                {doesProgramExist &&
                <Portlet
                    title={`Program: ${name}`}
                    toolbar={logoUrl && <Portlet.LogoImg src={logoUrl} alt={`${clientName} logo`} />}
                    body={
                        <StyledForm>
                            <SectionLabel>{emailBodyLabel}</SectionLabel>
                            <StyledRichTextEditor
                                value={this.fieldValue('emailCommunicationBody')}
                                onChange={this.proposeNew('emailCommunicationBody')}
                                toolbarConfig={internals.toolbarConfig}
                            />
                            <SectionLabel>{emailFooterLabel}</SectionLabel>
                            <StyledRichTextEditor
                                value={this.fieldValue('emailCommunicationFooter')}
                                onChange={this.proposeNew('emailCommunicationFooter')}
                                toolbarConfig={internals.toolbarConfig}
                            />
                        </StyledForm>
                    }
                    foot={
                        <ButtonWrapper>
                            <StyledButton
                                onClick={this.handleSubmit}
                                variant='contained'
                                color='primary'
                                disabled={this.shouldDisableSubmit()}
                            >
                                Save
                            </StyledButton>
                            <StyledButton
                                component={ForwardedLink}
                                to='/'
                                variant='text'
                            >
                                Cancel
                            </StyledButton>
                        </ButtonWrapper>
                    }
                />}
                {!doesProgramExist &&
                    <Portlet
                        title='Program Not Found'
                        body={
                            <StyledButton
                                component={ForwardedLink}
                                to='/programs/search'
                                variant='text'
                            >
                                Search Programs
                            </StyledButton>
                        }
                    />}
            </PageContainer>
        );
    }
};

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.StyledButton = Styled(Button)`
    margin: 0 8px;
`;

internals.ButtonWrapper = Styled.div`
    display: flex;
`;

internals.StyledForm = Styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

internals.StyledRichTextEditor = Styled(RichTextEditor)`
    width: 100%;
    max-width: 520px;
`;

internals.ForwardedLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);

internals.toolbarConfig = {
    display: ['BLOCK_TYPE_DROPDOWN', 'INLINE_STYLE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_BUTTONS'],
    BLOCK_TYPE_DROPDOWN: [
        { label: 'Normal', style: 'unstyled' },
        { label: 'Heading Large', style: 'header-one' },
        { label: 'Heading Medium', style: 'header-two' },
        { label: 'Heading Small', style: 'header-three' }
    ],
    INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' }
    ]
};
