const React = require('react');
const { useState } = require('react');
const T = require('prop-types');
const { default: Button } = require('@material-ui/core/Button');
const { default: FormGroup } = require('@material-ui/core/FormGroup');
const { default: FormLabel } = require('@material-ui/core/FormLabel');
const { default: FormControlLabel } = require('@material-ui/core/FormControlLabel');
const { default: RadioGroup } = require('@material-ui/core/RadioGroup');
const { default: Radio } = require('@material-ui/core/Radio');
const { default: Checkbox } = require('@material-ui/core/Checkbox');
const { default: ArrowRight } = require('@material-ui/icons/ArrowRight');
const { default: ArrowDown } = require('@material-ui/icons/ArrowDropDown');
const { Field, Settings } = require('./styles');
const FieldHeader = require('./FieldHeader');
const Choices = require('./Choices');

const internals = {};

module.exports = function CheckboxesField(props) {

    const { field, onChange } = props;

    const [showSettings, setShowSettings] = useState(true);
    const [isEditing, setIsEditing] = useState(false);

    return (
        <Field isEditing={isEditing}>
            <FieldHeader {...props} toggleEdit={() => setIsEditing(!isEditing)} />
            {isEditing && (
                <>
                    <Choices field={field} onChange={onChange} />
                    <Settings>
                        <Button
                            onClick={() => setShowSettings(!showSettings)}
                        >
                            {showSettings ? <ArrowDown /> : <ArrowRight />} Settings
                        </Button>
                        {showSettings && (
                            <div>
                                <FormLabel component='legend'>Validation</FormLabel>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Checkbox checked={field.settings.isRequired} onChange={(ev, isRequired) => onChange(field, { settings: { isRequired } })} name='isRequired' />}
                                        label='Required'
                                    />
                                </FormGroup>
                                <FormLabel component='legend'>Width</FormLabel>
                                <RadioGroup row aria-label='width' name='width' value={field.settings.width} defaultValue='100' onChange={(ev, width) => onChange(field, { settings: { width } })}>
                                    <FormControlLabel value='100' control={<Radio color='primary' />} label='Full' />
                                    <FormControlLabel value='50' control={<Radio color='primary' />} label='50%' />
                                    <FormControlLabel value='33' control={<Radio color='primary' />} label='33%' />
                                    <FormControlLabel value='25' control={<Radio color='primary' />} label='25%' />
                                </RadioGroup>
                            </div>
                        )}
                    </Settings>
                </>
            )}
        </Field>
    );
};

module.exports.propTypes = {
    field: T.object.isRequired,
    onChange: T.func.isRequired
};
