const Connect = require('react-redux').connect;
const PatientPortalWrapper = require('../components/PatientPortalWrapper');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        searchResults: M.selectors.model.searchPatients(state),
        program: M.selectors.model.fetchProgram(state)

    }),
    (dispatch, props) => ({
        fetchData: () => M.dispatch.model.fetchProgram(props.match.params.id),
        searchPatients: (searchCriteria) => M.dispatch.model.searchPatients(searchCriteria),
        createPatient: M.dispatch.model.createPatient
    })
);

module.exports = internals.connect(PatientPortalWrapper);
