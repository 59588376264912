const Connect = require('react-redux').connect;
const SearchPatientsPage = require('../components/SearchPatientsPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        searchResults: M.selectors.model.searchPatients(state),
        program: M.selectors.model.fetchProgram(state),
        role: M.selectors.auth.getUserRole(state),
        key: M.selectors.app.getCurrentProgramId(state)
    }),
    (dispatch, props) => ({
        fetchData: () => {

            // Fetch the data for the program in the URL (admin layout)
            // The screener layout uses a URL scheme without the ID, and
            // should fetch the activeProgram.
            // If an activeProgram hasn't been set yet (i.e., on initial visit),
            // fetch the first program in the list of programs assigned to the screener.
            // Many or most screeners will only have one propgram assigned at a time
            const programIdToFetch = props.match.params.id ||
                M.selectors.app.getCurrentProgramId(M.store.getState());
            return M.dispatch.model.fetchProgram(programIdToFetch);
        },
        searchPatients: (searchCriteria) => M.dispatch.model.searchPatients(searchCriteria)
    })
);

module.exports = internals.connect(SearchPatientsPage);
