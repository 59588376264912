const React = require('react');
const T = require('prop-types');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: Divider } = require('@material-ui/core/Divider');
const GetDateString = require('../utils/get-date-string');
const GetFormattedTime = require('../utils/get-formatted-time');

module.exports = (props) => {

    const { event, appointment, align } = props;

    return (
        <>
            {event.date && appointment.time && <Typography align={align}><strong>{GetDateString(event.date)} at {GetFormattedTime(appointment.time)}</strong></Typography>}
            <Typography align={align}>{event.location} – {event.city}</Typography>
            <Typography align={align}>{event.name}</Typography>

            <Divider style={{ margin: '10px 0' }} />
            {event.address1 && <Typography align={align}>{event.address1}</Typography>}
            {event.address2 && <Typography align={align}>{event.address2}</Typography>}
            {event.city && <Typography align={align}>{event.city}</Typography>}
            {event.state && <Typography align={align}>{event.state}{event.zip && `, ${event.zip}`}</Typography>}
        </>
    );
};

module.exports.propTypes = {
    event: T.shape({
        date: T.instanceOf(Date),
        address1: T.string,
        address2: T.string,
        city: T.string,
        name: T.string,
        location: T.string,
        state: T.string,
        zip: T.string
    }).isRequired,
    appointment: T.shape({
        time: T.string
    }).isRequired,
    align: T.string
};

module.exports.defaultProps = {
    align: 'left'
};

module.exports.displayName = 'DateMaskInput';
