const Axios = require('axios');
const SupportsLocalStorage = require('../utils/check-local-storage');
const M = require('../middle-end');
const { API_BASE_URL } = require('../constants');

localStorage = SupportsLocalStorage() ? localStorage : {};

const client = module.exports = Axios.create({
    baseURL: API_BASE_URL,
    responseType: 'json',
    headers: { common: {} }
});

client.logout = async () => {

    try {
        await client.post('/logout');
    }
    catch (error) {
        throw error;
    }
    finally {
        client.access_token = null;

        if (localStorage instanceof Storage) {
            localStorage.removeItem('access_token');
        }
        else {
            localStorage.access_token = null;
        }
    }
};

client.access_token = null;

client.interceptors.request.use(
    (config) => {

        const token = client.access_token || localStorage.access_token;

        // Ensure token exists and is well-formed
        if (token && token !== 'undefined' && token !== 'null') {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    }
);

client.interceptors.response.use(null, async (error) => {

    if (error.config && error.config._isRetry) {
        throw error;
    }

    if (error.config && error.response && error.response.status === 403) {
        M.dispatch.app.pushApiErrors(error);
    }

    if (error.config && error.response && error.response.status === 401) {

        error.config._isRetry = true;

        try {

            const { data: results } = await client.get('/reauthorize');

            if (results.data.token) {
                client.access_token = localStorage.access_token = results.data.token;
            }
        }
        catch (reauthErr) {

            if (Axios.isAxiosError(reauthErr)) {
                // Rethrow the original error if /reauthorize fails so that
                // consumers don't have to worry about this case.
                throw error;
            }

            // Something unexpected, non-HTTP went wrong during reauth
            throw reauthErr;
        }

        return client.request(error.config);
    }

    throw error;
});
