const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: Select } = require('@material-ui/core/Select');
const { default: MenuItem } = require('@material-ui/core/MenuItem');

const internals = {};

module.exports = function SelectField({ field, value, onChange, error }) {

    const { Error } = internals;

    return (
        <>
            <div>{field.name}{field.settings.isRequired && '*'}</div>
            <Select
                fullWidth
                displayEmpty
                value={value}
                onChange={(e) => onChange(e.target.value)}
                required={field.settings.isRequired}
            >
                <MenuItem value=''><em>Select one...</em></MenuItem>
                {
                    field.choices.map((choice) => <MenuItem key={choice} value={choice}>{choice}</MenuItem>)
                }
            </Select>
            <Error>{error}</Error>
        </>
    );
};

module.exports.propTypes = {
    field: T.object.isRequired,
    onChange: T.func.isRequired,
    value: T.string,
    error: T.string
};

module.exports.defaultProps = {
    value: ''
};

internals.Error = Styled.div`
    color: ${({ theme }) => theme.palette.error.main};
`;
