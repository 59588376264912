const React = require('react');
const T = require('prop-types');
const { default: Dialog } = require('@material-ui/core/Dialog');
const { default: DialogActions } = require('@material-ui/core/DialogActions');
const { default: DialogContent } = require('@material-ui/core/DialogContent');
const { default: DialogContentText } = require('@material-ui/core/DialogContentText');
const { default: DialogTitle } = require('@material-ui/core/DialogTitle');
const { default: Button } = require('@material-ui/core/Button');

module.exports = class AlertDialog extends React.Component {

    static propTypes = {
        confirmAction: T.func.isRequired,
        cancelAction: T.func,
        dialogTitle: T.string,
        dialogDescription: T.oneOfType([T.string, T.node]),
        affirmativeLabel: T.string,
        negativeLabel: T.oneOfType([T.string, T.oneOf([null])]),
        toggleModal: T.func.isRequired,
        isModalOpen: T.bool
    };

    static defaultProps = {
        affirmativeLabel: 'Confirm',
        cancelAction: () => {},
        negativeLabel: 'Cancel',
        isModalOpen: false
    };

    constructor(props) {

        super(props);

        this.state = {
            isSubmitting: false
        };

        this._unmounted = false;
    }

    handleSubmit = async () => {

        this.setState({ isSubmitting: true });
        await this.props.confirmAction();
        if (!this._unmounted) {
            this.setState({ isSubmitting: false });
            this.props.toggleModal();
        }
    }

    componentWillUnmount() {

        // "Necessary" evil since props.confirmAction() is likely but not guaranteed to cause an unmount

        this._unmounted = true;
    }

    render() {

        // eslint-disable-next-line no-unused-vars
        const {
            isModalOpen,
            toggleModal,
            dialogTitle,
            dialogDescription,
            affirmativeLabel,
            negativeLabel,
            cancelAction,
            ...others
        } = this.props;

        const { isSubmitting } = this.state;

        return (
            <Dialog
                open={isModalOpen}
                onClose={toggleModal}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                {...others}
            >
                <DialogTitle id='alert-dialog-title'>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        {dialogDescription}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {negativeLabel !== null &&
                    <Button onClick={(...args) => {

                        toggleModal(args);
                        cancelAction(args);
                    }}
                    >
                        {negativeLabel}
                    </Button>}
                    <Button
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus
                        onClick={this.handleSubmit}
                        color='primary'
                        variant='contained'
                        disabled={isSubmitting}
                    >
                        {affirmativeLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
};
