const Connect = require('react-redux').connect;
const ReportingPage = require('../components/ReportingPage');
const M = require('../../../middle-end');
const DownloadPost = require('../../../utils/download-post');
const { API_BASE_URL } = require('../../../constants');

const internals = {};

internals.connect = Connect(
    (state) => ({
        events: M.selectors.model.fetchEvents(state),
        program: M.selectors.model.fetchProgram(state)
    }),
    (dispatch, props) => ({
        fetchData: async () => {

            await Promise.all([
                M.dispatch.model.fetchProgram(props.match.params.id),
                M.dispatch.model.fetchEvents(props.match.params.id)
            ]);
        },
        selectEvents: M.dispatch.app.selectEvents,
        fetchTPAReport: async (payload) => {

            await DownloadPost({
                url: `${API_BASE_URL}/reports/tpa`,
                payload
            });
        }
    })
);

module.exports = internals.connect(ReportingPage);
