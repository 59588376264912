const React = require('react');
const { default: Styled } = require('styled-components');
const { default: Typography } = require('@material-ui/core/Typography');

module.exports = Styled(
    (props) => <Typography component='span' color='textSecondary' {...props} />
)`
    && {
        display: initial;
        font-size: inherit;
    }
`;
