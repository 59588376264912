const Connect = require('react-redux').connect;
const CreateEventPage = require('../components/CreateEventPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        event: M.selectors.model.fetchEvent(state)
    }),
    (dispatch, props) => ({

        fetchData: async () => {

            await M.dispatch.model.fetchEvent(props.match.params.eventId);
        },
        onSubmit: async (eventInfo) => {

            const [err] = await M.dispatch.model.editEvent({ programId: props.match.params.id, ...eventInfo });

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Event saved successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err];
        },
        sendCommunication: async (communication) => {

            const [err] = await M.dispatch.model.sendEventCommunication(communication);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Communication sent successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err];
        },
        uploadAppointments: async (data) => {

            const [err] = await M.dispatch.model.uploadAppointments(data);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Appointments imported successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err];
        },
        cancelAppointment: async (appointmentInfo) => {

            const [err] = await M.dispatch.model.cancelAppointment(appointmentInfo);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Appointment cancelled successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }
        }
    })
);

module.exports = internals.connect(CreateEventPage);
