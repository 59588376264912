const React = require('react');
const FieldHeader = require('./FieldHeader');
const { Field } = require('./styles');

const internals = {};

module.exports = function Divider(props) {

    return (
        <Field>
            <FieldHeader display={() => <>Divider</>} {...props} />
        </Field>
    );
};
