const Connect = require('react-redux').connect;
const ResetPasswordPage = require('../components/ResetPasswordPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({

    }),
    (dispatch, props) => ({

        sendResetPassword: async (email) => {

            const [err, result] = await M.dispatch.auth.sendResetPassword(email);

            if (!err) {
                M.dispatch.snackbar.pushToQueue(`Email sent to ${email}`);
            }

            return [err, result];
        }
    })
);

module.exports = internals.connect(ResetPasswordPage);
