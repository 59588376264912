const { FETCH_CURRENT_USER, LOGIN } = require('./action-types');

exports.getIsAuthenticated = ({ auth }) => {

    const { [FETCH_CURRENT_USER.BASE]: user } = auth.indexes;

    return !!user && !!user.result;
};

exports.getUser = ({ auth }) => {

    const { [FETCH_CURRENT_USER.BASE]: user } = auth.indexes;
    return user && user.result;
};

exports.getUserRole = ({ auth }) => {

    const { [FETCH_CURRENT_USER.BASE]: user } = auth.indexes;
    return user && user.result && user.result.role && user.result.role.name;
};

exports.getHasAuthenticationSettled = ({ auth }) => {

    const { [FETCH_CURRENT_USER.BASE]: index } = auth.indexes;

    return !!index && !index.inFlight;
};

exports.getToken = ({ auth }) => {

    const { [LOGIN.BASE]: index } = auth.indexes;

    return index.result;
};
