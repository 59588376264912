const Connect = require('react-redux').connect;
const FormBuilderPage = require('../components/FormBuilderPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({

    }),
    (dispatch, props) => ({
        fetchForm: () => M.dispatch.model.fetchForm(props.match.params.id),
        onSubmit: async (form) => {

            const [err, res] = await M.dispatch.model.upsertForm(form);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Form saved successfully');

                if (!form.id) {
                    M.dispatch.router.push(`/forms/${res.id}/edit`);
                }
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }
        }
    })
);

module.exports = internals.connect(FormBuilderPage);
