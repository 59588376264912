const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: FormHelperText } = require('@material-ui/core/FormHelperText');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: Card } = require('@material-ui/core/Card');
const { default: CardContent } = require('@material-ui/core/CardContent');
const { default: Select } = require('@material-ui/core/Select');
const { default: MenuItem } = require('@material-ui/core/MenuItem');
const { default: FormControl } = require('@material-ui/core/FormControl');
const { default: FormControlLabel } = require('@material-ui/core/FormControlLabel');
const { default: Checkbox } = require('@material-ui/core/Checkbox');
const StrangeForms = require('strange-forms');
const DateMaskInput = require('./DateMaskInput');
const StateSelect = require('./StateSelect');
const { Button } = require('@material-ui/core');

const internals = {};

module.exports = class PersonalDetailFields extends StrangeForms(React.Component) {

    static propTypes = {
        value: T.object,
        onChange: T.func,
        title: T.string,
        titleVariant: T.string,
        showSsn: T.bool,
        isCovidForm: T.bool,
        // The presence of onSave will decide whether
        // the save button will show or not
        onSave: T.func,
        error: T.string
    };

    static defaultProps = {
        showSsn: false,
        isCovidForm: false
    }

    static fields = [
        'firstName',
        'middleName',
        'lastName',
        'identifierDecrypted',
        'dob',
        'gender',
        'address1',
        'address2',
        'city',
        'st',
        'zip',
        'phone',
        'email',
        'locationCode',
        'ssn',
        'noSsn'
    ];

    constructor(props) {

        super(props);

        this.state = {
            isSubmitting: false,
            activeStep: 0,
            dialogName: null
        };

        this.strangeForm({
            fields: PersonalDetailFields.fields,
            get: {
                '*': (someProps, field) => (someProps.value && someProps.value[field]) || ''
            },
            act: (field, value) => this.props.onChange({ ...this.props.value, [field]: value }),
            getFormValue: {
                noSsn: (ev) => ev.target.checked,
                '*': (ev) => ev.target.value
            }
        });
    }

    handleSave = async (e) => {

        e.preventDefault();

        if (!this.props.onSave) {
            return;
        }

        this.setState({ isSubmitting: true });
        await this.props.onSave();
        this.setState({ isSubmitting: false });
    }

    render() {

        const { title, titleVariant, showSsn, isCovidForm, onSave, error } = this.props;

        const { StyledForm, StyledCard } = internals;

        return (
            <form onSubmit={this.handleSave}>
                <StyledCard>
                    <CardContent>
                        <Typography variant={titleVariant || 'h6'} gutterBottom>{title || 'Personal Details'}</Typography>
                        <StyledForm>
                            <TextField
                                label='First Name'
                                value={this.fieldValue('firstName')}
                                onChange={this.proposeNew('firstName')}
                                required
                            />
                            <TextField
                                label='M.I.'
                                value={this.fieldValue('middleName')}
                                onChange={this.proposeNew('middleName')}
                            />
                            <TextField
                                label='Last Name'
                                value={this.fieldValue('lastName')}
                                onChange={this.proposeNew('lastName')}
                                required
                            />
                            {!isCovidForm && (
                                <TextField
                                    label='EID'
                                    value={this.fieldValue('identifierDecrypted')}
                                    onChange={this.proposeNew('identifierDecrypted')}
                                    required={!isCovidForm}
                                />
                            )}
                            <TextField
                                type='locationCode'
                                label='Location Code'
                                value={this.fieldValue('locationCode')}
                                onChange={this.proposeNew('locationCode')}
                            />
                            <TextField
                                label='Date of Birth'
                                value={this.fieldValue('dob')}
                                onChange={this.proposeNew('dob')}
                                margin='dense'
                                required
                                InputProps={{
                                    inputComponent: DateMaskInput
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                            <FormControl margin='dense'>
                                <Select
                                    value={this.fieldValue('gender')}
                                    onChange={this.proposeNew('gender')}
                                    displayEmpty
                                    required
                                >
                                    <MenuItem value=''><em>Sex</em></MenuItem>
                                    <MenuItem value='M'>Male</MenuItem>
                                    <MenuItem value='F'>Female</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                label='Address 1'
                                value={this.fieldValue('address1')}
                                onChange={this.proposeNew('address1')}
                            />
                            <TextField
                                label='Address 2'
                                value={this.fieldValue('address2')}
                                onChange={this.proposeNew('address2')}
                            />
                            <TextField
                                label='City'
                                value={this.fieldValue('city')}
                                onChange={this.proposeNew('city')}
                            />
                            <StateSelect
                                fullWidth={false}
                                value={this.fieldValue('st')}
                                onChange={this.proposeNew('st')}
                            />
                            <TextField
                                label='Zip'
                                value={this.fieldValue('zip')}
                                onChange={this.proposeNew('zip')}
                            />
                            <TextField
                                label='Phone'
                                value={this.fieldValue('phone')}
                                onChange={this.proposeNew('phone')}
                            />
                            <TextField
                                type='email'
                                label='Email'
                                value={this.fieldValue('email')}
                                onChange={this.proposeNew('email')}
                            />
                            {showSsn && (
                                <>
                                    <TextField
                                        label='SSN'
                                        value={this.fieldValue('ssn')}
                                        onChange={this.proposeNew('ssn')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                value={this.fieldValue('noSsn')}
                                                checked={this.fieldValue('noSsn')}
                                                onChange={this.proposeNew('noSsn')}
                                            />
                                        }
                                        label='No SSN'
                                    />
                                </>
                            )}
                        </StyledForm>
                        {error && <FormHelperText error>{error}</FormHelperText>}
                        {onSave && (
                            <Button
                                color='primary'
                                variant='contained'
                                type='submit'
                                disabled={this.state.isSubmitting}
                                style={{ marginLeft: 0 }}
                            >
                                Save
                            </Button>
                        )}
                    </CardContent>
                </StyledCard>
            </form>
        );
    }
};

internals.StyledCard = Styled(Card)`
    max-width: 900px;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

internals.StyledForm = Styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    > div {
        flex: 1 1 auto;
        margin-right: ${({ theme }) => theme.spacing(1)}px;
    }
`;

module.exports.validateStep = (fields) => {

    const { firstName, lastName, identifierDecrypted, dob, gender } = fields;

    return !(firstName && lastName && identifierDecrypted && dob && gender);
};
