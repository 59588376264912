const React = require('react');
const T = require('prop-types');
const { default: Memoize } = require('memoize-one');
const { default: Styled } = require('styled-components');
const { NavLink } = require('react-router-dom');
const OrderBy = require('lodash/orderBy');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: InputAdornment } = require('@material-ui/core/InputAdornment');
const { default: IconButton } = require('@material-ui/core/IconButton');
const { default: FormControl } = require('@material-ui/core/FormControl');
const { default: Select } = require('@material-ui/core/Select');
const { default: MenuItem } = require('@material-ui/core/MenuItem');
const { default: Chip } = require('@material-ui/core/Chip');
const { default: TablePagination } = require('@material-ui/core/TablePagination');
const { default: SearchIcon } = require('@material-ui/icons/Search');
const { default: EditIcon } = require('@material-ui/icons/Edit');
const { default: ArchiveIcon } = require('@material-ui/icons/Archive');
const { default: UnarchiveIcon } = require('@material-ui/icons/Unarchive');
const { default: LinkIcon } = require('@material-ui/icons/Link');
const { default: DeleteIcon } = require('@material-ui/icons/Delete');
const StrangeForms = require('strange-forms');
const Portlet = require('../../../components/Portlet');
const Nullable = require('../../../components/Nullable');
const ClientSelect = require('../../../components/ClientSelect');
const AlertDialog = require('../../../components/AlertDialog');
const Constants = require('../../../utils/constants');

const Table = require('../../../components/Table');

const { MANAGER_URL_PART } = require('../../../constants');

const internals = {};

module.exports = class SearchProgramsPage extends StrangeForms(React.Component) {

    static propTypes = {
        programs: T.arrayOf(T.shape({
            id: T.string,
            name: T.string,
            clientId: T.string
        })),
        clients: T.array,
        role: T.string
    };

    static fields = {
        status: '',
        filterText: '',
        clientId: ''
    };

    constructor(props) {

        super(props);

        this.state = {
            status: '',
            filterText: '',
            orderDirection: 'desc',

            // Pagination
            rowsPerPage: 10,
            page: 0
        };

        this.strangeForm({
            fields: Object.keys(SearchProgramsPage.fields),
            get: () => '',
            act: () => null
        });
    }

    componentDidMount() {

        this.props.fetchData();
    }

    getOrderedPrograms = Memoize(
        (programs) => OrderBy(programs, ['archivedAt', 'name'], ['desc', 'asc'])
    );

    handleRequestSort = (event, order) => {

        const { orderBy, orderDirection, compareFn } = order;

        this.setState({
            orderBy,
            orderDirection,
            compareFn
        });
    }

    filterPrograms = (programs) => {

        let filteredPrograms = programs;
        const filterText = this.fieldValue('filterText').toLowerCase();
        const status = this.fieldValue('status');
        const clientId = this.fieldValue('clientId');

        if (filterText) {
            filteredPrograms = programs.filter(({ name, client }) => {

                return name.toLowerCase().includes(filterText) || client.name.toLowerCase().includes(filterText);
            });
        }

        if (status) {
            filteredPrograms = filteredPrograms.filter(({ archivedAt }) => {

                return status === 'active' ? !archivedAt : !!archivedAt;
            });
        }

        if (clientId) {
            filteredPrograms = filteredPrograms.filter(({ client }) => {

                return client.id === clientId;
            });
        }

        return filteredPrograms;
    }

    sortPrograms = (filteredPrograms) => {

        const { compareFn } = this.state;

        if (!compareFn) {
            return filteredPrograms;
        }

        return compareFn([...filteredPrograms]);
    }

    handleChangePage = (ev, newPage) => {

        this.setState({
            page: newPage,
            dialogName: null
        });
    }

    handleShowModal = (program, dialog = 'archive') => {

        this.setState({
            dialogName: dialog,
            stagedProgram: program
        });
    }

    closeDialog = () => {

        this.setState({ dialogName: null });
    };

    handleChangeRowsPerPage = (ev) => {

        this.setState({
            page: 0,
            rowsPerPage: ev.target.value
        });
    }

    render() {

        const { PageContainer, StyledSelect, StyledClientSelect, FieldsWrapper, StyledLinkIcon } = internals;
        const { rowsPerPage, page } = this.state;
        const { role } = this.props;
        const isProduction = Constants.ENVIRONMENT === 'production';

        const programs = this.getOrderedPrograms(this.props.programs);

        const uniqueClientIds = [...new Set(programs.map(({ client }) => client.id))];
        const allClients = programs.map(({ client }) => client); // Possible duplicate entries here
        const clients = uniqueClientIds.map((id) => allClients.find(({ id: clientId }) => clientId === id));
        const columns = [
            {
                id: 'name',
                header: 'Name',
                label: true,
                sortable: true,
                format: (name) => <Nullable value={name} />
            },
            {
                id: 'client',
                header: 'Client',
                label: true,
                sortable: (rows, { direction = 'desc' } = {}) => {

                    return OrderBy(rows, ['client.name', 'name'], [direction, 'asc']);
                },
                format: (_, data) => <Nullable value={data.client && data.client.name} />
            },
            {
                id: 'status',
                header: 'Status',
                label: true,
                sortable: (rows, { direction = 'desc' } = {}) => {

                    return OrderBy(rows, ['archivedAt', 'name'], [direction === 'desc' ? 'asc' : 'desc', 'asc']);
                },
                format: (_, { archivedAt }) => {

                    const active = !archivedAt;
                    return (
                        <Chip
                            size='small'
                            color={active ? 'secondary' : 'default'}
                            label={active ? 'Active' : 'Archived'}
                        />
                    );
                }
            },
            {
                id: 'portal',
                header: 'Portal',
                label: true,
                format: (_, { id }) => {

                    return (
                        <IconButton
                            onClick={() => this.props.selectProgram(id)}
                            to={`/portal/${id}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            component={NavLink}
                            // counteract padding from size='medium while maintaining a healthy click/tap target
                            style={{ margin: '-12px -4px' }}
                        >
                            <StyledLinkIcon />
                        </IconButton>);
                }
            },
            {
                id: 'manager-portal',
                header: 'MPortal',
                label: true,
                format: (_, { id }) => {

                    return (
                        <IconButton
                            onClick={() => this.props.selectProgram(id)}
                            to={`/${MANAGER_URL_PART}/portal/${id}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            component={NavLink}
                            // counteract padding from size='medium while maintaining a healthy click/tap target
                            style={{ margin: '-12px -4px' }}
                        >
                            <StyledLinkIcon />
                        </IconButton>);
                }
            },
            {
                id: 'edit',
                header: 'Edit',
                label: true,
                format: (_, { id }) => {

                    return (
                        <IconButton
                            onClick={() => this.props.selectProgram(id)}
                            to={role === Constants.ROLE.SCREENER ? '/screener' : `/programs/${id}/edit`}
                            component={NavLink}
                            // counteract padding from size='medium while maintaining a healthy click/tap target
                            style={{ margin: -12 }}
                        >
                            <EditIcon />
                        </IconButton>);
                }
            },
            {
                id: 'archive',
                header: 'Archive',
                label: true,
                format: (_, program) => {

                    return (
                        <IconButton
                            onClick={
                                program.archivedAt ?
                                    () => this.props.archiveProgram(program.id) :
                                    () => this.handleShowModal(program)
                            }
                            // counteract padding from size='medium while maintaining a healthy click/tap target
                            style={{ margin: '-12px auto' }}
                        >
                            {program.archivedAt ? <UnarchiveIcon /> : <ArchiveIcon />}
                        </IconButton>);
                }
            }
        ];

        if (!isProduction) {
            columns.push({
                id: 'delete',
                header: 'Delete',
                label: true,
                format: (_, client) => {

                    return (
                        <IconButton
                            onClick={() => this.handleShowModal(client, 'delete')}
                            // counteract padding from size='medium while maintaining a healthy click/tap target
                            style={{ margin: '-12px auto' }}
                        >
                            <DeleteIcon />
                        </IconButton>);
                }
            });
        }

        const filteredPrograms = this.filterPrograms(programs);
        const sortedPrograms = this.sortPrograms(filteredPrograms) || [];
        const slicedPrograms = sortedPrograms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        const { dialogName } = this.state;

        return (
            <PageContainer>
                <Portlet
                    title='Search Programs'
                    toolbar={
                        role === 'superadmin' &&
                        <Chip
                            color='secondary'
                            label='Add New Program +'
                            component={NavLink} to='/programs/new'
                            clickable
                        />
                    }
                    body={
                        <>
                            <FieldsWrapper>
                                <StyledClientSelect
                                    clients={clients}
                                    value={this.fieldValue('clientId')}
                                    onChange={this.proposeNew('clientId')}
                                />
                                <FormControl margin='dense'>
                                    <StyledSelect
                                        value={this.fieldValue('status')}
                                        onChange={this.proposeNew('status')}
                                        displayEmpty
                                    >
                                        <MenuItem value=''><em>Status</em></MenuItem>
                                        <MenuItem value='active'>Active</MenuItem>
                                        <MenuItem value='archived'>Archived</MenuItem>
                                    </StyledSelect>
                                </FormControl>
                                <TextField
                                    InputProps={{
                                        startAdornment: <InputAdornment position='start'><SearchIcon /></InputAdornment>
                                    }}
                                    label='Search'
                                    value={this.fieldValue('filterText')}
                                    onChange={this.proposeNew('filterText')}
                                    margin='dense'
                                />
                            </FieldsWrapper>
                            <Table.Wrapper>
                                <Table
                                    columns={columns}
                                    idAttribute='id'
                                    orderDirection={this.state.orderDirection}
                                    orderBy={this.state.orderBy}
                                    onRequestSort={this.handleRequestSort}
                                >
                                    <Table.Rows data={slicedPrograms} />
                                </Table>
                                <TablePagination
                                    count={sortedPrograms.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </Table.Wrapper>
                        </>
                    }
                />
                <AlertDialog
                    dialogTitle='Archive program?'
                    dialogDescription={`Archiving "${this.state.stagedProgram && this.state.stagedProgram.name}" will revoke program access for associated program administrators and screeners.`}
                    affirmativeLabel='Archive'
                    isModalOpen={dialogName === 'archive'}
                    confirmAction={() => this.props.archiveProgram(this.state.stagedProgram.id)}
                    toggleModal={this.closeDialog}
                />
                <AlertDialog
                    dialogTitle='Delete program?'
                    dialogDescription={`Deleting "${this.state.stagedProgram && this.state.stagedProgram.name}" will remove this program permanently.`}
                    affirmativeLabel='Delete'
                    isModalOpen={dialogName === 'delete'}
                    confirmAction={() => this.props.deleteProgram(this.state.stagedProgram.id)}
                    toggleModal={this.closeDialog}
                />
            </PageContainer>
        );
    }
};

internals.StyledForm = Styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.StyledSelect = Styled(Select)`
    margin-right: ${({ theme }) => theme.spacing(2)}px;
`;

internals.StyledClientSelect = Styled(ClientSelect)`
    margin-right: ${({ theme }) => theme.spacing(2)}px;
`;

internals.FieldsWrapper = Styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

internals.StyledLinkIcon = Styled(LinkIcon)`
    transform: rotate(-45deg);
`;
