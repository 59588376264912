const Connect = require('react-redux').connect;
const CovidLabel = require('../components/CovidLabel');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        appointment: M.selectors.model.fetchAppointment(state)
    }),
    (dispatch, props) => ({
        fetchData: async () => {

            await Promise.all([
                M.dispatch.model.fetchAppointment(props.match.params.appointmentId)
            ]);
        }
    })
);

module.exports = internals.connect(CovidLabel);
