const Connect = require('react-redux').connect;
const ScreeningReportPage = require('../components/ScreeningSurveyPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        screening: M.selectors.model.fetchScreening(state),
        program: M.selectors.model.fetchProgram(state),
        patient: M.selectors.model.fetchPatient(state)
    }),
    (dispatch, props) => ({
        fetchData: async () => {

            await Promise.all([
                M.dispatch.model.fetchScreening(props.match.params.id)
            ]);
        },
        fetchProgram: (id) => M.dispatch.model.fetchProgram(id),
        fetchPatient: (id) => M.dispatch.model.fetchPatient(id),
        fetchForm: (id) => M.dispatch.model.fetchForm(id),
        fetchFormSubmission: M.dispatch.model.fetchFormSubmission,
        submitFormSubmission: async (submission) => {

            const [err] = await M.dispatch.model.upsertFormSubmission(submission);

            if (err) {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }
        }
    })
);

module.exports = internals.connect(ScreeningReportPage);
