const { connect } = require('react-redux');
const { withRouter } = require('react-router-dom');
const M = require('../middle-end');
const Snackbar = require('../components/Snackbar');

module.exports = withRouter(connect(
    (state) => ({
        nextFromQueue: M.selectors.snackbar.getNextFromQueue(state)
    }),
    {
        shiftQueue: (...args) => M.actions.snackbar.shiftQueue(...args),
        pushToQueue: (...args) => M.actions.snackbar.pushToQueue(...args)
    }
)(Snackbar));
