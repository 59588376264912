const Connect = require('react-redux').connect;
const ResultsMetricsPage = require('../components/ResultsMetricsPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        program: M.selectors.model.fetchProgram(state),
        screeningMetrics: M.selectors.model.fetchScreeningMetrics(state),
        screeningCategories: M.selectors.model.fetchScreeningCategories(state),
        doesProgramExist: !M.selectors.model.fetchProgramNotFound(state)
    }),
    (dispatch, props) => ({
        fetchData: async () => {

            await Promise.all([
                M.dispatch.model.fetchScreeningCategories(),
                M.dispatch.model.fetchScreeningMetrics(),
                M.dispatch.model.fetchProgram(props.match.params.id)
            ]);
        },
        onSubmit: async (metrics) => {

            const [err] = await M.dispatch.model.editProgram(metrics);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Email metrics updated successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err];
        }
    })
);

module.exports = internals.connect(ResultsMetricsPage);
