const React = require('react');
const T = require('prop-types');
const { default: Memoize } = require('memoize-one');
const { default: Styled } = require('styled-components');
const { NavLink } = require('react-router-dom');
const OrderBy = require('lodash/orderBy');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: InputAdornment } = require('@material-ui/core/InputAdornment');
const { default: IconButton } = require('@material-ui/core/IconButton');
const { default: Chip } = require('@material-ui/core/Chip');
const { default: TablePagination } = require('@material-ui/core/TablePagination');
const { default: SearchIcon } = require('@material-ui/icons/Search');
const { default: EditIcon } = require('@material-ui/icons/Edit');
const { default: DeleteIcon } = require('@material-ui/icons/Delete');
const StrangeForms = require('strange-forms');
const Portlet = require('../../../components/Portlet');
const Nullable = require('../../../components/Nullable');
const AlertDialog = require('../../../components/AlertDialog');

const Table = require('../../../components/Table');

const internals = {};

module.exports = class SearchForms extends StrangeForms(React.Component) {

    static propTypes = {
        forms: T.array,
        fetchForms: T.func.isRequired,
        deleteForm: T.func.isRequired
    };

    static fields = {
        filterText: ''
    };

    constructor(props) {

        super(props);

        this.state = {
            filterText: '',
            orderDirection: 'desc',

            // Pagination
            rowsPerPage: 10,
            page: 0
        };

        this.strangeForm({
            fields: Object.keys(SearchForms.fields),
            get: () => '',
            act: () => null
        });
    }

    componentDidMount() {

        this.props.fetchForms();
    }

    getOrderedForms = Memoize(
        (forms) => OrderBy(forms, ['name'], ['desc', 'asc'])
    );

    handleRequestSort = (event, order) => {

        const { orderBy, orderDirection, compareFn } = order;

        this.setState({
            orderBy,
            orderDirection,
            compareFn
        });
    }

    filterForms = (forms) => {

        let filteredForms = forms;
        const filterText = this.fieldValue('filterText').toLowerCase();

        if (filterText) {
            filteredForms = forms.filter(({ name }) => {

                return name.toLowerCase().includes(filterText);
            });
        }

        return filteredForms;
    }

    sortForms = (filteredForms) => {

        const { compareFn } = this.state;

        if (!compareFn) {
            return filteredForms;
        }

        return compareFn([...filteredForms]);
    }

    handleChangePage = (ev, newPage) => {

        this.setState({
            page: newPage,
            dialogName: null
        });
    }

    handleShowModal = (form, dialog = 'archive') => {

        this.setState({
            dialogName: dialog,
            stagedForm: form
        });
    }

    closeDialog = () => {

        this.setState({ dialogName: null, stagedForm: null });
    };

    handleChangeRowsPerPage = (ev) => {

        this.setState({
            page: 0,
            rowsPerPage: ev.target.value
        });
    }

    render() {

        const { PageContainer, FieldsWrapper } = internals;
        const { rowsPerPage, page } = this.state;

        const forms = this.getOrderedForms(this.props.forms);

        const columns = [
            {
                id: 'name',
                header: 'Name',
                label: true,
                sortable: true,
                format: (name) => <Nullable value={name} />
            },
            {
                id: 'edit',
                header: 'Edit',
                label: true,
                format: (_, { id }) => {

                    return (
                        <IconButton
                            to={`/forms/${id}/edit`}
                            component={NavLink}
                            style={{ margin: -12 }}
                        >
                            <EditIcon />
                        </IconButton>);
                }
            },
            {
                id: 'delete',
                header: 'Delete',
                label: true,
                format: (_, client) => {

                    return (
                        <IconButton
                            onClick={() => this.handleShowModal(client, 'delete')}
                            style={{ margin: '-12px auto' }}
                        >
                            <DeleteIcon />
                        </IconButton>);
                }
            }
        ];

        const filteredForms = this.filterForms(forms);
        const sortedForms = this.sortForms(filteredForms) || [];
        const slicedForms = sortedForms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        const { dialogName } = this.state;

        return (
            <PageContainer>
                <Portlet
                    title='Search Forms'
                    toolbar={
                        <Chip
                            color='secondary'
                            label='+ Add New Form'
                            component={NavLink} to='/forms/new'
                            clickable
                        />
                    }
                    body={
                        <>
                            <FieldsWrapper>
                                <TextField
                                    InputProps={{
                                        startAdornment: <InputAdornment position='start'><SearchIcon /></InputAdornment>
                                    }}
                                    label='Search'
                                    value={this.fieldValue('filterText')}
                                    onChange={this.proposeNew('filterText')}
                                    margin='dense'
                                />
                            </FieldsWrapper>
                            <Table.Wrapper>
                                <Table
                                    columns={columns}
                                    idAttribute='id'
                                    orderDirection={this.state.orderDirection}
                                    orderBy={this.state.orderBy}
                                    onRequestSort={this.handleRequestSort}
                                >
                                    <Table.Rows data={slicedForms} />
                                </Table>
                                <TablePagination
                                    count={sortedForms.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </Table.Wrapper>
                        </>
                    }
                />
                <AlertDialog
                    dialogTitle='Delete form?'
                    dialogDescription={`Deleting "${this.state.stagedForm && this.state.stagedForm.name}" will remove this form permanently.`}
                    affirmativeLabel='Delete'
                    isModalOpen={dialogName === 'delete'}
                    confirmAction={() => this.props.deleteForm(this.state.stagedForm.id)}
                    toggleModal={this.closeDialog}
                />
            </PageContainer>
        );
    }
};

internals.StyledForm = Styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.FieldsWrapper = Styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;
