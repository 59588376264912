const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: Select } = require('@material-ui/core/Select');
const { default: MenuItem } = require('@material-ui/core/MenuItem');
const { default: FormControl } = require('@material-ui/core/FormControl');
const { default: FormControlLabel } = require('@material-ui/core/FormControlLabel');
const { default: Checkbox } = require('@material-ui/core/Checkbox');
const StrangeForms = require('strange-forms');
const StateSelect = require('./StateSelect');
const GetDateString = require('../utils/get-date-string');

const internals = {};

module.exports = class EventFilter extends StrangeForms(React.Component) {

    static propTypes = {
        events: T.arrayOf(T.shape({
            id: T.string,
            name: T.string,
            isPublished: T.bool,
            address1: T.string,
            address2: T.string,
            state: T.string,
            zip: T.string,
            appointments: T.arrayOf(T.shape({
                id: T.string,
                patientId: T.string,
                time: T.string
            }))
        })),
        value: T.number,
        onChange: T.func.isRequired
    };

    static fields = [
        'city',
        'state',
        'date',
        'showPastEvents'
    ];

    constructor(props) {

        super(props);

        this.state = {
            isSubmitting: false
        };

        // If there's an event ID, backfill the appropriate values to the inputs
        // When a field is changed, clear out any of the following fields
        // When the appointment field is filled, assign the ID to the component
        this.strangeForm({
            fields: EventFilter.fields,
            get: {
                showPastEvents: (someProps) => false,
                '*': (someProps, field) => ''
            },
            act: {
                state: (field, value) => {

                    this.props.onChange({
                        ...this.props.fields,
                        city: '',
                        date: '',
                        [field]: value
                    });
                },
                city: (field, value) => {

                    this.props.onChange({
                        ...this.props.fields,
                        date: '',
                        [field]: value
                    });
                },
                '*': (field, value) => {

                    this.props.onChange({
                        ...this.props.fields,
                        [field]: value
                    });
                }
            },
            getFormValue: {
                'showPastEvents': (ev) => ev.target.checked || false,
                '*': (ev) => ev.target.value || ''
            }
        });
    }

    render() {

        const { StyledForm } = internals;
        // Prevent an issue where onChange is spread into the element by destructuring onChange here
        // eslint-disable-next-line no-unused-vars
        const { events, onChange, ...otherProps } = this.props;
        const availableStates = [...new Set(events.map((event) => event.state).sort())];
        const availableCities = this.fieldValue('state') ?
            [...new Set(
                events.filter(({ state }) => state === this.fieldValue('state')).map(({ city }) => city)
            )] :
            [];
        const availableEvents = this.fieldValue('city') ?
            events.filter(({ city }) => city === this.fieldValue('city')) :
            [];
        const availableDates = [...new Set(availableEvents.map(({ date }) => date))]
            .map((value) => new Date(value))
            .sort();

        return (
            <StyledForm {...otherProps}>
                <StateSelect
                    value={this.fieldValue('state')}
                    onChange={this.proposeNew('state')}
                    stateSubset={availableStates}
                />
                <FormControl margin='dense'>
                    <Select
                        displayEmpty
                        name='city'
                        value={this.fieldValue('city')}
                        onChange={this.proposeNew('city')}
                    >
                        <MenuItem value=''><em>City</em></MenuItem>
                        {availableCities.map((city) => <MenuItem key={city} value={city}>{city}</MenuItem>)}
                    </Select>
                </FormControl>
                <FormControl margin='dense'>
                    <Select
                        displayEmpty
                        name='date'
                        value={this.fieldValue('date')}
                        onChange={this.proposeNew('date')}
                    >
                        <MenuItem value=''><em>Date</em></MenuItem>
                        {!!availableDates.length && availableDates.map((date) => <MenuItem key={GetDateString(date)} value={GetDateString(date)}>{GetDateString(date)}</MenuItem>)}
                    </Select>
                </FormControl>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.fieldValue('showPastEvents')}
                            onChange={this.proposeNew('showPastEvents')}
                        />
                    }
                    label='Show Past Events'
                />
            </StyledForm>
        );
    }
};

internals.getFormattedEvent = (event) => {

    return event && event.name && event.date ? [event.name, GetDateString(event.date)].join(' ') : '';
};

internals.StyledForm = Styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    >div {
        flex: 0 150px;
        margin-right: ${({ theme }) => theme.spacing(1)}px;
    }
`;
