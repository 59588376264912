const MiddleEnd = require('strange-middle-end');
const {
    PUSH_TO_QUEUE,
    SHIFT_QUEUE
} = require('./action-types');

const internals = {};

exports.pushToQueue = MiddleEnd.createAction(PUSH_TO_QUEUE, {
    transform: (messageInfo) => {

        return {
            message: messageInfo.message || messageInfo || '',
            key: messageInfo.key || new Date().getTime()
        };
    }
});

exports.shiftQueue = MiddleEnd.createAction(SHIFT_QUEUE);
