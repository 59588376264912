const Connect = require('react-redux').connect;
const CreateEventPage = require('../components/CreateEventPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({}),
    (dispatch, props) => ({

        fetchData: () => null,
        onSubmit: async (eventInfo) => {

            const [err, result] = await M.dispatch.model.editEvent({ programId: props.match.params.id, ...eventInfo });

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Event saved successfully');

                if (result && result.result) {

                    M.dispatch.router.push(`/programs/${props.match.params.id}/events/${result.result}/edit`);
                }
                else {

                    M.dispatch.router.push('search');
                }
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err];
        }
    })
);

module.exports = internals.connect(CreateEventPage);
