const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: StarIcon } = require('@material-ui/icons/Star');

const internals = {};

module.exports = function RatingField({ field, value, onChange, error }) {

    const { RatingGroup, RatingButton, Error } = internals;

    const handleClick = (e, v) => {

        e.preventDefault();

        onChange(v);
    };

    const stars = [];

    for (let i = field.min - 1; i < field.max; ++i) {
        stars.push((
            <RatingButton
                key={i}
                field={field}
                onClick={(e) => handleClick(e, i + 1)}
                className={value > i ? 'is-active' : ''}
            >
                <StarIcon />
            </RatingButton>
        ));
    }

    return (
        <>
            <p style={{ marginTop: 0 }}><strong>{field.name}{field.settings.isRequired && '*'}</strong></p>
            <RatingGroup>
                {stars}
            </RatingGroup>
            <Error>{error}</Error>
        </>
    );
};

module.exports.propTypes = {
    field: T.object.isRequired,
    onChange: T.func.isRequired,
    value: T.number,
    error: T.string
};

module.exports.defaultProps = {
    value: 0
};

internals.RatingGroup = Styled.div`
    &:hover {
        color: ${({ theme }) => theme.palette.ochre.main};
    }
`;

internals.RatingButton = Styled.button`
    width: ${({ field }) => 100 / field.max}%;
    border: none;
    background: none;
    color: inherit;
    opacity: 0.5;
    cursor: pointer;
    outline: none;

    &.is-active,
    &:hover,
    &:focus {
        color: ${({ theme }) => theme.palette.ochre.main};
        opacity: 1;
    }

    &:hover ~ & {
        color: ${({ theme }) => theme.palette.primary.light};
    }

    svg {
        width: 100%;
        height: 100%;
    }
`;

internals.Error = Styled.div`
    color: ${({ theme }) => theme.palette.error.main};
`;
