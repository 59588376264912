const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');

const ParagraphContainer = Styled.div`

    > p:first-child {
        margin-top: 0;
    }

    > p:last-child {
        margin-bottom: 0;
    }
`;

module.exports = function Paragraph({ field }) {

    return <ParagraphContainer dangerouslySetInnerHTML={{ __html: field.value }} />;
};

module.exports.propTypes = {
    field: T.object.isRequired
};
