const Connect = require('react-redux').connect;
const UnlistedPatientScreeningPage = require('../components/UnlistedPatientScreeningPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        currentProgramId: M.selectors.app.getCurrentProgramId(state),
        program: M.selectors.model.fetchProgram(state),
        events: M.selectors.model.fetchEvents(state),
        role: M.selectors.auth.getUserRole(state),
        screeningCategories: M.selectors.model.fetchScreeningCategories(state)
    }),
    (dispatch, props) => ({
        fetchData: async () => {

            await Promise.all([
                M.dispatch.model.fetchProgram(props.match.params.id),
                M.dispatch.model.fetchEvents(props.match.params.id),
                M.dispatch.model.fetchScreeningCategories()
            ]);
        },
        onSubmit: async (screeningInfo) => {

            const [err, result] = await M.dispatch.model.createUnlinkedScreening(screeningInfo);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Screening created successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err, result];
        }
    })
);

module.exports = internals.connect(UnlistedPatientScreeningPage);
