const React = require('react');
const { useState } = require('react');
const T = require('prop-types');
const { default: Button } = require('@material-ui/core/Button');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: ArrowRight } = require('@material-ui/icons/ArrowRight');
const { default: ArrowDown } = require('@material-ui/icons/ArrowDropDown');
const { Settings, Choice, DeleteButton } = require('./styles');

module.exports = function Choices({ field, onChange, ...rest }) {

    const [showChoices, setShowChoices] = useState(true);

    const updateChoice = (event, index) => {

        const stagedChoices = [...field.choices];

        stagedChoices[index] = event.target.value;

        onChange(field, { choices: stagedChoices });
    };

    const addChoice = () => {

        onChange(field, { choices: [...field.choices, 'New Choice'] });
    };

    const deleteChoice = (index) => {

        const stagedChoices = [...field.choices];

        stagedChoices.splice(index, 1);

        onChange(field, { choices: stagedChoices });
    };

    return (
        <Settings {...rest}>
            <Button
                onClick={() => setShowChoices(!showChoices)}
            >
                {showChoices ? <ArrowDown /> : <ArrowRight />} Choices
            </Button>
            {showChoices && (
                <div>
                    {field.choices.map((choice, i) => (

                        <Choice key={i}>
                            <TextField
                                value={choice}
                                onChange={(e) => updateChoice(e, i)}
                            />
                            <DeleteButton onClick={() => deleteChoice(i)}>Delete</DeleteButton>
                        </Choice>
                    ))}
                    <Button
                        onClick={addChoice}
                    >
                        + Add New Choice
                    </Button>
                </div>
            )}
        </Settings>
    );
};

module.exports.propTypes = {
    field: T.object.isRequired,
    onChange: T.func.isRequired
};
