const Connect = require('react-redux').connect;
const EditProgramPage = require('../components/EditProgramPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        clients: M.selectors.model.fetchClients(state),
        users: M.selectors.model.fetchUsers(state),
        program: M.selectors.model.fetchProgram(state),
        forms: M.selectors.model.fetchForms(state)
    }),
    (dispatch, props) => ({
        fetchData: async () => {

            await Promise.all([
                M.dispatch.model.fetchUsers(),
                M.dispatch.model.fetchClients(),
                M.dispatch.model.fetchProgram(props.match.params.id),
                M.dispatch.model.fetchForms()
            ]);
        },
        onSubmit: async (programInfo) => {

            const [err] = await M.dispatch.model.editProgram(programInfo);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Program updated successfully');
                M.dispatch.router.push(`/programs/search`);
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }
        },
        onConfirmDelete: async () => {

            const [err] = await M.dispatch.model.deleteProgram(props.match.params.id);
            if (!err) {
                M.dispatch.snackbar.pushToQueue('Program deleted successfully');
                M.dispatch.router.push(`/`);
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }
        }
    })
);

module.exports = internals.connect(EditProgramPage);
