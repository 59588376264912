const React = require('react');
const T = require('prop-types');
const { NavLink } = require('react-router-dom');
const StrangeForms = require('strange-forms');

const { default: Divider } = require('@material-ui/core/Divider');
const { default: Styled } = require('styled-components');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: Card } = require('@material-ui/core/Card');
const { default: CardContent } = require('@material-ui/core/CardContent');
const { default: CardActions } = require('@material-ui/core/CardActions');
const { default: Button } = require('@material-ui/core/Button');
const { default: Chip } = require('@material-ui/core/Chip');
const { default: DeleteOutline } = require('@material-ui/icons/DeleteOutline');

const Portlet = require('../../../components/Portlet');
const SectionLabel = require('../../../components/SectionLabel');
const EventFilter = require('../../../components/EventFilter');
const AlertDialog = require('../../../components/AlertDialog');

const GetDateString = require('../../../utils/get-date-string');
const GetFormattedTime = require('../../../utils/get-formatted-time');
const SortEvents = require('../../../utils/sort-events');

const internals = {};

module.exports = class SearchEventsPage extends StrangeForms(React.Component) {

    static propTypes = {
        fetchProgram: T.func,
        events: T.array
    };

    constructor(props) {

        super(props);

        this.state = {
            dialogName: null,
            stagedEvent: null
        };

        this.strangeForm({
            fields: ['eventFilterCriteria'],
            get: () => ({}),
            act: () => null,
            getFormValue: (val) => val
        });
    }

    componentDidMount() {

        this.props.fetchData();
    }

    filterEvents = (events) => {

        const { city, state, date, showPastEvents } = this.fieldValue('eventFilterCriteria');
        let filteredArray = events;
        if (state) {
            filteredArray = filteredArray.filter(({ state: eventState }) => eventState === state);
        }

        if (city) {
            filteredArray = filteredArray.filter(({ city: eventCity }) => eventCity === city);
        }

        if (date) {
            filteredArray = filteredArray.filter(({ date: eventDate }) => GetDateString(new Date(eventDate)) === date);
        }

        if (!showPastEvents) {
            const yesterday = new Date();

            yesterday.setDate(yesterday.getDate() - 1);

            filteredArray = filteredArray.filter(({ date: eventDate }) => new Date(eventDate) > yesterday);
        }

        return filteredArray;
    }

    handleShowModal = (event) => {

        this.setState({
            dialogName: 'delete',
            stagedEvent: event
        });
    }

    closeDialog = () => {

        this.setState({ dialogName: null });
    };

    handleDeleteEvent = async () => {

        await this.props.deleteEvent(this.state.stagedEvent.id, this.props.match.params.id);
    };

    render() {

        const { PageContainer, StyledDivider, EventCard, EventList, StyledChip, StyledCardContent, StyledCardActions, DeleteButton, BoldTypography } = internals;
        const { program, events } = this.props;
        const { name = '', id = '' } = program || {};
        const filteredEvents = this.filterEvents(events).sort(SortEvents);

        return (
            <PageContainer>
                <Portlet
                    title={`Search Events | Program: ${name}`}
                    toolbar={
                        this.props.role !== 'manager' &&
                        <Chip
                            color='secondary'
                            label='Add New Event +'
                            component={NavLink} to={`/programs/${id}/events/new`}
                            clickable
                        />
                    }
                    body={
                        <>
                            <SectionLabel>Search for Program Events</SectionLabel>
                            <EventFilter
                                events={events}
                                fields={this.fieldValue('eventFilterCriteria')}
                                onChange={this.proposeNew('eventFilterCriteria')}
                            />
                            <StyledDivider />
                            <EventList>
                                {
                                    filteredEvents &&
                                    filteredEvents.map((event) => {

                                        return (
                                            <EventCard key={event.id}>
                                                <StyledCardContent>
                                                    <Typography variant='h5' gutterBottom>{event.name}</Typography>
                                                    <BoldTypography>{GetDateString(event.date)}</BoldTypography>
                                                    {event.startTime && event.endTime && <BoldTypography>{`${GetFormattedTime(event.startTime)} to ${GetFormattedTime(event.endTime)}`}</BoldTypography>}
                                                    {event.startTime && !event.endTime && <BoldTypography>{`Starts at ${GetFormattedTime(event.startTime)}`}</BoldTypography>}
                                                    {!event.startTime && event.endTime && <BoldTypography>{`Ends at ${GetFormattedTime(event.endTime)}`}</BoldTypography>}
                                                    <Typography variant='body2'>{event.address1}</Typography>
                                                    {event.address2 && <Typography variant='body2'>{event.address2}</Typography>}
                                                    <Typography variant='body2' gutterBottom>{`${event.city}, ${event.state} ${event.zip}`}</Typography>
                                                    <StyledChip
                                                        color={event.isPublished ? 'secondary' : 'default'}
                                                        label={event.isPublished ? 'Published' : 'Unpublished'}
                                                        size='small'
                                                    />
                                                </StyledCardContent>
                                                <StyledCardActions>
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                        component={NavLink}
                                                        exact to={`/${this.props.role === 'screener' ? this.props.role + '/' : ''}programs/${id}/events/${event.id}/edit`}
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        component={NavLink}
                                                        to={`/manager/event/${event.id}`}
                                                        variant='contained'
                                                    >
                                                        Summary
                                                    </Button>
                                                    <Button
                                                        component={NavLink}
                                                        to={`/covid-label/all-for-event/${event.id}`}
                                                        target='_blank'
                                                        variant='contained'
                                                    >
                                                        Print Labels
                                                    </Button>
                                                    <DeleteButton
                                                        onClick={() => this.handleShowModal(event)}
                                                    >
                                                        <DeleteOutline />
                                                    </DeleteButton>
                                                </StyledCardActions>
                                            </EventCard>
                                        );
                                    })
                                }
                                {
                                    events && !events.length &&
                                    <SectionLabel>No events found</SectionLabel>
                                }
                            </EventList>
                        </>
                    }
                />
                <AlertDialog
                    dialogTitle='Delete Event?'
                    dialogDescription={`Deleting "${this.state.stagedEvent && this.state.stagedEvent.name}" will also delete the event's appointments.`}
                    affirmativeLabel='Delete'
                    isModalOpen={this.state.dialogName === 'delete'}
                    confirmAction={() => this.handleDeleteEvent(this.state.stagedEvent.id)}
                    toggleModal={this.closeDialog}
                />
            </PageContainer>
        );
    }
};

internals.BoldTypography = Styled(Typography).attrs({ variant: 'body2' })`
    font-weight: bold;
`;

internals.StyledCardContent = Styled(CardContent)`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

internals.StyledCardActions = Styled(CardActions)`
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
`;

internals.StyledChip = Styled(Chip)`
    margin-top: auto;
    align-self: flex-start;
`;

internals.EventCard = Styled(Card)`
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    flex-basis: calc(50% - ${({ theme }) => theme.spacing(2)}px);
    flex-grow: 1;
    min-width: 400px;
    margin: 0 ${({ theme }) => theme.spacing(1)}px ${({ theme }) => theme.spacing(2)}px;

    &:last-child {
        flex-grow: unset;
    }
`;

internals.EventList = Styled.div`
    display: flex;
    flex-wrap: wrap;
`;

internals.StyledDivider = Styled(Divider)`
    margin: ${({ theme }) => theme.spacing(2)}px 0;
`;

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.FieldsWrapper = Styled.form`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;

    // Select all but the last two (final text field and submit button)
    > :not(:nth-last-child(-n + 2)) {
        margin-right: ${({ theme }) => theme.spacing(1)}px;
    }
`;

internals.DeleteButton = Styled(Button)`
    position: relative;
    top: 5px; // vertically center with chip
    min-width: 0;
    color: ${({ theme }) => theme.palette.error.main};
`;
