const Connect = require('react-redux').connect;
const SearchUsersPage = require('../components/SearchUsersPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        users: M.selectors.model.fetchUsers(state)
    }),
    (dispatch, props) => ({
        fetchUsers: () => M.dispatch.model.fetchUsers(),
        onSubmit: async (userInfo) => {

            const [err] = await M.dispatch.model.editUser(userInfo);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('User updated successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err];
        }
    })
);

module.exports = internals.connect(SearchUsersPage);
