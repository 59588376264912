const Connect = require('react-redux').connect;
const PatientScheduleSuccessPage = require('../components/PatientScheduleSuccessPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        program: M.selectors.model.fetchProgram(state),
        appointment: M.selectors.model.fetchAppointment(state)

    }),
    (dispatch, props) => ({
        fetchData: async () => {

            await Promise.all([
                M.dispatch.model.fetchProgram(props.match.params.id),
                M.dispatch.model.fetchAppointment(props.match.params.appointmentId)
            ]);
        }
    })
);

module.exports = internals.connect(PatientScheduleSuccessPage);
