const React = require('react');
const T = require('prop-types');
const { NavLink } = require('react-router-dom');
const { default: Styled } = require('styled-components');
const { default: Format } = require('date-fns/format');
const GetDateString = require('../../../utils/get-date-string');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: Card } = require('@material-ui/core/Card');
const { default: CardContent } = require('@material-ui/core/CardContent');
const { default: Button } = require('@material-ui/core/Button');
const { default: Select } = require('@material-ui/core/Select');
const { default: FormControl } = require('@material-ui/core/FormControl');
const { default: MenuItem } = require('@material-ui/core/MenuItem');
const StrangeForms = require('strange-forms');
const Portlet = require('../../../components/Portlet');
const ScreeningFields = require('../../../components/ScreeningFields');
const AppointmentAdmin = require('../../../components/AppointmentAdmin');
const ScheduleModal = require('../../../components/ScheduleModal');
const RescheduleModal = require('../../../components/RescheduleModal');
const AlertDialog = require('../../../components/AlertDialog');
const CovidForm = require('../../../components/CovidForm');
const PersonalDetailFields = require('../../../components/PersonalDetailFields');
const GetCurrentAppointment = require('../../../utils/get-current-appointment');

const internals = {};

module.exports = class PatientScreeningPage extends StrangeForms(React.Component) {

    static propTypes = {
        searchResults: T.array,
        role: T.string,
        fetchData: T.func,
        patient: T.shape({
            firstName: T.string,
            lastName: T.string,
            address1: T.string,
            address2: T.string,
            city: T.string,
            st: T.string,
            zip: T.string,
            id: T.string,
            screenings: T.arrayOf(T.shape({
                id: T.string,
                createdAt: T.instanceOf(Date)
            }))
        }),
        program: T.shape({
            name: T.string,
            client: T.shape({
                name: T.string,
                logoUrl: T.string
            }),
            screeningMetrics: T.arrayOf(T.shape({
                id: T.string,
                name: T.string,
                formula: T.string,
                fields: T.arrayOf(T.shape({
                    name: T.string,
                    type: T.string,
                    units: T.string
                }))
            }))
        }),
        pushToSnackbar: T.func.isRequired
    };

    constructor(props) {

        super(props);

        this.state = {
            hasSearched: false,
            isSubmitting: false,
            isWrongProgramOverride: false
        };

        this.strangeForm({
            fields: [
                'screeningFieldsAndNotes',
                'selectedAppointmentId',
                'updatedPersonalDetails'
            ],
            get: {
                // The ScreeningFields component will handle resetting the form
                // with useEffect when it detects a change in the passed 'appointmentId'
                // screeningFieldsAndNotes:
                selectedAppointmentId: (someProps) => {

                    const { patient = {} } = someProps || {};
                    const { appointments = [] } = patient || {};
                    const recentAppointmentId = !!appointments.length ? GetCurrentAppointment(appointments)?.id : null;
                    return recentAppointmentId;
                },
                updatedPersonalDetails: () => null,
                '*': () => ''
            },
            act: {
                '*': () => null
            },
            getFormValue: {
                screeningFieldsAndNotes: (val) => val,
                updatedPersonalDetails: (val) => val,
                '*': (evt) => evt?.target?.value
            }
        });

        // Ensuring that this func is locked in and doesn't change across renders
        this.handleScreeningFieldsOnChange = this.proposeNew('screeningFieldsAndNotes');
    }

    async componentDidMount() {

        await this.props.fetchData();
        const { patient = {} } = this.props || {};
        const { appointments = [] } = patient || {};
        const mostRecentAppointment = internals.getMostRecentAppointment(appointments);
        const programId = mostRecentAppointment?.event?.programId || this.props.currentProgramId;
        this.props.fetchProgram(programId);
        this.props.fetchEvents(programId);

        const firstScreening = patient?.screenings?.length && patient.screenings[patient.screenings.length - 1];

        if (firstScreening.id) {
            await this.props.fetchScreening(firstScreening.id);
        }
    }

    handleSubmit = async () => {

        const { patient } = this.props;
        const { serializeScreeningValuesFromIds } = internals;
        const appointment = patient.appointments.find(({ id }) => id === this.fieldValue('selectedAppointmentId'));

        const screeningId = appointment?.screening?.id;

        if (!screeningId) {
            return [new Error('No screening ID present')];
        }

        const { notes, ...resultsById } = this.fieldValue('screeningFieldsAndNotes');

        const [err] = await this.props.onSubmit({
            id: screeningId,
            patientId: patient.id,
            programId: this.props.program.id,
            appointmentId: appointment.id,
            results: serializeScreeningValuesFromIds(resultsById || {}),
            notes
        });

        return [err];
    }

    handleConfirm = async () => {

        // Save the values in the form again before locking the record
        // (in case the user has unsaved changes)
        const [saveErr] = await this.handleSubmit();
        if (saveErr) {
            // Error messaging is handled in containers/PatientScreeningPage
            return;
        }

        const { appointments } = this.props.patient;

        const id = appointments
            .find(({ id: _id }) => _id === this.fieldValue('selectedAppointmentId'))
            ?.screening?.id;

        const [err] = await this.props.onConfirm({
            id,
            confirmedAt: new Date()
        });

        if (!err && !this.props.program.isCovidTesting) {
            this.props.history.push(`/survey/${id}`);
        }
    }

    handleClickSchedule = () => {

        this.setState({
            dialogName: 'schedule'
        });
    }

    onClickReschedule = () => {

        this.setState({
            dialogName: 'reschedule'
        });
    }

    onClickSaveAppointment = (appointmentInfo) => {

        this.props.updateAppointment(appointmentInfo);
    }

    handleRelease = async () => {

        const { patient = {} } = this.props || {};
        const { appointments = [] } = patient || {};
        const id = appointments.length ? GetCurrentAppointment(appointments).screening.id : null;
        await this.props.onSubmit({
            id,
            patientId: this.props.patient.id,
            confirmedAt: null
        });
    }

    handleShowModal = (client) => {

        this.setState({
            dialogName: 'release'
        });
    }

    closeDialog = () => {

        this.setState({ dialogName: null });
    };

    handleSchedule = async (scheduleInfo) => {

        const {
            eventId,
            appointmentId,
            email,
            phone,
            sendEmail
        } = scheduleInfo;

        const { patient } = this.props;
        const { id: patientId = '' } = patient || {};

        const [err] = await this.props.submitAppointment({
            eventId,
            appointmentId,
            patientId,
            phone: phone || '',
            email,
            sendEmail
        });

        if (!err) {
            this.closeDialog();
        }
    }

    handleReschedule = async (rescheduleInfo) => {

        const {
            currentAppointment,
            eventId,
            appointmentId,
            email,
            phone,
            sendEmail
        } = rescheduleInfo;

        const { patient } = this.props;

        const [err] = await this.props.rescheduleAppointment({
            patientId: patient.id,
            originalAppointmentId: currentAppointment.id,
            newEventId: eventId,
            newAppointmentId: appointmentId,
            email,
            phone: phone || '',
            sendEmail
        });

        if (!err) {
            this.closeDialog();
        }
    }

    onCancelAppointment = async ({ sendEmail }) => {

        const selectedAppointmentId = this.fieldValue('selectedAppointmentId');

        if (selectedAppointmentId) {

            await this.props.cancelAppointment({
                id: selectedAppointmentId,
                sendEmail
            });

            await this.props.fetchData();
        }
    }

    handlePatientInfoUpdate = async () => {

        const {
            patient,
            program,
            pushToSnackbar
        } = this.props;

        this.setState({ patientInfoError: null });

        // Set by PersonalDetailFields, initializes to null
        const updatedPersonalDetails = this.fieldValue('updatedPersonalDetails');

        if (!updatedPersonalDetails) {
            // Lie to the user, since no changes have actually been made
            // We just want to send a consistent message when the user clicks the save button
            return pushToSnackbar('Patient updated successfully');
        }

        const formattedPatient = PersonalDetailFields.fields
            .filter((field) => field !== 'noSsn')
            .reduce((collector, field) => {

                // Avoid accidental ssn changes
                if (field === 'ssn') {
                    return collector;
                }

                if (program.isCovidTesting) {
                    if (field === 'identifier') {
                        return collector;
                    }
                }

                return {
                    ...collector,
                    [field]: updatedPersonalDetails[field]
                };
            }, { id: patient.id });

        const [err] = await this.props.updatePatient(formattedPatient);

        if (err) {
            this.setState({ patientInfoError: err.message });
        }
    }

    continueWrongProgram = () => {

        this.setState({ isWrongProgramOverride: true });
    }

    cancelWrongProgram = () => {

        this.props.history.push(`/programs/${this.props.currentProgramId}/screening/search`);
    }

    render() {

        const {
            PageContainer,
            PatientCard,
            AlertHeader,
            getInitScreeningFieldsValues
        } = internals;

        const {
            dialogName,
            patientInfoError,
            isWrongProgramOverride
        } = this.state;

        const {
            patient,
            currentProgramId,
            role,
            program,
            events = [],
            screeningCategories
        } = this.props;

        if (!program) {
            return null;
        }

        const {
            appointments = [],
            firstName = '',
            lastName = ''
        } = (patient || {});

        const mostRecentAppointment = internals.getMostRecentAppointment(appointments);

        let isWrongProgram = false;
        let isWrongDate = false;

        if (!isWrongProgramOverride && mostRecentAppointment?.event?.date) {
            const mostRecentAppointmentProgramId = mostRecentAppointment?.event?.programId;
            isWrongDate = mostRecentAppointment?.event?.date !== Format(new Date(), 'MM/dd/yyyy');
            isWrongProgram = mostRecentAppointmentProgramId !== currentProgramId || isWrongDate;
        }

        const { name = '', client = {}, screeningMetrics = [] } = program;
        const selectedAppointmentId = this.fieldValue('selectedAppointmentId');
        const selectedAppointment = appointments.find(({ id }) => id === selectedAppointmentId);
        const { logoUrl, name: clientName } = client;
        // const todaysEvents = events.filter(({ date }) => internals.isToday(new Date(date)));
        const allEventsSorted = events.sort((a, b) => new Date(a.date) - new Date(b.date));
        const screeningId = selectedAppointment && selectedAppointment.screening && selectedAppointment.screening.id;

        const compareTimes = (a, b) => Number(b.time.replace(':', '')) < Number(a.time.replace(':', ''));

        const sortedAppointments = appointments.slice().sort((a, b) => {

            if (b.event.date < a.event.date) {
                return -1;
            }
            else if (b.event.date > a.event.date) {
                return 1;
            }
            // Account for walk-in appointments, which have null as 'time'
            else if (a.time && b.time && compareTimes(a, b)) {
                return -1;
            }

            // No other sorting criteria so,
            return 1;
        });

        return (
            <PageContainer>
                <Portlet
                    title={`Program: ${name}`}
                    toolbar={logoUrl && <Portlet.LogoImg src={logoUrl} alt={`${clientName} logo`} />}
                    body={
                        <>
                            <PersonalDetailFields
                                value={this.fieldValue('updatedPersonalDetails') || patient}
                                onChange={this.proposeNew('updatedPersonalDetails')}
                                title='Patient Information'
                                titleVariant='h5'
                                showSsn={false}
                                isCovidForm={program && program.isCovidTesting}
                                error={patientInfoError}
                                onSave={this.handlePatientInfoUpdate}
                            />
                            <PatientCard>
                                <CardContent>
                                    <div>
                                        <Typography variant='h5'>Select Appointment</Typography>
                                        <div className='screeningList'>
                                            {!!appointments.length && (
                                                <>
                                                    <FormControl margin='dense'>
                                                        <Select
                                                            value={this.fieldValue('selectedAppointmentId')}
                                                            onChange={this.proposeNew('selectedAppointmentId')}
                                                            displayEmpty
                                                        >
                                                            <MenuItem value=''><em>Screening Date</em></MenuItem>
                                                            {
                                                                sortedAppointments.map(({ id: appointmentId, screening, event }) => {

                                                                    return (
                                                                        <MenuItem key={appointmentId} value={appointmentId}>
                                                                            {screening && `${event.name} - ${GetDateString(event.date)}`}
                                                                            {!screening && <em>{event.name} - {GetDateString(event.date)}</em>}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                    <Button
                                                        color='secondary'
                                                        variant='contained'
                                                        disabled={!selectedAppointment}
                                                        component={NavLink}
                                                        exact
                                                        to={`/screening/${screeningId}`}
                                                    >
                                                        Generate Report
                                                    </Button>
                                                </>
                                            )}
                                            <Button
                                                variant='contained'
                                                color='secondary'
                                                onClick={this.handleClickSchedule}
                                            >
                                                Schedule Appointment
                                            </Button>
                                        </div>
                                    </div>
                                </CardContent>
                            </PatientCard>

                            {mostRecentAppointment && selectedAppointment && (
                                <PatientCard>
                                    <CardContent>
                                        <div>
                                            <Typography variant='h5' gutterBottom>Appointment Details</Typography>
                                            <div className='appointmentInformation'>
                                                <AppointmentAdmin
                                                    program={program}
                                                    appointment={selectedAppointment}
                                                    event={selectedAppointment.event}
                                                    selectedAppointmentId={selectedAppointmentId}
                                                    handleClickSave={this.onClickSaveAppointment}
                                                    handleClickReschedule={this.onClickReschedule}
                                                    handleClickCancel={this.onCancelAppointment}
                                                    handleSendConfirmation={this.props.sendAppointmentConfirmation}
                                                    handleSignConsent={this.props.updateAppointment}
                                                />
                                            </div>
                                        </div>
                                    </CardContent>
                                </PatientCard>
                            )}
                            <ScreeningFields
                                disabled={!selectedAppointment}
                                role={role}
                                program={program}
                                screeningCategories={screeningCategories}
                                screeningMetrics={screeningMetrics}
                                // Watching this in a useEffect
                                appointmentId={selectedAppointmentId}
                                // Will set to this value when 'appointmentId' changes
                                initValue={getInitScreeningFieldsValues({
                                    screeningMetrics,
                                    screening: selectedAppointment?.screening
                                })}
                                // Keep track of the edited form in this value
                                value={this.fieldValue('screeningFieldsAndNotes')}
                                onChange={this.handleScreeningFieldsOnChange}
                                onClickSubmit={this.handleSubmit}
                                onClickConfirm={this.handleConfirm}
                                onClickRelease={this.handleShowModal}
                                confirmedAt={selectedAppointment?.screening?.confirmedAt}
                            />
                            {program && program.isCovidTesting &&
                                <CovidForm
                                    patient={patient}
                                    program={program}
                                    screeningId={screeningId}
                                    updatePatient={this.props.updatePatient}
                                    onConfirm={this.handleConfirm}
                                />}
                        </>
                    }
                />
                <ScheduleModal
                    patientName={[firstName, lastName].join(' ')}
                    events={allEventsSorted}
                    affirmativeLabel='Submit'
                    negativeLabel={null}
                    isModalOpen={this.state.dialogName === 'schedule'}
                    // eslint-disable-next-line react/jsx-handler-names
                    confirmAction={this.handleSchedule}
                    toggleModal={this.closeDialog}
                    consentFormUrl={program && program.consentFormUrl}
                    showNotificationToggle
                />
                {selectedAppointment && (
                    <RescheduleModal
                        currentAppointment={selectedAppointment}
                        patientName={[firstName, lastName].join(' ')}
                        events={allEventsSorted}
                        affirmativeLabel='Submit'
                        negativeLabel={null}
                        isModalOpen={this.state.dialogName === 'reschedule'}
                        // eslint-disable-next-line react/jsx-handler-names
                        confirmAction={this.handleReschedule}
                        toggleModal={this.closeDialog}
                        consentFormUrl={program && program.consentFormUrl}
                        showNotificationToggle
                    />
                )}
                <AlertDialog
                    dialogTitle='Release client?'
                    dialogDescription={`Releasing ${firstName} ${lastName} will allow them to be re-screened.`}
                    affirmativeLabel='Release'
                    isModalOpen={dialogName === 'release'}
                    // eslint-disable-next-line react/jsx-handler-names
                    confirmAction={this.handleRelease}
                    toggleModal={this.closeDialog}
                />
                {mostRecentAppointment && (
                    <AlertDialog
                        dialogTitle={<AlertHeader>STOP</AlertHeader>}
                        dialogDescription={(
                            <div>
                                <Typography gutterBottom>This patient is scheduled for a different event:</Typography>
                                <Typography gutterBottom><strong>{program.name} - {mostRecentAppointment.event.name} - {mostRecentAppointment.event.date} - {mostRecentAppointment.time}</strong></Typography>
                                <Typography>Are you sure you want to continue?</Typography>
                            </div>
                        )}
                        affirmativeLabel='Continue'
                        negativeLabel='Go Back'
                        isModalOpen={isWrongProgram}
                        confirmAction={this.continueWrongProgram}
                        cancelAction={this.cancelWrongProgram}
                        toggleModal={() => this.setState({ isWrongProgram: false })}
                    />
                )}
            </PageContainer>
        );
    }
};

internals.AlertHeader = Styled.span`
    font-weight: bold;
    color: ${({ theme }) => theme.palette.error.main};
`;

internals.PatientCard = Styled(Card)`
    max-width: 900px;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;

    > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .appointmentInformation {
            align-self: flex-start
        }

        .changeEvent {
            display: flex;
            flex-direction: column;
        }
    }
`;

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.getMostRecentAppointment = (appointments) => {

    // First get the event id of the most-recent appointment
    const sortedEvents = !!appointments.length ?
        appointments
            .map(({ event }) => event)
            .sort((a, b) => new Date(b.date) - new Date(a.date)) :
        [];

    // Then find() the appointment with the same eventId
    return appointments.find(({ eventId }) => eventId === sortedEvents[0].id) || null;
};

internals.isToday = (someDate) => {

    const today = new Date();
    return someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear();
};

internals.getInitScreeningFieldsValues = ({ screeningMetrics, screening }) => {

    const vals = {};

    if (!screeningMetrics || !screening) {
        return vals;
    }

    const screeningResults = screening.results;
    vals.notes = screening.notes;

    screeningMetrics.forEach(({ id }) => {

        const result = !!screeningResults?.length &&
            screeningResults.find(({ screeningMetricId }) => screeningMetricId === id);

        vals[id] = result;
    });

    return vals;
};

internals.serializeScreeningValuesFromIds = (valuesById) => {

    return Object.keys(valuesById).reduce((collector, id) => ([
        ...collector,
        {
            screeningMetricId: valuesById[id].screeningMetricId,
            values: valuesById[id].values
        }
    ]), []);
};
