const React = require('react');
const T = require('prop-types');
const { default: Card } = require('@material-ui/core/Card');
const { default: CardContent } = require('@material-ui/core/CardContent');
const AppointmentInfo = require('./AppointmentInfo');

module.exports = (props) => {

    const { event, appointment, ...otherProps } = props;

    return (
        <Card {...otherProps}>
            <CardContent>
                <AppointmentInfo
                    event={event}
                    appointment={appointment}
                    align='center'
                />
            </CardContent>
        </Card>
    );
};

module.exports.propTypes = {
    event: T.shape({
        date: T.instanceOf(Date),
        address1: T.string,
        address2: T.string,
        city: T.string,
        name: T.string,
        location: T.string
    }).isRequired,
    appointment: T.shape({
        time: T.string
    }).isRequired
};

module.exports.displayName = 'DateMaskInput';
