const React = require('react');
const T = require('prop-types');
const { NavLink } = require('react-router-dom');
const { default: Styled } = require('styled-components');
const { ChromePicker: ColorPicker } = require('react-color');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: Button } = require('@material-ui/core/Button');
const { default: FormHelperText } = require('@material-ui/core/FormHelperText');
const StrangeForms = require('strange-forms');
const Portlet = require('../../../components/Portlet');
const SectionLabel = require('../../../components/SectionLabel');

const internals = {};

module.exports = class CreateClientPage extends StrangeForms(React.Component) {

    static propTypes = {
        onSubmit: T.func.isRequired,
        removeLogo: T.func,
        uploadLogo: T.func.isRequired
    };

    static fields = {
        clientName: '',
        clientContact: '',
        brandColor: ''
    };

    constructor(props) {

        super(props);

        this.state = {
            clientName: '',
            clientContact: '',
            clientLogoUrl: '',
            isSubmitting: false,
            id: null,
            logo: null
        };

        this.strangeForm({
            fields: Object.keys(CreateClientPage.fields),
            get: () => '',
            act: () => null,
            getFormValue: {
                '*': (ev) => ev.target.value || '',
                brandColor: (color) => color.hex
            }
        });
    }

    shouldDisableSubmit() {

        const { logo } = this.state;
        return !logo || !this.fieldValue('clientName') || this.state.isSubmitting;
    }

    handleLogoChange = async (ev) => {

        // We only allow uploading 1 file, so we pick the first from the list
        const file = ev.target.files.item(0);
        if (!file) {
            return;
        }

        const reader = new window.FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {

            this.setState({ logo: reader.result });
        };

        const [err, result] = await this.props.uploadLogo(file);

        if (err) {
            return;
        }

        this.setState({ clientLogoUrl: result });
    }

    handleLogoRemove = (ev) => {

        this.setState({ logo: null });
    }

    handleSubmit = async (ev) => {

        ev.preventDefault();

        const accountInfo = this.formatFields();

        this.setState({ isSubmitting: true });

        await this.props.onSubmit(accountInfo);

        this.setState({ isSubmitting: false });
    }

    formatFields = () => {

        return {
            name: this.fieldValue('clientName'),
            contact: this.fieldValue('clientContact'),
            logoUrl: this.state.clientLogoUrl,
            brandColor: this.fieldValue('brandColor')
        };
    };

    render() {

        const { PageContainer, StyledForm, StyledButton, LogoContainer, ForwardedLink, StyledFormHelperText } = internals;
        const { logo } = this.state;

        return (
            <PageContainer>
                <Portlet
                    title='Add New Client'
                    body={
                        <StyledForm>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <SectionLabel>Client Details</SectionLabel>
                                <TextField
                                    required
                                    label='Client Name'
                                    InputLabelProps={{ shrink: true }}
                                    placeholder='This will be patient-facing'
                                    helperText='Name as you would like it to appear for patients'
                                    value={this.fieldValue('clientName')}
                                    onChange={this.proposeNew('clientName')}
                                />
                                <TextField
                                    label='Client Contact'
                                    InputLabelProps={{ shrink: true }}
                                    placeholder='Day-to-day contact person'
                                    helperText='This is for reference only and is not required'
                                    value={this.fieldValue('clientContact')}
                                    onChange={this.proposeNew('clientContact')}
                                />
                                <SectionLabel>Brand Color</SectionLabel>
                                <StyledFormHelperText>Used as the header background in the patient portal and as a menu accent for other users</StyledFormHelperText>
                                <ColorPicker
                                    color={this.fieldValue('brandColor')}
                                    onChangeComplete={this.proposeNew('brandColor')}
                                />
                                <div className='logo'>
                                    <SectionLabel>Logo</SectionLabel>
                                    {!!logo &&
                                    <LogoContainer>
                                        <img
                                            alt='Logo preview'
                                            src={logo}
                                        />
                                    </LogoContainer>}
                                    <input
                                        accept='image/*'
                                        id='upload-logo'
                                        type='file'
                                        onChange={this.handleLogoChange}
                                        style={{ display: 'none' }}
                                    />
                                    <label htmlFor='upload-logo'>
                                        <Button component='span' color='secondary'>
                                            Choose Image
                                        </Button>
                                    </label>
                                    <Button onClick={this.handleLogoRemove} disabled={!logo} color='primary'>Remove logo</Button>
                                </div>

                            </div>
                        </StyledForm>
                    }
                    foot={
                        <>
                            <StyledButton
                                onClick={this.handleSubmit}
                                variant='contained'
                                color='primary'
                                disabled={this.shouldDisableSubmit()}
                            >
                                Save
                            </StyledButton>
                            <StyledButton
                                component={ForwardedLink}
                                to='/'
                                variant='text'
                            >
                                Cancel
                            </StyledButton>
                        </>
                    }
                />
            </PageContainer>
        );
    }
};

internals.StyledFormHelperText = Styled(FormHelperText)`
    && {
        margin: -${({ theme }) => theme.spacing(1)}px ${({ theme }) => theme.spacing(2)}px ${({ theme }) => theme.spacing(2)}px ${({ theme }) => theme.spacing(2)}px;
    }
`;

internals.StyledForm = Styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.StyledButton = Styled(Button)`
    margin: 0 8px;
`;

internals.LogoContainer = Styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(45deg, #cecece52 25%, transparent 25%), linear-gradient(-45deg, #cecece52 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #cecece52 75%), linear-gradient(-45deg, transparent 75%, #cecece52 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
    padding: 15px 0;
    img {
        max-height: 50px;
        max-width: 100%;
    }
`;

internals.ForwardedLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);
