const Qs = require('qs');
const Constants = require('./constants');

module.exports = ({ location, history, role }) => {

    const queryParam = Qs.parse(location.search.replace(/^\?/, '')).redirect;
    const redirectTo = queryParam && queryParam !== '/' ? queryParam : null;

    history.push(redirectTo || Constants.LANDING_PAGES[role]);
};
