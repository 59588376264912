const React = require('react');
const T = require('prop-types');
const { default: FormControlLabel } = require('@material-ui/core/FormControlLabel');
const { default: FormGroup } = require('@material-ui/core/FormGroup');
const { default: Checkbox } = require('@material-ui/core/Checkbox');

module.exports = function BooleanField({ field, value, onChange }) {

    return (
        <FormGroup>
            <FormControlLabel
                control={<Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} name={field.name} />}
                label={field.name}
            />
        </FormGroup>
    );
};

module.exports.propTypes = {
    field: T.object.isRequired,
    onChange: T.func.isRequired,
    value: T.bool
};

module.exports.defaultProps = {
    value: false
};
