const WebClient = require('./web-client');

const internals = {};

module.exports = async ({ url, filename, payload }) => {

    const { data, headers } = await WebClient({
        method: 'POST',
        url,
        responseType: 'blob',
        data: payload
    });

    const linkUrl = window.URL.createObjectURL(new Blob([data], { type: headers['content-type'] }));

    internals.synthLinkClick(linkUrl, headers['x-filename']);
};

internals.synthLinkClick = (href, filename) => {

    const link = document.createElement('a');

    link.href = href;
    link.setAttribute('download', filename || 'export.txt');

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);

    // Ensure the href is cleaned up in the next event cycle
    setTimeout(() => {

        URL.revokeObjectURL(link.href);
    }, 0);
};
