const React = require('react');
const T = require('prop-types');
const { NavLink } = require('react-router-dom');
const { default: Memoize } = require('memoize-one');
const { default: Styled } = require('styled-components');
const { default: Button } = require('@material-ui/core/Button');
const { default: Chip } = require('@material-ui/core/Chip');
const StrangeForms = require('strange-forms');
const Portlet = require('../../../components/Portlet');
const AlertDialog = require('../../../components/AlertDialog');
const ProgramFields = require('../../../components/ProgramFields');

const internals = {};

module.exports = class EditProgramPage extends StrangeForms(React.Component) {

    static propTypes = {
        program: T.object,
        onSubmit: T.func.isRequired,
        clients: T.array,
        users: T.array,
        forms: T.array
    };

    constructor(props) {

        super(props);

        const defaultProgramFields = {
            name: '',
            clientId: '',
            useEligibilityFile: true,
            isCovidTesting: false,
            ageRequirementDate: null,
            intakeFormId: '',
            surveyFormId: '',
            users: []
        };

        this.state = {
            programFields: { ...defaultProgramFields },
            isSubmitting: false,
            activeStep: 0,
            dialogName: null
        };

        this.fields = {
            ...defaultProgramFields,
            programFields: { ...defaultProgramFields }
        };

        this.strangeForm({
            fields: Object.keys(this.fields),
            get: {
                '*': (someProps, field) => someProps.program && someProps.program[field] || '',
                programFields: Memoize((someProps) => {

                    return someProps.program || {};
                }, ([props1], [props2]) => {

                    return (
                        (props1.program && props2.program) &&
                        props1.program.id === props2.program.id &&
                        props1.program.name === props2.program.name &&
                        props1.program.clientId === props2.program.clientId &&
                        props1.program.useEligibilityFile === props2.program.useEligibilityFile &&
                        props1.program.isCovidTesting === props2.program.isCovidTesting &&
                        props1.program.ageRequirementDate === props2.program.ageRequirementDate &&
                        (props1.program.users && props2.program.users) && props1.program.users.every(({ id }, index) => props2.program.users[index] && (id === props2.program.users[index].id))
                    );
                })
            },
            act: () => null,
            getFormValue: {
                '*': (ev) => ev.target.value,
                programFields: (val) => val
            }
        });
    }

    componentDidMount() {

        this.props.fetchData();
    }

    handleSubmit = async (ev, params = { shouldArchive: false } ) => {

        if (ev && ev.preventDefault) {
            ev.preventDefault();
        }

        const { program: { archivedAt } } = this.props;
        const isArchived = !!archivedAt;
        const { shouldArchive } = params;

        const programInfo = this.formatFields();

        if (shouldArchive && !isArchived) {
            programInfo.archivedAt = new Date();
        }
        else {
            programInfo.archivedAt = null;
        }

        this.setState({ isSubmitting: true });
        await this.props.onSubmit(programInfo);
        this.setState({ isSubmitting: false });
    }

    handleBack = () => {

        this.setState(({ activeStep }) => ({
            activeStep: activeStep - 1
        }));
    };

    handleNext = () => {

        this.setState(({ activeStep }) => ({
            activeStep: activeStep + 1
        }));
    };

    goToStep = (step) => {

        this.setState({
            activeStep: step
        });
    }

    formatFields = () => {

        const {
            name,
            clientId,
            useEligibilityFile,
            isCovidTesting,
            ageRequirementDate,
            users,
            intakeFormId,
            surveyFormId
        } = this.fieldValue('programFields');

        return {
            id: this.props.program && this.props.program.id,
            name,
            clientId,
            useEligibilityFile,
            isCovidTesting,
            ageRequirementDate,
            intakeFormId,
            surveyFormId,
            users: users.map(({ id }) => ({ id }))
        };
    };

    handleShowModal = (dialogName) => {

        this.setState({
            dialogName
        });
    }

    closeDialog = () => {

        this.setState({ dialogName: null });
    };

    render() {

        const { PageContainer, Controls } = internals;
        const { activeStep, dialogName } = this.state;
        const { clients, users, program, forms } = this.props;
        const { archivedAt = null } = program || {};
        const isArchived = !!archivedAt;
        const activeClients = clients && clients.filter((client) => !client.archivedAt);

        // If editing an archived client, include that in the dropdown, too.
        if (program && program.client && program.client.archivedAt) {
            activeClients.push(program.client);
        }

        const activeUsers = users && users.filter((user) => !user.archivedAt);

        const totalSteps = 5;
        const isLastStep = (activeStep === totalSteps - 1);

        return (
            <PageContainer>
                <Portlet
                    title='Edit Program'
                    toolbar={
                        isArchived &&
                        <Chip
                            size='small'
                            label='Archived'
                        />
                    }
                    body={
                        <ProgramFields
                            program={this.props.program}
                            forms={forms}
                            activeClients={activeClients}
                            activeUsers={activeUsers}
                            activeStep={activeStep}
                            goToStep={this.goToStep}
                            value={this.fieldValue('programFields')}
                            onChange={this.proposeNew('programFields')}
                        />
                    }
                    foot={
                        <Controls
                            isFirstStep={activeStep === 0}
                            isLastStep={isLastStep}
                            onClickNext={this.handleNext}
                            onClickBack={this.handleBack}
                            onSubmit={this.handleSubmit}
                            finalButtonActionText='Save Program'
                            shouldDisableNext={!ProgramFields.validateStep(activeStep, this.fieldValue('programFields'))}
                        />
                    }
                />
                <AlertDialog
                    dialogTitle='Delete program?'
                    dialogDescription={`This will delete "${this.fieldValue('name')}" permanently.`}
                    affirmativeLabel='Delete'
                    isModalOpen={dialogName === 'delete'}
                    confirmAction={() => this.props.onConfirmDelete()}
                    toggleModal={this.closeDialog}
                />
                <AlertDialog
                    dialogTitle='Archive program?'
                    dialogDescription={`Archiving "${this.fieldValue('name')}" will deactivate all its programs and revoke program access for associated program administrators and screeners.`}
                    affirmativeLabel='Archive'
                    isModalOpen={dialogName === 'archive'}
                    confirmAction={(ev) => this.handleSubmit(ev, { shouldArchive: true })}
                    toggleModal={this.closeDialog}
                />
            </PageContainer>
        );
    }
};

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.Controls = (props) => {

    const { onClickBack, isFirstStep, isLastStep, shouldDisableNext, onClickNext, onSubmit, finalButtonActionText = 'Add' } = props;

    return (
        <div style={{ display: 'flex' }}>
            <Button
                disabled={isFirstStep}
                onClick={onClickBack}
                style={{ minWidth: 'auto', marginRight: 20 }}
                variant='outlined'
            >
                Back
            </Button>
            <Button
                style={{ marginRight: 20 }}
                onClick={isLastStep ? onSubmit : onClickNext}
                variant='contained'
                color='primary'
                disabled={shouldDisableNext}
            >
                {isLastStep && finalButtonActionText}
                {!isLastStep && 'Next →'}
            </Button>
            <Button
                component={NavLink}
                to='/programs/search'
                style={{ marginLeft: 'auto' }}
            >
                Cancel
            </Button>
        </div>
    );
};

internals.Controls.propTypes = {
    onClickNext: T.func,
    onClickBack: T.func,
    onSubmit: T.func,
    isFirstStep: T.bool,
    isLastStep: T.bool,
    shouldDisableNext: T.bool,
    finalButtonActionText: T.string
};
