const React = require('react');
const T = require('prop-types');
const { NavLink } = require('react-router-dom');
const { default: Styled } = require('styled-components');
const { default: Button } = require('@material-ui/core/Button');
const StrangeForms = require('strange-forms');
const Portlet = require('../../../components/Portlet');
const ProgramFields = require('../../../components/ProgramFields');

const internals = {};

module.exports = class CreateProgramPage extends StrangeForms(React.Component) {

    static propTypes = {
        onSubmit: T.func.isRequired,
        clients: T.array,
        forms: T.array,
        users: T.array
    };

    constructor(props) {

        super(props);

        this.state = {
            isSubmitting: false,
            activeStep: 0
        };

        this.fields = {
            programFields: { ...ProgramFields.fields }
        };

        this.strangeForm({
            fields: Object.keys(this.fields),
            get: () => ({ ...ProgramFields.fields }),
            act: () => null,
            getFormValue: {
                programFields: (val) => val
            }
        });
    }

    componentDidMount() {

        this.props.fetchData();
    }

    handleSubmit = async (ev) => {

        ev.preventDefault();
        const programInfo = this.formatFields();
        this.setState({ isSubmitting: true });
        await this.props.onSubmit(programInfo);
        this.setState({ isSubmitting: false });
    }

    handleBack = () => {

        this.setState(({ activeStep }) => ({
            activeStep: activeStep - 1
        }));
    };

    handleNext = () => {

        this.setState(({ activeStep }) => ({
            activeStep: activeStep + 1
        }));
    };

    goToStep = (step) => {

        this.setState({
            activeStep: step
        });
    }

    formatFields = () => {

        const { name, clientId, useEligibilityFile, isCovidTesting, users } = this.fieldValue('programFields');

        return {
            name,
            clientId,
            useEligibilityFile,
            isCovidTesting,
            users: users.map(({ id }) => ({ id }))
        };
    };

    render() {

        const { PageContainer, Controls } = internals;
        const { activeStep } = this.state;
        const { clients, users, forms } = this.props;

        const activeClients = clients && clients.filter((client) => !client.archivedAt);
        const activeUsers = users && users.filter((user) => !user.archivedAt);

        const totalSteps = 5;
        const isLastStep = (activeStep === totalSteps - 1);

        return (
            <PageContainer>
                <Portlet
                    title='Add New Program'
                    body={
                        <ProgramFields
                            program={this.fieldValue('programFields')}
                            forms={forms}
                            activeClients={activeClients}
                            activeUsers={activeUsers}
                            activeStep={activeStep}
                            goToStep={this.goToStep}
                            value={this.fieldValue('programFields')}
                            onChange={this.proposeNew('programFields')}
                        />
                    }
                    foot={
                        <Controls
                            isFirstStep={activeStep === 0}
                            isLastStep={isLastStep}
                            onClickNext={this.handleNext}
                            onClickBack={this.handleBack}
                            onSubmit={this.handleSubmit}
                            finalButtonActionText='Save Program'
                            shouldDisableNext={!ProgramFields.validateStep(activeStep, this.fieldValue('programFields'))}
                        />
                    }
                />
            </PageContainer>
        );
    }
};

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.Controls = (props) => {

    const { onClickBack, isFirstStep, isLastStep, shouldDisableNext, onClickNext, onSubmit, finalButtonActionText = 'Add' } = props;

    return (
        <div style={{ display: 'flex' }}>
            <Button
                disabled={isFirstStep}
                onClick={onClickBack}
                style={{ minWidth: 'auto', marginRight: 20 }}
                variant='outlined'
            >
                Back
            </Button>
            <Button
                style={{ marginRight: 20 }}
                onClick={isLastStep ? onSubmit : onClickNext}
                variant='contained'
                color='primary'
                disabled={shouldDisableNext}
            >
                {isLastStep && finalButtonActionText}
                {!isLastStep && 'Next →'}
            </Button>
            <Button
                component={NavLink}
                to='/'
                style={{ marginLeft: 'auto' }}
            >
                Cancel
            </Button>
        </div>
    );
};

internals.Controls.propTypes = {
    onClickNext: T.func,
    onClickBack: T.func,
    onSubmit: T.func,
    isFirstStep: T.bool,
    isLastStep: T.bool,
    shouldDisableNext: T.bool,
    finalButtonActionText: T.string
};
