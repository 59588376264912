const React = require('react');
const T = require('prop-types');
const { default: Memoize } = require('memoize-one');
const { default: Styled } = require('styled-components');
const { NavLink } = require('react-router-dom');
const OrderBy = require('lodash/orderBy');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: InputAdornment } = require('@material-ui/core/InputAdornment');
const { default: IconButton } = require('@material-ui/core/IconButton');
const { default: FormControl } = require('@material-ui/core/FormControl');
const { default: Select } = require('@material-ui/core/Select');
const { default: MenuItem } = require('@material-ui/core/MenuItem');
const { default: Chip } = require('@material-ui/core/Chip');
const { default: TablePagination } = require('@material-ui/core/TablePagination');
const { default: SearchIcon } = require('@material-ui/icons/Search');
const { default: EditIcon } = require('@material-ui/icons/Edit');
const { default: ArchiveIcon } = require('@material-ui/icons/Archive');
const { default: UnarchiveIcon } = require('@material-ui/icons/Unarchive');
const { default: DeleteIcon } = require('@material-ui/icons/Delete');
const StrangeForms = require('strange-forms');
const Portlet = require('../../../components/Portlet');
const Nullable = require('../../../components/Nullable');
const Table = require('../../../components/Table');
const AlertDialog = require('../../../components/AlertDialog');
const Constants = require('../../../utils/constants');

const internals = {};

module.exports = class SearchClientsPage extends StrangeForms(React.Component) {

    static propTypes = {
        clients: T.arrayOf(T.shape({
            id: T.string,
            name: T.string,
            contact: T.string,
            archivedAt: T.instanceOf(Date)
        }))
    };

    static fields = {
        status: '',
        filterText: ''
    };

    constructor(props) {

        super(props);

        this.state = {
            status: '',
            filterText: '',
            orderDirection: 'desc',
            dialogName: null,

            // Pagination
            rowsPerPage: 10,
            page: 0
        };

        this.strangeForm({
            fields: Object.keys(SearchClientsPage.fields),
            get: () => '',
            act: () => null
        });
    }

    componentDidMount() {

        this.props.fetchClients();
    }

    getOrderedClients = Memoize(
        (clients) => OrderBy(clients, ['archivedAt', 'name'], ['desc', 'asc'])
    );

    handleRequestSort = (event, order) => {

        const { orderBy, orderDirection, compareFn } = order;

        this.setState({
            orderBy,
            orderDirection,
            compareFn
        });
    }

    handleShowModal = (client, dialog = 'archive') => {

        this.setState({
            dialogName: dialog,
            stagedClient: client
        });
    }

    closeDialog = () => {

        this.setState({ dialogName: null });
    };

    filterClients = (clients) => {

        let filteredClients = clients;
        const filterText = this.fieldValue('filterText').toLowerCase();
        const status = this.fieldValue('status');

        if (filterText) {
            filteredClients = clients.filter(({ name, contact }) => {

                return name.toLowerCase().includes(filterText) || contact.toLowerCase().includes(filterText);
            });
        }

        if (status) {
            filteredClients = filteredClients.filter(({ archivedAt }) => {

                return status === 'active' ? !archivedAt : !!archivedAt;
            });
        }

        return filteredClients;
    }

    sortClients = (filteredClients) => {

        const { compareFn } = this.state;

        if (!compareFn) {
            return filteredClients;
        }

        return compareFn([...filteredClients]);
    }

    handleChangePage = (ev, newPage) => {

        this.setState({
            page: newPage
        });
    }

    handleChangeRowsPerPage = (ev) => {

        this.setState({
            page: 0,
            rowsPerPage: ev.target.value
        });
    }

    render() {

        const { PageContainer, StyledSelect, FieldsWrapper } = internals;
        const  { rowsPerPage, page } = this.state;
        const isProduction = Constants.ENVIRONMENT === 'production';

        const columns = [
            {
                id: 'name',
                header: 'Client Name',
                label: true,
                sortable: true,
                format: (name) => <Nullable value={name} />
            },
            {
                id: 'contact',
                header: 'Key Contact',
                label: true,
                sortable: true,
                format: (contact) => <Nullable value={contact} />
            },
            {
                // NOTE: programs hasn't been implemented yet in the API
                id: 'programs',
                header: 'Programs',
                label: true,
                sortable: true
            },
            {
                id: 'status',
                header: 'Status',
                label: true,
                sortable: (rows, { direction = 'desc' } = {}) => {

                    return OrderBy(rows, ['archivedAt', 'name'], [direction === 'desc' ? 'asc' : 'desc', 'asc']);
                },
                format: (_,{ archivedAt }) => {

                    const active = !archivedAt;
                    return (
                        <Chip
                            size='small'
                            color={active ? 'secondary' : 'default'}
                            label={active ? 'Active' : 'Archived'}
                        />
                    );
                }
            },
            {
                id: 'edit',
                header: 'Edit',
                label: true,
                format: (_, { id }) => {

                    return (
                        <IconButton
                            to={`/clients/${id}/edit`}
                            component={NavLink}
                            // counteract padding from size='medium while maintaining a healthy click/tap target
                            style={{ margin: -12 }}
                        >
                            <EditIcon />
                        </IconButton>);
                }
            },
            {
                id: 'archive',
                header: 'Archive',
                label: true,
                format: (_, client) => {

                    return (
                        <IconButton
                            onClick={
                                client.archivedAt ?
                                    () => this.props.archiveClient(client.id) :
                                    () => this.handleShowModal(client)
                            }
                            // counteract padding from size='medium while maintaining a healthy click/tap target
                            style={{ margin: '-12px auto' }}
                        >
                            {client.archivedAt ? <UnarchiveIcon /> : <ArchiveIcon />}
                        </IconButton>);
                }
            }
        ];

        if (!isProduction) {
            columns.push({
                id: 'delete',
                header: 'Delete',
                label: true,
                format: (_, client) => {

                    return (
                        <IconButton
                            onClick={() => this.handleShowModal(client, 'delete')}
                            // counteract padding from size='medium while maintaining a healthy click/tap target
                            style={{ margin: '-12px auto' }}
                        >
                            <DeleteIcon />
                        </IconButton>);
                }
            });
        }

        const clients = this.getOrderedClients(this.props.clients);
        const filteredClients = this.filterClients(clients);
        const sortedClients = this.sortClients(filteredClients);
        const slicedClients = sortedClients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        const { dialogName } = this.state;

        return (
            <PageContainer>
                <Portlet
                    title='Search Clients'
                    toolbar={
                        <Chip
                            color='secondary'
                            label='Add New Client +'
                            component={NavLink} to='/clients/new'
                            clickable
                        />
                    }
                    body={
                        <>
                            <FieldsWrapper>
                                <FormControl margin='dense'>
                                    <StyledSelect
                                        value={this.fieldValue('status')}
                                        onChange={this.proposeNew('status')}
                                        displayEmpty
                                    >
                                        <MenuItem value=''><em>Status</em></MenuItem>
                                        <MenuItem value='active'>Active</MenuItem>
                                        <MenuItem value='archived'>Archived</MenuItem>
                                    </StyledSelect>
                                </FormControl>
                                <TextField
                                    InputProps={{
                                        startAdornment: <InputAdornment position='start'><SearchIcon /></InputAdornment>
                                    }}
                                    label='Search'
                                    value={this.fieldValue('filterText')}
                                    onChange={this.proposeNew('filterText')}
                                    margin='dense'
                                />
                            </FieldsWrapper>
                            <Table.Wrapper>
                                <Table
                                    columns={columns}
                                    idAttribute='id'
                                    orderDirection={this.state.orderDirection}
                                    orderBy={this.state.orderBy}
                                    onRequestSort={this.handleRequestSort}
                                >
                                    <Table.Rows data={slicedClients} />
                                </Table>
                                <TablePagination
                                    count={sortedClients.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </Table.Wrapper>
                        </>
                    }
                />
                <AlertDialog
                    dialogTitle='Archive client?'
                    dialogDescription={`Archiving "${this.state.stagedClient && this.state.stagedClient.name}" will deactivate all of its programs and revoke program access for associated program administrators and screeners.`}
                    affirmativeLabel='Archive'
                    isModalOpen={dialogName === 'archive'}
                    confirmAction={() => this.props.archiveClient(this.state.stagedClient.id)}
                    toggleModal={this.closeDialog}
                />
                <AlertDialog
                    dialogTitle='Delete client?'
                    dialogDescription={`Deleting "${this.state.stagedClient && this.state.stagedClient.name}" will remove all of its programs and revoke program access for associated program administrators and screeners.`}
                    affirmativeLabel='Delete'
                    isModalOpen={dialogName === 'delete'}
                    confirmAction={() => this.props.deleteClient(this.state.stagedClient.id)}
                    toggleModal={this.closeDialog}
                />
            </PageContainer>
        );
    }
};

internals.StyledForm = Styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.StyledSelect = Styled(Select)`
    margin-right: ${({ theme }) => theme.spacing(2)}px;
`;

internals.FieldsWrapper = Styled.div`
    display: flex;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;
