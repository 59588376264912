const LocalStorageAvailable = require('../../utils/check-local-storage');

module.exports = (M) => {

    if (!LocalStorageAvailable()) {
        M.dispatch.app.selectProgram(null);
        return;
    }

    const persistSet = (key, value) => localStorage.setItem(key, JSON.stringify(value));
    const persistGet = (key) => JSON.parse(localStorage.getItem(key) || 'null');

    const getCurrentProgram = () => M.selectors.app.getCurrentProgramId(M.store.getState());
    const currentProgram = persistGet('currentProgram');

    M.store.subscribe((...args) => {

        persistSet('currentProgram', getCurrentProgram());
    });

    M.dispatch.app.selectProgram(currentProgram);
};
