const Connect = require('react-redux').connect;
const CreateUserPage = require('../components/CreateUserPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({}),
    (dispatch, props) => ({

        onSubmit: async (userInfo) => {

            const [err] = await M.dispatch.model.createUser(userInfo);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('User created successfully');
                M.dispatch.router.push('/users/search');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err];
        }
    })
);

module.exports = internals.connect(CreateUserPage);
