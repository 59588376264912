const React = require('react');
const T = require('prop-types');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: CheckIcon } = require('@material-ui/icons/Check');
const { default: Styled } = require('styled-components');
const Portlet = require('../../../components/Portlet');
const Table = require('../../../components/Table');
const Nullable = require('../../../components/Nullable');
const GetFormattedTime = require('../../../utils/get-formatted-time');

const internals = {};

module.exports = class EventDetailPage extends React.Component {

    static propTypes = {
        event: T.shape({
            name: T.string,
            location: T.string
        }),
        fetchData: T.func
    };

    constructor(props) {

        super(props);

        this.state = {
            orderDirection: 'desc'
        };
    }

    componentDidMount() {

        this.props.fetchData();
    }

    render() {

        const { PageContainer, BodyContainer, SummaryPortlet } = internals;
        const { event } = this.props;
        const { name = '', location = '', date, appointments = [] } = event || {};
        const appointmentsClone = [...appointments];
        const sortedAppointments = appointmentsClone.sort((a, b) => {

            if (a.time === null && b.time === null) {
                return 0;
            }

            // Put null values (walk-ins) at the bottom
            if (a.time !== null && b.time === null) {
                return -1;
            }

            if (a.time === null && b.time !== null) {
                return 1;
            }

            return new Date(`1970/01/01 ${a.time}`) - new Date(`1970/01/01 ${b.time}`);
        });
        const columns = [
            {
                id: 'time',
                header: 'Time',
                label: true,
                format: (time) => <Nullable value={time && GetFormattedTime(time)} />
            },
            {
                id: 'name',
                header: 'Name',
                label: true,
                format: (_, data) => <Nullable value={data.patient && `${data.patient.firstName} ${data.patient.lastName}`} otherwise=' ' />
            },
            {
                id: 'saved',
                header: 'Saved',
                label: true,
                format: (_, data) => <>{internals.didSave(data) && <CheckIcon />}</>
            },
            {
                id: 'finalized',
                header: 'Finalized',
                label: true,
                format: (_, data) => <>{internals.didFinalize(data) && <CheckIcon />}</>
            },
            {
                id: 'noShow',
                header: 'No-Show',
                label: true,
                format: (_, data) => <>{internals.didNoShow(data) && <CheckIcon />}</>
            }
        ];

        const signupsCount = sortedAppointments.filter(internals.didSignUp).length;
        const savedCount = sortedAppointments.filter(internals.didSave).length;
        const finalizedCount = sortedAppointments.filter(internals.didFinalize).length;
        const walkInCount = sortedAppointments.filter(internals.didWalkIn).length;
        const noShowCount = sortedAppointments.filter(internals.didNoShow).length;

        return (
            <PageContainer>
                <SummaryPortlet
                    title='Summary'
                    body={
                        <div style={{ display: 'grid', gridTemplateColumns: '5fr 1fr' }}>
                            <Typography>Signups</Typography><Typography align='right'>{signupsCount}</Typography>
                            <Typography>Walk-Ins</Typography><Typography align='right'>{walkInCount}</Typography>
                            <Typography>No-Shows</Typography><Typography align='right'>{noShowCount}</Typography>
                            <Typography>Saved</Typography><Typography align='right'>{savedCount}</Typography>
                            <Typography>Finalized</Typography><Typography align='right'>{finalizedCount}</Typography>
                        </div>
                    }
                />
                <Portlet
                    title={`${name} | ${location} | ${date && date.toLocaleString()}`}
                    body={
                        <BodyContainer>
                            <Table.Wrapper>
                                <Table
                                    columns={columns}
                                    idAttribute='id'
                                    orderDirection={this.state.orderDirection}
                                >
                                    <Table.Rows data={sortedAppointments} />
                                </Table>
                            </Table.Wrapper>
                        </BodyContainer>
                    }
                />
            </PageContainer>
        );
    }
};

internals.SummaryPortlet = Styled(Portlet)`
    max-width: 250px;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    order: 1;
    flex-basis: 400px;
    flex-shrink: 1;
    ${({ theme }) => theme.breakpoints.up('md')} {
        position: sticky;
        top: ${({ theme }) => theme.spacing(2)}px;
        margin-left: ${({ theme }) => theme.spacing(2)}px;
    }
`;

internals.BodyContainer = Styled.div``;

internals.StyledForm = Styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

internals.PageContainer = Styled.div`
    width: 100%;
    ${({ theme }) => theme.breakpoints.up('md')} {
        display: flex;
        align-items: flex-start;
    }
`;

internals.didSignUp = (appt) => Boolean(appt.patient);
internals.didSave = (appt) => Boolean(appt.screening?.createdAt !== appt.screening?.updatedAt);
internals.didFinalize = (appt) => Boolean(appt.screening?.confirmedAt);
internals.didWalkIn = (appt) => appt.time === null;
internals.didNoShow = (appt) => Boolean(appt.patientId && appt.screening?.updatedAt === appt.screening?.createdAt);
