const React = require('react');
const T = require('prop-types');
const { default: ReactJson } = require('react-json-view');
const { default: Styled } = require('styled-components');
const { default: Button } = require('@material-ui/core/Button');
const Portlet = require('../../../components/Portlet');

const internals = {};

module.exports = class EditEligibilityMapping extends React.Component {

    static propTypes = {
        program: T.object,
        eligibilityMapping: T.object,
        fetchProgram: T.func.isRequired,
        updateEligibilityMapping: T.func.isRequired,
        fetchEligibilityMapping: T.func.isRequired,
        isLoading: T.bool
    };

    constructor(props) {

        super(props);

        this.state = {
            isSubmitting: false,
            updatedMapping: null
        };
    }

    componentDidMount() {

        this.props.fetchProgram();
        this.props.fetchEligibilityMapping();
    }

    handleEligibilityEdit = (e) => this.setState({ updatedMapping: e.updated_src });

    handleSave = async () => {

        this.setState({ isSubmitting: true });

        await this.props.updateEligibilityMapping(this.state.updatedMapping);
        await this.props.fetchEligibilityMapping();

        this.setState({ updatedMapping: null, isSubmitting: false });
    }

    render() {

        const { PageContainer, StyledButton } = internals;
        const { isLoading, program, eligibilityMapping } = this.props;
        const { name = '' } = program || {};

        if (isLoading) {
            return null;
        }

        return (
            <PageContainer>
                <Portlet
                    title={`Program: ${name}`}
                    toolbar={
                        <div>
                            <StyledButton
                                onClick={this.handleSave}
                                disabled={!this.state.updatedMapping || this.state.isSubmitting}
                                variant='contained'
                                color='primary'
                            >
                                Save
                            </StyledButton>
                        </div>
                    }
                    body={
                        <div>
                            <ReactJson
                                src={eligibilityMapping}
                                onEdit={this.handleEligibilityEdit}
                                sortKeys
                            />
                        </div>
                    }
                />
            </PageContainer>
        );
    }
};

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.StyledButton = Styled(Button)`
    margin: 0 ${({ theme }) => theme.spacing(1)}px;
`;
