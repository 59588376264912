const Connect = require('react-redux').connect;
const EventDetailPage = require('../components/EventDetailPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        event: M.selectors.model.fetchEvent(state)
    }),
    (dispatch, props) => ({

        fetchData: async () => {

            await M.dispatch.model.fetchEvent(props.match.params.id);
        }
    })
);

module.exports = internals.connect(EventDetailPage);
