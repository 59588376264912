const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: FormControlLabel } = require('@material-ui/core/FormControlLabel');
const { default: Checkbox } = require('@material-ui/core/Checkbox');
const { default: Dialog } = require('@material-ui/core/Dialog');
const { default: DialogTitle } = require('@material-ui/core/DialogTitle');
const { default: DialogContent } = require('@material-ui/core/DialogContent');
const { default: DialogActions } = require('@material-ui/core/DialogActions');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: Button } = require('@material-ui/core/Button');
const StrangeForms = require('strange-forms');

const internals = {};

module.exports = class EventCommunicationDialog extends StrangeForms(React.Component) {

    static propTypes = {
        event: T.object.isRequired,
        onSubmit: T.func.isRequired,
        onClose: T.func.isRequired,
        isOpen: T.bool
    };

    static fields = [
        'selectedIds',
        'shouldSelectAll',
        'message'
    ];

    constructor(props) {

        super(props);

        this.state = {
            isSubmitting: false,
            shouldDisable: false,
            dialogName: null
        };

        this.strangeForm({
            fields: EventCommunicationDialog.fields,
            get: {
                '*': (someProps, field) => (someProps.fields && someProps.fields[field]) || '',
                selectedIds: (someProps, field) => someProps.value || []
            },
            act: {
                '*': () => null
            },
            getFormValue: {
                '*': (ev) => ev.target.value || '',
                selectedIds: (selectedId) => {

                    const selectedIds = this.fieldValue('selectedIds');

                    if (selectedIds.some((id) => id === selectedId)) {
                        // Perform uncheck action
                        return selectedIds.filter((id) => id !== selectedId);
                    }

                    return [...selectedIds, selectedId];
                },
                'shouldSelectAll': (ev) => {

                    if (ev.target.checked) {
                        this.proposeNew('selectedIds', () => {

                            return this.props.event.appointments.map(({ id }) => id);
                        })();
                    }
                    else {
                        this.proposeNew('selectedIds', () => [])();
                    }

                    return ev.target.checked;
                }
            }
        });
    }

    formatFields = () => {

        const { event: { id: eventId } } = this.props;
        const patientIds = this.props.event.appointments.filter((appt) => this.fieldValue('selectedIds').includes(appt.id)).map((appt) => appt.patientId);

        return {
            eventId,
            patientIds,
            message: this.fieldValue('message')
        };
    };

    handleSubmit = async (ev) => {

        ev.preventDefault();

        const communicationInfo = this.formatFields();

        this.setState({ isSubmitting: true });

        const [err] = await this.props.onSubmit(communicationInfo);

        if (!err) {
            this.handleClose();
        }

        this.setState({ isSubmitting: false });
    }

    handleClose = (ev) => {

        ev && ev.preventDefault();

        this.proposeNew('shouldSelectAll', () => '')();
        this.proposeNew('selectedIds', () => '')();
        this.proposeNew('message', () => '')();

        this.props.onClose();
    }

    shouldDisableSubmit() {

        return (
            !this.fieldValue('selectedIds').length ||
            !this.fieldValue('message') ||
            this.state.isSubmitting
        );
    }

    render() {

        const { StyledTable } = internals;
        const { event } = this.props;

        return (
            <Dialog open={this.props.isOpen} onClose={this.handleClose} fullWidth maxWidth='lg' aria-labelledby='form-dialog-title'>
                <form>
                    <DialogTitle id='form-dialog-title'>Send Registrant(s) a Message</DialogTitle>
                    <DialogContent>

                        <div><strong>Event:</strong> {event.name}</div>
                        <div><strong>Site:</strong> {event.location}</div>
                        <div><strong>Location:</strong> {event.address1} {event.address2} {event.city}, {event.state} {event.zip}</div>
                        <div><strong>Status:</strong> {event.isPublished ? 'Published' : 'Draft'}</div>

                        <StyledTable>
                            <thead>
                                <tr>
                                    <th>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={this.state.isSubmitting}
                                                    checked={this.fieldValue('shouldSelectAll')}
                                                    onChange={this.proposeNew('shouldSelectAll')}
                                                />
                                            }
                                        />
                                    </th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                </tr>
                            </thead>
                            <tbody>
                                {event.appointments.filter((appt) => appt.patientId).map((appt) => (

                                    <tr key={appt.id}>
                                        <td>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        disabled={this.state.isSubmitting}
                                                        checked={this.fieldValue('selectedIds').includes(appt.id)}
                                                        onChange={() => this.proposeNew('selectedIds')(appt.id)}
                                                    />
                                                }
                                            />
                                        </td>
                                        {appt.patient &&
                                            <>
                                                <td>{appt.patient.firstName}</td>
                                                <td>{appt.patient.lastName}</td>
                                            </>}
                                        <td>{appt.email}</td>
                                        <td>{appt.phone}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </StyledTable>
                        <TextField
                            disabled={this.state.isSubmitting}
                            required
                            fullWidth
                            id='communication-message'
                            label='Message'
                            multiline
                            rows={8}
                            variant='outlined'
                            value={this.fieldValue('message')}
                            onChange={this.proposeNew('message')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color='primary'>
                            Cancel
                        </Button>
                        <Button onClick={this.handleSubmit} color='primary' disabled={this.shouldDisableSubmit()}>
                            Send Message
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
};

internals.StyledTable = Styled.table`
    width: 100%;

    th {
        text-align: left;
    }

    th,
    td {
        padding: ${({ theme }) => theme.spacing(1)}px;
        padding-left: 0;
    }
`;
