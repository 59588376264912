const React = require('react');
const T = require('prop-types');
const { default: Memoize } = require('memoize-one');
const { default: Styled } = require('styled-components');
const { NavLink } = require('react-router-dom');
const OrderBy = require('lodash/orderBy');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: InputAdornment } = require('@material-ui/core/InputAdornment');
const { default: IconButton } = require('@material-ui/core/IconButton');
const { default: FormControl } = require('@material-ui/core/FormControl');
const { default: Select } = require('@material-ui/core/Select');
const { default: MenuItem } = require('@material-ui/core/MenuItem');
const StrangeForms = require('strange-forms');
const { default: Chip } = require('@material-ui/core/Chip');
const { default: TablePagination } = require('@material-ui/core/TablePagination');
const { default: SearchIcon } = require('@material-ui/icons/Search');
const { default: EditIcon } = require('@material-ui/icons/Edit');
const { default: ArchiveIcon } = require('@material-ui/icons/Archive');
const { default: UnarchiveIcon } = require('@material-ui/icons/Unarchive');
const Portlet = require('../../../components/Portlet');
const Nullable = require('../../../components/Nullable');
const AlertDialog = require('../../../components/AlertDialog');
const Table = require('../../../components/Table');

const internals = {};

module.exports = class SearchUsersPage extends StrangeForms(React.Component) {

    static propTypes = {
        users: T.arrayOf(T.shape({
            id: T.string,
            name: T.string,
            roleId: T.string,
            archivedAt: T.instanceOf(Date),
            role: T.string
        }))
    };

    static fields = {
        status: '',
        filterText: '',
        permissionLevel: ''
    };

    constructor(props) {

        super(props);

        this.state = {
            status: '',
            filterText: '',
            permissionLevel: '',
            orderDirection: 'desc',

            // Pagination
            rowsPerPage: 10,
            page: 0,
            dialogName: null
        };

        this.strangeForm({
            fields: Object.keys(SearchUsersPage.fields),
            get: () => '',
            act: () => null
        });
    }

    componentDidMount() {

        this.props.fetchUsers();
    }

    getOrderedUsers = Memoize(
        (users) => OrderBy(users, ['archivedAt', 'name'], ['desc', 'asc'])
    );

    handleRequestSort = (event, order) => {

        const { orderBy, orderDirection, compareFn } = order;

        this.setState({
            orderBy,
            orderDirection,
            compareFn
        });
    }

    filterUsers = (users) => {

        let filteredUsers = users;
        const filterText = this.fieldValue('filterText').toLowerCase();
        const status = this.fieldValue('status');
        const permissionLevel = this.fieldValue('permissionLevel');

        if (filterText) {
            filteredUsers = users.filter(({ name }) => {

                return name.toLowerCase().includes(filterText);
            });
        }

        if (status) {
            filteredUsers = filteredUsers.filter(({ archivedAt }) => {

                return status === 'active' ? !archivedAt : !!archivedAt;
            });
        }

        if (permissionLevel) {
            filteredUsers = filteredUsers.filter(({ role }) => {

                return role === permissionLevel;
            });
        }

        return filteredUsers;
    }

    sortUsers = (filteredUsers) => {

        const { compareFn } = this.state;

        if (!compareFn) {
            return filteredUsers;
        }

        return compareFn([...filteredUsers]);
    }

    handleChangePage = (ev, newPage) => {

        this.setState({
            page: newPage
        });
    }

    handleChangeRowsPerPage = (ev) => {

        this.setState({
            page: 0,
            rowsPerPage: ev.target.value
        });
    }

    handleShowModal = (user, role) => {

        this.setState({
            dialogName: 'archive',
            stagedUser: user,
            stagedUserRole: role
        });
    }

    closeDialog = () => {

        this.setState({ dialogName: null });
    };

    render() {

        const { PageContainer, StyledSelect, FieldsWrapper } = internals;
        const { rowsPerPage, page } = this.state;
        const columns = [
            {
                id: 'name',
                header: 'Name',
                label: true,
                sortable: true,
                format: (name) => <Nullable value={name} />
            },
            {
                id: 'role',
                header: 'Permission Level',
                label: true,
                sortable: false,
                format: (role) => <Nullable value={internals.formatPermissionLevel(role)} />
            },
            {
                id: 'status',
                header: 'Status',
                label: true,
                sortable: (rows, { direction = 'desc' } = {}) => {

                    return OrderBy(rows, ['archivedAt', 'name'], [direction === 'desc' ? 'asc' : 'desc', 'asc']);
                },
                format: (_, { archivedAt }) => {

                    const active = !archivedAt;
                    return (
                        <Chip
                            size='small'
                            color={active ? 'secondary' : 'default'}
                            label={active ? 'Active' : 'Archived'}
                        />
                    );
                }
            },
            {
                id: 'edit',
                header: 'Edit',
                label: true,
                format: (_, { id }) => {

                    return (
                        <IconButton
                            to={`/users/${id}/edit`}
                            component={NavLink}
                            // counteract padding from size='medium while maintaining a healthy click/tap target
                            style={{ margin: -12 }}
                        >
                            <EditIcon />
                        </IconButton>);
                }
            },
            {
                id: 'archive',
                header: 'Archive',
                label: true,
                format: (_,  { roleId, role, ...user }) => {

                    return (
                        <IconButton
                            onClick={
                                user.archivedAt ?
                                    () => this.props.onSubmit({ user: { ...user, archivedAt: null }, role }) :
                                    () => this.handleShowModal(user, role)
                            }
                            // counteract padding from size='medium while maintaining a healthy click/tap target
                            style={{ margin: '-12px auto' }}
                        >
                            {user.archivedAt ? <UnarchiveIcon /> : <ArchiveIcon />}
                        </IconButton>);
                }
            }
        ];

        const users = this.getOrderedUsers(this.props.users);
        const filteredUsers = this.filterUsers(users);
        const sortedUsers = this.sortUsers(filteredUsers);
        const slicedUsers = sortedUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        const { dialogName } = this.state;

        return (
            <PageContainer>
                <Portlet
                    title='Search Users'
                    toolbar={
                        <Chip
                            color='secondary'
                            label='Add New User +'
                            component={NavLink} to='/users/new'
                            clickable
                        />
                    }
                    body={
                        <>
                            <FieldsWrapper>
                                <FormControl margin='dense'>
                                    <StyledSelect
                                        value={this.fieldValue('permissionLevel')}
                                        onChange={this.proposeNew('permissionLevel')}
                                        displayEmpty
                                    >
                                        <MenuItem value=''><em>Permission Level</em></MenuItem>
                                        <MenuItem value='superadmin'>Super Admin</MenuItem>
                                        <MenuItem value='admin'>Program Admin</MenuItem>
                                        <MenuItem value='screener'>Screener</MenuItem>
                                        <MenuItem value='manager'>Manager</MenuItem>
                                    </StyledSelect>
                                </FormControl>
                                <FormControl margin='dense'>
                                    <StyledSelect
                                        value={this.fieldValue('status')}
                                        onChange={this.proposeNew('status')}
                                        displayEmpty
                                    >
                                        <MenuItem value=''><em>Status</em></MenuItem>
                                        <MenuItem value='active'>Active</MenuItem>
                                        <MenuItem value='archived'>Archived</MenuItem>
                                    </StyledSelect>
                                </FormControl>
                                <TextField
                                    InputProps={{
                                        startAdornment: <InputAdornment position='start'><SearchIcon /></InputAdornment>
                                    }}
                                    label='Search'
                                    value={this.fieldValue('filterText')}
                                    onChange={this.proposeNew('filterText')}
                                    margin='dense'
                                />
                            </FieldsWrapper>
                            <Table.Wrapper>
                                <Table
                                    columns={columns}
                                    idAttribute='id'
                                    orderDirection={this.state.orderDirection}
                                    orderBy={this.state.orderBy}
                                    onRequestSort={this.handleRequestSort}
                                >
                                    <Table.Rows data={slicedUsers} />
                                </Table>
                                <TablePagination
                                    count={sortedUsers.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </Table.Wrapper>
                        </>
                    }
                />
                <AlertDialog
                    dialogTitle='Archive user?'
                    dialogDescription={`Archiving "${this.state.stagedUser && this.state.stagedUser.name}" will revoke application access.`}
                    affirmativeLabel='Archive'
                    isModalOpen={dialogName === 'archive'}
                    confirmAction={() => this.props.onSubmit({ user: { ...this.state.stagedUser, archivedAt: new Date() }, role: this.state.stagedUserRole })}
                    toggleModal={this.closeDialog}
                />
            </PageContainer>
        );
    }
};

internals.StyledForm = Styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.StyledSelect = Styled(Select)`
    margin-right: ${({ theme }) => theme.spacing(2)}px;
`;

internals.FieldsWrapper = Styled.div`
    display: flex;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

internals.formatPermissionLevel = (permission) => {

    switch (permission) {
        case 'superadmin':
            return 'Super Admin';
        case 'admin':
            return 'Program Admin';
        case 'screener':
            return 'Screener';
        case 'manager':
            return 'Manager';
        default:
            return permission;
    }
};
