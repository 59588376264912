const React = require('react');
const T = require('prop-types');
const { NavLink } = require('react-router-dom');
const { default: Divider } = require('@material-ui/core/Divider');
const { default: Button } = require('@material-ui/core/Button');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: Menu } = require('@material-ui/core/Menu');
const { default: MenuItem } = require('@material-ui/core/MenuItem');
const { default: ListItemText } = require('@material-ui/core/ListItemText');
const { default: Styled } = require('styled-components');
const StrangeForms = require('strange-forms');
const Portlet = require('../../../components/Portlet');
const EventListTable = require('../../../components/EventListTable');
const { API_BASE_URL } = require('../../../constants');

const internals = {};

module.exports = class ReportingPage extends StrangeForms(React.Component) {

    static propTypes = {
        fetchProgram: T.func,
        fetchTPAReport: T.func,
        events: T.array
    };

    static tpas = [
        { key: 'optum', label: 'Optum' },
        { key: 'virgin-pulse', label: 'Virgin Pulse' },
        { key: 'etor', label: 'ETOR' },
        { key: 'redcap', label: 'RedCap' },
        { key: 'generic', label: 'Generic' }
    ];

    constructor(props) {

        super(props);

        const emptyObject = {};

        this.strangeForm({
            fields: ['startDate', 'endDate', 'selectedEvents'],
            get: {
                selectedEvents: () => emptyObject,
                '*': () => ''
            },
            act: {
                '*': () => null
            },
            getFormValue: {
                '*': (ev) => ev.target.value,
                selectedEvents: (val) => val
            }
        });
    }

    componentDidMount() {

        this.props.fetchData();
    }

    filterEvents = (events) => {

        const startDate = this.fieldValue('startDate');
        const endDate = this.fieldValue('endDate');

        if (!startDate || !endDate) {
            return [];
        }

        let filteredArray = events;

        if (startDate) {
            filteredArray = filteredArray.filter(({ date: eventDate }) => new Date(eventDate) >= new Date(startDate));
        }

        if (endDate) {
            filteredArray = filteredArray.filter(({ date: eventDate }) => new Date(eventDate) <= new Date(endDate));
        }

        return filteredArray;
    }

    shouldDisableButton() {

        const selectedEvents = this.fieldValue('selectedEvents');
        const selectedEventIds = Object.entries(selectedEvents).filter(([_, val]) => val).map(([key]) => key);
        return !selectedEventIds.length;
    }

    handleGenerateClientReport = () => {

        const selectedEvents = this.fieldValue('selectedEvents');
        const selectedEventIds = Object.entries(selectedEvents).filter(([_, val]) => val).map(([key]) => key);
        this.props.selectEvents(selectedEventIds);
        this.props.history.push(`/client-report`);
    }

    handleGenerateTPAReport = async (tpa) => {

        const selectedEvents = this.fieldValue('selectedEvents');
        const selectedEventIds = Object.entries(selectedEvents).filter(([_, val]) => val).map(([key]) => key);
        this.props.selectEvents(selectedEventIds);

        await this.props.fetchTPAReport({ eventIds: selectedEventIds, tpa });
    }

    handleOpenDownloadMenu = (ev) => {

        this.setState({ anchorEl: this.state.anchorEl ? null : ev.currentTarget });
    }

    handleClose = () => {

        this.setState({ anchorEl: null });
    }

    render() {

        const { PageContainer, StyledDivider, FieldsWrapper, AltButton, DropdownButton, ForwardedLink } = internals;
        const { program, events } = this.props;
        const { name = '' } = program || {};
        const filteredEvents = this.filterEvents(events);
        const sortedEvents = filteredEvents.sort((a, b) => new Date(a.date) - new Date(b.date));

        return (
            <PageContainer>
                <Portlet
                    title={`Reporting | Program: ${name}`}
                    body={
                        <>
                            <Typography>Generate a <strong>TPA Report</strong> to create a file for data submission. Generate a <strong>Client Report</strong> to create a PDF file for the client.</Typography>
                            <FieldsWrapper>
                                <TextField
                                    id='startDate'
                                    label='Start Date'
                                    type='date'
                                    value={this.fieldValue('startDate')}
                                    onChange={this.proposeNew('startDate')}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                <TextField
                                    id='endDate'
                                    label='End Date'
                                    type='date'
                                    value={this.fieldValue('endDate')}
                                    onChange={this.proposeNew('endDate')}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </FieldsWrapper>
                            <StyledDivider />
                            {this.fieldValue('startDate') && this.fieldValue('endDate') &&
                                <>
                                    <EventListTable
                                        events={sortedEvents}
                                        value={this.fieldValue('selectedEvents')}
                                        onChange={this.proposeNew('selectedEvents')}
                                    />
                                    <StyledDivider />
                                    <DropdownButton
                                        onClick={this.handleOpenDownloadMenu}
                                        variant='contained'
                                        color='primary'
                                        disabled={this.shouldDisableButton()}
                                    >
                                        Generate TPA Report
                                    </DropdownButton>

                                    <Menu
                                        anchorEl={this.state.anchorEl}
                                        open={Boolean(this.state.anchorEl)}
                                        keepMounted
                                        onClose={this.handleClose}
                                        elevation={0}
                                        getContentAnchorEl={null}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                    >
                                        {ReportingPage.tpas.map(({ key, label }) => (

                                            <MenuItem
                                                key={key}
                                                component='a'
                                                download
                                                onClick={() => this.handleGenerateTPAReport(key)}
                                            >
                                                <ListItemText primary={label} />
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        disabled={this.shouldDisableButton()}
                                        onClick={this.handleGenerateClientReport}
                                    >
                                        Generate Client Report
                                    </Button>
                                    <StyledDivider />
                                </>}
                            <Typography variant='h6'>Participation Report</Typography>
                            <Typography>See the participation</Typography>
                            <AltButton
                                to='participation-report'
                                component={ForwardedLink}
                                variant='contained'
                                color='primary'
                            >
                                Generate Participation Report
                            </AltButton>
                            <StyledDivider />
                            <Typography variant='h6'>Full TPA Report</Typography>
                            <Typography>Export aggregate data for all patients for upload into TPA</Typography>
                            <DropdownButton
                                onClick={this.handleOpenDownloadMenu}
                                variant='contained'
                                color='primary'
                            >
                                Generate Full TPA Report
                            </DropdownButton>

                            <Menu
                                anchorEl={this.state.anchorEl}
                                open={Boolean(this.state.anchorEl)}
                                keepMounted
                                onClose={this.handleClose}
                                elevation={0}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                            >
                                {ReportingPage.tpas.map(({ key, label }) => (

                                    <MenuItem
                                        key={key}
                                        component='a'
                                        download
                                        href={`${API_BASE_URL}/reports/tpa/${program && program.id}/${key}`}
                                    >
                                        <ListItemText primary={label} />
                                    </MenuItem>
                                ))}
                            </Menu>
                        </>
                    }
                />
            </PageContainer>
        );
    }
};

internals.AltButton = Styled(Button)`
    margin-left: 0;
`;

internals.DropdownButton = Styled(Button)`
    position: relative;
    margin-left: 0;
    padding-right: ${({ theme }) => theme.spacing(6)}px;

    &:after {
        content: '▼';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        padding: 7px 12px; // vertically center the caret
        background: rgba(0,0,0,0.05);
    }
`;

internals.StyledDivider = Styled(Divider)`
    margin: ${({ theme }) => theme.spacing(2)}px 0;
`;

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.FieldsWrapper = Styled.form`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;

    // Select all but the last two (final text field and submit button)
    > div {
        margin-right: ${({ theme }) => theme.spacing(1)}px;
    }
`;

internals.ForwardedLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);
