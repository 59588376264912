const Connect = require('react-redux').connect;
const SetupFormsPage = require('../components/SetupFormsPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        program: M.selectors.model.fetchProgram(state),
        eligibilityFiles: M.selectors.model.fetchEligibilityFiles(state),
        doesProgramExist: !M.selectors.model.fetchProgramNotFound(state)
    }),
    (dispatch, props) => ({
        fetchProgram: () => M.dispatch.model.fetchProgram(props.match.params.id),
        fetchEligibilityFiles: () => M.dispatch.model.fetchEligibilityFiles(props.match.params.id),
        uploadEligibility: async (data) => {

            const [err] = await M.dispatch.model.uploadEligibility(data);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Eligibility file saved successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err];
        },
        uploadConsent: async (data) => {

            const [err] = await M.dispatch.model.uploadConsent(data);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Consent form saved successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err];
        },
        uploadEvents: async (data) => {

            const [err] = await M.dispatch.model.uploadEvents(data);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Events imported successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err];
        },
        uploadPDFForm: async (data) => {

            const [err] = await M.dispatch.model.uploadPDFForm(data);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('PDF form uploaded successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err];
        },
        uploadCovidResults: async (data) => {

            const [err, result] = await M.dispatch.model.uploadCovidResults(data);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('COVID results uploaded successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err, result];
        },
        uploadCovidRequisition: async (data) => {

            const [err, result] = await M.dispatch.model.uploadCovidRequisition(data);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('COVID requisition uploaded successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err, result];
        }
    })
);

module.exports = internals.connect(SetupFormsPage);
