const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const Portlet = require('../../../components/Portlet');
const CovidForm = require('../../../components/CovidForm');

const internals = {};

module.exports = class PatientCovidPage extends React.Component {

    static propTypes = {
        fetchData: T.func,
        updatePatient: T.func,
        program: T.object,
        patient: T.object,
        history: T.object
    };

    componentDidMount() {

        this.props.fetchData();
    }

    render() {

        const { PageContainer, StyledPortlet } = internals;

        return (
            <PageContainer>
                <StyledPortlet
                    body={
                        <CovidForm
                            history={this.props.history}
                            patient={this.props.patient}
                            program={this.props.program}
                            updatePatient={this.props.updatePatient}
                        />
                    }
                />
            </PageContainer>
        );
    }
};

internals.PageContainer = Styled.div`
    display: flex;
    width: 100%;
    max-width: ${({ theme }) => theme.spacing(100)}px;
    margin: 0 auto;

    flex-direction: column;

    ${({ theme }) => theme.breakpoints.up('sm')} {
        align-items: flex-start;
        flex-direction: row;
    }

`;

internals.StyledPortlet = Styled(Portlet)`

    ${({ theme }) => theme.breakpoints.up('sm')} {
        flex: 1
    }
    margin: ${({ theme }) => theme.spacing(1)}px;
`;
