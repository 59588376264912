const React = require('react');
const { default: Styled } = require('styled-components');
const { default: LogoImage } = require('../../../img/promerica-mark.svg');

const internals = {};

module.exports = () => {

    const { Image, HomepageContainer } = internals;

    return (
        <HomepageContainer>
            <Image
                alt='Promerica Health'
                src={LogoImage}
            />
        </HomepageContainer>
    );
};

internals.Image = Styled.img`
    display: block;
    width: 250px;
    margin: 1.5rem auto;
`;

internals.HomepageContainer = Styled.div`
    align-self: center;
    margin: auto;
`;
