const Connect = require('react-redux').connect;
const SearchClientsPage = require('../components/SearchClientsPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        clients: M.selectors.model.fetchClients(state)
    }),
    (dispatch, props) => ({
        fetchClients: () => M.dispatch.model.fetchClients(),
        archiveClient: async (id) => {

            const [err] = await M.dispatch.model.archiveClient(id);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Client updated successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }
        },
        deleteClient: async (id) => {

            const [err] = await M.dispatch.model.deleteClient(id);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Client deleted successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }
        },
        onSubmit: async (clientInfo) => {

            const [err] = await M.dispatch.model.editClient(clientInfo);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Client updated successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }
        }
    })
);

module.exports = internals.connect(SearchClientsPage);
