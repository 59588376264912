const React = require('react');
const T = require('prop-types');
const { default: Styled, ...S } = require('styled-components');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: Paper } = require('@material-ui/core/Paper');
const { default: Divider } = require('@material-ui/core/Divider');

const internals = {};

module.exports = (props) => {

    const { PaperContainer, PortletTitle, HeaderContainer, BodyContainer } = internals;
    const { title, toolbar, body, foot, noBodyPadding, ...otherProps } = props;

    return (
        <Paper position='static' {...otherProps}>
            {(!!title || !!toolbar) &&
                <>
                    <PaperContainer>
                        <HeaderContainer>
                            {!!title && <PortletTitle>{title}</PortletTitle>}
                            {!!toolbar && toolbar}
                        </HeaderContainer>
                    </PaperContainer>
                    <Divider />
                </>}
            <BodyContainer noBodyPadding={noBodyPadding}>
                {!!body && body}
            </BodyContainer>
            {!!foot &&
            <>
                <Divider />
                <PaperContainer>{foot}</PaperContainer>
            </>}
        </Paper>
    );
};

module.exports.propTypes = {
    title: T.string,
    toolbar: T.node,
    body: T.node,
    foot: T.node,
    noBodyPadding: T.bool
};

module.exports.displayName = 'Portlet';

module.exports.LogoImg = Styled.img`
    max-height: 100%;
`;

internals.PortletTitle = Styled(Typography).attrs({ variant: 'h3' })`
    font-size: 1.2rem;
`;

internals.PaperContainer = Styled.div`
    padding: ${({ theme }) => theme.spacing(2)}px;
`;

internals.BodyContainer = Styled(internals.PaperContainer)`
    ${(props) => props.noBodyPadding && S.css`
        && { padding: 0; }
    `}
`;

internals.HeaderContainer = Styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    // Give this a fixed height so we can size a logo appropriately
    height: ${({ theme }) => theme.spacing(3)}px;
`;
