const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: FormControl } = require('@material-ui/core/FormControl');
const { default: FormControlLabel } = require('@material-ui/core/FormControlLabel');
const { default: FormLabel } = require('@material-ui/core/FormLabel');
const { default: Checkbox } = require('@material-ui/core/Checkbox');
const { default: InputLabel } = require('@material-ui/core/InputLabel');
const { default: Select } = require('@material-ui/core/Select');
const { default: RadioGroup } = require('@material-ui/core/RadioGroup');
const { default: Radio } = require('@material-ui/core/Radio');
const { default: MenuItem } = require('@material-ui/core/MenuItem');

const internals = {};

module.exports = function ScreeningFieldsGroup(props) {

    const {
        disabled,
        label,
        fieldsMap,
        formula,
        value,
        onChange
    } = props;

    const {
        StyledTextField,
        StyledSelect,
        StyledRadioGroup
    } = internals;

    const onFieldOnChange = (field, type) => {

        return (evt) => {

            let val = evt.target.value;

            if (type === 'boolean') {
                val = evt.target.checked;
            }

            onChange({
                ...value,
                values: {
                    ...value?.values,
                    [field]: val
                }
            });
        };
    };

    return (
        <>
            {
                fieldsMap.map((field) => {

                    const fieldLabel = field.name === '@' ? label : field.name;
                    const fieldVal = (value?.values && value.values[field.name]) ?? '';

                    if (field.type === 'boolean') {
                        return (
                            <FormControlLabel
                                disabled={disabled}
                                key={field.name}
                                control={
                                    <Checkbox
                                        value={fieldVal}
                                        checked={!!fieldVal}
                                        onChange={onFieldOnChange(field.name, field.type)}
                                    />
                                }
                                label={fieldLabel}
                            />
                        );
                    }

                    if (field.type === 'dropdown') {
                        return (
                            <FormControl variant='outlined' disabled={disabled}>
                                <InputLabel id={`${label}-label`}>{label}</InputLabel>
                                <StyledSelect
                                    labelId={`${label}-label`}
                                    value={fieldVal}
                                    onChange={onFieldOnChange(field.name, field.type)}
                                    label={fieldLabel}
                                    displayEmpty
                                >
                                    {
                                        field.choices &&
                                        field.choices.map((choice) => (

                                            <MenuItem
                                                key={choice}
                                                value={choice}
                                            >
                                                {/* This ensures empty items get full height */}
                                                {choice || '\xa0'}
                                            </MenuItem>
                                        ))
                                    }
                                </StyledSelect>
                            </FormControl>
                        );
                    }

                    if (field.type === 'radio') {
                        return (
                            <FormControl component='fieldset' disabled={disabled}>
                                <FormLabel component='legend'>{label}</FormLabel>
                                <StyledRadioGroup
                                    aria-label={fieldLabel}
                                    name={label}
                                    value={fieldVal}
                                    onChange={onFieldOnChange(field.name, field.type)}
                                >
                                    {
                                        field.choices &&
                                        field.choices.map((choice) => (

                                            <FormControlLabel
                                                key={choice}
                                                value={choice}
                                                control={<Radio />}
                                                label={choice}
                                            />
                                        ))
                                    }
                                </StyledRadioGroup>
                            </FormControl>
                        );
                    }

                    return (
                        <StyledTextField
                            type={field.type}
                            key={field.name}
                            label={fieldLabel}
                            value={fieldVal}
                            onChange={onFieldOnChange(field.name, field.type)}
                            InputProps={{
                                endAdornment: field.units || ''
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            // If there's a formula involved, we don't want user input
                            disabled={disabled || !!formula}
                        />
                    );
                })
            }
        </>
    );
};

module.exports.propTypes = {
    disabled: T.bool,
    fieldsMap: T.array.isRequired,
    label: T.string,
    formula: T.string,
    value: T.shape({
        screeningMetricId: T.string,
        values: T.object
    }),
    onChange: T.func.isRequired
};

// This is a one-off color, not used elsewhere
internals.highlightColor = '#fff5d9';

internals.StyledTextField = Styled(TextField)`
    ${({ value, disabled }) => {

        if (!value && !disabled) {
            return `> div { background: ${internals.highlightColor}; }`;
        }
    }}
`;

internals.StyledSelect = Styled(Select)`
    ${({ value, disabled }) => {

        if (!value && !disabled) {
            return `> div { background: ${internals.highlightColor}; }`;
        }
    }}
`;

internals.StyledRadioGroup = Styled(RadioGroup)`
    ${({ value, disabled }) => {

        if (!value && !disabled) {
            return `background: ${internals.highlightColor};`;
        }
    }}
`;
