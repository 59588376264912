const React = require('react');
const T = require('prop-types');
const { default: Styled, ...S } = require('styled-components');
const { default: SwapIcon } = require('@material-ui/icons/SwapHoriz');
const { default: Button } = require('@material-ui/core/Button');
const { lighten, darken, getContrast } = require('polished');


const internals = {};

module.exports = (props) => {

    const { program } = props;
    const { SwitcherContainer, ProgramName, ProgramSwitchButton, StyledSwapIcon } = internals;

    return (
        <SwitcherContainer client={program.client} onClick={props.onClick}>
            <ProgramName>
                {program.client.name}<br />
                <strong>{program.name}</strong>
            </ProgramName>
            {props.onClick && <ProgramSwitchButton><StyledSwapIcon /></ProgramSwitchButton>}
        </SwitcherContainer>
    );
};

module.exports.propTypes = {
    program: T.object,
    onClick: T.func
};

module.exports.displayName = 'ActiveProgramDisplay';

internals.SwitcherContainer = Styled(Button)`
    display: flex;
    width: 100%;
    border-radius: 0;
    justify-content: space-between;
    align-items: center;    
    margin: ${({ theme }) => theme.spacing(1)}px 0;
    padding: ${({ theme }) => theme.spacing(2)}px;
    text-align: left;

    // Increase specificity to prevent mui-button defaults on hover
    && {
    background-color: ${(props) => props.client.brandColor};
    color: ${(props) => {

        const whiteContrast = getContrast(props.client.brandColor, props.theme.palette.common.white);
        const blackContrast = getContrast(props.client.brandColor, props.theme.palette.common.black);

        return whiteContrast > blackContrast ? props.theme.palette.common.white : props.theme.palette.common.black;
    }};        
    }


    ${({ onClick }) => {

        return onClick && S.css`
    &:hover,
    &:focus {
        background-color: ${(props) => {

        const whiteContrast = getContrast(props.client.brandColor, props.theme.palette.common.white);
        const blackContrast = getContrast(props.client.brandColor, props.theme.palette.common.black);

        return whiteContrast > blackContrast ? lighten(0.1, props.client.brandColor) : darken(0.05, props.client.brandColor);
    }};
    `;
    }}
`;

internals.ProgramName = Styled.div`
    margin-right: ${({ theme }) => theme.spacing(1)}px;
`;

internals.ProgramSwitchButton = Styled.div`
    color: ${({ theme }) => theme.palette.common.white};
    font-size: .75rem;
    margin: 0;
`;

internals.StyledSwapIcon = Styled(SwapIcon)`
    width: ${({ theme }) => theme.spacing(3)}px;
    height: ${({ theme }) => theme.spacing(3)}px;
`;
