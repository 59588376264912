const Connect = require('react-redux').connect;
const ClientReport = require('../components/ClientReport');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        selectedEvents: M.selectors.app.getSelectedEvents(state),
        clientReport: M.selectors.model.fetchClientReport(state)
    }),
    (dispatch, props) => ({
        generateClientReport: async (eventIds) => {

            await M.dispatch.model.fetchClientReport(eventIds);
        }
    })
);

module.exports = internals.connect(ClientReport);
