const React = require('react');
const T = require('prop-types');
const { NavLink } = require('react-router-dom');
const { default: Styled } = require('styled-components');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: Button } = require('@material-ui/core/Button');
const { default: Chip } = require('@material-ui/core/Chip');
const { default: FormHelperText } = require('@material-ui/core/FormHelperText');
const { ChromePicker: ColorPicker } = require('react-color');
const StrangeForms = require('strange-forms');
const Portlet = require('../../../components/Portlet');
const SectionLabel = require('../../../components/SectionLabel');
const AlertDialog = require('../../../components/AlertDialog');

const internals = {};

module.exports = class EditClientPage extends StrangeForms(React.Component) {

    static propTypes = {
        onSubmit: T.func.isRequired,
        removeLogo: T.func,
        uploadLogo: T.func.isRequired
    };

    static fields = {
        name: '',
        contact: '',
        logoUrl: '',
        brandColor: ''
    };

    constructor(props) {

        super(props);

        this.state = {
            isSubmitting: false,
            dialogName: null
        };

        this.strangeForm({
            fields: Object.keys(EditClientPage.fields),
            get: (someProps, field) => someProps.client && someProps.client[field] || '',
            act: () => null,
            getFormValue: {
                '*': (ev) => ev.target.value || '',
                brandColor: (color) => color.hex
            }
        });
    }

    componentDidMount() {

        this.props.fetchClient();
    }

    shouldDisableSubmit() {

        const logoUrl = this.fieldValue('logoUrl');
        return !logoUrl || !this.fieldValue('name') || this.state.isSubmitting;
    }

    handleLogoChange = async (ev) => {

        // We only allow uploading 1 file, so we pick the first from the list
        const file = ev.target.files.item(0);
        if (!file) {
            return;
        }

        const [err, result] = await this.props.uploadLogo(file);

        if (err) {
            return;
        }

        this.proposeNew('logoUrl', () => result)();
    }

    handleLogoRemove = (ev) => {

        this.proposeNew('logoUrl', () => null)();
    }

    handleShowModal = (dialogName) => {

        this.setState({
            dialogName
        });
    }

    closeDialog = () => {

        this.setState({ dialogName: null });
    };

    handleSubmit = async (ev) => {

        if (ev && ev.preventDefault) {
            ev.preventDefault();
        }

        const accountInfo = this.formatFields();

        this.setState({ isSubmitting: true });
        await this.props.onSubmit(accountInfo);
        this.setState({ isSubmitting: false });
    }

    formatFields = () => {

        const { client: { id } } = this.props;

        return {
            id,
            name: this.fieldValue('name'),
            contact: this.fieldValue('contact'),
            logoUrl: this.fieldValue('logoUrl'),
            brandColor: this.fieldValue('brandColor')
        };
    };

    render() {

        const { PageContainer, StyledForm, StyledButton, DeleteButton, LogoContainer, ForwardedLink, ButtonWrapper, StyledFormHelperText } = internals;
        const { dialogName } = this.state;
        const { client = {} } = this.props;
        const { archivedAt = null } = client || {};
        const isArchived = !!archivedAt;

        const logoUrl = this.fieldValue('logoUrl');

        return (
            <PageContainer>
                <Portlet
                    title='Edit Client'
                    toolbar={
                        isArchived &&
                        <Chip
                            size='small'
                            label='Archived'
                        />
                    }
                    body={
                        <StyledForm>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <SectionLabel>Client Details</SectionLabel>
                                <TextField
                                    required
                                    label='Client Name'
                                    InputLabelProps={{ shrink: true }}
                                    placeholder='This will be patient-facing'
                                    helperText='Name as you would like it to appear for patients'
                                    value={this.fieldValue('name')}
                                    onChange={this.proposeNew('name')}
                                />
                                <TextField
                                    label='Client Contact'
                                    InputLabelProps={{ shrink: true }}
                                    placeholder='Day-to-day contact person'
                                    helperText='This is for reference only and is not required'
                                    value={this.fieldValue('contact')}
                                    onChange={this.proposeNew('contact')}
                                />
                                <SectionLabel>Brand Color</SectionLabel>
                                <StyledFormHelperText>Used as the header background in the patient portal and as a menu accent for other users</StyledFormHelperText>
                                <ColorPicker
                                    color={this.fieldValue('brandColor')}
                                    onChangeComplete={this.proposeNew('brandColor')}
                                />
                                <div className='logo'>
                                    <SectionLabel>Logo</SectionLabel>
                                    {!!logoUrl &&
                                    <LogoContainer>
                                        <img
                                            alt='Logo preview'
                                            src={logoUrl}
                                        />
                                    </LogoContainer>}
                                    <input
                                        accept='image/*'
                                        id='upload-logo'
                                        type='file'
                                        onChange={this.handleLogoChange}
                                        style={{ display: 'none' }}
                                    />
                                    <label htmlFor='upload-logo'>
                                        <Button component='span' color='secondary'>
                                            Choose Image
                                        </Button>
                                    </label>
                                    <Button onClick={this.handleLogoRemove} disabled={!logoUrl} color='primary'>Remove logo</Button>
                                </div>
                            </div>
                        </StyledForm>
                    }
                    foot={
                        <>
                            <ButtonWrapper>
                                <StyledButton
                                    onClick={this.handleSubmit}
                                    variant='contained'
                                    color='primary'
                                    disabled={this.shouldDisableSubmit()}
                                >
                                    Save
                                </StyledButton>
                                <StyledButton
                                    component={ForwardedLink}
                                    to='/clients/search'
                                    variant='text'
                                >
                                    Cancel
                                </StyledButton>
                                <DeleteButton
                                    onClick={() => this.handleShowModal('delete')}
                                >
                                    Delete
                                </DeleteButton>
                            </ButtonWrapper>
                        </>
                    }
                />
                <AlertDialog
                    dialogTitle='Delete client?'
                    dialogDescription={`This will delete "${this.fieldValue('name')}" permanently.`}
                    affirmativeLabel='Delete'
                    isModalOpen={dialogName === 'delete'}
                    confirmAction={() => this.props.onConfirmDelete()}
                    toggleModal={this.closeDialog}
                />
            </PageContainer>
        );
    }
};

internals.StyledFormHelperText = Styled(FormHelperText)`
    && {
        margin: -${({ theme }) => theme.spacing(1)}px ${({ theme }) => theme.spacing(2)}px ${({ theme }) => theme.spacing(2)}px ${({ theme }) => theme.spacing(2)}px;
    }
`;

internals.StyledForm = Styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.StyledButton = Styled(Button)`
    margin: 0 ${({ theme }) => theme.spacing(1)}px;
    max-width: fit-content;
`;

internals.DeleteButton = Styled(internals.StyledButton)`
    margin-left: auto;
    color: ${({ theme }) => theme.palette.error.main};
`;

internals.ButtonWrapper = Styled.div`
    display: flex;
`;

internals.LogoContainer = Styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(45deg, #cecece52 25%, transparent 25%), linear-gradient(-45deg, #cecece52 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #cecece52 75%), linear-gradient(-45deg, transparent 75%, #cecece52 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
    padding: 15px 0;
    img {
        max-height: 50px;
        max-width: 100%;
    }
`;

internals.ForwardedLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);
