const Connect = require('react-redux').connect;
const PatientScreeningPage = require('../components/PatientScreeningPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        currentProgramId: M.selectors.app.getCurrentProgramId(state),
        patient: M.selectors.model.fetchPatient(state),
        program: M.selectors.model.fetchProgram(state),
        events: M.selectors.model.fetchEvents(state),
        screening: M.selectors.model.fetchScreening(state),
        role: M.selectors.auth.getUserRole(state),
        screeningCategories: M.selectors.model.fetchScreeningCategories(state)
    }),
    (dispatch, props) => ({
        fetchData: async () => {

            const [patient] = await Promise.all([
                M.dispatch.model.fetchPatient(props.match.params.id),
                M.dispatch.model.fetchScreeningCategories()
            ]);

            const [, result] = patient;
            return result;
        },
        fetchProgram: async (id) => await M.dispatch.model.fetchProgram(id),
        fetchEvents: async (id) => await M.dispatch.model.fetchEvents(id),
        fetchScreening: async (id) => await M.dispatch.model.fetchScreening(id),
        onSubmit: async (screeningInfo) => {

            const [err] = await M.dispatch.model.editScreening(screeningInfo);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Screening updated successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err];
        },
        onConfirm: async (screeningInfo) => {

            const [err] = await M.dispatch.model.confirmScreening(screeningInfo);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Screening confirmed successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err];
        },
        submitAppointment: async (appointmentInfo) => {

            const [err] = await M.dispatch.model.scheduleAppointment(appointmentInfo);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Appointment scheduled successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err];
        },
        rescheduleAppointment: async (appointmentInfo) => {

            const [err] = await M.dispatch.model.rescheduleAppointment(appointmentInfo);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Appointment rescheduled successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err];
        },
        updatePatient: async (patientInfo) => {

            const [err, result] = await M.dispatch.model.updatePatient(patientInfo);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Patient updated successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err, result];
        },
        cancelAppointment: async (appointmentInfo) => {

            const [err] = await M.dispatch.model.cancelAppointment({ patientId: props.match.params.id, ...appointmentInfo });

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Appointment cancelled successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }
        },
        updateAppointment: async (appointmentInfo) => {

            const [err] = await M.dispatch.model.updateAppointment(appointmentInfo);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Appointment updated successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }
        },
        sendAppointmentConfirmation: async (id) => {

            const [err] = await M.dispatch.model.sendAppointmentConfirmation(id);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Confirmation message sent successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }
        },
        pushToSnackbar: (message) => {

            M.dispatch.snackbar.pushToQueue(message);
        }
    })
);

module.exports = internals.connect(PatientScreeningPage);
