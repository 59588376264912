const React = require('react');
const T = require('prop-types');
const { NavLink } = require('react-router-dom');
const { default: Styled } = require('styled-components');
const { default: CloseIcon } = require('@material-ui/icons/Close');
const Portlet = require('../../../components/Portlet');
const CustomFormConsumer = require('../../../components/CustomFormConsumer');

const internals = {};

module.exports = class PatientScreeningPage extends React.Component {

    static propTypes = {
        screening: T.object,
        program: T.object,
        patient: T.object,
        fetchData: T.func,
        fetchProgram: T.func,
        fetchPatient: T.func,
        fetchForm: T.func,
        fetchFormSubmission: T.func,
        submitFormSubmission: T.func
    };

    constructor(props) {

        super(props);

        this.state = {
            isComplete: false
        };
    }

    async componentDidMount() {

        await this.props.fetchData();

        this.props.fetchProgram(this.props.screening.programId);
        this.props.fetchPatient(this.props.screening.patientId);
    }

    onSubmit = async (...args) => {

        await this.props.submitFormSubmission(...args);

        this.setState({
            isComplete: true
        });
    }

    render() {

        const { PageContainer, StyledPortlet, Greeting, CloseButton } = internals;

        const { program, patient, fetchForm, fetchFormSubmission } = this.props;
        const { isComplete } = this.state;

        if (!patient) {
            return null;
        }

        if (!isComplete) {
            return (
                <PageContainer>
                    <StyledPortlet
                        body={
                            <>
                                <Greeting variant='h4'>Welcome, {patient.firstName} {patient.lastName}</Greeting>
                                {program && patient && (
                                    <CustomFormConsumer
                                        formId={program.surveyFormId}
                                        fetchForm={fetchForm}
                                        fetchFormSubmission={fetchFormSubmission}
                                        submitFormSubmission={this.onSubmit}
                                        program={program}
                                        patient={patient}
                                    />
                                )}
                            </>
                        }
                    />
                </PageContainer>
            );
        }

        return (
            <PageContainer>
                <StyledPortlet
                    body={
                        <>
                            <CloseButton to='/screener'><CloseIcon /></CloseButton>
                            <Greeting variant='h4'>Thank you, {patient.firstName} {patient.lastName}!</Greeting>
                        </>
                    }
                />
            </PageContainer>
        );
    }
};

internals.PageContainer = Styled.div`
    width: 100%;
    margin: auto;

    ${({ theme }) => theme.breakpoints.up('sm')} {
        max-width: 70vw;
    }
`;

internals.StyledPortlet = Styled(Portlet)`
    padding: 8vw 10vw;
`;

internals.Greeting = Styled.div`
    margin-bottom: ${({ theme }) => theme.spacing(6)}px;
    font-size: ${({ theme }) => theme.spacing(5)}px;
    line-height: 1;

    ${({ theme }) => theme.breakpoints.up('md')} {
        font-size: 3vw;
    }
`;

internals.CloseButton = Styled(NavLink)`
    position: absolute;
    top: ${({ theme }) => theme.spacing(2)}px;
    right: ${({ theme }) => theme.spacing(2)}px;

    svg {
        width: ${({ theme }) => theme.spacing(6)}px;
        height: ${({ theme }) => theme.spacing(6)}px;
    }
`;
