const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: FormControl } = require('@material-ui/core/FormControl');
const { default: FormLabel } = require('@material-ui/core/FormLabel');
const { default: FormGroup } = require('@material-ui/core/FormGroup');
const { default: FormControlLabel } = require('@material-ui/core/FormControlLabel');
const { default: Checkbox } = require('@material-ui/core/Checkbox');
const StrangeForms = require('strange-forms');
const SectionLabel = require('./SectionLabel');
const CustomStepper = require('./CustomStepper');
const ClientSelect = require('./ClientSelect');
const FormSelect = require('./FormSelect');
const DateMaskInput = require('./DateMaskInput');

const internals = {};

module.exports = class ProgramFields extends StrangeForms(React.Component) {

    static propTypes = {
        value: T.object,
        program: T.object,
        clients: T.array,
        users: T.array,
        onChange: T.func.isRequired
    };

    static fields = {
        name: '',
        clientId: '',
        intakeFormId: '',
        surveyFormId: '',
        useEligibilityFile: true,
        isCovidTesting: false,
        ageRequirementDate: null,
        users: []
    };

    constructor(props) {

        super(props);

        this.state = {
            isSubmitting: false,
            activeStep: 0,
            dialogName: null
        };

        this.strangeForm({
            fields: Object.keys(ProgramFields.fields),
            get: {
                '*': (someProps, field) => (someProps.program && someProps.program[field]) || '',
                clientId: (someProps) => someProps.program && someProps.program.client && someProps.program.client.id || '',
                users: (someProps) => (someProps.program && someProps.program.users) || [],
                useEligibilityFile: (someProps) => (!someProps.program ? null : someProps.program.useEligibilityFile),
                isCovidTesting: (someProps) => (!someProps.program ? null : someProps.program.isCovidTesting)
            },
            act: (field, value) => this.props.onChange({ ...this.props.value, [field]: value }),
            getFormValue: {
                users: (user) => {

                    const users = this.fieldValue('users');

                    if (users.some(({ id }) => id === user.id)) {
                        // Perform "uncheck" action
                        // User is in the list and needs to be removed
                        return users.filter(({ id }) => id !== user.id);
                    }

                    return [...users, user];
                },
                '*': (ev) => ev.target.value,
                useEligibilityFile: (ev) => ev.target.checked,
                isCovidTesting: (ev) => ev.target.checked
            }
        });
    }

    render() {

        const { StyledForm } = internals;
        const { activeStep, goToStep, activeClients, activeUsers, forms } = this.props;

        return (
            <StyledForm>
                <CustomStepper
                    activeStep={activeStep}
                    goToStep={goToStep}
                    allowArbitraryNavigation
                >
                    <CustomStepper.Step title='Enter Details'>
                        {() => {

                            return (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <CustomStepper.StepTitle>Enter your program details</CustomStepper.StepTitle>
                                    <SectionLabel>Program Name</SectionLabel>
                                    <TextField
                                        required
                                        label='Program Name'
                                        helperText='This will be patient-facing'
                                        value={this.fieldValue('name')}
                                        onChange={this.proposeNew('name')}
                                    />
                                    <SectionLabel>Assign Client</SectionLabel>
                                    <ClientSelect
                                        clients={activeClients}
                                        value={this.fieldValue('clientId')}
                                        onChange={this.proposeNew('clientId')}
                                    />
                                    <SectionLabel>Assign Intake Form</SectionLabel>
                                    <FormSelect
                                        forms={forms}
                                        value={this.fieldValue('intakeFormId')}
                                        onChange={this.proposeNew('intakeFormId')}
                                    />
                                    <SectionLabel>Assign Survey Form</SectionLabel>
                                    <FormSelect
                                        forms={forms}
                                        value={this.fieldValue('surveyFormId')}
                                        onChange={this.proposeNew('surveyFormId')}
                                    />
                                    <SectionLabel>Age Requirement</SectionLabel>
                                    <TextField
                                        label='Date'
                                        helperText='Patients born after this date will be ineligible to participate'
                                        value={this.fieldValue('ageRequirementDate')}
                                        onChange={this.proposeNew('ageRequirementDate')}
                                        InputProps={{
                                            inputComponent: DateMaskInput
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.fieldValue('useEligibilityFile') !== false}
                                                onChange={this.proposeNew('useEligibilityFile')}
                                            />
                                        }
                                        label='Use Eligibility File'
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={!!this.fieldValue('isCovidTesting')}
                                                onChange={this.proposeNew('isCovidTesting')}
                                            />
                                        }
                                        label='Is COVID Testing'
                                    />
                                </div>
                            );
                        }}
                    </CustomStepper.Step>
                    <CustomStepper.Step title='Assign Admins'>
                        {() => {

                            return (
                                <>
                                    <CustomStepper.StepTitle>Assign program admins</CustomStepper.StepTitle>
                                    <FormControl component='fieldset'>
                                        <FormLabel component='legend' hidden label='assign admins' />
                                        <FormGroup>
                                            {
                                                activeUsers && activeUsers.filter(({ role }) => role === 'admin').map((user) => {

                                                    return (
                                                        <FormControlLabel
                                                            key={user.id}
                                                            control={
                                                                <Checkbox
                                                                    value={user}
                                                                    checked={this.fieldValue('users').some(({ id }) => id === user.id)}
                                                                    onChange={() => this.proposeNew('users')(user)}
                                                                />
                                                            }
                                                            label={user.name}
                                                        />
                                                    );
                                                })
                                            }
                                        </FormGroup>
                                    </FormControl>
                                </>
                            );
                        }}
                    </CustomStepper.Step>
                    <CustomStepper.Step title='Assign Screeners'>
                        {() => {

                            return (
                                <>
                                    <CustomStepper.StepTitle>Assign program screeners</CustomStepper.StepTitle>
                                    <FormControl component='fieldset'>
                                        <FormLabel component='legend' hidden label='assign screeners' />
                                        <FormGroup>
                                            {
                                                activeUsers && activeUsers.filter(({ role }) => role === 'screener').map((user) => {

                                                    return (
                                                        <FormControlLabel
                                                            key={user.id}
                                                            control={
                                                                <Checkbox
                                                                    value={user}
                                                                    checked={this.fieldValue('users').some(({ id }) => id === user.id)}
                                                                    onChange={() => this.proposeNew('users')(user)}
                                                                />
                                                            }
                                                            label={user.name}
                                                        />
                                                    );
                                                })
                                            }
                                        </FormGroup>
                                    </FormControl>
                                </>
                            );
                        }}
                    </CustomStepper.Step>
                    <CustomStepper.Step title='Assign Managers'>
                        {() => {

                            return (
                                <>
                                    <CustomStepper.StepTitle>Assign program managers</CustomStepper.StepTitle>
                                    <FormControl component='fieldset'>
                                        <FormLabel component='legend' hidden label='assign managers' />
                                        <FormGroup>
                                            {
                                                activeUsers && activeUsers.filter(({ role }) => role === 'manager').map((user) => {

                                                    return (
                                                        <FormControlLabel
                                                            key={user.id}
                                                            control={
                                                                <Checkbox
                                                                    value={user}
                                                                    checked={this.fieldValue('users').some(({ id }) => id === user.id)}
                                                                    onChange={() => this.proposeNew('users')(user)}
                                                                />
                                                            }
                                                            label={user.name}
                                                        />
                                                    );
                                                })
                                            }
                                        </FormGroup>
                                    </FormControl>
                                </>
                            );
                        }}
                    </CustomStepper.Step>
                    <CustomStepper.Step title='Final Review'>
                        {() => {

                            const clientObj = activeClients.find(({ id }) => id === this.fieldValue('clientId')) || {};
                            const clientName = clientObj.name;

                            return (
                                <>
                                    <CustomStepper.StepTitle>Final review</CustomStepper.StepTitle>
                                    <SectionLabel>Program Name</SectionLabel>
                                    <Typography>{this.fieldValue('name')}</Typography>
                                    <SectionLabel>Assigned Client</SectionLabel>
                                    <Typography>{clientName}</Typography>
                                    <SectionLabel>Assigned Admins</SectionLabel>
                                    {this.fieldValue('users').filter(({ role }) => role === 'admin').map(({ id, name }) => <Typography key={id}>{name}</Typography>)}
                                    <SectionLabel>Assigned Screeners</SectionLabel>
                                    {this.fieldValue('users').filter(({ role }) => role === 'screener').map(({ id, name }) => <Typography key={id}>{name}</Typography>)}
                                    <SectionLabel>Assigned Managers</SectionLabel>
                                    {this.fieldValue('users').filter(({ role }) => role === 'manager').map(({ id, name }) => <Typography key={id}>{name}</Typography>)}
                                </>
                            );
                        }}
                    </CustomStepper.Step>
                </CustomStepper>
            </StyledForm>
        );
    }
};

internals.StyledForm = Styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

module.exports.validateStep = (step, programData) => {

    const { name, clientId } = programData;

    if (step === 0) {
        return !!name && !!clientId;
    }

    return true;
};
