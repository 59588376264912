const MiddleEnd = require('strange-middle-end');
const {
    LOGIN,
    LOGOUT,
    FETCH_CURRENT_USER,
    SEND_FORGOT_PASSWORD,
    SEND_RESET_PASSWORD
} = require('./action-types');
const WebClient = require('../../utils/web-client');
const M = require('../');

exports.fetchCurrentUser = MiddleEnd.createAction(FETCH_CURRENT_USER, {
    index: true,
    handler: async () => {

        const { data: results } = await WebClient.get(`/validate`);

        return results.data.user;
    }
});

exports.login = MiddleEnd.createAction(LOGIN, {
    handler: async ({ email, password } = {}) => {

        const { data: results } = await WebClient.post('/login', { username: email, password });

        if (results.data.accessToken) {
            localStorage.access_token = results.data.accessToken;
        }

        await M.dispatch.auth.fetchCurrentUser();
    }
});

exports.sendForgotPassword = MiddleEnd.createAction(SEND_FORGOT_PASSWORD, {
    index: true,
    handler: async (username) => {

        await WebClient.post(`/auth/forgot`, { username });

        return true;
    }
});

exports.sendResetPassword = MiddleEnd.createAction(SEND_RESET_PASSWORD, {
    index: true,
    handler: async ({ hash, token, password }) => {

        await WebClient.post(`/auth/reset`, { hash, token, password });

        return true;
    }
});

exports.logout = MiddleEnd.createAction(LOGOUT, {
    index: FETCH_CURRENT_USER.BASE,
    handler: async () => {

        try {
            await WebClient.logout();
            return null;
        }
        catch (err) {
            if (err.message.includes('401')) {
                return null;
            }

            throw err;
        }
    }
});
