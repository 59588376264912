const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: Button } = require('@material-ui/core/Button');
const { default: Link } = require('@material-ui/core/Link');
const SectionLabel = require('../../../components/SectionLabel');

const internals = {};

module.exports = class FileUpload extends React.Component {

    static propTypes = {
        label: T.string,
        currentFileUrl: T.string,
        onSubmit: T.func.isRequired
    };

    constructor(props) {

        super(props);

        this.state = {
            file: null,
            isSubmitting: false
        };
    }

    shouldDisableSubmit() {

        return !this.state.file || this.state.isSubmitting;
    }

    handleChange = (ev) => {

        const file = ev.target.files.item(0);

        this.setState({ file });
    }

    handleSubmit = async (ev) => {

        ev.preventDefault();

        this.setState({ isSubmitting: true });

        await this.props.onSubmit(this.state.file);

        if (this.fileInputElement) {
            this.fileInputElement.value = '';
        }

        this.setState({ isSubmitting: false, file: null });
    }

    render() {

        const { StyledForm, FileLink, ButtonWrapper } = internals;
        const { label, currentFileUrl } = this.props;

        return (

            <StyledForm>
                <SectionLabel>{label}</SectionLabel>
                <input
                    ref={(el) => {

                        this.fileInputElement = el;
                    }}
                    accept='*/*'
                    type='file'
                    onChange={this.handleChange}
                />
                <ButtonWrapper>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={this.handleSubmit}
                        disabled={this.shouldDisableSubmit()}
                    >
                        Upload File
                    </Button>
                    {currentFileUrl && <FileLink href={currentFileUrl}>View File</FileLink>}
                </ButtonWrapper>
            </StyledForm>
        );
    }
};

internals.FileLink = Styled(Link).attrs({ target: '_blank', rel: 'noopener noreferrer' })`
    align-self: center;
`;

internals.ButtonWrapper = Styled.div`
    display: flex;

    button {
        margin-left: 0;
    }
`;

internals.StyledForm = Styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
`;
