const MiddleEnd = require('strange-middle-end');
const Immer = require('immer');

const Schema = require('./schema');

const {
    FETCH_PROGRAMS,
    FETCH_CLIENTS,
    FETCH_SCREENING_CATEGORIES,
    FETCH_SCREENING_METRICS,
    FETCH_USERS
} = require('./action-types');

const {
    SELECT_PROGRAM
} = require('../app/action-types');

const internals = {};

module.exports = (prevState, action) => {

    const { type } = action;

    const state = internals.reducer(prevState, action);

    // A util used for clearing data when switching programs
    // Also note: SELECT_PROGRAM is part of the 'app' middle-end
    // mod — we're watching for it across mods so we can clear
    // things in the 'model' mod from an action fired in the 'app' mod.
    if (type === SELECT_PROGRAM) {
        return Immer.produce(state, (draft) => {

            const INDEXES_TO_KEEP = [
                FETCH_PROGRAMS.BASE,
                FETCH_CLIENTS.BASE,
                FETCH_SCREENING_CATEGORIES.BASE,
                FETCH_SCREENING_METRICS.BASE,
                FETCH_USERS.BASE
            ];

            Object.keys(draft.indexes).forEach((name) => {

                if (INDEXES_TO_KEEP.includes(name)) {
                    return;
                }

                delete draft.indexes[name];
            });
        });
    }

    return state;
};

internals.reducer = MiddleEnd.createEntityReducer({
    schema: Schema,
    shouldIndex: (indexName) => indexName.startsWith('model/'),
    shouldMerge: true
});
