const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: TextField } = require('@material-ui/core/TextField');
const DateMaskInput = require('../DateMaskInput');

const internals = {};

module.exports = function DateField({ field, value, onChange, error }) {

    const { Error } = internals;

    return (
        <>
            <TextField
                fullWidth
                label={field.name}
                type='text'
                value={value}
                placeholder='mm/dd/yyy'
                onChange={(e) => onChange(e.target.value)}
                InputProps={{
                    inputComponent: DateMaskInput
                }}
                InputLabelProps={{
                    shrink: true
                }}
                required={field.settings.isRequired}
            />
            <Error>{error}</Error>
        </>
    );
};

module.exports.propTypes = {
    field: T.object.isRequired,
    onChange: T.func.isRequired,
    value: T.string,
    error: T.string
};

module.exports.defaultProps = {
    value: ''
};

internals.Error = Styled.div`
    color: ${({ theme }) => theme.palette.error.main};
`;
