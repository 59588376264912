const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: FormControlLabel } = require('@material-ui/core/FormControlLabel');
const { default: RadioGroup } = require('@material-ui/core/RadioGroup');
const { default: Radio } = require('@material-ui/core/Radio');

const internals = {};

module.exports = function RadioField({ field, value, onChange, error }) {

    const { Error } = internals;

    return (
        <>
            <p style={{ marginTop: 0 }}><strong>{field.name}{field.settings.isRequired && '*'}</strong></p>
            <RadioGroup
                aria-label={field.name}
                name={field.name}
                value={value}
                onChange={(e) => onChange(e.target.value)}
            >
                {field.choices.map((choice) => {

                    return <FormControlLabel key={choice} value={choice} control={<Radio />} label={choice} />;
                })}
            </RadioGroup>
            <Error>{error}</Error>
        </>
    );
};

module.exports.propTypes = {
    field: T.object.isRequired,
    onChange: T.func.isRequired,
    value: T.string,
    error: T.string
};

module.exports.defaultProps = {
    value: ''
};

internals.Error = Styled.div`
    color: ${({ theme }) => theme.palette.error.main};
`;
