const Connect = require('react-redux').connect;
const CreateProgramPage = require('../components/CreateProgramPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        clients: M.selectors.model.fetchClients(state),
        users: M.selectors.model.fetchUsers(state),
        forms: M.selectors.model.fetchForms(state)
    }),
    (dispatch, props) => ({
        fetchData: async () => {

            await Promise.all([
                M.dispatch.model.fetchUsers(),
                M.dispatch.model.fetchClients(),
                M.dispatch.model.fetchForms()
            ]);
        },
        onSubmit: async (programInfo) => {

            const [err, result] = await M.dispatch.model.createProgram(programInfo);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Program created successfully');
                M.dispatch.router.push(`/programs/search`);

                // If there is a successful result, set as new active program
                if (result && result.id) {

                    M.dispatch.app.selectProgram(result.id);
                }
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }
        }
    })
);

module.exports = internals.connect(CreateProgramPage);
