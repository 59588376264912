const React = require('react');
const ReactDOM = require('react-dom');
const T = require('prop-types');
const { default: FormControl } = require('@material-ui/core/FormControl');
const { default: InputLabel } = require('@material-ui/core/InputLabel');
const { default: OutlinedInput } = require('@material-ui/core/OutlinedInput');
const { default: Select } = require('@material-ui/core/Select');

const internals = {};

module.exports = class StateSelect extends React.Component {

    static propTypes = {
        value: T.string,
        label: T.string,
        onChange: T.func,
        className: T.string,
        // Normally we would use isRequired, but "required" matches material-ui's convention for inputs
        required: T.bool,
        fullWidth: T.bool,
        error: T.node,
        // Allow an optional array of state abbreviations to only show those states in the dropdown
        stateSubset: T.arrayOf(T.string)
    };

    static defaultProps = {
        label: 'State',
        stateSubset: null,
        fullWidth: true
    }

    state = {
        labelWidth: 0
    };

    componentDidMount() {

        this.setState({
            labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
        });
    }

    render() {

        const { label, error, required, stateSubset, fullWidth, ...props } = this.props;

        const states = stateSubset || internals.states;

        return (
            <FormControl variant='outlined' margin='dense' fullWidth={fullWidth} required={required} className={this.props.className}>
                <InputLabel
                    error={error}
                    htmlFor='state'
                    ref={(ref) => {

                        this.InputLabelRef = ref;
                    }}
                >
                    {label}
                </InputLabel>
                <Select
                    native
                    {...props}
                    error={error}
                    input={
                        <OutlinedInput
                            name='state'
                            labelWidth={this.state.labelWidth}
                            fullWidth={fullWidth}
                        />
                    }
                    inputProps={{
                        name: 'state',
                        id: 'state'
                    }}
                >
                    <option value='' />
                    {
                        states.map((state) => <option key={state} value={state}>{state}</option>)
                    }
                </Select>
            </FormControl>
        );
    }
};

internals.states = [
    'AK',
    'AL',
    'AR',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DC',
    'DE',
    'FL',
    'GA',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'MD',
    'ME',
    'MI',
    'MN',
    'MO',
    'MS',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VA',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY'
];
