const React = require('react');
const T = require('prop-types');
const { default: Styled, ...S } = require('styled-components');
const { default: ErrorBoundary } = require('react-error-boundary');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: Toolbar } = require('@material-ui/core/Toolbar');
const { default: AppBar } = require('@material-ui/core/AppBar');
const ErrorFallback = require('./ErrorFallback');
const LoadingFallback = require('./LoadingFallback');
const Header = require('./Header');

const internals = {};

module.exports = class ManagerLayout extends React.Component {

    static propTypes = {
        children: T.any,
        isAuthenticated: T.bool,
        logout: T.func.isRequired,
        location: T.shape({
            key: T.string
        })
    };

    componentDidMount() {

        window.scrollTo(0, 0);
    }

    render() {

        const { Container, AppContainer, LayoutContainer, StyledAppBar, StyledToolbar } = internals;
        const { children, location, isAuthenticated, logout } = this.props;

        return (
            <LayoutContainer>
                <AppContainer>
                    <StyledAppBar>
                        <Header
                            hideMenuIcon
                            isAuthenticated={isAuthenticated}
                            logout={logout}
                        />
                    </StyledAppBar>
                    <Container>
                        <ErrorBoundary key={location.key} FallbackComponent={ErrorFallback}>
                            <React.Suspense fallback={<LoadingFallback />}>
                                {children}
                            </React.Suspense>
                        </ErrorBoundary>
                    </Container>
                    <StyledAppBar>
                        <StyledToolbar variant='dense'>
                            <Typography variant='body2' align='center'>©{new Date().getFullYear()} Promerica Health – All Rights Reserved</Typography>
                        </StyledToolbar>
                    </StyledAppBar>
                </AppContainer>
            </LayoutContainer>
        );
    }
};

internals.StyledToolbar = Styled(Toolbar)`
    margin: auto;
`;

internals.LayoutContainer = Styled.div`
    display: flex;
`;

internals.StyledAppBar = Styled(AppBar).attrs({ position: 'static', elevation: 0 })`
    ${(props) => {

        return props.brandColor && S.css`
            background-color: ${props.brandColor};
        `;
    }}`;

internals.Container = Styled.div`
    display: flex;
    padding-top:10px;
    background-color: ${({ theme }) => theme.palette.background.paleGray};

    // Alternatively,the Toolbar component in <Header /> could be given
    // a disableGutters prop, and the left/right padding could be applied
    // to the entire AppContainer.
    padding-left: ${({ theme }) => theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
    flex: 1;
    @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
        padding-left: ${(props) => props.theme.spacing(3)}px;
        padding-right: ${(props) => props.theme.spacing(3)}px;
    }
`;

internals.AppContainer = Styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;
