const MiddleEnd = require('strange-middle-end');
const {
    PUSH_TO_QUEUE,
    SHIFT_QUEUE
} = require('./action-types');

const internals = {};

module.exports = MiddleEnd.createReducer({ mutable: true }, { queue: [] }, {
    [PUSH_TO_QUEUE]: (state, { payload }) => {

        state.queue.push(payload);
    },
    [SHIFT_QUEUE]: (state, { payload }) => {

        state.queue.shift();
    }
});
