const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: Button } = require('@material-ui/core/Button');
const { default: FormControl } = require('@material-ui/core/FormControl');
const { default: FormLabel } = require('@material-ui/core/FormLabel');
const { default: FormGroup } = require('@material-ui/core/FormGroup');
const { default: FormControlLabel } = require('@material-ui/core/FormControlLabel');
const { default: Checkbox } = require('@material-ui/core/Checkbox');
const StrangeForms = require('strange-forms');
const Portlet = require('../../../components/Portlet');
const SectionLabel = require('../../../components/SectionLabel');

const internals = {};

const emptyMetric = {
    name: 'New Metric',
    formula: null,
    fields: [
        {
            name: 'New Metric',
            type: 'text',
            choices: []
        }
    ],
    referenceRanges: [],
    template: '[@]'
};

module.exports = class SetupScreeningMetricsPage extends StrangeForms(React.Component) {

    static propTypes = {
        fetchData: T.func.isRequired,
        program: T.object,
        screeningMetrics: T.array,
        screeningCategories: T.array,
        onSubmit: T.func.isRequired
    };

    constructor(props) {

        super(props);

        this.state = {
            isSubmitting: false,
            activeMetric: '',
            updatedMetric: null
        };

        this.strangeForm({
            fields: ['screeningMetrics'],
            get: (someProps, field) => someProps.program && someProps.program[field] || [],
            act: () => null,
            getFormValue: (metric) => {

                const screeningMetrics = this.fieldValue('screeningMetrics');

                if (screeningMetrics.some(({ id }) => id === metric.id)) {
                    // Perform "uncheck" action
                    // Metric is in the list and needs to be removed
                    return screeningMetrics.filter(({ id }) => id !== metric.id);
                }

                return [...screeningMetrics, metric];
            }
        });
    }

    componentDidMount() {

        this.props.fetchData();
    }

    shouldDisableSubmit() {

        return false;
    }

    handleSubmit = async (ev) => {

        ev.preventDefault();
        this.setState({ isSubmitting: true });

        await this.props.onSubmit(
            {
                id: this.props.program.id,
                screeningMetrics: this.fieldValue('screeningMetrics').map(({ id }) => ({ id }))
            }
        );
        this.setState({ isSubmitting: false });
    }

    handleFormChange = (e) => this.setState({ updatedMetric: e.updated_src });

    handleNew = (e) => {

        e.preventDefault();
        this.setState({ updatedMetric: { ...emptyMetric, categoryId: this.props.screeningCategories[0].id } }, async () => {

            await this.handleSave(e);
        });
    }

    handleSave = async (e) => {

        e.preventDefault();
        this.setState({ isSubmitting: true });
        await this.props.onSubmitEditMetric(this.state.updatedMetric);
        await this.props.fetchData();

        this.setState({ activeMetric: null, isSubmitting: false });
    }

    handleToggleEdit = (e) => {

        e.preventDefault();
        if (e.currentTarget.value === this.state.activeMetric) {

            return this.setState({ activeMetric: null });
        }

        this.setState({ activeMetric: e.currentTarget.value });
    }

    render() {

        const { PageContainer, StyledButton, ForwardedLink, ButtonWrapper, StyledForm } = internals;
        const { doesProgramExist, isLoading, program, screeningMetrics: metricList, screeningCategories } = this.props;
        const { name = '', client = {} } = program || {};
        const { logoUrl, name: clientName } = client;

        if (isLoading) {
            return null;
        }

        return (
            <PageContainer>
                {doesProgramExist &&
                <Portlet
                    title={`Program: ${name}`}
                    toolbar={logoUrl && <Portlet.LogoImg src={logoUrl} alt={`${clientName} logo`} />}
                    body={
                        <StyledForm>
                            <SectionLabel>Screening Metrics</SectionLabel>
                            <FormControl component='fieldset'>
                                {
                                    screeningCategories.map((category) => {

                                        return (
                                            <React.Fragment key={category.id}>
                                                <SectionLabel>{category.name}</SectionLabel>
                                                <FormLabel component='legend' hidden label={`Assign ${category.name} screening metrics`} />
                                                <FormGroup>
                                                    {
                                                        metricList.filter((metric) => metric.category.id === category.id).map((metric) => {

                                                            return (
                                                                <>
                                                                    <ButtonWrapper key={metric.id}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    value={metric}
                                                                                    checked={this.fieldValue('screeningMetrics').some(({ id }) => id === metric.id)}
                                                                                    onChange={() => this.proposeNew('screeningMetrics')(metric)}
                                                                                />
                                                                            }
                                                                            label={metric.name}
                                                                        />
                                                                    </ButtonWrapper>
                                                                </>
                                                            );
                                                        })
                                                    }
                                                </FormGroup>
                                            </React.Fragment>
                                        );
                                    })
                                }
                            </FormControl>
                        </StyledForm>
                    }
                    foot={
                        <ButtonWrapper>
                            <StyledButton
                                onClick={this.handleSubmit}
                                variant='contained'
                                color='primary'
                                disabled={this.shouldDisableSubmit()}
                            >
                                Save
                            </StyledButton>
                            <StyledButton
                                component={ForwardedLink}
                                to='/'
                                variant='text'
                            >
                                Cancel
                            </StyledButton>
                        </ButtonWrapper>
                    }
                />}
                {!doesProgramExist &&
                    <Portlet
                        title='Program Not Found'
                        body={
                            <StyledButton
                                component={ForwardedLink}
                                to='/programs/search'
                                variant='text'
                            >
                                Search Programs
                            </StyledButton>
                        }
                    />}
            </PageContainer>
        );
    }
};

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.StyledButton = Styled(Button)`
    margin: 0 8px;
`;

internals.ButtonWrapper = Styled.div`
    display: flex;
`;

internals.StyledForm = Styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
