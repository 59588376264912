const M = require('../');
const Constants = require('../../utils/constants');

exports.getCurrentProgramId = (state) => {

    const user = M.selectors.auth.getUser(state);
    const role = M.selectors.auth.getUserRole(state);
    const isScreener = role === Constants.ROLE.SCREENER;
    const isAuthenticated = M.selectors.auth.getIsAuthenticated(state);

    if (isScreener && !user.programs.length) {
        throw new Error('Screener has no programs assigned');
    }

    return isAuthenticated ?
        state.app.currentProgramId ||
        (isScreener ? user.programs[0].id : null) :
        null;
};

exports.getSelectedEvents = (state) => state.app.selectedEvents || [];
