const Connect = require('react-redux').connect;
const RegistrationFormPage = require('../components/RegistrationFormPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        program: M.selectors.model.fetchProgram(state),
        patient: M.selectors.model.fetchPatient(state),
        form: M.selectors.model.fetchForm(state)

    }),
    (dispatch, props) => ({
        fetchData: async () => {

            return await Promise.all([
                M.dispatch.model.fetchProgram(props.match.params.id),
                M.dispatch.model.fetchPatient(props.match.params.patientId)
            ]);
        },
        fetchForm: (id) => M.dispatch.model.fetchForm(id),
        fetchFormSubmission: M.dispatch.model.fetchFormSubmission,
        submitFormSubmission: async (submission) => {

            const [err, res] = await M.dispatch.model.upsertFormSubmission(submission);

            if (err) {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err, res];
        }
    })
);

module.exports = internals.connect(RegistrationFormPage);
