const React = require('react');
const T = require('prop-types');
const { default: Divider } = require('@material-ui/core/Divider');
const { default: Styled } = require('styled-components');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: Button } = require('@material-ui/core/Button');
const StrangeForms = require('strange-forms');
const Portlet = require('../../../components/Portlet');
const SectionLabel = require('../../../components/SectionLabel');
const DateMaskInput = require('../../../components/DateMaskInput');
const { default: Alert } = require('@material-ui/lab/Alert');

const internals = {};

module.exports = class PatientPortalPage extends StrangeForms(React.Component) {

    static propTypes = {
        searchResults: T.array,
        fetchData: T.func,
        searchPatients: T.func,
        history: T.object,
        buildRegistrationLink: T.func,
        buildScheduleLink: T.func
    };

    static fields = {
        dob: '',
        identifier: ''
    };

    constructor(props) {

        super(props);

        this.state = {
            hasSearched: false,
            isSubmitting: false
        };

        this.strangeForm({
            fields: Object.keys(PatientPortalPage.fields),
            get: () => '',
            act: () => null
        });
    }

    componentDidMount() {

        this.props.fetchData();
    }

    handleSearch = async (ev) => {

        ev.preventDefault();
        const identifier = this.fieldValue('identifier');
        const dob = this.fieldValue('dob');
        this.setState({ isSubmitting: true });
        const [err, result] = await this.props.searchPatients({
            clientId: this.props.program.client && this.props.program.client.id,
            identifier,
            dob
        });

        const {
            buildRegistrationLink,
            buildScheduleLink
        } = this.props;

        if (!err && result && this.props.program.intakeFormId) {
            return this.props.history.push(
                buildRegistrationLink({
                    programId: this.props.program.id,
                    patientId: this.props.searchResults[0].id
                })
            );
        }

        if (!err && result) {
            // Exactly one result — redirect to schedule page
            if (this.props.searchResults.length === 1) {
                return this.props.history.push(
                    buildScheduleLink({
                        programId: this.props.program.id,
                        patientId: this.props.searchResults[0].id
                    })
                );
            }
        }

        this.setState({ hasSearched: true, isSubmitting: false });
    }

    shouldDisableSubmit() {

        return (
            !this.fieldValue('identifier') || !this.fieldValue('dob') ||
            this.state.isSubmitting
        );
    }

    render() {

        const { PageContainer, FieldsWrapper, StyledPortlet, PortletBodyContainer, StyledAlert, PortalCopy } = internals;
        const { searchResults } = this.props;

        return (
            <PageContainer>
                <StyledPortlet
                    body={
                        <>
                            <SectionLabel>Schedule Your Appointment</SectionLabel>
                            {this.props.program && this.props.program.portalLandingCopy && <PortalCopy dangerouslySetInnerHTML={{ __html: this.props.program.portalLandingCopy }} />}
                        </>
                    }
                />
                <StyledPortlet
                    body={
                        <PortletBodyContainer>
                            <SectionLabel>Enter Your Information</SectionLabel>
                            <FieldsWrapper onSubmit={this.handleSearch}>
                                <TextField
                                    label='Employee ID'
                                    value={this.fieldValue('identifier')}
                                    onChange={this.proposeNew('identifier')}
                                    margin='dense'
                                />
                                <TextField
                                    label='Date of Birth'
                                    value={this.fieldValue('dob')}
                                    onChange={this.proposeNew('dob')}
                                    margin='dense'
                                    InputProps={{
                                        inputComponent: DateMaskInput
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                <Button
                                    type='submit'
                                    color='primary'
                                    variant='contained'
                                    disabled={this.shouldDisableSubmit()}
                                >
                                    Continue
                                </Button>
                            </FieldsWrapper>
                            {
                                searchResults && !searchResults.length && this.state.hasSearched &&
                                <>
                                    <SectionLabel>We're sorry</SectionLabel>
                                    {(this.props.program && this.props.program.portalEligibilityErrorMessage) ?
                                        <PortalCopy dangerouslySetInnerHTML={{ __html: this.props.program.portalEligibilityErrorMessage }} /> :
                                        <Typography gutterBottom>We could not find you in the system. Please contact your HR Program Manager for further assistance.</Typography>}
                                </>
                            }
                            <StyledAlert severity='info'>Note: To cancel or reschedule, enter your information</StyledAlert>
                        </PortletBodyContainer>
                    }
                />
            </PageContainer>
        );
    }
};

internals.PortalCopy = Styled.div`
    p {
        line-height: 1.5;
    }
`;

internals.PortletBodyContainer = Styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

internals.StyledAlert = Styled(Alert)`
    margin-top: auto;
`;

internals.StyledDivider = Styled(Divider)`
    margin: ${({ theme }) => theme.spacing(2)}px 0;
`;

internals.PageContainer = Styled.div`
    display: flex;
    max-width: ${({ theme }) => theme.spacing(150)}px;
    margin: 0 auto;

    flex-direction: column;

    ${({ theme }) => theme.breakpoints.up('sm')} {
        align-items: flex-start;
        flex-direction: row;
    }

`;

internals.StyledPortlet = Styled(Portlet)`

    ${({ theme }) => theme.breakpoints.up('sm')} {
        flex: 1
    }
    margin: ${({ theme }) => theme.spacing(1)}px;
`;

internals.FieldsWrapper = Styled.form`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;

    // Select all but the last two (final text field and submit button)
    > :not(:nth-last-child(-n + 2)) {
        margin-right: ${({ theme }) => theme.spacing(1)}px;
    }
`;
