module.exports = (date, options = { padDate: false }) => {

    const { padDate } = options;

    // If the date isn't a date object, return whatever was passed in
    if (!(date instanceof Date)) {
        return date;
    }

    const month = padDate ?
        (date.getMonth() + 1).toString().padStart(2, '0') :
        date.getMonth() + 1;
    const day = padDate ?
        date.getDate().toString().padStart(2, '0') :
        date.getDate();
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
};
