exports.ROLE = {
    SUPERADMIN: 'superadmin',
    ADMIN: 'admin',
    MANAGER: 'manager',
    SCREENER: 'screener'
};

exports.LANDING_PAGES = {
    [exports.ROLE.SUPERADMIN]: '/',
    [exports.ROLE.ADMIN]: '/',
    [exports.ROLE.MANAGER]: '/manager',
    [exports.ROLE.SCREENER]: '/screener'
};

exports.ENVIRONMENT = process.env.NODE_ENV || 'production';
