const Connect = require('react-redux').connect;
const SetupPatientSignupPage = require('../components/SetupPatientSignupPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        program: M.selectors.model.fetchProgram(state),
        doesProgramExist: !M.selectors.model.fetchProgramNotFound(state)
    }),
    (dispatch, props) => ({
        fetchProgram: () => M.dispatch.model.fetchProgram(props.match.params.id),
        onSubmit: async (messageInfo) => {

            const [err] = await M.dispatch.model.editProgram(messageInfo);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Messaging updated successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err];
        }
    })
);

module.exports = internals.connect(SetupPatientSignupPage);
