const { schema: { Entity } } = require('normalizr');

const internals = {};

exports.client = new Entity('clients');

exports.user = new Entity('users');

exports.program = new Entity('programs');

exports.patient = new Entity('patients');

exports.screening = new Entity('screenings');

exports.event = new Entity('events');

exports.appointment = new Entity('appointments');

exports.form = new Entity('forms');

exports.program.relations = ({ user }) => ({ users: [user] });
