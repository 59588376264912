const Connect = require('react-redux').connect;
const EditClientPage = require('../components/EditClientPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        client: M.selectors.model.fetchClient(state)
    }),
    (dispatch, props) => ({

        fetchClient: () => M.dispatch.model.fetchClient(props.match.params.id),
        onConfirmDelete: async () => {

            const [err] = await M.dispatch.model.deleteClient(props.match.params.id);
            if (!err) {
                M.dispatch.snackbar.pushToQueue('Client deleted successfully');
                M.dispatch.router.push(`/`);
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }
        },
        onSubmit: async (clientInfo) => {

            const [err] = await M.dispatch.model.editClient(clientInfo);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Client updated successfully');
                M.dispatch.router.push('/clients/search');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }
        },
        uploadLogo: async (file) => {

            const [err, result] = await M.dispatch.model.uploadClientLogo(file);
            if (!err) {
                M.dispatch.snackbar.pushToQueue('Logo uploaded successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err, result];
        }
    })
);

module.exports = internals.connect(EditClientPage);
