const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: Stepper } = require('@material-ui/core/Stepper');
const { default: Step } = require('@material-ui/core/Step');
const { default: StepContent } = require('@material-ui/core/StepContent');
const { default: StepLabel } = require('@material-ui/core/StepLabel');
const Theme = require('../theme');

const internals = {};

module.exports = class CustomStepper extends React.Component {

    static propTypes = {
        steps: T.arrayOf(T.string),
        getStepContent: T.func,
        allowArbitraryNavigation: T.bool,
        activeStep: T.number.isRequired,
        goToStep: T.func,
        children: T.node
    };

    constructor(props) {

        super(props);

        this.state = {};
    }

    componentDidMount() {

        this.checkWidth = () => {

            // Switch to vertical stepper layout at 960px
            // Normally we could use theme.breakpoints.down('md'), but that gives us the "@media" too
            const { matches } = window.matchMedia(`(max-width: ${Theme.breakpoints.values.md}px)`);
            this.setState({ displayVertical: matches });
        };

        this.checkWidth();
        window.addEventListener('resize', this.checkWidth);
    }

    componentWillUnmount() {

        window.removeEventListener('resize', this.checkWidth);
    }

    stepLabelsFromChildren = () => {

        const { children } = this.props;

        return React.Children.map(children, (el) => el.props.title);
    }

    getStepContentFromChildren = (step) => {

        const { children } = this.props;

        return React.Children.toArray(children)[step];
    }

    render() {

        const { displayVertical } = this.state;
        const { children, steps, getStepContent: getStepContentFn, allowArbitraryNavigation, activeStep } = this.props;

        const stepLabels = children ? this.stepLabelsFromChildren() : steps;
        const getStepContent = children ? this.getStepContentFromChildren : getStepContentFn;

        return (
            <>
                <Stepper
                    nonLinear={allowArbitraryNavigation}
                    activeStep={activeStep}
                    orientation={displayVertical ? 'vertical' : 'horizontal'}
                >
                    {stepLabels.map((label, index) => {

                        return (
                            <Step key={label}>
                                <StepLabel
                                    style={allowArbitraryNavigation ? { cursor: 'pointer' } : {}}
                                    onClick={allowArbitraryNavigation ?  () => this.props.goToStep(index) : null}
                                >
                                    {label}
                                </StepLabel>
                                {displayVertical && <StepContent>{getStepContent(activeStep)}</StepContent>}
                            </Step>
                        );
                    })}
                </Stepper>
                {!displayVertical && getStepContent(activeStep)}
            </>
        );
    }
};

module.exports.Step = class CustomStep extends React.Component {

    static propTypes = {
        children: T.func
    }

    render() {

        const { children } = this.props;

        return children && children();
    }
};

module.exports.StepTitle = Styled(Typography).attrs({ variant: 'h6' })`
    ${(props) => props.theme.breakpoints.down('sm')} {
        display: none;
    }
`;
