const Connect = require('react-redux').connect;
const SetupScreeningMetricsPage = require('../components/ManageScreeningMetricsPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        screeningMetrics: M.selectors.model.fetchScreeningMetrics(state),
        screeningCategories: M.selectors.model.fetchScreeningCategories(state),
        role: M.selectors.auth.getUserRole(state)
    }),
    (dispatch, props) => ({
        fetchData: async () => {

            await Promise.all([
                M.dispatch.model.fetchScreeningCategories(),
                M.dispatch.model.fetchScreeningMetrics()
            ]);
        },
        onSubmit: async (metrics) => {

            const [err] = await M.dispatch.model.updateScreeningMetricsSort({ metrics });

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Screening metrics updated successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err];
        },
        onSubmitEditMetric: async (screeningMetricData) => {

            if (!screeningMetricData) {
                return;
            }

            const [err] = await M.dispatch.model.editScreeningMetrics(screeningMetricData);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Screening metrics updated successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err];
        }
    })
);

module.exports = internals.connect(SetupScreeningMetricsPage);
