const React = require('react');
const T = require('prop-types');

const PatientPortalPage = require('./PatientPortalPage');
const PatientSignupPage = require('./PatientSignupPage');

const { MANAGER_URL_PART } = require('../../../constants');

const internals = {};

module.exports = class PatientPortalWrapper extends React.Component {

    static propTypes = {
        fetchData: T.func,
        program: T.object,
        history: T.object
    };

    componentDidMount() {

        this.props.fetchData();

        // isManagerActing
        const { location: { pathname } } = this.props.history;

        // Strip leading slash if it exists
        this.isManagerActing = pathname.replace(/^\//, '').startsWith(MANAGER_URL_PART);
    }

    render() {

        if (!this.props.program) {
            return null;
        }

        const {
            buildRegistrationLink,
            buildScheduleLink,
            buildCovidLink
        } = internals;

        const props = {
            ...this.props,
            buildRegistrationLink: buildRegistrationLink(this.isManagerActing),
            buildScheduleLink: buildScheduleLink(this.isManagerActing),
            buildCovidLink: buildCovidLink(this.isManagerActing)
        };

        return this.props.program.useEligibilityFile ? <PatientPortalPage {...props} /> : <PatientSignupPage {...props} />;
    }
};

internals.buildRegistrationLink = (isManagerActing) => {

    return ({ programId, patientId }) => {

        const addManagerPart = isManagerActing ? `${MANAGER_URL_PART}/` : '';

        return `/${addManagerPart}portal/${programId}/registration/${patientId}`;
    };
};

internals.buildScheduleLink = (isManagerActing) => {

    return ({ programId, patientId }) => {

        const addManagerPart = isManagerActing ? `${MANAGER_URL_PART}/` : '';

        return `/${addManagerPart}portal/${programId}/schedule/${patientId}`;
    };
};

internals.buildCovidLink = (isManagerActing) => {

    return ({ programId, patientId }) => {

        const addManagerPart = isManagerActing ? `${MANAGER_URL_PART}/` : '';

        return `/${addManagerPart}portal/${programId}/covid/${patientId}`;
    };
};
