const React = require('react');
const T = require('prop-types');
const Joi = require('joi').extend(require('@hapi/joi-date'));
const { default: Divider } = require('@material-ui/core/Divider');
const { default: Styled } = require('styled-components');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: Button } = require('@material-ui/core/Button');
const { default: FormControl } = require('@material-ui/core/FormControl');
const { default: FormLabel } = require('@material-ui/core/FormLabel');
const { default: FormControlLabel } = require('@material-ui/core/FormControlLabel');
const { default: RadioGroup } = require('@material-ui/core/RadioGroup');
const { default: Radio } = require('@material-ui/core/Radio');
const StrangeForms = require('strange-forms');
const Portlet = require('../../../components/Portlet');
const SectionLabel = require('../../../components/SectionLabel');
const DateMaskInput = require('../../../components/DateMaskInput');
const PhoneMaskInput = require('../../../components/PhoneMaskInput');
const StateSelect = require('../../../components/StateSelect');
const AlertDialog = require('../../../components/AlertDialog');

const internals = {};

module.exports = class PatientSignupPage extends StrangeForms(React.Component) {

    static propTypes = {
        fetchData: T.func,
        program: T.object,
        history: T.object,
        buildScheduleLink: T.func,
        buildCovidLink: T.func
    };

    static fields = [
        'firstName',
        'lastName',
        'dob',
        'gender',
        'address1',
        'address2',
        'city',
        'st',
        'zip',
        'email',
        'phone'
    ];

    static patientSchema = Joi.object({
        firstName: Joi.string(),
        lastName: Joi.string(),
        dob: Joi.date().format('MM/DD/YYYY').min(new Date('01/01/1900')).message('"dob" must be a valid date'),
        gender: Joi.string(),
        address1: Joi.string(),
        address2: Joi.string().empty(''),
        city: Joi.string(),
        st: Joi.string(),
        zip: Joi.string().pattern(/^\d{5}(-\d{4})?$/).message('"zip" is invalid'),
        email: Joi.string().email({ tlds: { allow: false } }),
        phone: Joi.string().pattern(/^\(\d{3}\)\s\d{3}-\d{4}?$/).message('"phone" must use the format (xxx) xxx-xxxx')
    });

    constructor(props) {

        super(props);

        this.state = {
            isSubmitting: false,
            showAgeRequirementDialog: false,
            errors: {}
        };

        this.strangeForm({
            fields: PatientSignupPage.fields,
            get: () => '',
            act: () => null
        });
    }

    handleSubmit = async (ev) => {

        ev.preventDefault();

        const patient = PatientSignupPage.fields.reduce((acc, field) => ({ ...acc, [field]: this.fieldValue(field) }), {});

        const errors = this.validate(patient);

        if (this.props.program.ageRequirementDate &&
            new Date(patient.dob) > new Date(this.props.program.ageRequirementDate)) {
            this.setState({ showAgeRequirementDialog: true });
            return;
        }

        if (errors) {
            this.setState({ errors });
            return;
        }

        this.setState({ isSubmitting: true });

        // eslint-disable-next-line no-unused-vars
        const [_, { result }] = await this.props.createPatient({
            ...patient,
            clientId: this.props.program.client && this.props.program.client.id
        });

        const {
            history,
            buildCovidLink,
            buildScheduleLink
        } = this.props;

        if (result && this.props.program.isCovidTesting) {
            return history.push(
                buildCovidLink({
                    programId: this.props.program.id,
                    patientId: result
                })
            );
        }

        if (result) {
            return history.push(
                buildScheduleLink({
                    programId: this.props.program.id,
                    patientId: result
                })
            );
        }

        this.setState({ isSubmitting: false });
    }

    validate = (patient) => {

        const results = PatientSignupPage.patientSchema.validate(patient);

        if (!results.error) {
            return null;
        }

        const field = results.error.message.match(/\"(.+)\"/)[1];

        return { [field]: results.error.message };
    }

    shouldDisableSubmit() {

        return (
            !this.fieldValue('firstName') ||
            !this.fieldValue('lastName') ||
            !this.fieldValue('dob') ||
            !this.fieldValue('gender') ||
            !this.fieldValue('address1') ||
            !this.fieldValue('city') ||
            !this.fieldValue('st') ||
            !this.fieldValue('zip') ||
            !this.fieldValue('email') ||
            !this.fieldValue('phone') ||
            this.state.isSubmitting
        );
    }

    render() {

        const { showAgeRequirementDialog, errors } = this.state;
        const { PageContainer, FieldsWrapper, StyledPortlet, PortletBodyContainer, PortalCopy, StyledRadioGroup, StyledRadio } = internals;

        return (
            <PageContainer>
                <StyledPortlet
                    body={
                        <PortletBodyContainer>
                            <SectionLabel>
                                SCHEDULE APPOINTMENT - STEP 1 / 3<br />
                                <em style={{ fontWeight: 'normal' }}>Use Chrome For Best Experience; Do Not Use Internet Explorer.</em>
                            </SectionLabel>
                            <FieldsWrapper onSubmit={this.handleSubmit}>
                                <TextField
                                    label='First Name'
                                    value={this.fieldValue('firstName')}
                                    autoComplete='disabled'
                                    onChange={this.proposeNew('firstName')}
                                    error={errors.hasOwnProperty('firstName')}
                                    helperText={errors.hasOwnProperty('firstName') ? errors.firstName : ''}
                                />
                                <TextField
                                    label='Last Name'
                                    value={this.fieldValue('lastName')}
                                    autoComplete='disabled'
                                    onChange={this.proposeNew('lastName')}
                                    error={errors.hasOwnProperty('lastName')}
                                    helperText={errors.hasOwnProperty('lastName') ? errors.lastName : ''}
                                />
                                <TextField
                                    label='Date of Birth'
                                    type='text'
                                    value={this.fieldValue('dob')}
                                    pattern='[0-9]*'
                                    placeholder='mm/dd/yyyy'
                                    autoComplete='disabled'
                                    onChange={this.proposeNew('dob')}
                                    InputProps={{
                                        inputComponent: DateMaskInput
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={errors.hasOwnProperty('dob')}
                                    helperText={errors.hasOwnProperty('dob') ? errors.dob : ''}
                                />
                                <FormControl component='fieldset'>
                                    <FormLabel component='legend'>Gender</FormLabel>
                                    <StyledRadioGroup aria-label='gender' name='gender' value={this.fieldValue('gender')} onChange={this.proposeNew('gender')}>
                                        <FormControlLabel value='F' control={<StyledRadio />} label='Female' />
                                        <FormControlLabel value='M' control={<StyledRadio />} label='Male' />
                                    </StyledRadioGroup>
                                </FormControl>
                                <TextField
                                    label='Address'
                                    autoComplete='disabled'
                                    value={this.fieldValue('address1')}
                                    onChange={this.proposeNew('address1')}
                                    error={errors.hasOwnProperty('address1')}
                                    helperText={errors.hasOwnProperty('address1') ? errors.address1 : ''}
                                />
                                <TextField
                                    label='Address 2'
                                    autoComplete='disabled'
                                    value={this.fieldValue('address2')}
                                    onChange={this.proposeNew('address2')}
                                    error={errors.hasOwnProperty('address2')}
                                    helperText={errors.hasOwnProperty('address2') ? errors.address2 : ''}
                                />
                                <TextField
                                    label='City'
                                    autoComplete='disabled'
                                    value={this.fieldValue('city')}
                                    onChange={this.proposeNew('city')}
                                    error={errors.hasOwnProperty('city')}
                                    helperText={errors.hasOwnProperty('city') ? errors.city : ''}
                                />
                                <StateSelect
                                    label='State'
                                    autoComplete='disabled'
                                    value={this.fieldValue('st')}
                                    onChange={this.proposeNew('st')}
                                    error={errors.hasOwnProperty('st')}
                                    helperText={errors.hasOwnProperty('st') ? errors.st : ''}
                                />
                                <TextField
                                    label='Zip Code'
                                    pattern='[0-9]*'
                                    autoComplete='disabled'
                                    value={this.fieldValue('zip')}
                                    onChange={this.proposeNew('zip')}
                                    error={errors.hasOwnProperty('zip')}
                                    helperText={errors.hasOwnProperty('zip') ? errors.zip : ''}
                                />
                                <TextField
                                    label='Email'
                                    type='email'
                                    autoComplete='disabled'
                                    value={this.fieldValue('email')}
                                    onChange={this.proposeNew('email')}
                                    error={errors.hasOwnProperty('email')}
                                    helperText={errors.hasOwnProperty('email') ? errors.email : ''}
                                />
                                <TextField
                                    label='Phone Number'
                                    type='tel'
                                    value={this.fieldValue('phone')}
                                    autoComplete='disabled'
                                    onChange={this.proposeNew('phone')}
                                    InputProps={{
                                        inputComponent: PhoneMaskInput
                                    }}
                                    error={errors.hasOwnProperty('phone')}
                                    helperText={errors.hasOwnProperty('phone') ? errors.phone : ''}
                                />
                                <Button
                                    type='submit'
                                    color='primary'
                                    variant='contained'
                                    disabled={this.shouldDisableSubmit()}
                                >
                                    Continue
                                </Button>
                            </FieldsWrapper>
                        </PortletBodyContainer>
                    }
                />
                <StyledPortlet
                    body={
                        this.props.program &&
                        this.props.program.portalLandingCopy &&
                        <PortalCopy dangerouslySetInnerHTML={{ __html: this.props.program.portalLandingCopy }} />
                    }
                />
                <AlertDialog
                    dialogTitle='Age requirement not met'
                    dialogDescription='You do not fulfill the age requirements to participate in this program. We apologize for any inconvenience this has caused.'
                    affirmativeLabel='Ok'
                    negativeLabel={null}
                    isModalOpen={showAgeRequirementDialog}
                    confirmAction={() => this.setState({ showAgeRequirementDialog: false })}
                    toggleModal={() => this.setState({ showAgeRequirementDialog: false })}
                />
            </PageContainer>
        );
    }
};

internals.PortalCopy = Styled.div`
    p {
        line-height: 1.5;
    }
`;

internals.PortletBodyContainer = Styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

internals.StyledDivider = Styled(Divider)`
    margin: ${({ theme }) => theme.spacing(2)}px 0;
`;

internals.PageContainer = Styled.div`
    display: flex;
    max-width: ${({ theme }) => theme.spacing(150)}px;
    margin: 0 auto;

    flex-direction: column;

    ${({ theme }) => theme.breakpoints.up('sm')} {
        align-items: flex-start;
        flex-direction: row;
    }

`;

internals.StyledPortlet = Styled(Portlet)`

    ${({ theme }) => theme.breakpoints.up('sm')} {
        flex: 1
    }
    margin: ${({ theme }) => theme.spacing(1)}px;
`;

internals.FieldsWrapper = Styled.form`
    display: flex;
    flex-direction: column;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

internals.StyledRadioGroup = Styled(RadioGroup)`
    flex-direction: row;
`;

internals.StyledRadio = Styled(Radio)`
    padding: 3px 9px;
`;
