const MiddleEnd = require('strange-middle-end');

const {
    API_ERRORS,
    CLEAR_API_ERRORS,
    SELECT_PROGRAM,
    SELECT_EVENTS
} = require('./action-types');

exports.pushApiErrors = MiddleEnd.createAction(API_ERRORS);

exports.clearApiErrors = MiddleEnd.createAction(CLEAR_API_ERRORS);

exports.selectProgram = MiddleEnd.createAction(SELECT_PROGRAM, (id) => ({ id }));

exports.selectEvents = MiddleEnd.createAction(SELECT_EVENTS, (eventIds) => ({ eventIds }));
