const Connect = require('react-redux').connect;
const SearchAllEventsPage = require('../components/SearchAllEventsPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        events: M.selectors.model.fetchAllEvents(state),
        role: M.selectors.auth.getUserRole(state)

    }),
    (dispatch, props) => ({
        fetchData: async () => {

            await Promise.all([
                M.dispatch.model.fetchAllEvents()
            ]);
        }
    })
);

module.exports = internals.connect(SearchAllEventsPage);
