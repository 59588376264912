const React = require('react');
const { useState, useEffect } = require('react');
const { useAsync } = require('react-use');
const T = require('prop-types');
const { NavLink } = require('react-router-dom');
const ArrayMove = require('array-move');
const { default: Styled } = require('styled-components');
const CloneDeep = require('lodash/cloneDeep');
const { v4: UUID } = require('uuid');
const { default: Button } = require('@material-ui/core/Button');
const { default: Paper } = require('@material-ui/core/Paper');
const { default: TextField } = require('@material-ui/core/TextField');
const Portlet = require('../../../components/Portlet');
const Types = require('../types');

const internals = {};

module.exports = function FormBuilderPage({ match, fetchForm, onSubmit }) {

    const [disableSubmit, setDisableSubmit] = useState(false);
    const [formName, setFormName] = useState('');
    const [fields, setFields] = useState(null);

    const { value: form } = useAsync(async () => {

        if (match.params.id) {
            const [, res] = await fetchForm();
            return res;
        }

        return null;
    }, [match.params.id]);

    useEffect(() => {

        if (form && !formName) {
            setFormName(form.name);
        }

        if (form && !fields) {
            setFields(form.fields.map((field) => ({
                ...field,
                id: `temp-${UUID()}`
            })));
        }
    }, [form, formName, fields]);

    const {
        PageContainer,
        FormBuilder,
        FormContainer,
        AddInputButton,
        FieldSelectorContainer,
        StyledButton
    } = internals;

    const saveForm = async () => {

        setDisableSubmit(true);

        const stagedFields = CloneDeep(fields).map((field) => {

            if (field.id.startsWith('temp')) {
                delete field.id;
            }

            return field;
        });

        const payload = {
            name: formName,
            fields: stagedFields
        };

        if (form) {
            payload.id = form.id;
        }

        await onSubmit(payload);

        setDisableSubmit(false);
    };

    const addField = (type) => {

        setFields([
            ...(fields || []),
            Types[type].new
        ]);
    };

    const updateField = (field, patch) => {

        const entry = fields.findIndex((f) => f.id === field.id);
        const stagedFields = [...fields];

        const settings = 'settings' in patch ?
            {
                ...field.settings,
                ...patch.settings
            } : field.settings;

        stagedFields[entry] = {
            ...field,
            ...patch,
            settings
        };

        setFields(stagedFields);
    };

    const deleteField = (index) => {

        const stagedFields = [...fields];

        stagedFields.splice(index, 1);

        setFields(stagedFields);
    };

    const updateSort = (field, { move }) => {

        const stagedFields = [...fields];
        const from = stagedFields.findIndex((f) => f.id === field.id);
        const to = from + move;

        setFields(ArrayMove(stagedFields, from, to));
    };

    return (
        <>
            <PageContainer>
                <Portlet
                    title='Form Builder'
                    body={
                        <>
                            <TextField
                                label='Form Name'
                                value={formName}
                                required
                                fullWidth
                                onChange={(e) => setFormName(e.target.value)}
                            />
                            <FormBuilder>
                                <FormContainer>
                                    {fields && fields.map((field, i) => {

                                        const Component = Types[field.type].component;

                                        return (
                                            <div key={field.id}>
                                                <Component
                                                    field={field}
                                                    isFirst={i === 0}
                                                    isLast={i === fields.length - 1}
                                                    onChange={updateField}
                                                    onDelete={() => deleteField(i)}
                                                    onSort={updateSort}
                                                />
                                            </div>
                                        );
                                    })}
                                </FormContainer>
                                <FieldSelectorContainer>
                                    {Object.keys(Types).map((key) => {

                                        const { icon: Icon } = Types[key];

                                        return (
                                            <AddInputButton
                                                key={key}
                                                variant='contained'
                                                onClick={() => addField(key)}
                                            >
                                                <Icon /> {key}
                                            </AddInputButton>
                                        );
                                    })}
                                </FieldSelectorContainer>
                            </FormBuilder>
                        </>
                    }
                    foot={
                        <>
                            <StyledButton
                                onClick={saveForm}
                                variant='contained'
                                color='primary'
                                disabled={disableSubmit}
                            >
                                Save
                            </StyledButton>
                            <StyledButton
                                component={NavLink}
                                to='/'
                                variant='text'
                            >
                                Cancel
                            </StyledButton>
                        </>
                    }
                />
            </PageContainer>
        </>
    );
};

module.exports.propTypes = {
    match: T.object.isRequired,
    fetchForm: T.func.isRequired,
    onSubmit: T.func.isRequired
};

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.FormBuilder = Styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

internals.FormContainer = Styled(Paper)`
    width: 100%;
    max-width: 800px;
    padding: ${({ theme }) => theme.spacing(2)}px;
`;

internals.AddInputButton = Styled(Button)`
    display: block;
    flex-grow: 1;
    margin: ${({ theme }) => theme.spacing(2)}px ${({ theme }) => theme.spacing(1)}px 0;
    background: ${({ theme }) => theme.palette.background.mediumGray};
    color: ${({ theme }) => theme.palette.text.primary};

    &:hover,
    &:focus {
        background: ${({ theme }) => theme.palette.background.paleGray};
    }

    svg {
        display: block;
        margin: ${({ theme }) => theme.spacing(0.5)}px auto 0;
    }
`;

internals.FieldSelectorContainer = Styled.div`
    display: flex;
    flex-wrap: wrap;
    position: sticky;
    top: 0;
    max-width: 350px;
`;

internals.StyledButton = Styled(Button)`
    margin: 0 8px;
`;
