const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: Button } = require('@material-ui/core/Button');

const StyledField = exports.StyledField = Styled.div`
    position: relative;
    margin: ${({ theme }) => theme.spacing(2)}px 0;

    .type-icon {
        position: relative;
        top: 6px;
        margin-right: ${({ theme }) => theme.spacing(1)}px;
    }
`;

exports.Settings = Styled.div`
    margin: 0 ${({ theme }) => theme.spacing(-1)}px;

    > div {
        margin: 0 ${({ theme }) => theme.spacing(5.5)}px;
    }

    .MuiFormGroup-row {
        margin-bottom: ${({ theme }) => theme.spacing(1)}px;
    }
`;

exports.Choice = Styled.div``;

exports.DeleteButton = Styled(Button)`
    margin-left: auto;
    color: ${({ theme }) => theme.palette.error.main};
`;

exports.EditButton = Styled(Button)`
    margin-left: auto;
    color: ${({ theme }) => theme.palette.success.main};
`;

exports.Field = ({ children, ...props }) => {

    return (
        <StyledField {...props}>
            {children}
        </StyledField>
    );
};

exports.Field.propTypes = {
    children: T.any.isRequired
};

