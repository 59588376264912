module.exports = (time) => {

    if (!time) {
        return '';
    }

    let [hh, mm] = time.split(':');
    const decorator = hh < 12 ? 'AM' : 'PM';
    hh = (hh % 12 === 0) ? 12 : hh % 12;
    return `${hh}:${mm} ${decorator}`;
};
