const Connect = require('react-redux').connect;
const PatientCovidPage = require('../components/PatientCovidPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        program: M.selectors.model.fetchProgram(state),
        patient: M.selectors.model.fetchPatient(state)
    }),
    (dispatch, props) => ({
        fetchData: async () => {

            await M.dispatch.model.fetchProgram(props.match.params.id);
            await M.dispatch.model.fetchPatient(props.match.params.patientId);
        },
        updatePatient: M.dispatch.model.updatePatient
    })
);

module.exports = internals.connect(PatientCovidPage);
