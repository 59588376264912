const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: Modal } = require('@material-ui/core/Modal');
const Portlet = require('./Portlet');
const { default: List } = require('@material-ui/core/List');
const { default: ListItem } = require('@material-ui/core/ListItem');
const { default: ListItemText } = require('@material-ui/core/ListItemText');
const { default: Button } = require('@material-ui/core/Button');

const internals = {};

module.exports = class ProgramSwitcher extends React.Component {

    static propTypes = {
        programs: T.array,
        isOpen: T.bool,
        handleClose: T.func,
        onSelectProgram: T.func,
        activeProgram: T.object
    };

    constructor(props) {

        super(props);

        this.state = {
            selectedProgram: props.activeProgram ? props.activeProgram.id : null
        };
    }

    handleClickProgram(id) {

        this.setState({
            selectedProgram: id
        });
    }

    handleSetActiveProgram = () => {

        const { selectedProgram } = this.state;
        this.props.onSelectProgram(selectedProgram);
        this.props.handleClose();
    }

    render() {

        const { programs, isOpen } = this.props;
        const { selectedProgram } = this.state;
        const { StyledModal, StyledList, StyledListItem, StyledButton, ButtonWrapper, StyledPortlet } = internals;

        return (
            <StyledModal open={isOpen} onClose={this.props.handleClose}>
                <StyledPortlet
                    noBodyPadding
                    title='Set Active Program'
                    body={
                        <StyledList>
                            {programs.map((program) => {

                                return (
                                    <StyledListItem
                                        button
                                        key={program.id}
                                        onClick={() => this.handleClickProgram(program.id)}
                                        selected={program.id === selectedProgram}
                                    >
                                        <ListItemText primary={program.name} />
                                    </StyledListItem>
                                );
                            })}
                        </StyledList>
                    }
                    foot={
                        <ButtonWrapper>
                            <StyledButton
                                onClick={this.handleSetActiveProgram}
                                variant='contained'
                                color='primary'
                            >
                                Set Active
                            </StyledButton>
                            <StyledButton
                                onClick={this.props.handleClose}
                                variant='text'
                            >
                                Cancel
                            </StyledButton>
                        </ButtonWrapper>
                    }
                />
            </StyledModal>
        );
    }
};

internals.StyledModal = Styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

internals.StyledPortlet = Styled(Portlet)`
    width: 500px;
    max-width: ${({ theme }) => `calc(100% - ${theme.spacing(4)}px)`};
`;

internals.StyledList = Styled(List)`
    max-height: 40vh;
    overflow-y: scroll;
`;

internals.StyledListItem = Styled(ListItem)`
    height: 43px; // 16px total padding (theme.spacing(1) top/bottom) and 27px text height
    &.Mui-selected, &.Mui-selected:hover {
        background-color: ${({ theme }) => theme.palette.fjord};
    }
`;

internals.StyledButton = Styled(Button)`
    margin: 0;
`;

internals.ButtonWrapper = Styled.div`
    display: flex;
    justify-content: space-between;
`;

