const React = require('react');
const T = require('prop-types');
const { NavLink } = require('react-router-dom');
const { default: Styled } = require('styled-components');
const { default: Button } = require('@material-ui/core/Button');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: Drawer } = require('@material-ui/core/Drawer');
const StrangeForms = require('strange-forms');
const Portlet = require('../../../components/Portlet');
const SectionLabel = require('../../../components/SectionLabel');
const Theme = require('../../../theme');

const internals = {};

module.exports = class SetupSmsPage extends StrangeForms(React.Component) {

    static propTypes = {
        program: T.object,
        onSubmit: T.func.isRequired,
        doesProgramExist: T.bool.isRequired
    };

    constructor(props) {

        super(props);

        const defaultSmsFields = {
            smsConfirmationText: '',
            smsCancellationText: '',
            smsRescheduledText: '',
            smsReminderText: ''
        };

        this.state = {
            isSubmitting: false,
            isDrawerOpen: false
        };

        this.strangeForm({
            fields: Object.keys(defaultSmsFields),
            get: (someProps, field) => (someProps.program && someProps.program[field]) || '',
            act: () => null
        });
    }

    componentDidMount() {

        this.props.fetchProgram();
    }

    shouldDisableSubmit() {

        const confirmationSms = this.fieldValue('smsConfirmationText');
        const cancelationSms = this.fieldValue('smsCancellationText');
        const rescheduleSms = this.fieldValue('smsRescheduledText');
        const reminderSms = this.fieldValue('smsReminderText');

        return !confirmationSms || !cancelationSms || !rescheduleSms || !reminderSms || this.state.isSubmitting;
    }

    handleSubmit = async (ev) => {

        ev.preventDefault();
        const smsConfirmationText = this.fieldValue('smsConfirmationText');
        const smsCancellationText = this.fieldValue('smsCancellationText');
        const smsRescheduledText = this.fieldValue('smsRescheduledText');
        const smsReminderText = this.fieldValue('smsReminderText');
        this.setState({ isSubmitting: true });

        await this.props.onSubmit(
            {
                id: this.props.program.id,
                smsConfirmationText,
                smsCancellationText,
                smsRescheduledText,
                smsReminderText
            }
        );
        this.setState({ isSubmitting: false });
    }

    render() {

        const { PageContainer, StyledButton, ForwardedLink, ButtonWrapper, StyledForm, StyledTextField, SMSVariables } = internals;
        const { doesProgramExist, isLoading, program } = this.props;
        const { isDrawerOpen } = this.state;
        const { name = '', client = {} } = program || {};
        const { logoUrl, name: clientName } = client;

        if (isLoading) {
            return null;
        }

        return (
            <PageContainer>
                {doesProgramExist &&
                    <Portlet
                        title={`Program: ${name}`}
                        toolbar={logoUrl && <Portlet.LogoImg src={logoUrl} alt={`${clientName} logo`} />}
                        body={
                            <StyledForm>
                                <SectionLabel>SMS Setup</SectionLabel>
                                <StyledTextField
                                    multiline
                                    label='Confirmation SMS'
                                    value={this.fieldValue('smsConfirmationText')}
                                    onChange={this.proposeNew('smsConfirmationText')}
                                />
                                <StyledTextField
                                    multiline
                                    label='Cancellation SMS'
                                    value={this.fieldValue('smsCancellationText')}
                                    onChange={this.proposeNew('smsCancellationText')}
                                />
                                <StyledTextField
                                    multiline
                                    label='Reschedule SMS'
                                    value={this.fieldValue('smsRescheduledText')}
                                    onChange={this.proposeNew('smsRescheduledText')}
                                />
                                <StyledTextField
                                    multiline
                                    label='Reminder SMS'
                                    value={this.fieldValue('smsReminderText')}
                                    onChange={this.proposeNew('smsReminderText')}
                                />
                            </StyledForm>
                        }
                        foot={
                            <ButtonWrapper>
                                <StyledButton
                                    onClick={this.handleSubmit}
                                    variant='contained'
                                    color='primary'
                                    disabled={this.shouldDisableSubmit()}
                                >
                                    Save
                                </StyledButton>
                                <StyledButton
                                    component={ForwardedLink}
                                    to='/'
                                    variant='text'
                                >
                                    Cancel
                                </StyledButton>
                                <Button
                                    color='primary'
                                    size='small'
                                    style={{ margin: 0, marginLeft: 'auto' }}
                                    onClick={() => this.setState({ isDrawerOpen: true })}
                                >
                                    View template variables
                                </Button>
                            </ButtonWrapper>
                        }
                    />}
                {!doesProgramExist &&
                    <Portlet
                        title='Program Not Found'
                        body={
                            <StyledButton
                                component={ForwardedLink}
                                to='/programs/search'
                                variant='text'
                            >
                                Search Programs
                            </StyledButton>
                        }
                    />}
                <Drawer
                    anchor='right'
                    open={isDrawerOpen}
                    onClose={() => this.setState({ isDrawerOpen: false })}
                    PaperProps={{
                        style: {
                            width: 400,
                            background: Theme.palette.background.paper,
                            color: Theme.palette.text.primary
                        }
                    }}
                >
                    <SMSVariables>
                        <div>
                            <strong>appointment</strong>
                            <br />
                            <pre>{'{{'} appointment.time {'}}'}</pre>
                            <pre>{'{{'} appointment.email {'}}'}</pre>
                            <pre>{'{{'} appointment.phone {'}}'}</pre>
                            <pre>{'{{'} appointment.consentText {'}}'}</pre>
                            <pre>{'{{'} appointment.screening.results {'}}'}</pre>
                            <pre>{'{{'} appointment.screening.notes {'}}'}</pre>
                            <pre>{'{{'} appointment.screening.confirmedAt {'}}'}</pre>
                            <pre>{'{{'} appointment.screening.sentResultsEmail {'}}'}</pre>
                        </div>
                        <div>
                            <strong>patient</strong>
                            <br />
                            <pre>{'{{'} patient.firstName {'}}'}</pre>
                            <pre>{'{{'} patient.lastName {'}}'}</pre>
                            <pre>{'{{'} patient.dob {'}}'}</pre>
                            <pre>{'{{'} patient.gender {'}}'}</pre>
                            <pre>{'{{'} patient.email {'}}'}</pre>
                            <pre>{'{{'} patient.storeIdentifier {'}}'}</pre>
                            <pre>{'{{'} patient.middleName {'}}'}</pre>
                            <pre>{'{{'} patient.address1 {'}}'}</pre>
                            <pre>{'{{'} patient.address2 {'}}'}</pre>
                            <pre>{'{{'} patient.city {'}}'}</pre>
                            <pre>{'{{'} patient.st {'}}'}</pre>
                            <pre>{'{{'} patient.zip {'}}'}</pre>
                            <pre>{'{{'} patient.phone {'}}'}</pre>
                            <pre>{'{{'} patient.relationship {'}}'}</pre>
                            <pre>{'{{'} patient.belongsToHealthPlan {'}}'}</pre>
                            <pre>{'{{'} patient.healthPlanName {'}}'}</pre>
                        </div>
                        <div>
                            <strong>event</strong>
                            <br />
                            <pre>{'{{'} event.name {'}}'}</pre>
                            <pre>{'{{'} event.date {'}}'}</pre>
                            <pre>{'{{'} event.location {'}}'}</pre>
                            <pre>{'{{'} event.address1 {'}}'}</pre>
                            <pre>{'{{'} event.address2 {'}}'}</pre>
                            <pre>{'{{'} event.city {'}}'}</pre>
                            <pre>{'{{'} event.state {'}}'}</pre>
                            <pre>{'{{'} event.zip {'}}'}</pre>
                            <pre>{'{{'} event.notes {'}}'}</pre>
                            <pre>{'{{'} event.offerFluShots {'}}'}</pre>
                            <pre>{'{{'} event.isPublished {'}}'}</pre>
                        </div>
                        <div>
                            <strong>program</strong>
                            <br />
                            <pre>{'{{'} program.name {'}}'}</pre>
                            <pre>{'{{'} program.useEligibilityFile {'}}'}</pre>
                            <pre>{'{{'} program.isCovidTesting {'}}'}</pre>
                            <pre>{'{{'} program.pdfFormUrl {'}}'}</pre>
                            <pre>{'{{'} program.rescheduleUrl {'}}'}</pre>
                        </div>
                        <div>
                            <strong>functions</strong>
                            <br />
                            <pre>{'{{'} formatDate <em>format</em> <em>date</em> <em>time</em> {'}}'}</pre>
                            <pre>{'{{'} isEqual <em>lhs</em> <em>rhs</em> {'}}'}</pre>
                        </div>
                    </SMSVariables>
                </Drawer>
            </PageContainer>
        );
    }
};

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.StyledButton = Styled(Button)`
    margin: 0 8px;
`;

internals.ButtonWrapper = Styled.div`
    display: flex;
`;

internals.StyledForm = Styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

internals.StyledTextField = Styled(TextField)`
    width: 100%;
    max-width: 520px;
    margin: ${({ theme }) => theme.spacing(2)}px 0;

    textarea {
        font-size: 14px;
        line-height: 1.5;
    }
`;

internals.SMSVariables = Styled.div`
    padding: ${({ theme }) => theme.spacing(2)}px;
    font-size: 14px;

    pre {
        background: #f7f7f7;
        border: 1px solid #ccc;
        overflow: auto;
    }
`;

internals.ForwardedLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);
