const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: StarIcon } = require('@material-ui/icons/Star');
const { default: StarOutlineIcon } = require('@material-ui/icons/StarBorder');
const { default: StarHalfIcon } = require('@material-ui/icons/StarHalf');
const { default: LogoImage } = require('../../../img/logo-white.svg');
const SectionLabel = require('../../../components/SectionLabel');
const Table = require('../../../components/Table');
const Nullable = require('../../../components/Nullable');
const GetFormattedDate = require('../../../utils/get-formatted-date');

const internals = {};

module.exports = class ClientReport extends React.Component {

    static propTypes = {
        selectedEvents: T.arrayOf(T.string),
        generateClientReport: T.func,
        clientReport: T.shape({
            clientName: T.string,
            programName: T.string,
            startDate: T.string,
            endDate: T.string,
            participation: T.shape({
                appointments: T.number,
                signups: T.number,
                appointmentScreenings: T.number,
                walkinScreenings: T.number,
                totalScreenings: T.number,
                utilization: T.number
            }),
            highRisk: T.shape({
                1: T.shape({
                    count: T.number,
                    percentage: T.number
                }),
                2: T.shape({
                    count: T.number,
                    percentage: T.number
                }),
                3: T.shape({
                    count: T.number,
                    percentage: T.number
                }),
                4: T.shape({
                    count: T.number,
                    percentage: T.number
                })
            }),
            employees: T.shape({
                male: T.number,
                female: T.number
            }),
            satisfaction: T.number,
            risks: T.arrayOf(T.shape({
                name: T.string,
                ranges: T.arrayOf(T.shape({
                    name: T.string,
                    percentage: T.number
                }))
            }))
        })
    };

    constructor(props) {

        super(props);

        this.state = {
            hasSearched: false,
            isSubmitting: false
        };
    }

    componentDidMount() {

        window.scrollTo(0, 0);
        this.props.generateClientReport({ eventIds: this.props.selectedEvents });
    }

    render() {

        if (!this.props.clientReport) {
            return null;
        }

        const { ReportHeader, ReportGrid, GridItem, StyledSectionLabel, PaddedContainer } = internals;
        const { clientReport } = this.props;
        const { program, startDate, endDate } = clientReport || {};
        const formattedStartDate = startDate &&  GetFormattedDate(startDate);
        const formattedEndDate = endDate && GetFormattedDate(endDate);

        const participationData = clientReport && [
            {
                key: 'Appointments',
                value: clientReport.participation.appointments
            },
            {
                key: 'Signups',
                value: clientReport.participation.signups
            },
            {
                key: 'Appointment Screenings',
                value: clientReport.participation.appointmentScreenings
            },
            {
                key: 'Walk-In Screenings',
                value: clientReport.participation.walkinScreenings
            },
            {
                key: 'Total Screenings',
                value: clientReport.participation.totalScreenings
            },
            {
                key: 'Utilization',
                value: clientReport.participation.utilization
            }
        ] || [];

        const highRiskData = clientReport && [
            {
                key: 'One or no high risk categories',
                value: clientReport.highRisk[1].percentage
            },
            {
                key: 'Two high risk categories',
                value: clientReport.highRisk[2].percentage
            },
            {
                key: 'Three high risk categories',
                value: clientReport.highRisk[3].percentage
            },
            {
                key: 'All high risk',
                value: clientReport.highRisk[4].percentage
            }
        ] || [];

        const highRiskColumns = [
            {
                id: 'key',
                format: (val) => <Nullable value={val} />
            },
            {
                id: 'value',
                format: (val) => <Nullable value={`${Math.round(val)}%`} />,
                align: 'right'
            }
        ];

        const employeesColumns = [
            {
                id: 'maleLabel',
                format: () => <strong>Male</strong>
            },
            {
                id: 'maleVal',
                format: (val, data) => <Nullable value={`${Math.round(data.male)}%`} />,
                align: 'right'
            },
            {
                id: 'femaleLabel',
                format: () => <strong>Female</strong>
            },
            {
                id: 'femaleVal',
                format: (val, data) => <Nullable value={`${Math.round(data.female)}%`} />,
                align: 'right'
            }
        ];

        const riskColumns = [
            {
                id: 'name',
                header: 'Ranges',
                format: (val) => <Nullable value={val} />
            },
            {
                id: 'percentage',
                header: 'Percentage',
                format: (val) => <Nullable value={`${Math.round(val)}%`} />,
                align: 'right'
            }
        ];

        return (
            <div style={{ backgroundColor: 'white' }}>
                <ReportHeader>
                    <Typography style={{ marginRight: 'auto' }} variant='h4'>Events Report Card</Typography>
                    <img style={{ maxWidth: 250 }} src={LogoImage} alt='promerica logo' />
                </ReportHeader>
                <PaddedContainer>
                    <Typography variant='h5'>{program.name} — {formattedStartDate} – {formattedEndDate}</Typography>
                </PaddedContainer>
                <ReportGrid>
                    <GridItem>
                        <SectionLabel>Participation Summary</SectionLabel>
                        <Table
                            columns={highRiskColumns}
                            idAttribute='id'
                            hideHead
                        >
                            <Table.Rows data={participationData} />
                        </Table>
                    </GridItem>
                    <GridItem>
                        <SectionLabel>High-Risk Summary</SectionLabel>
                        <Table
                            columns={highRiskColumns}
                            idAttribute='id'
                            hideHead
                        >
                            <Table.Rows data={highRiskData} />
                        </Table>
                    </GridItem>
                    <GridItem>
                        <StyledSectionLabel>Employees</StyledSectionLabel>
                        <Table
                            columns={employeesColumns}
                            idAttribute='id'
                            hideHead
                        >
                            <Table.Rows data={clientReport && [{ ...clientReport.employees }] || []} />
                        </Table>
                    </GridItem>
                    <GridItem>
                        <StyledSectionLabel>Satisfaction – {clientReport && clientReport.satisfaction || 'N/A'}</StyledSectionLabel>
                        {clientReport && clientReport.satisfaction && internals.computeRating(clientReport.satisfaction)}
                    </GridItem>

                    {
                        clientReport &&
                        clientReport.risks &&
                        clientReport.risks.map((risk) => {

                            return (
                                <GridItem key={risk.name}>
                                    <StyledSectionLabel>{risk.name}</StyledSectionLabel>
                                    <Table
                                        columns={riskColumns}
                                        idAttribute='id'
                                    >
                                        <Table.Rows data={risk.ranges || []} />
                                    </Table>
                                </GridItem>
                            );
                        })
                    }

                </ReportGrid>
            </div>
        );
    }
};

internals.computeRating = (rating) => {

    const stars = [...Array(Math.floor(rating))].map((_, index) => <StarIcon key={'star' + index} />);
    const emptyStars = [...Array(5 - Math.ceil(rating))].map((_, index) => <StarOutlineIcon key={'outline' + index} />);
    if (rating % 1 !== 0) {
        stars.push(<StarHalfIcon />);
    }

    return stars.concat(emptyStars);
};

internals.PaddedContainer = Styled.div`
    padding: ${({ theme }) => theme.spacing(2)}px ${({ theme }) => theme.spacing(4)}px 0px;
`;

internals.StyledSectionLabel = Styled(SectionLabel)`
    && { margin-top: ${({ theme }) => theme.spacing(4)}px; }
`;

internals.ReportHeader = Styled.div`
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.promericaBlue.main};
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    color: white;
    grid-template-columns: repeat(2, 1fr);
    > * {
        padding: ${({ theme }) => theme.spacing(2)}px ${({ theme }) => theme.spacing(4)}px;
    }
`;

internals.GridItem = Styled.div`
    page-break-inside: avoid;
`;

internals.ReportGrid = Styled.div`
    display: grid;
    gap: ${({ theme }) => theme.spacing(3)}px;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    grid-template-columns: 1fr 1fr;
    padding: ${({ theme }) => theme.spacing(2)}px ${({ theme }) => theme.spacing(4)}px;
    align-items: start;
`;
