const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: Button } = require('@material-ui/core/Button');
const { default: ArrowDown } = require('@material-ui/icons/ArrowDropDown');
const { default: ArrowUp } = require('@material-ui/icons/ArrowDropUp');
const { DeleteButton, EditButton } = require('./styles');

const internals = {};

module.exports = function FieldHeader(props) {

    const {
        display: Display,
        icon: Icon,
        field,
        isFirst,
        isLast,
        onChange,
        onDelete,
        onSort,
        toggleEdit
    } = props;

    const { Header, Controls, SortWrapper, SortButton } = internals;

    return (
        <Header>
            <SortWrapper>
                <SortButton
                    disabled={isFirst}
                    onClick={() => onSort(field, { move: -1 })}
                >
                    <ArrowUp />
                </SortButton>
                <SortButton
                    disabled={isLast}
                    onClick={() => onSort(field, { move: 1 })}
                >
                    <ArrowDown />
                </SortButton>
            </SortWrapper>
            <Icon className='type-icon' />
            {Display ? <Display /> : (
                <TextField
                    label='Field Name'
                    value={field.name}
                    required
                    fullWidth
                    onChange={(e) => onChange(field, { name: e.target.value })}
                />
            )}
            <Controls>
                {toggleEdit && (
                    <EditButton onClick={toggleEdit}>Edit</EditButton>
                )}
                <DeleteButton onClick={onDelete}>Delete</DeleteButton>
            </Controls>
        </Header>
    );
};

module.exports.propTypes = {
    icon: T.elementType.isRequired,
    field: T.object.isRequired,
    isFirst: T.bool,
    isLast: T.bool,
    onChange: T.func.isRequired,
    onDelete: T.func.isRequired,
    onSort: T.func.isRequired,
    toggleEdit: T.func,
    display: T.elementType
};

module.exports.defaultProps = {
    display: null,
    toggleEdit: null,
    isFirst: false,
    isLast: false
};

internals.Header = Styled.div`
    display: flex;
    align-items: baseline;
    width: 100%;
`;

internals.Controls = Styled.div`
    display: flex;
    margin-left: auto;
`;

internals.SortButton = Styled(Button)`
    min-width: 0;
    margin: 0 ${({ theme }) => theme.spacing(2)}px 0 0;
    padding: 0;
`;

internals.SortWrapper = Styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    top: -6px;
`;
