const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const GetFormattedDate = require('../../../utils/get-formatted-date');
const GetFormattedTime = require('../../../utils/get-formatted-time');

const internals = {};

module.exports = class CovidLabel extends React.Component {

    static propTypes = {
        appointment: T.shape({
            patient: T.shape({
                firstName: T.string.isRequired,
                lastName: T.string.isRequired,
                dob: T.string
            }),
            event: T.shape({
                date: T.string
            }),
            screening: T.shape({
                results: T.array
            }),
            time: T.string
        }),
        fetchData: T.func.isRequired
    };

    async componentDidMount() {

        await this.props.fetchData();

        window.print();
    }

    handleCloseWindow() {

        window.close();
    }

    render() {

        const { appointment } = this.props;
        const { PrintContent, CloseButton } = internals;

        if (!appointment) {
            return null;
        }

        const { patient, event, screening } = appointment;
        let results = null;

        if (screening && screening.results.length) {
            results = screening.results.find((r) => r.name === 'Results');
        }

        return (
            <>
                <PrintContent>
                    <div>{patient.lastName}, {patient.firstName}</div>
                    <div>DOB: {GetFormattedDate(patient.dob)}</div>
                    <div>
                        Collection Date: {GetFormattedDate(event.date)} {appointment.time && ` - ${GetFormattedTime(appointment.time)}`}
                    </div>
                    <div>Nasal mid-turbinate</div>
                    {results && <div>Results: {results.values['@']}</div>}
                </PrintContent>
                <CloseButton onClick={this.handleCloseWindow}>Close Window</CloseButton>
            </>
        );
    }
};

internals.PrintContent = Styled.div`
    @media screen {
        width: 4in;
        margin: 1em;
        padding: 1em;
        background: white;
    }
`;

internals.CloseButton = Styled.button`
    margin: 1em;
    font-family: inherit;
    font-size: inherit;

    @media print {
        display: none;
    }
`;
