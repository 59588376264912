const Connect = require('react-redux').connect;
const SearchEventsPage = require('../components/SearchEventsPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        events: M.selectors.model.fetchEvents(state),
        program: M.selectors.model.fetchProgram(state),
        role: M.selectors.auth.getUserRole(state)

    }),
    (dispatch, props) => ({
        fetchData: async () => {

            await Promise.all([
                M.dispatch.model.fetchProgram(props.match.params.id),
                M.dispatch.model.fetchEvents(props.match.params.id)
            ]);
        },
        deleteEvent: async (id, programId) => {

            const [err] = await M.dispatch.model.deleteEvent(id, programId);

            if (!err) {
                M.dispatch.snackbar.pushToQueue('Event deleted successfully');
            }
            else {
                M.dispatch.snackbar.pushToQueue('Something went wrong');
            }

            return [err];
        }
    })
);

module.exports = internals.connect(SearchEventsPage);
