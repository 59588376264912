const React = require('react');
const T = require('prop-types');
const { default: Styled, ...S } = require('styled-components');
const { default: Typography } = require('@material-ui/core/Typography');

const internals = {};

module.exports = class ClientReport extends React.Component {

    static propTypes = {
        screening: T.object,
        fetchData: T.func
    };

    constructor(props) {

        super(props);

        this.state = {
            hasSearched: false,
            isSubmitting: false
        };
    }

    componentDidMount() {

        this.props.fetchData();
    }

    render() {

        const { screening = {} } = this.props;
        const { patient = {}, program = {}, results = [] } = screening || {};
        const { screeningMetrics = [] } = program;
        const { firstName = '', lastName = '' } = patient;
        const { ReportHeader, RangelessGrid, ReportGrid, GridItem, GridTypography } = internals;
        const fieldsDictionary = screeningMetrics.map(({ name: metricName, id: metricId }) => ({ id: metricId, name: metricName }));
        const maximumReferences = Math.max(...results.map((result) => {

            return !!result.reference && !!result.reference.length ? result.reference.length : 0;
        }));

        // Account for the first column which displays label and value
        const numberOfColumns = maximumReferences;

        const screeningResults = screening &&
            screening.results &&
            !!screening.results.length &&
            screening.results.filter((result) => result.reference !== null);

        const rangelessResults = screening &&
            screening.results &&
            !!screening.results.length &&
            screening.results.filter((result) => result.reference === null);

        return (
            <div style={{ backgroundColor: 'white' }}>
                <ReportHeader>
                    <Typography>Patient Report</Typography>
                    <Typography>PROMERICA HEALTH</Typography>
                    {firstName && lastName && <Typography>{internals.obfuscateName(firstName, lastName)}</Typography>}
                </ReportHeader>
                {rangelessResults && !!rangelessResults.length &&
                    <RangelessGrid numberOfColumns={rangelessResults.length}>
                        {
                            rangelessResults.map((result, colIndex) => {

                                const label = fieldsDictionary && !!fieldsDictionary.length && fieldsDictionary.find(({ id }) => id === result.screeningMetricId).name;

                                return (
                                    <GridItem
                                        rangeless
                                        key={label}
                                        // This will always be in column 1
                                        row={1}
                                        column={colIndex + 1}
                                    >
                                        <Typography variant='h6'>{label}</Typography>
                                        <Typography variant='h3'>{result.display}</Typography>
                                    </GridItem>);
                            })
                        }
                    </RangelessGrid>}
                <ReportGrid numberOfColumns={numberOfColumns}>

                    {screeningResults && !!screeningResults.length && screeningResults.map((result, rowIndex) => {

                        const label = fieldsDictionary && !!fieldsDictionary.length && fieldsDictionary.find(({ id }) => id === result.screeningMetricId).name;

                        return (
                            <>
                                <GridItem
                                    firstCol
                                    key={label}
                                    row={rowIndex + 1}

                                    // Label will always be in column 1
                                    column={1}
                                >
                                    <Typography variant='h6'>{label}</Typography>
                                    <Typography variant='h3'>{result.display}</Typography>
                                </GridItem>
                                {
                                    result.reference.map((reference, colIndex) => {

                                        return (
                                            <GridItem
                                                key={reference.label}
                                                isActive={reference.isActive}

                                                // color to display if isActive is true
                                                level={reference.level}
                                                row={rowIndex + 1}
                                                column={colIndex + 2}
                                            >
                                                <GridTypography>{reference.range}</GridTypography>
                                                <GridTypography>{reference.label}</GridTypography>
                                            </GridItem>
                                        );
                                    })
                                }
                            </>
                        );
                    })}
                </ReportGrid>
            </div>
        );
    }
};

internals.ReportHeader = Styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    > * {
        padding: ${({ theme }) => theme.spacing(2)}px ${({ theme }) => theme.spacing(4)}px;
    }
    // Pushes every other item to the edge without setting a textalign attribute
    > :nth-child(even) {
        justify-self: end;
    }
`;

internals.GridItem = Styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.palette.common.white};
    page-break-inside: avoid;

    ${(props) => {

        return props.isActive && S.css`
            background-color: ${props.level};
            color: ${({ theme }) => theme.palette.common.white};
            // make sure background-color is included in the print view
            -webkit-print-color-adjust: exact;
            color-adjust: exact;
        `;
    }}

    ${(props) => {

        return props.firstCol && S.css`
            align-items: flex-end;
            text-align: right;
            padding: ${({ theme }) => theme.spacing(2)}px;
        `;
    }}

    ${(props) => {

        return props.rangeless && S.css`
            padding: ${({ theme }) => theme.spacing(2)}px;
            -webkit-print-color-adjust: exact;
            color-adjust: exact;
            background-color: ${({ theme }) => theme.palette.background.paleGray};
        `;
    }}

    ${(props) => {

        return S.css`
            grid-row-start: ${props.row};
            grid-column-start: ${props.column};
        `;
    }}
`;

// This will probably need to go in the class so we can generate grid columns
// based on screening metrics
internals.ReportGrid = Styled.div`
    display: grid;
    gap: 1px;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    grid-template-columns: 3fr repeat(${(props) => props.numberOfColumns}, 2fr);
`;

internals.RangelessGrid = Styled.div`
    display: grid;
    gap: 1px;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    background-color: ${({ theme }) => theme.palette.background.paleGray};
    grid-template-columns: repeat(${(props) => props.numberOfColumns}, 1fr);
`;

internals.GridTypography = Styled(Typography)`
    && {
        font-size: 1.25rem;
        font-weight: 500;
        text-align: center;
    }
`;

internals.obfuscateName = (firstName, lastName) => lastName.slice(0, 3).concat(firstName[0]);
