const React = require('react');
const NullableTypography = require('./NullableTypography');

const internals = {};

module.exports = ({ isDate, children, otherwise, ...props }) => {

    const display = (typeof children !== 'undefined') ? children : props.value;
    let check = (props.hasOwnProperty('value')) ? props.value : children;
    check = (typeof check === 'string') ? check.trim() : check;

    if (!check && check !== 0) {
        return (
            <NullableTypography {...props}>
                {otherwise ? otherwise : (isDate ? 'Never' : '(none)')}
            </NullableTypography>);
    }

    return (typeof display === 'function') ? display() : display;
};
