const React = require('react');
const T = require('prop-types');
const ErrorFallback = require('./ErrorFallback');
const LoadingFallback = require('./LoadingFallback');
const { default: Styled, ...S } = require('styled-components');
const { default: ErrorBoundary } = require('react-error-boundary');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: Toolbar } = require('@material-ui/core/Toolbar');
const { default: AppBar } = require('@material-ui/core/AppBar');
const { getContrast } = require('polished');

const internals = {};

module.exports = class PatientLayout extends React.Component {

    static propTypes = {
        children: T.any,
        location: T.shape({
            key: T.string
        }),
        fetchData: T.func,
        program: T.shape({
            name: T.string,
            client: T.shape({
                brandColor: T.string
            })
        })
    };

    componentDidMount() {

        window.scrollTo(0, 0);
        this.props.fetchData();
    }

    render() {

        const { Container, AppContainer, LayoutContainer, StyledAppBar, StyledToolbar } = internals;
        const { children, location, program = {} } = this.props;
        const { name: programName, client = {} } = program || {};
        const { brandColor } = client;

        return (
            <LayoutContainer>
                <AppContainer>
                    <StyledAppBar brandColor={brandColor}>
                        <StyledToolbar variant='dense'>
                            <Typography variant='h6'>{programName}</Typography>
                        </StyledToolbar>
                    </StyledAppBar>
                    <Container>
                        <ErrorBoundary key={location.key} FallbackComponent={ErrorFallback}>
                            <React.Suspense fallback={<LoadingFallback />}>
                                {children}
                            </React.Suspense>
                        </ErrorBoundary>
                    </Container>
                    <StyledAppBar brandColor={brandColor}>
                        <StyledToolbar variant='dense'>
                            <Typography variant='body2' align='center'>©{new Date().getFullYear()} Promerica Health – All Rights Reserved</Typography>
                        </StyledToolbar>
                    </StyledAppBar>
                </AppContainer>
            </LayoutContainer>
        );
    }
};

internals.StyledToolbar = Styled(Toolbar)`
    margin: auto;
`;

internals.LayoutContainer = Styled.div`
    display: flex;
`;

internals.StyledAppBar = Styled(AppBar).attrs({ position: 'static', elevation: 0 })`
    ${(props) => {

        if (props.brandColor) {
            const whiteContrast = getContrast(props.brandColor, props.theme.palette.common.white);
            const blackContrast = getContrast(props.brandColor, props.theme.palette.common.black);
            const contrastColor = whiteContrast > blackContrast ? props.theme.palette.common.white : props.theme.palette.common.black;

            return props.brandColor && S.css`
                background-color: ${props.brandColor};
                color: ${contrastColor}; 
            `;
        }
    }}
`;

internals.Container = Styled.div`
    display: flex;
    padding-top:10px;
    background-color: ${({ theme }) => theme.palette.background.paleGray};

    // Alternatively,the Toolbar component in <Header /> could be given
    // a disableGutters prop, and the left/right padding could be applied
    // to the entire AppContainer.
    padding-left: ${({ theme }) => theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
    flex: 1;
    @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
        padding-left: ${(props) => props.theme.spacing(3)}px;
        padding-right: ${(props) => props.theme.spacing(3)}px;
    }
`;

internals.AppContainer = Styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;
