const React = require('react');
const { NavLink } = require('react-router-dom');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: Link } = require('@material-ui/core/Link');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: Button } = require('@material-ui/core/Button');
const { default: Box } = require('@material-ui/core/Box');
const { default: Alert } = require('@material-ui/lab/Alert');
const StrangeForms = require('strange-forms');
const { default: LogoImage } = require('../../../img/promerica-mark.svg');
const { default: LandingPic } = require('../../../img/landing-pic.jpg');
const RedirectRole = require('../../../utils/redirect-role');

const internals = {};

module.exports = class LoginPage extends StrangeForms(React.Component) {

    static propTypes = {
        login: T.func.isRequired,
        isAuthenticated: T.bool,
        role: T.string
    };

    static fields = {
        email: '',
        password: ''
    };

    constructor(props) {

        super(props);

        this.state = {
            email: '',
            password: '',
            isSubmitting: false,
            error: null
        };

        this.strangeForm({
            fields: Object.keys(LoginPage.fields),
            get: () => '',
            act: () => null
        });
    }

    shouldDisableSubmit() {

        return !this.fieldValue('password') || !this.fieldValue('email');
    }

    componentDidMount() {

        if (this.props.isAuthenticated) {
            RedirectRole(this.props);
        }
    }

    handleSubmit = async (ev) => {

        ev.preventDefault();

        const accountInfo = this.formatFields();

        this.setState({ isSubmitting: true });

        const [err] = await this.props.login(accountInfo);

        this.setState({ isSubmitting: false });

        if (err) {
            this.setState({ error: err });
        }
        else {
            RedirectRole(this.props);
        }
    }

    formatFields = () => {

        return {
            email: this.fieldValue('email'),
            password: this.fieldValue('password')
        };
    };

    render() {

        const { error, isSubmitting } = this.state;
        const { PageContainer, FormContainer, StyledForm, SiteLogo, Background, Headline, StyledButton, ForgotLink, StyledAlert } = internals;

        return (
            <PageContainer>
                <FormContainer>
                    <SiteLogo src={LogoImage} alt='Promerica Logo' />
                    <Typography variant='h4' align='center' gutterBottom>Log In</Typography>
                    {error && <StyledAlert severity='error'>Your email or password is incorrect</StyledAlert>}
                    <StyledForm onSubmit={this.handleSubmit}>
                        <TextField
                            required
                            type='email'
                            label='Email'
                            value={this.fieldValue('email')}
                            onChange={this.proposeNew('email')}
                        />
                        <TextField
                            required
                            type='password'
                            label='Password'
                            value={this.fieldValue('password')}
                            onChange={this.proposeNew('password')}
                        />
                        <ForgotLink
                            to='/forgot-password'
                            component={NavLink}
                        >
                            Forgot password?
                        </ForgotLink>
                        <Box
                            my={2}
                        >
                            <StyledButton
                                type='submit'
                                variant='contained'
                                color='primary'
                                fullWidth
                                disabled={this.shouldDisableSubmit() || isSubmitting}
                            >
                                Log In
                            </StyledButton>
                        </Box>
                    </StyledForm>
                </FormContainer>
                <Background>
                    <Headline>Welcome to Promerica Health</Headline>
                </Background>
            </PageContainer>
        );
    }
};

internals.StyledForm = Styled.form`
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: 0 auto;
`;

internals.StyledButton = Styled(Button)`
    margin: 0;
`;

internals.Background = Styled.div`
    position: relative;
    width: auto;
    flex: 1;
    background-image: url(${LandingPic});
    background-size: cover;
    background-position: 70% center;

    @media(max-width: 700px) {
        display: none;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: ${(props) => (props.theme.palette.primary.dark)};
        opacity: 0.8;
    }
`;

internals.Headline = Styled(Typography)`
    position: absolute;
    width: 100%;
    padding: ${(props) => props.theme.spacing(2)}px;
    color: ${({ theme }) => theme.palette.common.white};
    top: 50%;
    transform: translateY(-50%);
    font-size: ${(props) => (props.theme.typography.fontSize * 4)}px;
    font-weight: bold;
    text-align: center;
    z-index: 10;

    @media(max-width: 1200px) {
        font-size: ${(props) => (props.theme.typography.fontSize * 3)}px;
    }

    @media(max-width: 900px) {
        font-size: ${(props) => (props.theme.typography.fontSize * 2)}px;
    }
`;

internals.PageContainer = Styled.div`
    display: flex;
    width: 100%;
`;

internals.FormContainer = Styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    width: 100%;
    max-width: 700px;
    padding: ${(props) => props.theme.spacing(3)}px;
    background: white;
`;

internals.SiteLogo = Styled.img`
    display: block;
    margin: 0 auto ${(props) => props.theme.spacing(6)}px;
    height: 100px;
`;

internals.ForgotLink = Styled(Link)`
    text-align: right;
`;

internals.StyledAlert = Styled(Alert)`
    width: 100%;
    max-width: 300px;
    margin: ${(props) => props.theme.spacing(2)}px auto ${(props) => props.theme.spacing(1)}px;
`;
