const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const Portlet = require('../../../components/Portlet');
const SectionLabel = require('../../../components/SectionLabel');
const CustomFormConsumer = require('../../../components/CustomFormConsumer');

const internals = {};

module.exports = class RegistrationFormPage extends React.Component {

    static propTypes = {
        fetchData: T.func.isRequired,
        fetchForm: T.func.isRequired,
        fetchFormSubmission: T.func.isRequired,
        submitFormSubmission: T.func.isRequired,
        program: T.object,
        patient: T.object,
        history: T.object
    };

    componentDidMount() {

        this.props.fetchData();
    }

    onSubmit = async (...args) => {

        const { program, patient, history, submitFormSubmission } = this.props;

        const [err] = await submitFormSubmission(...args);

        if (!err) {
            return history.push(`/portal/${program.id}/schedule/${patient.id}`);
        }
    }

    render() {

        const {
            PageContainer,
            StyledPortlet,
            PortletBodyContainer
        } = internals;

        const { program, patient, fetchForm, fetchFormSubmission } = this.props;

        return (
            <PageContainer>
                <StyledPortlet
                    body={
                        <PortletBodyContainer>
                            <SectionLabel>
                                SCHEDULE APPOINTMENT - STEP 2 / 3<br />
                                <em style={{ fontWeight: 'normal' }}>Use Chrome For Best Experience; Do Not Use Internet Explorer.</em>
                            </SectionLabel>
                            {program && patient && (
                                <CustomFormConsumer
                                    fetchForm={fetchForm}
                                    fetchFormSubmission={fetchFormSubmission}
                                    submitFormSubmission={this.onSubmit}
                                    program={program}
                                    patient={patient}
                                />
                            )}
                        </PortletBodyContainer>
                    }
                />
            </PageContainer>
        );
    }
};

internals.PageContainer = Styled.div`
    display: flex;
    width: 100%;
    max-width: ${({ theme }) => theme.spacing(100)}px;
    margin: 0 auto;

    flex-direction: column;

    ${({ theme }) => theme.breakpoints.up('sm')} {
        align-items: flex-start;
        flex-direction: row;
    }

`;

internals.StyledPortlet = Styled(Portlet)`
    
    ${({ theme }) => theme.breakpoints.up('sm')} {
        flex: 1
    }
    margin: ${({ theme }) => theme.spacing(1)}px;
`;

internals.PortletBodyContainer = Styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;
