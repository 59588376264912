const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const Flat = require('flat');

const Theme = require('../../../theme');
const { default: Select } = require('@material-ui/core/Select');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: InputAdornment } = require('@material-ui/core/InputAdornment');
const { default: Checkbox } = require('@material-ui/core/Checkbox');
const { default: MenuItem } = require('@material-ui/core/MenuItem');
const { default: Chip } = require('@material-ui/core/Chip');
const { default: FormControl } = require('@material-ui/core/FormControl');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: SearchIcon } = require('@material-ui/icons/Search');

const Portlet = require('../../../components/Portlet');
const Nullable = require('../../../components/Nullable');
const Table = require('../../../components/Table');
const AlertDialog = require('../../../components/AlertDialog');
const GetFormattedDate = require('../../../utils/get-formatted-date');

const NBSP = '\u00A0';

const internals = {};

module.exports = class EmailScreeningResultsPage extends React.Component {

    static propTypes = {
        screenings: T.arrayOf({
            id: T.string,
            createdAt: T.instanceOf(Date),
            patient: T.object
        }),
        fetchScreenings: T.func.isRequired,
        onSubmit: T.func.isRequired
    };

    constructor(props) {

        super(props);

        this.state = {
            sentEmailFilter: 'notSent',
            filterText: '',
            selectAllChecked: false,
            orderBy: '',
            orderDirection: 'desc',
            // Each screening will have a checkbox value keyed by id
            checkboxes: {},
            sentFilter: null
        };
    }

    // Helps us fake dot syntax to config our table columns more easily
    formatDataForTable = (screenings) => screenings.map(Flat)

    columns = () => {

        const {
            selectAllChecked,
            checkboxes
        } = this.state;

        // Dot syntax for patient here is faked — see this.formatDataForTable()
        return [
            {
                id: 'id',
                header: (
                    <Checkbox
                        checked={selectAllChecked}
                        onChange={this.handleToggleSelectAllChecked}
                    />
                ),
                format: (id) => (

                    <Checkbox
                        checked={checkboxes[id] || false}
                        onChange={this.handleToggleScreeningCheckbox(id)}
                    />
                )
            },
            {
                id: 'patient.firstName',
                header: 'First Name',
                label: true,
                sortable: true,
                format: (name) => <Nullable value={name} />
            },
            {
                id: 'patient.lastName',
                header: 'Last Name',
                label: true,
                sortable: true,
                format: (name) => <Nullable value={name} />
            },
            {
                id: 'patient.email',
                header: 'Email',
                label: true,
                sortable: true
            },
            {
                id: 'sentResultsEmail',
                header: 'Results email sent',
                label: true,
                sortable: true,
                format: (emailSent) => {

                    return emailSent ? <Typography style={{ color: Theme.palette.success.main }}>Email sent</Typography> : <Typography style={{ color: Theme.palette.error.main }}>Email NOT sent</Typography>;
                }
            },
            {
                id: 'appointment.event.name',
                header: `Event${NBSP}name`,
                label: true,
                sortable: true
            },
            {
                id: 'appointment.event.date',
                // Here for grepping
                // Event date
                header: `Event${NBSP}date`,
                label: true,
                sortable: true,
                format: (date) => <Nullable value={GetFormattedDate(date)} />
            }
        ];
    }

    componentDidMount() {

        this.props.fetchScreenings();
    }

    visibleScreenings = () => {

        const { screenings } = this.props;
        const { compareFn, filterText, sentEmailFilter } = this.state;

        let visibleScreenings = screenings;

        // Sent email filter
        if (sentEmailFilter === 'sent') {
            visibleScreenings = visibleScreenings.filter(({ sentResultsEmail }) => !!sentResultsEmail);
        }
        else if (sentEmailFilter === 'notSent') {
            visibleScreenings = visibleScreenings.filter(({ sentResultsEmail }) => !sentResultsEmail);
        }

        // Search filter
        if (filterText) {
            visibleScreenings = visibleScreenings.filter(({ patient: { firstName, lastName, email } }) => {

                const lowerFilterText = filterText.toLowerCase();

                return firstName.toLowerCase().includes(lowerFilterText) ||
                    lastName.toLowerCase().includes(lowerFilterText) ||
                    email.toLowerCase().includes(lowerFilterText);
            });
        }

        // Table sorting
        if (compareFn) {
            const sortedRows = compareFn(this.formatDataForTable([...visibleScreenings]));
            visibleScreenings = sortedRows.map(({ id }) => this.getScreeningById(id));
        }

        return visibleScreenings;
    }

    getScreeningById = (id) => {

        return this.props.screenings.find(({ id: screeningId }) => screeningId === id);
    }

    checkedScreeningIds = () => {

        const { checkboxes } = this.state;

        return this.visibleScreenings().filter(({ id }) => !!checkboxes[id])
            .map(({ id }) => id);
    }

    handleRequestSort = (evt, order) => {

        const { orderBy, orderDirection, compareFn } = order;

        this.setState({
            orderBy,
            orderDirection,
            compareFn
        });
    }

    getStateSettingAllCheckboxesTo = (val) => {

        const { screenings } = this.props;
        const { checkboxes } = this.state;

        return screenings.reduce((state, { id }) => ({
            ...state,
            checkboxes: {
                ...state.checkboxes,
                [id]: val
            }
        }), { checkboxes });
    }

    handleToggleSelectAllChecked = () => {

        this.setState(({ selectAllChecked }) => {

            const newState = {
                selectAllChecked: !selectAllChecked
            };

            if (selectAllChecked) {
                return {
                    ...newState,
                    ...this.getStateSettingAllCheckboxesTo(false)
                };
            }

            return {
                ...newState,
                ...this.getStateSettingAllCheckboxesTo(true)
            };
        });
    }

    handleFilterTextChange = (evt) => {

        this.setState({ filterText: evt.target.value });
    }

    handleToggleScreeningCheckbox = (id) => {

        return () => {

            this.setState(({ checkboxes, selectAllChecked }) => ({
                selectAllChecked: checkboxes[id] ? false : selectAllChecked,
                checkboxes: {
                    ...checkboxes,
                    [id]: !checkboxes[id]
                }
            }));
        };
    }

    handleSetEmailSentFilter = (evt) => {

        this.setState({ sentEmailFilter: evt.target.value });
    }

    toggleDialog = () => {

        this.setState(({ showConfirmationModal }) => {

            return { showConfirmationModal: !showConfirmationModal };
        });
    }

    onSubmit = async () => {

        const [err] = await this.props.onSubmit(this.checkedScreeningIds());

        if (!err) {
            this.setState({
                selectAllChecked: false,
                ...this.getStateSettingAllCheckboxesTo(false)
            });
        }
    }

    render() {

        const { PageContainer, FieldsWrapper } = internals;

        const {
            showConfirmationModal,
            orderDirection,
            orderBy,
            filterText,
            sentEmailFilter
        } = this.state;

        const checkedScreeningIds = this.checkedScreeningIds();
        const tableData = this.formatDataForTable(this.visibleScreenings());

        return (
            <PageContainer>
                <Portlet
                    title='Secure Email Results Information'
                    toolbar={
                        <Chip
                            disabled={!checkedScreeningIds.length}
                            color='secondary'
                            label='Email Results Now'
                            clickable
                            onClick={() => this.toggleDialog()}
                        />
                    }
                    body={
                        <>
                            <FieldsWrapper>
                                <TextField
                                    InputProps={{
                                        startAdornment: <InputAdornment position='start'><SearchIcon /></InputAdornment>
                                    }}
                                    label='Search'
                                    value={filterText}
                                    onChange={this.handleFilterTextChange}
                                    margin='dense'
                                />
                                <FormControl margin='dense'>
                                    <Select
                                        displayEmpty
                                        value={sentEmailFilter}
                                        onChange={this.handleSetEmailSentFilter}
                                    >
                                        <MenuItem value=''><em>View all emails</em></MenuItem>
                                        <MenuItem value='sent'>Sent email</MenuItem>
                                        <MenuItem value='notSent'>Email not sent</MenuItem>
                                    </Select>
                                </FormControl>
                            </FieldsWrapper>
                            <Table.Wrapper>
                                <Table
                                    columns={this.columns()}
                                    idAttribute='id'
                                    orderBy={orderBy}
                                    orderDirection={orderDirection}
                                    onRequestSort={this.handleRequestSort}
                                >
                                    <Table.Rows data={tableData} />
                                </Table>
                            </Table.Wrapper>
                        </>
                    }
                />
                <AlertDialog
                    dialogTitle='Send results emails?'
                    dialogDescription={`Are you sure you want to send out ${checkedScreeningIds.length} results?`}
                    affirmativeLabel='Send'
                    isModalOpen={showConfirmationModal}
                    confirmAction={this.onSubmit}
                    toggleModal={this.toggleDialog}
                />
            </PageContainer>
        );
    }
};

internals.StyledForm = Styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

internals.PageContainer = Styled.div`
    width: 100%;
`;

internals.StyledSelect = Styled(Select)`
    margin-right: ${({ theme }) => theme.spacing(2)}px;
`;

internals.FieldsWrapper = Styled.div`
    display: flex;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    > * {
        margin-right: ${({ theme }) => theme.spacing(1)}px;
    }
`;
