const React = require('react');
const { default: Styled } = require('styled-components');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: Typography } = require('@material-ui/core/Typography');
const StrangeForms = require('strange-forms');
const SectionLabel = require('./SectionLabel');
const StateSelect = require('./StateSelect');
const GetFormattedDate = require('../utils/get-formatted-date');

const internals = {};

module.exports = class EventFields extends StrangeForms(React.Component) {

    static fields = [
        'name',
        'location',
        'locationCode',
        'date',
        'address1',
        'address2',
        'city',
        'state',
        'zip',
        'notes',
        'fax',
        'contact',
        'telephone',
        'startTime',
        'endTime'
    ];

    constructor(props) {

        super(props);

        this.state = {
            isSubmitting: false,
            dialogName: null
        };

        this.strangeForm({
            fields: EventFields.fields,
            get: {
                '*': (someProps, field) => (someProps.fields && someProps.fields[field]) || '',
                date: (someProps, field) => (someProps.fields && someProps.fields[field] && someProps.fields[field].replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$1-$2')) || '',
                contact: (someProps) => (someProps.event && someProps.event.additionalInformation && someProps.event.additionalInformation.contact) || '',
                telephone: (someProps) => (someProps.event && someProps.event.additionalInformation && someProps.event.additionalInformation.telephone) || '',
                fax: (someProps) => (someProps.event && someProps.event.additionalInformation && someProps.event.additionalInformation.fax) || ''
            },
            act: (field, value) => this.props.onChange({ ...this.props.fields, [field]: value }),
            getFormValue: {
                'date': (value) => value || '',
                '*': (ev) => ev.target.value || ''
            }
        });
    }

    render() {

        const { errors = {} } = this.props;
        const { StyledForm } = internals;

        return (
            <>
                <SectionLabel>Event Details</SectionLabel>
                <StyledForm>
                    <TextField
                        label='Event Name'
                        value={this.fieldValue('name')}
                        onChange={this.proposeNew('name')}
                        required
                        error={errors.hasOwnProperty('name')}
                        helperText={errors.hasOwnProperty('name') ? errors.name : ''}
                    />
                    <TextField
                        label='Event Location'
                        value={this.fieldValue('location')}
                        onChange={this.proposeNew('location')}
                        required
                        error={errors.hasOwnProperty('location')}
                        helperText={errors.hasOwnProperty('location') ? errors.location : ''}
                    />
                    <TextField
                        label='Location Code'
                        value={this.fieldValue('locationCode')}
                        onChange={this.proposeNew('locationCode')}
                    />
                    <TextField
                        label='Address 1'
                        value={this.fieldValue('address1')}
                        onChange={this.proposeNew('address1')}
                        required
                        error={errors.hasOwnProperty('address1')}
                        helperText={errors.hasOwnProperty('address1') ? errors.address1 : ''}
                    />
                    <TextField
                        label='Address 2'
                        value={this.fieldValue('address2')}
                        onChange={this.proposeNew('address2')}
                    />
                    <TextField
                        label='City'
                        value={this.fieldValue('city')}
                        onChange={this.proposeNew('city')}
                        required
                        error={errors.hasOwnProperty('city')}
                        helperText={errors.hasOwnProperty('city') ? errors.city : ''}
                    />
                    <StateSelect
                        className='small'
                        value={this.fieldValue('state')}
                        onChange={this.proposeNew('state')}
                        required
                        error={errors.hasOwnProperty('state')}
                        helperText={errors.hasOwnProperty('state') ? errors.state : ''}
                    />
                    <TextField
                        className='small'
                        label='Zip'
                        value={this.fieldValue('zip')}
                        onChange={this.proposeNew('zip')}
                        required
                        error={errors.hasOwnProperty('zip')}
                        helperText={errors.hasOwnProperty('zip') ? errors.zip : ''}
                    />
                    <TextField
                        label='Event Date'
                        type='date'
                        value={this.fieldValue('date')}
                        onChange={(e) => this.proposeNew('date')(GetFormattedDate(e.target.value))}
                        margin='dense'
                        InputLabelProps={{
                            shrink: true
                        }}
                        required
                        error={errors.hasOwnProperty('date')}
                        helperText={errors.hasOwnProperty('date') ? errors.date : ''}
                    />
                    <TextField
                        className='small'
                        label='Start Time'
                        type='time'
                        value={this.fieldValue('startTime')}
                        onChange={this.proposeNew('startTime')}
                        InputLabelProps={{
                            shrink: true
                        }}
                        inputProps={{
                            step: 300 // 5 min
                        }}
                    />
                    <TextField
                        className='small'
                        label='End Time'
                        type='time'
                        value={this.fieldValue('endTime')}
                        onChange={this.proposeNew('endTime')}
                        InputLabelProps={{
                            shrink: true
                        }}
                        inputProps={{
                            step: 300 // 5 min
                        }}
                    />
                    <TextField
                        multiline
                        label='Notes'
                        value={this.fieldValue('notes')}
                        onChange={this.proposeNew('notes')}
                    />
                    {this.props.event && this.props.event.program.isCovidTesting &&
                        <>
                            <Typography>COVID Testing Information</Typography>
                            <TextField
                                label='Contact Name'
                                value={this.fieldValue('contact')}
                                onChange={this.proposeNew('contact')}
                            />
                            <TextField
                                label='Phone Number'
                                value={this.fieldValue('telephone')}
                                onChange={this.proposeNew('telephone')}
                            />
                            <TextField
                                label='Fax'
                                value={this.fieldValue('fax')}
                                onChange={this.proposeNew('fax')}
                            />
                        </>}
                </StyledForm>
            </>
        );
    }
};

internals.StyledForm = Styled.form`
    display: grid;
    gap: 0 ${({ theme }) => theme.spacing(1)}px;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    >div {
        grid-column: span 2;
    }
    .small {
        grid-column: span 1;
    }
`;
