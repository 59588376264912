const Connect = require('react-redux').connect;
const ParticipationReportPage = require('../components/ParticipationReportPage');
const M = require('../../../middle-end');

const internals = {};

internals.connect = Connect(
    (state) => ({
        participationResults: M.selectors.model.fetchParticipationResult(state)
    }),
    (dispatch, props) => ({
        fetchData: async () => {

            await M.dispatch.model.fetchParticipationReport(props.match.params.id);
        }
    })
);

module.exports = internals.connect(ParticipationReportPage);
