const React = require('react');
const T = require('prop-types');
const StrangeForms = require('strange-forms');
const DateMaskInput = require('./DateMaskInput');
const { default: Styled } = require('styled-components');
const { default: Dialog } = require('@material-ui/core/Dialog');
const { default: DialogActions } = require('@material-ui/core/DialogActions');
const { default: DialogContent } = require('@material-ui/core/DialogContent');
const { default: DialogContentText } = require('@material-ui/core/DialogContentText');
const { default: DialogTitle } = require('@material-ui/core/DialogTitle');
const { default: Button } = require('@material-ui/core/Button');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: FormControlLabel } = require('@material-ui/core/FormControlLabel');
const { default: Checkbox } = require('@material-ui/core/Checkbox');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: IconButton } = require('@material-ui/core/IconButton');
const { default: FormHelperText } = require('@material-ui/core/FormHelperText');
const { default: Link } = require('@material-ui/core/Link');
const { default: CloseIcon } = require('@material-ui/icons/Close');
const GetDateString = require('../utils/get-date-string');

const internals = {};

module.exports = class ConsentDialog extends StrangeForms(React.Component) {

    static propTypes = {
        onConfirmAction: T.func.isRequired,
        dialogTitle: T.string,
        dialogDescription: T.oneOfType([T.string, T.node]),
        affirmativeLabel: T.string,
        negativeLabel: T.oneOfType([T.string, T.oneOf([null])]),
        consentFormUrl: T.string.isRequired,
        toggleModal: T.func.isRequired,
        isModalOpen: T.bool
    };

    static defaultProps = {
        affirmativeLabel: 'Submit',
        negativeLabel: 'Cancel',
        isModalOpen: true
    };

    static fields = [
        'consentText',
        'date',
        'doesAgree'
    ];

    constructor(props) {

        super(props);

        this.state = {
            isSubmitting: false
        };

        this._unmounted = false;

        this.strangeForm({
            fields: ConsentDialog.fields,
            get: {
                doesAgree: () => false,
                date: () => GetDateString(new Date(), { padDate: true }),
                '*': (someProps, field) => ''
            },
            act: (field, value) => null,
            getFormValue: {
                'doesAgree': (ev) => ev.target.checked,
                '*': (ev) => ev.target.value
            }
        });
    }

    handleSubmit = async () => {

        this.setState({ isSubmitting: true });

        await this.props.onConfirmAction({
            consentText: this.fieldValue('consentText')
        });

        if (!this._unmounted) {
            this.setState({ isSubmitting: false });
            this.props.toggleModal();
        }
    }

    shouldDisableSubmit = () => {

        if (!this.fieldValue('consentText') ||
            !internals.isToday(new Date(this.fieldValue('date'))) ||
            !this.fieldValue('doesAgree') ||
            this.state.isSubmitting
        ) {
            return true;
        }

        return false;
    }

    componentWillUnmount() {

        // "Necessary" evil since props.onConfirmAction() is likely but not guaranteed to cause an unmount
        this._unmounted = true;
    }

    render() {

        // eslint-disable-next-line no-unused-vars
        const { isModalOpen, toggleModal, dialogTitle, dialogDescription, affirmativeLabel, negativeLabel, onConfirmAction, consentFormUrl, ...others } = this.props;
        const { FieldsContainer, CloseButton, StyledTypography } = internals;

        return (
            <Dialog
                open={isModalOpen}
                onClose={toggleModal}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                {...others}
            >
                <DialogTitle disableTypography id='alert-dialog-title'>
                    <StyledTypography variant='h6'>Please sign the consent form to proceed</StyledTypography>
                    <CloseButton aria-label='close' onClick={toggleModal}>
                        <CloseIcon />
                    </CloseButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        I wish to voluntarily participate in this program or authorize the participation of my child/dependent for whom I scheduled this appointment.
                    </DialogContentText>
                    <DialogContentText>
                        By signing below and checking the I Agree box, I consent to receiving these Screening Services from Promerica Health, and further agree to the terms and conditions outlined in this <Link href={consentFormUrl} target='_blank' rel='noopener noreferrer'>Consent Form</Link>.
                    </DialogContentText>
                    <DialogContentText>
                        To finalize your appointment and provide consent, enter your name below, check the I Agree box, then click Submit.
                    </DialogContentText>
                    <FieldsContainer>
                        <TextField
                            label='Full Name'
                            value={this.fieldValue('consentText')}
                            onChange={this.proposeNew('consentText')}
                        />
                        <TextField
                            label={'Today\'s Date'}
                            value={this.fieldValue('date')}
                            onChange={this.proposeNew('date')}
                            margin='dense'
                            InputProps={{
                                inputComponent: DateMaskInput
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={this.fieldValue('doesAgree')}
                                    checked={this.fieldValue('doesAgree')}
                                    onChange={this.proposeNew('doesAgree')}
                                />
                            }
                            label='I Agree'
                        />
                        <FormHelperText><Link href={consentFormUrl} target='_blank' rel='noopener noreferrer'>Read the full consent form</Link></FormHelperText>
                    </FieldsContainer>
                </DialogContent>
                <DialogActions>
                    {negativeLabel !== null &&
                    <Button onClick={toggleModal}>
                        {negativeLabel}
                    </Button>}
                    <Button
                        onClick={this.handleSubmit}
                        color='primary'
                        variant='contained'
                        disabled={this.shouldDisableSubmit()}
                    >
                        {affirmativeLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
};

internals.isToday = (someDate) => {

    const today = new Date();
    return someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear();
};

internals.FieldsContainer = Styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

internals.StyledTypography = Styled(Typography)`
    // 45px is the size of the CloseButton
    max-width: calc(100% - 45px);
`;

internals.CloseButton = Styled(IconButton)`
    position: absolute;
    right: ${({ theme }) => theme.spacing(1)}px;
    top: ${({ theme }) => theme.spacing(1)}px;
`;
