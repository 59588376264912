const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: Radio } = require('@material-ui/core/Radio');
const { default: RadioGroup } = require('@material-ui/core/RadioGroup');
const { default: FormHelperText } = require('@material-ui/core/FormHelperText');
const { default: FormControlLabel } = require('@material-ui/core/FormControlLabel');
const { default: FormControl } = require('@material-ui/core/FormControl');
const { default: FormLabel } = require('@material-ui/core/FormLabel');
const SectionLabel = require('../components/SectionLabel');
const StrangeForms = require('strange-forms');

const internals = {};

module.exports = class UserFields extends StrangeForms(React.Component) {

    static propTypes = {
        fields: T.object.isRequired
    };

    static fields = {
        name: '',
        phone: '',
        email: '',
        role: ''
    };

    constructor(props) {

        super(props);

        this.strangeForm({
            fields: Object.keys(UserFields.fields),
            get: (someProps, field) => someProps.fields[field],
            act: (field, value) => this.props.onChange({ ...this.props.fields, [field]: value })
        });
    }

    render() {

        const { StyledForm } = internals;

        return (

            <StyledForm>
                <SectionLabel>Contact Info:</SectionLabel>
                <TextField
                    label='Full Name'
                    value={this.fieldValue('name')}
                    onChange={this.proposeNew('name')}
                />
                <TextField
                    label='Phone'
                    value={this.fieldValue('phone')}
                    onChange={this.proposeNew('phone')}
                />
                <TextField
                    required
                    type='email'
                    label='Email'
                    value={this.fieldValue('email')}
                    onChange={this.proposeNew('email')}
                />
                <SectionLabel>Permissions:</SectionLabel>
                <FormControl component='fieldset'>
                    <FormLabel hidden component='legend'>User Role</FormLabel>
                    <RadioGroup
                        aria-label='user role'
                        name='userRole'
                        value={this.fieldValue('role')}
                        onChange={this.proposeNew('role')}
                        row
                    >
                        <FormControlLabel value='superadmin' control={<Radio />} label='Super Admin' />
                        <FormControlLabel value='admin' control={<Radio />} label='Program Admin' />
                        <FormControlLabel value='screener' control={<Radio />} label='Screener' />
                        <FormControlLabel value='manager' control={<Radio />} label='Manager' />
                    </RadioGroup>
                    <FormHelperText>Be sure you understand these before assigning permissions. Ask for help if needed.</FormHelperText>
                </FormControl>
            </StyledForm>
        );
    }
};

internals.StyledForm = Styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
