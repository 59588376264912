const React = require('react');
const T = require('prop-types');
const { NavLink } = require('react-router-dom');
const { default: Divider } = require('@material-ui/core/Divider');
const { default: Styled } = require('styled-components');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: Button } = require('@material-ui/core/Button');
const { default: FormControl } = require('@material-ui/core/FormControl');
const { default: FormLabel } = require('@material-ui/core/FormLabel');
const { default: FormGroup } = require('@material-ui/core/FormGroup');
const { default: FormControlLabel } = require('@material-ui/core/FormControlLabel');
const { default: RadioGroup } = require('@material-ui/core/RadioGroup');
const { default: Radio } = require('@material-ui/core/Radio');
const { default: Checkbox } = require('@material-ui/core/Checkbox');
const StrangeForms = require('strange-forms');
const DateMaskInput = require('./DateMaskInput');
const GetCurrentAppointment = require('../utils/get-current-appointment');
const { API_BASE_URL } = require('../constants');
const SectionLabel = require('./SectionLabel');

const internals = {};

module.exports = class CovidForm extends StrangeForms(React.Component) {

    static propTypes = {
        program: T.object,
        patient: T.object,
        screeningId: T.string,
        updatePatient: T.func,
        onConfirm: T.func,
        history: T.object
    };

    // Note keep this in sync with the order of form elements in render
    // We use this ordering to scroll to the top of whichever errored field
    // is closest to the top of the form
    static fields = [
        'gender',
        'hasPreferredLanguage',
        'preferredLanguage',
        'ethnicity',
        'race',
        'wasHospitalized',
        'hospitalizedFacility',
        'isHealthCareWorker',
        'healthCareFacility',
        'isFirstResponder',
        'firstResponderOrganization',
        'isCongregateSetting',
        'congregateFacility',
        'isOver60',
        'isPregnant',
        'hasFever',
        'hasFatigue',
        'hasHeadache',
        'hasNausea',
        'hasCough',
        'hasMuscleAches',
        'hasSoreThroat',
        'hasDiarrhea',
        'hasShortnessOfBreath',
        'hasSenseLoss',
        'hasCongestion',
        'dateOfOnset',
        'hasUnderlyingConditions',
        'underlyingConditions',
        'airline',
        'flightNumber',
        'seatNumber',
        'travelCircumstance',
        'airport',
        'flightDate'
    ]

    static requiredFields = [
        'gender',
        'race',
        'ethnicity',
        'wasHospitalized',
        'isHealthCareWorker'
    ]

    constructor(props) {

        super(props);

        this.state = {
            isSubmitting: false,
            errors: {}
        };

        this.strangeForm({
            fields: CovidForm.fields,
            get: (someProps, field) => someProps.patient && someProps.patient.additionalInformation && someProps.patient.additionalInformation[field] || '',
            act: () => null,
            getFormValue: {
                '*': (ev) => (ev.target.type === 'checkbox' ? ev.target.checked : (ev.target.value || ''))
            }
        });

        this.formRefs = CovidForm.fields.reduce((collector, field) => ({
            ...collector,
            [field]: React.createRef()
        }), {});
    }

    handleSubmit = async (ev) => {

        ev.preventDefault();

        const covidInformation = CovidForm.fields.reduce((acc, field) => ({ ...acc, [field]: this.fieldValue(field) }), {});

        const errors = this.validate(covidInformation);

        this.setState({ errors });

        const errKeys = Object.keys(errors);

        if (errKeys.length) {

            // Note, it's for this reason that CovidForm.fields should be
            // kept in-sync with the actual order of fields in the form.
            // This helps for both documentation when reading the fields in code,
            // and for right here too!
            const topmostFormField = CovidForm.fields.find((field) => errKeys.includes(field));

            const ref = this.formRefs[topmostFormField];

            if (ref && ref.current) {
                window.scrollBy(0, ref.current.getBoundingClientRect().top - 50);
            }

            return;
        }

        this.setState({ isSubmitting: true });

        // eslint-disable-next-line no-unused-vars
        const [_, { result }] = await this.props.updatePatient({
            additionalInformation: covidInformation,
            id: this.props.patient.id
        });

        const {
            history,
            buildScheduleLink
        } = this.props;

        if (!this.props.onConfirm && result) {
            return history.push(
                buildScheduleLink({
                    programId: this.props.program.id,
                    patientId: result
                })
            );
        }

        this.setState({ isSubmitting: false });
    }

    handleConfirm = async (ev) => {

        ev.preventDefault();

        this.setState({ isSubmitting: true });

        await this.props.onConfirm();

        this.setState({ isSubmitting: false });
    }

    validate = (values) => {

        const errors = {};

        CovidForm.requiredFields
            .filter((field) => !values[field])
            .forEach((field) => {

                errors[field] = 'Required';
            });

        if (values.hasPreferredLanguage && !values.preferredLanguage) {
            errors.preferredLanguage = 'Must specify preferred language';
        }

        if (values.wasHospitalized === 'Yes' && !values.hospitalizedFacility) {
            errors.hospitalizedFacility = 'Must specify facility';
        }

        if (values.isHealthCareWorker === 'Yes' && !values.healthCareFacility) {
            errors.healthCareFacility = 'Must specify facility';
        }

        if (values.isFirstResponder === 'Yes' && !values.firstResponderOrganization) {
            errors.firstResponderOrganization = 'Must specify organization';
        }

        if (values.isCongregateSetting === 'Yes' && !values.congregateFacility) {
            errors.congregateFacility = 'Must specify facility';
        }

        if (values.hasUnderlyingConditions && !values.underlyingConditions) {
            errors.underlyingConditions = 'Must specify underlying conditions';
        }

        if (values.dateOfOnset && !(/\d{2}\/\d{2}\/\d{4}/.test(values.dateOfOnset))) {
            errors.dateOfOnset = 'Date must be mm/dd/yyyy';
        }

        if (!values.dateOfOnset && (
            values.hasFever ||
            values.hasFatigue ||
            values.hasHeadache ||
            values.hasNausea ||
            values.hasCough ||
            values.hasMuscleAches ||
            values.hasSoreThroat ||
            values.hasDiarrhea)) {
            errors.dateOfOnset = 'Must specify date of symptom onset';
        }

        return errors;
    }

    shouldDisableSubmit() {

        return (
            this.state.isSubmitting
        );
    }

    render() {

        if (!this.props.patient) {
            return null;
        }

        const {
            FieldsWrapperForm,
            PortletBodyContainer,
            StyledFormControl,
            StyledFormLabel,
            StyledFormGroup,
            FormRow,
            errorProps
        } = internals;

        const { errors } = this.state;
        const { patient } = this.props;

        const appointment = patient && patient.appointments ? GetCurrentAppointment(this.props.patient.appointments) || {} : {};

        const fieldErrorProps = errorProps.bind(null, errors);

        return (
            <PortletBodyContainer>
                <SectionLabel>
                    SCHEDULE APPOINTMENT - STEP 2 / 3<br />
                    <em style={{ fontWeight: 'normal' }}>Use Chrome For Best Experience; Do Not Use Internet Explorer.</em>
                </SectionLabel>
                <FieldsWrapperForm onSubmit={this.handleSubmit}>
                    <StyledFormControl
                        required
                        component='fieldset'
                        {...fieldErrorProps('gender')}
                        ref={this.formRefs.gender}
                    >
                        <StyledFormLabel component='legend'>Gender</StyledFormLabel>
                        <RadioGroup
                            aria-label='gender'
                            name='gender'
                            value={this.fieldValue('gender')}
                            onChange={this.proposeNew('gender')}
                        >
                            <FormControlLabel value='Male' control={<Radio />} label='Male' />
                            <FormControlLabel value='Female' control={<Radio />} label='Female' />
                            <FormControlLabel value='Transgender M-F' control={<Radio />} label='Transgender M-F' />
                            <FormControlLabel value='Transgender F-M' control={<Radio />} label='Transgender F-M' />
                            <FormControlLabel value='Not Specified' control={<Radio />} label='Not Specified' />
                            <FormControlLabel value='Unknown' control={<Radio />} label='Unknown' />
                        </RadioGroup>
                    </StyledFormControl>
                    <StyledFormControl component='fieldset'>
                        <StyledFormLabel component='legend'>Patient preferred language, if not English</StyledFormLabel>
                        <FormGroup>
                            <FormRow>
                                <FormControlLabel
                                    control={<Checkbox checked={this.fieldValue('hasPreferredLanguage')} onChange={this.proposeNew('hasPreferredLanguage')} name='hasPreferredLanguage' />}
                                />
                                <TextField
                                    placeholder='Please specify'
                                    value={this.fieldValue('preferredLanguage')}
                                    onChange={this.proposeNew('preferredLanguage')}
                                    disabled={!this.fieldValue('hasPreferredLanguage')}
                                    error={this.fieldValue('hasPreferredLanguage') && !this.fieldValue('preferredLanguage')}
                                    helperText={(this.fieldValue('hasPreferredLanguage') && !this.fieldValue('preferredLanguage')) ? errors.preferredLanguage || '' : ''}
                                    ref={this.formRefs.preferredLanguage}
                                />
                            </FormRow>
                        </FormGroup>
                    </StyledFormControl>
                    <StyledFormControl
                        required
                        component='fieldset'
                        {...fieldErrorProps('ethnicity')}
                        ref={this.formRefs.ethnicity}
                    >
                        <StyledFormLabel component='legend'>Ethnicity</StyledFormLabel>
                        <RadioGroup
                            aria-label='ethnicity'
                            name='ethnicity'
                            value={this.fieldValue('ethnicity')}
                            onChange={this.proposeNew('ethnicity')}
                        >
                            <FormControlLabel value='Hispanic or Latino' control={<Radio />} label='Hispanic or Latino' />
                            <FormControlLabel value='Non-Hispanic or Latino' control={<Radio />} label='Non-Hispanic or Latino' />
                            <FormControlLabel value='Not Specified' control={<Radio />} label='Not Specified' />
                        </RadioGroup>
                    </StyledFormControl>
                    <StyledFormControl
                        required
                        component='fieldset'
                        {...fieldErrorProps('race')}
                        ref={this.formRefs.race}
                    >
                        <StyledFormLabel component='legend'>Race</StyledFormLabel>
                        <RadioGroup
                            aria-label='race'
                            name='race'
                            value={this.fieldValue('race')}
                            onChange={this.proposeNew('race')}
                        >
                            <FormControlLabel value='American Indian or Alaskan Native' control={<Radio />} label='American Indian or Alaskan Native' />
                            <FormControlLabel value='Asian' control={<Radio />} label='Asian' />
                            <FormControlLabel value='Black or African American' control={<Radio />} label='Black or African American' />
                            <FormControlLabel value='Native Hawaiian or other Pacific Islander' control={<Radio />} label='Native Hawaiian or other Pacific Islander' />
                            <FormControlLabel value='Not Specified' control={<Radio />} label='Not Specified' />
                            <FormControlLabel value='Some other race' control={<Radio />} label='Some other race' />
                            <FormControlLabel value='Two or more races' control={<Radio />} label='Two or more races' />
                            <FormControlLabel value='White' control={<Radio />} label='White' />
                        </RadioGroup>
                    </StyledFormControl>
                    <StyledFormControl component='fieldset'>
                        <StyledFormLabel component='legend'>Special Conditions: Please indicate if any of the following conditions exist</StyledFormLabel>
                        <FormControl
                            required
                            {...fieldErrorProps('wasHospitalized')}
                            ref={this.formRefs.wasHospitalized}
                        >
                            <StyledFormGroup>
                                <FormLabel component='legend'>Hospitalized</FormLabel>
                                <FormRow>
                                    <RadioGroup
                                        aria-label='wasHospitalized'
                                        name='wasHospitalized'
                                        value={this.fieldValue('wasHospitalized')}
                                        onChange={this.proposeNew('wasHospitalized')}
                                    >
                                        <FormControlLabel value='Yes' control={<Radio />} label='Yes' />
                                        <FormControlLabel value='No' control={<Radio />} label='No' />
                                    </RadioGroup>
                                    <TextField
                                        label='Facility name'
                                        value={this.fieldValue('hospitalizedFacility')}
                                        onChange={this.proposeNew('hospitalizedFacility')}
                                        disabled={(this.fieldValue('wasHospitalized') !== 'Yes')}
                                        {...fieldErrorProps('hospitalizedFacility')}
                                        ref={this.formRefs.hospitalizedFacility}
                                    />
                                </FormRow>
                            </StyledFormGroup>
                        </FormControl>
                        <FormControl
                            required
                            {...fieldErrorProps('isHealthCareWorker')}
                            ref={this.formRefs.isHealthCareWorker}
                        >
                            <StyledFormGroup>
                                <FormLabel component='legend'>Health Care Worker</FormLabel>
                                <FormRow>
                                    <RadioGroup
                                        aria-label='isHealthCareWorker'
                                        name='isHealthCareWorker'
                                        value={this.fieldValue('isHealthCareWorker')}
                                        onChange={this.proposeNew('isHealthCareWorker')}
                                    >
                                        <FormControlLabel value='Yes' control={<Radio />} label='Yes' />
                                        <FormControlLabel value='No' control={<Radio />} label='No' />
                                    </RadioGroup>
                                    <TextField
                                        label='Facility name'
                                        value={this.fieldValue('healthCareFacility')}
                                        onChange={this.proposeNew('healthCareFacility')}
                                        disabled={(this.fieldValue('isHealthCareWorker') !== 'Yes')}
                                        {...fieldErrorProps('healthCareFacility')}
                                        ref={this.formRefs.healthCareFacility}
                                    />
                                </FormRow>
                            </StyledFormGroup>
                        </FormControl>
                        <StyledFormGroup>
                            <FormLabel component='legend'>First Responder (Police, Fire, EMS)</FormLabel>
                            <FormRow>
                                <RadioGroup aria-label='isFirstResponder' name='isFirstResponder' value={this.fieldValue('isFirstResponder')} onChange={this.proposeNew('isFirstResponder')}>
                                    <FormControlLabel value='Yes' control={<Radio />} label='Yes' />
                                    <FormControlLabel value='No' control={<Radio />} label='No' />
                                </RadioGroup>
                                <TextField
                                    label='Facility name'
                                    value={this.fieldValue('firstResponderOrganization')}
                                    onChange={this.proposeNew('firstResponderOrganization')}
                                    disabled={(this.fieldValue('isFirstResponder') !== 'Yes')}
                                    {...fieldErrorProps('firstResponderOrganization')}
                                    ref={this.formRefs.firstResponderOrganization}
                                />
                            </FormRow>
                        </StyledFormGroup>
                        <StyledFormGroup>
                            <FormLabel component='legend'>Congregate Setting (LTC, Jail, Shelter, Farm, etc)</FormLabel>
                            <FormRow>
                                <RadioGroup aria-label='isCongregateSetting' name='isCongregateSetting' value={this.fieldValue('isCongregateSetting')} onChange={this.proposeNew('isCongregateSetting')}>
                                    <FormControlLabel value='Yes' control={<Radio />} label='Yes' />
                                    <FormControlLabel value='No' control={<Radio />} label='No' />
                                </RadioGroup>
                                <TextField
                                    label='Facility name'
                                    value={this.fieldValue('congregateFacility')}
                                    onChange={this.proposeNew('congregateFacility')}
                                    disabled={(this.fieldValue('isCongregateSetting') !== 'Yes')}
                                    {...fieldErrorProps('congregateFacility')}
                                    ref={this.formRefs.congregateFacility}
                                />
                            </FormRow>
                        </StyledFormGroup>
                        <StyledFormGroup>
                            <FormLabel component='legend'>Patients older than 60 years</FormLabel>
                            <FormRow>
                                <FormControl ref={this.formRefs.isOver60}>
                                    <RadioGroup aria-label='isOver60' name='isOver60' value={this.fieldValue('isOver60')} onChange={this.proposeNew('isOver60')}>
                                        <FormControlLabel value='Yes' control={<Radio />} label='Yes' />
                                        <FormControlLabel value='No' control={<Radio />} label='No' />
                                    </RadioGroup>
                                </FormControl>
                            </FormRow>
                        </StyledFormGroup>
                        <StyledFormGroup>
                            <FormLabel component='legend'>Pregnant</FormLabel>
                            <FormRow>
                                <FormControl ref={this.formRefs.isPregnant}>
                                    <RadioGroup aria-label='isPregnant' name='isPregnant' value={this.fieldValue('isPregnant')} onChange={this.proposeNew('isPregnant')}>
                                        <FormControlLabel value='Yes' control={<Radio />} label='Yes' />
                                        <FormControlLabel value='No' control={<Radio />} label='No' />
                                    </RadioGroup>
                                </FormControl>
                            </FormRow>
                        </StyledFormGroup>
                    </StyledFormControl>
                    <StyledFormControl component='fieldset'>
                        <StyledFormLabel component='legend'>Symptoms</StyledFormLabel>
                        <FormGroup>
                            <FormControlLabel
                                label='Fever or chills'
                                control={
                                    <Checkbox
                                        checked={this.fieldValue('hasFever')}
                                        onChange={this.proposeNew('hasFever')}
                                        name='hasFever'
                                    />
                                }
                            />
                            <FormControlLabel
                                label='Fatigue'
                                control={
                                    <Checkbox
                                        checked={this.fieldValue('hasFatigue')}
                                        onChange={this.proposeNew('hasFatigue')}
                                        name='hasFatigue'
                                    />
                                }
                            />
                            <FormControlLabel
                                label='Headache'
                                control={
                                    <Checkbox
                                        checked={this.fieldValue('hasHeadache')}
                                        onChange={this.proposeNew('hasHeadache')}
                                        name='hasHeadache'
                                    />
                                }
                            />
                            <FormControlLabel
                                label='Nausea or vomiting'
                                control={
                                    <Checkbox
                                        checked={this.fieldValue('hasNausea')}
                                        onChange={this.proposeNew('hasNausea')}
                                        name='hasNausea'
                                    />
                                }
                            />
                            <FormControlLabel
                                label='Cough'
                                control={
                                    <Checkbox
                                        checked={this.fieldValue('hasCough')}
                                        onChange={this.proposeNew('hasCough')}
                                        name='hasCough'
                                    />
                                }
                            />
                            <FormControlLabel
                                label='Muscle or body aches'
                                control={
                                    <Checkbox
                                        checked={this.fieldValue('hasMuscleAches')}
                                        onChange={this.proposeNew('hasMuscleAches')}
                                        name='hasMuscleAches'
                                    />
                                }
                            />
                            <FormControlLabel
                                label='Sore Throat'
                                control={
                                    <Checkbox
                                        checked={this.fieldValue('hasSoreThroat')}
                                        onChange={this.proposeNew('hasSoreThroat')}
                                        name='hasSoreThroat'
                                    />
                                }
                            />
                            <FormControlLabel
                                label='Diarrhea'
                                control={
                                    <Checkbox
                                        checked={this.fieldValue('hasDiarrhea')}
                                        onChange={this.proposeNew('hasDiarrhea')}
                                        name='hasDiarrhea'
                                    />
                                }
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel
                                label='Shortness of breath or difficulty breathing'
                                control={
                                    <Checkbox
                                        checked={this.fieldValue('hasShortnessOfBreath')}
                                        onChange={this.proposeNew('hasShortnessOfBreath')}
                                        name='hasShortnessOfBreath'
                                    />
                                }
                            />
                            <FormControlLabel
                                label='New loss of taste or smell'
                                control={
                                    <Checkbox
                                        checked={this.fieldValue('hasSenseLoss')}
                                        onChange={this.proposeNew('hasSenseLoss')}
                                        name='hasSenseLoss'
                                    />
                                }
                            />
                            <FormControlLabel
                                label='Congestion or runny nose'
                                control={
                                    <Checkbox
                                        checked={this.fieldValue('hasCongestion')}
                                        onChange={this.proposeNew('hasCongestion')}
                                        name='hasCongestion'
                                    />
                                }
                            />
                        </FormGroup>
                    </StyledFormControl>
                    <StyledFormControl component='fieldset'>
                        <TextField
                            label='Date of symptom onset'
                            type='text'
                            pattern='[0-9]*'
                            placeholder='mm/dd/yyyy'
                            autoComplete='disabled'
                            value={this.fieldValue('dateOfOnset')}
                            onChange={this.proposeNew('dateOfOnset')}
                            InputProps={{
                                inputComponent: DateMaskInput
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            {...fieldErrorProps('dateOfOnset')}
                            ref={this.formRefs.dateOfOnset}
                        />
                    </StyledFormControl>
                    <StyledFormControl component='fieldset'>
                        <StyledFormLabel component='legend'>Patient with underlying medical conditions?</StyledFormLabel>
                        <FormGroup>
                            <FormRow>
                                <FormControlLabel
                                    control={<Checkbox checked={this.fieldValue('hasUnderlyingConditions')} onChange={this.proposeNew('hasUnderlyingConditions')} name='hasUnderlyingConditions' />}
                                />
                                <TextField
                                    placeholder='Please specify'
                                    value={this.fieldValue('underlyingConditions')}
                                    onChange={this.proposeNew('underlyingConditions')}
                                    disabled={!this.fieldValue('hasUnderlyingConditions')}
                                    {...fieldErrorProps('underlyingConditions')}
                                    ref={this.formRefs.underlyingConditions}
                                />
                            </FormRow>
                        </FormGroup>
                    </StyledFormControl>
                    <StyledFormControl component='fieldset'>
                        <StyledFormLabel component='legend'>Flight Information</StyledFormLabel>
                        <p>
                            {/* JSX whitespace rendering can pound sand */}
                            <strong>Attention Air Travelers:</strong>&nbsp;
                            If you have flown in to Maine within the last 72 hours, or plan to fly from Maine over the
                            next 72 hours, please provide additional information.<br />
                            <em>
                                Note: If both are true, please provide your “arrival” (previous flight) information in the
                                fields below.
                            </em>
                        </p>
                        <FormGroup>
                            <StyledFormControl component='fieldset'>
                                <StyledFormLabel component='legend'>Which best describes your travel circumstances?</StyledFormLabel>
                                <RadioGroup
                                    aria-label='travelCircumstance'
                                    name='travelCircumstance'
                                    value={this.fieldValue('travelCircumstance')}
                                    onChange={this.proposeNew('travelCircumstance')}
                                >
                                    <FormControlLabel value='Arriving in Maine (within last 72 hours)' control={<Radio />} label='Arriving in Maine (within last 72 hours)' />
                                    <FormControlLabel value='Departing Maine (within next 72 hours)' control={<Radio />} label='Departing Maine (within next 72 hours)' />
                                </RadioGroup>
                            </StyledFormControl>
                            <StyledFormControl component='fieldset'>
                                <StyledFormLabel component='legend'>Which airport is associated with this travel?</StyledFormLabel>
                                <RadioGroup
                                    aria-label='airport'
                                    name='airport'
                                    value={this.fieldValue('airport')}
                                    onChange={this.proposeNew('airport')}
                                >
                                    <FormControlLabel value='Portland International Jetport (PWM)' control={<Radio />} label='Portland International Jetport (PWM)' />
                                    <FormControlLabel value='Bangor International Airport (BGR)' control={<Radio />} label='Bangor International Airport (BGR)' />
                                </RadioGroup>
                            </StyledFormControl>
                            <FormRow>
                                <TextField
                                    label='Flight Date'
                                    type='date'
                                    value={this.fieldValue('flightDate')}
                                    onChange={this.proposeNew('flightDate')}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </FormRow>
                            <FormRow>
                                <TextField
                                    label='Airline'
                                    value={this.fieldValue('airline')}
                                    onChange={this.proposeNew('airline')}
                                />
                            </FormRow>
                            <FormRow>
                                <TextField
                                    label='Flight Number'
                                    value={this.fieldValue('flightNumber')}
                                    onChange={this.proposeNew('flightNumber')}
                                />
                            </FormRow>
                            <FormRow>
                                <TextField
                                    label='Seat Number'
                                    value={this.fieldValue('seatNumber')}
                                    onChange={this.proposeNew('seatNumber')}
                                />
                            </FormRow>
                        </FormGroup>
                    </StyledFormControl>

                    {!this.props.onConfirm &&
                        <Button
                            type='submit'
                            color='primary'
                            variant='contained'
                            disabled={this.shouldDisableSubmit()}
                        >
                            Continue
                        </Button>}

                    {this.props.onConfirm &&
                        <FormGroup>
                            <FormRow>
                                <Button
                                    type='submit'
                                    color='primary'
                                    variant='contained'
                                    disabled={this.shouldDisableSubmit()}
                                    style={{ marginLeft: 0 }}
                                >
                                    Save Record
                                </Button>
                                <Button
                                    download
                                    component='a'
                                    href={`${API_BASE_URL}/covid/form/${appointment.eventId}/${patient.id}`}
                                    variant='contained'
                                >
                                    Download PDF
                                </Button>
                                <Button
                                    component={NavLink}
                                    to={`/covid-label/${appointment.id}`}
                                    target='_blank'
                                    variant='contained'
                                >
                                    Print Label
                                </Button>
                            </FormRow>
                        </FormGroup>}
                </FieldsWrapperForm>
            </PortletBodyContainer>
        );
    }
};

internals.PortletBodyContainer = Styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

internals.StyledDivider = Styled(Divider)`
    margin: ${({ theme }) => theme.spacing(2)}px 0;
`;

internals.FieldsWrapperForm = Styled.form`
    display: flex;
    flex-direction: column;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

internals.StyledFormControl = Styled(FormControl)`
    padding-bottom: ${({ theme }) => theme.spacing(2)}px;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    border-bottom: 1px solid #ddd;
`;

internals.StyledFormLabel = Styled(FormLabel)`
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
    font-weight: bold;
`;

internals.StyledFormGroup = Styled(FormGroup)`
    margin: ${({ theme }) => theme.spacing(2)}px 0;

    &:last-child {
        margin-bottom: 0;
    }
`;

internals.FormRow = Styled.div`
    display: flex;
    flex-direction: row;

    > div {
        display: flex;
        flex-direction: row;
    }

    div:last-child {
        flex-grow: 1;
    }
`;

internals.errorProps = (errors, field) => {

    return {
        error: errors.hasOwnProperty(field),
        helperText: errors.hasOwnProperty(field) ? errors[field] : ''
    };
};
