const React = require('react');
const T = require('prop-types');
const { v4: UUID } = require('uuid');
const { default: Styled } = require('styled-components');
const { default: Button } = require('@material-ui/core/Button');
const Types = require('./CustomFormFields');

const internals = {};

module.exports = class RegistrationFormPage extends React.Component {

    static propTypes = {
        formId: T.string,
        fetchForm: T.func.isRequired,
        fetchFormSubmission: T.func,
        submitFormSubmission: T.func.isRequired,
        program: T.object,
        patient: T.object
    };

    constructor(props) {

        super(props);

        this.state = {
            isSubmitting: false,
            form: null,
            formSubmissionId: null,
            values: {},
            errors: null
        };
    }

    async componentDidMount() {

        const { formId, patient, program, fetchForm, fetchFormSubmission } = this.props;

        const [, form] = await fetchForm(formId || program.intakeFormId);

        if (!form) {
            return;
        }

        const stagedForm = {
            ...form,
            fields: form.fields.map((field) => {

                field.id = UUID();
                return field;
            })
        };

        if (fetchFormSubmission) {
            const [, { id, values = {} } = {}] = await fetchFormSubmission({ formId: form.id, patientId: patient.id, programId: program.id });

            this.setState({ formSubmissionId: id, values });
        }

        this.setState({ form: stagedForm });
    }

    handleUpdateFormValues(field, patch) {

        this.setState({
            values: {
                ...this.state.values,
                [field.name]: patch
            }
        });
    }

    validate(form, values) {

        return form.fields.filter((field) => {

            return field.settings && field.settings.isRequired && !values[field.name];
        }).reduce((acc, field) => {

            return {
                ...acc,
                [field.name]: 'This field is required'
            };
        }, {});
    }

    async handleSubmit(e) {

        e.preventDefault();

        const { form, values } = this.state;

        const invalid = this.validate(form, values);

        if (Object.keys(invalid).length) {
            this.setState({ errors: invalid });
            return;
        }

        const { patient, program, submitFormSubmission } = this.props;

        const submission = {
            programId: program.id,
            formId: form.id,
            patientId: patient.id,
            values
        };

        if (this.state.formSubmissionId) {
            submission.id = this.state.formSubmissionId;
        }

        await submitFormSubmission(submission);
    }

    render() {

        const {
            FieldsWrapperForm,
            ComponentWrapper,
            SubmitButton
        } = internals;

        const { form, values, errors } = this.state;

        if (!form) {
            return null;
        }

        return (
            <FieldsWrapperForm onSubmit={(e) => this.handleSubmit(e)}>
                {form.fields.map((field) => {

                    if (field.type in Types) {
                        const Component = Types[field.type].component;
                        return (
                            <ComponentWrapper
                                key={field.id}
                                className={`${field.type} ${field.settings && `w${field.settings.width || 100}`}`}
                            >
                                <Component
                                    field={field}
                                    value={values && values[field.name]}
                                    onChange={(patch) => this.handleUpdateFormValues(field, patch)}
                                    error={errors && errors[field.name]}
                                />
                            </ComponentWrapper>
                        );
                    }
                })}
                <SubmitButton
                    fullWidth
                    variant='contained'
                    color='primary'
                    type='submit'
                >
                    Submit
                </SubmitButton>
            </FieldsWrapperForm>
        );
    }
};

internals.FieldsWrapperForm = Styled.form`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 ${({ theme }) => theme.spacing(-1)}px ${({ theme }) => theme.spacing(2)}px;
`;

internals.ComponentWrapper = Styled.div`
    width: 100%;
    margin: ${({ theme }) => theme.spacing(1)}px;

    &.w100 {
        width: 100%;
    }

    &.w50 {
        width: calc(50% - ${({ theme }) => theme.spacing(2)}px);
    }

    &.w33 {
        width: calc(33.333% - ${({ theme }) => theme.spacing(2)}px);
    }

    &.w25 {
        width: calc(25% - ${({ theme }) => theme.spacing(2)}px);
    }
`;

internals.SubmitButton = Styled(Button)`
    margin-top: ${({ theme }) => theme.spacing(4)}px;
`;
