const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: TextField } = require('@material-ui/core/TextField');
const { default: Typography } = require('@material-ui/core/Typography');
const { default: Button } = require('@material-ui/core/Button');
const { default: Divider } = require('@material-ui/core/Divider');
const StrangeForms = require('strange-forms');
const Portlet = require('../../../components/Portlet');
const SectionLabel = require('../../../components/SectionLabel');
const EventPicker = require('../../../components/EventPicker');
const ConsentDialog = require('../../../components/ConsentDialog');
const AlertDialog = require('../../../components/AlertDialog');
const AppointmentCard = require('../../../components/AppointmentCard');
const SortEvents = require('../../../utils/sort-events');

const { MANAGER_URL_PART } = require('../../../constants');

const internals = {};

module.exports = class PatientSchedulePage extends StrangeForms(React.Component) {

    static propTypes = {
        events: T.array,
        program: T.object,
        patient: T.object,
        fetchProgram: T.func,
        searchPatients: T.func,
        history: T.object
    };

    static fields = {
        email: '',
        phone: '',
        appointmentInfo: {
            eventId: null,
            appointmentId: null
        }
    };

    constructor(props) {

        super(props);

        this.state = {
            isSubmitting: false
        };

        this.strangeForm({
            fields: Object.keys(PatientSchedulePage.fields),
            get: {
                phone: () => null,
                appointmentInfo: ({ patient }) => {

                    let mostRecentAppointment = null;

                    if (patient?.appointments) {
                        mostRecentAppointment = internals.getMostRecentAppointmentId(patient.appointments);
                    }

                    return {
                        eventId: mostRecentAppointment ? mostRecentAppointment.eventId : null,
                        appointmentId: mostRecentAppointment || null
                    };
                },
                '*': (someProps, field) => someProps && someProps.patient && someProps.patient[field] || ''
            },
            act: {
                '*': () => null
            },
            getFormValue: {
                '*': (ev) => ev.target.value || '',
                appointmentInfo: (val) => val
            }
        });
    }

    componentDidMount() {

        this.props.fetchData();

        const { location: { pathname } } = this.props.history;

        // Strip leading slash if it exists
        this.isManagerActing = pathname.replace(/^\//, '').startsWith(MANAGER_URL_PART);
    }

    handleSubmit = async ({ consentText }) => {

        const email = this.fieldValue('email');
        const phone = this.fieldValue('phone');

        // Just showing what we're sending up
        const {
            eventId,
            appointmentId
        } = this.fieldValue('appointmentInfo');

        this.setState({ isSubmitting: true });

        await this.props.submitAppointment({
            email,
            phone,
            eventId,
            appointmentId,
            consentText
        });

        this.setState({ isSubmitting: false });
    }

    handleReschedule = async (originalAppointmentId) => {

        const { appointmentId, eventId } = this.fieldValue('appointmentInfo');
        const email = this.fieldValue('email');
        const phone = this.fieldValue('phone');

        this.setState({ isSubmitting: true });

        await this.props.rescheduleAppointment({
            originalAppointmentId,
            newAppointmentId: appointmentId,
            newEventId: eventId,
            phone,
            email
        });

        this.setState({ isSubmitting: false });
    }

    shouldDisableSubmit() {

        const {
            eventId,
            appointmentId
        } = this.fieldValue('appointmentInfo');

        if (!this.isManagerActing && !appointmentId) {
            return true;
        }

        return (
            !eventId ||
            !this.fieldValue('email') ||
            this.state.isSubmitting
        );
    }

    handleFormSubmit = (ev) => {

        ev.preventDefault();

        // If an appointment is on file,
        // Trigger the reschedule action
        // Otherwise show the consent modal
        let appointmentId;

        if (!!this.props.patient?.appointments?.length) {
            appointmentId = internals.getMostRecentAppointmentId(this.props.patient.appointments);
        }

        if (appointmentId) {
            this.handleReschedule(appointmentId);
            return;
        }

        this.handleShowModal('consent');
    }

    handleShowModal = (dialogName) => {

        this.setState({
            dialogName
        });
    }

    handleCancel = async () => {

        const appointmentId = internals.getMostRecentAppointmentId(this.props.patient.appointments);

        this.setState({ isSubmitting: true });

        await this.props.cancelAppointment({
            id: appointmentId
        });

        this.setState({ isSubmitting: false });
    }

    closeDialog = () => {

        this.setState({ dialogName: null });
    };

    render() {

        const {
            PageContainer,
            FieldsWrapper,
            StyledPortlet,
            PortletBodyContainer,
            CancelButton,
            StyledEventPicker,
            PortalCopy,
            StyledDivider,
            ColumnContainer,
            SubmitButton,
            ErrorMessage,
            isToday
        } = internals;

        const { events, patient, program } = this.props;

        if (!program) {
            return null;
        }

        // Check to make sure there are available appointments too i.e there are some appointments
        // that do not have patientId's already assigned to them, or if the appointment is assigned to
        // the already logged in patient.
        const todaysAndFutureEventsWithAvailability = events.filter(
            (event) => {

                const isTodayOrFutureEvent = isToday(new Date(event.date)) || new Date(event.date) > new Date();

                return isTodayOrFutureEvent && event.appointments.some((appt) => {

                    return !appt.patientId || (patient && patient.id && appt.patientId === patient.id);
                });
            })
            // Sort events by date then name
            .sort(SortEvents);

        let mostRecentAppointmentId = null;

        if (patient?.appointments?.length) {
            mostRecentAppointmentId = internals.getMostRecentAppointmentId(patient.appointments);
        }

        const steps = program.isCovidTesting || program.intakeFormId ? '3 / 3' : '2 / 2';

        const appointmentInfo = this.fieldValue('appointmentInfo');

        return (
            <PageContainer>
                <StyledPortlet
                    body={
                        <PortletBodyContainer>
                            <SectionLabel>
                                SCHEDULE APPOINTMENT - STEP {steps}<br />
                                <em style={{ fontWeight: 'normal' }}>Use Chrome For Best Experience; Do Not Use Internet Explorer.</em>
                            </SectionLabel>
                            {program && program.portalSchedulingCopy && <PortalCopy dangerouslySetInnerHTML={{ __html: program.portalSchedulingCopy }} />}
                            <FieldsWrapper onSubmit={this.handleFormSubmit}>
                                <StyledEventPicker
                                    events={todaysAndFutureEventsWithAvailability}
                                    locationCode={patient && patient.locationCode}
                                    value={appointmentInfo}
                                    onChange={this.proposeNew('appointmentInfo')}
                                    hidePastEvents
                                    // If a user puts in their email address, but hasn't completed
                                    // their appointment selection above it, outline appointment field in red
                                    showAppointmentTimeError={Boolean(this.fieldValue('email') && !appointmentInfo.appointmentId)}
                                />
                                <StyledDivider />
                                <Typography>* Email address <strong>required</strong> for confirmation and updates. An email reminder will be sent to the email address you provide.</Typography>
                                <TextField
                                    label='Email'
                                    type='email'
                                    value={this.fieldValue('email')}
                                    onChange={this.proposeNew('email')}
                                    fullWidth
                                />
                                <StyledDivider />
                                <Typography gutterBottom>If you would also like to receive a text message from the program, please provide your mobile number below (including area code — 555-555-5555).</Typography>
                                <Typography gutterBottom variant='body2'><strong>You will receive the following texts when applicable:</strong></Typography>
                                <Typography gutterBottom variant='body2'>Appointment Confirmation, Appointment Change Confirmation, Appointment Cancellation Confirmation, Appointment Reminder, and Event-Specific Communications.</Typography>
                                <Typography gutterBottom variant='body2'>At any time you can reply STOP to the text messages to unsubscribe, or HELP to receive further information.</Typography>
                                <TextField
                                    label='Mobile'
                                    type='tel'
                                    value={this.fieldValue('phone')}
                                    onChange={this.proposeNew('phone')}
                                    margin='dense'
                                />
                                <StyledDivider />
                                {!appointmentInfo.appointmentId && <ErrorMessage>Choose an appointment time to continue</ErrorMessage>}
                                {!this.fieldValue('email') && <ErrorMessage>Email address is required</ErrorMessage>}
                                <SubmitButton
                                    type='submit'
                                    color='primary'
                                    variant='contained'
                                    fullWidth
                                    disabled={this.shouldDisableSubmit()}
                                >
                                    {!!mostRecentAppointmentId ? 'Reschedule Appointment' : 'Confirm Appointment'}
                                </SubmitButton>
                            </FieldsWrapper>
                        </PortletBodyContainer>
                    }
                />
                <ColumnContainer>
                    <StyledPortlet
                        body={
                            program &&
                            program.portalInformationalCopy &&
                            <PortalCopy dangerouslySetInnerHTML={{ __html: program.portalInformationalCopy }} />
                        }
                    />
                    {!!mostRecentAppointmentId && (
                        <StyledPortlet
                            body={
                                <>
                                    <SectionLabel align='center'>Scheduled Appointment</SectionLabel>
                                    <AppointmentCard
                                        event={patient.appointments.find(({ id }) => id === mostRecentAppointmentId).event}
                                        appointment={patient.appointments.find(({ id }) => id === mostRecentAppointmentId)}
                                    />
                                    <CancelButton
                                        onClick={() => this.handleShowModal('cancel')}
                                    >
                                        Cancel Appointment
                                    </CancelButton>
                                </>
                            }
                        />
                    )}
                </ColumnContainer>
                {program && (
                    <ConsentDialog
                        affirmativeLabel='Submit'
                        negativeLabel={null}
                        isModalOpen={this.state.dialogName === 'consent'}
                        onConfirmAction={this.handleSubmit}
                        toggleModal={this.closeDialog}
                        consentFormUrl={program.consentFormUrl}
                    />
                )}
                <AlertDialog
                    dialogTitle='Cancel appointment?'
                    dialogDescription='This will cancel your appointment without rescheduling.'
                    affirmativeLabel='Cancel Appointment'
                    negativeLabel='Keep Appointment'
                    isModalOpen={this.state.dialogName === 'cancel'}
                    // eslint-disable-next-line
                    confirmAction={this.handleCancel}
                    toggleModal={this.closeDialog}
                />
            </PageContainer>
        );
    }
};

internals.PortalCopy = Styled.div`
    p {
        line-height: 1.5;
    }
`;

internals.PortletBodyContainer = Styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

internals.PageContainer = Styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: ${({ theme }) => theme.spacing(150)}px;
    margin: 0 auto;
`;

internals.StyledPortlet = Styled(Portlet)`
    flex: 1 250px;
    margin: ${({ theme }) => theme.spacing(1)}px;
`;

internals.ColumnContainer = Styled.div`
    flex: 1 250px;
    margin: ${({ theme }) => theme.spacing(1)}px;

    > div {
        margin: 0 0 ${({ theme }) => theme.spacing(2)}px;
    }
`;

internals.FieldsWrapper = Styled.form`
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

internals.ErrorMessage = Styled(Typography).attrs({ variant: 'caption' })`
    color: ${({ theme }) => theme.palette.error.main};
    display: block;
`;

internals.getMostRecentAppointmentId = (appointments) => {

    // First get the event id of the most-recent appointment
    const sortedEvents = !!appointments.length ? appointments.map(({ event }) => event).sort((a, b) => b.date - a.date) : [];

    // Then find() the appointment with the same eventId
    const mostRecentAppointment = appointments.find(({ eventId }) => eventId === sortedEvents[0].id) || {};

    if (!mostRecentAppointment || !mostRecentAppointment.event) {
        return null;
    }

    const currentYear = new Date().getFullYear();
    const appointmentYear = new Date(mostRecentAppointment.event.date).getFullYear();

    // If the closest appointment is in the previous calendar year the
    // patient is eligible for a new appointment, so don't reschedule
    return appointmentYear >= currentYear ? mostRecentAppointment.id : null;
};

internals.SubmitButton = Styled(Button)`
    margin-left: 0;
    margin-right: 0;
`;

internals.CancelButton = Styled(Button).attrs({ fullWidth: true })`
    margin: ${({ theme }) => theme.spacing(2)}px 0 0;
    background: ${({ theme }) => theme.palette.error.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
`;

internals.StyledEventPicker = Styled(EventPicker)`
    margin: ${({ theme }) => theme.spacing(1)}px 0;
`;

internals.StyledDivider = Styled(Divider)`
    margin: ${({ theme }) => theme.spacing(2)}px 0;
`;

internals.isToday = (someDate) => {

    const today = new Date();
    return someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear();
};
